import { FETCH_DEBT_DATA, CLEAR_DEBTS, CREATE_DEBT, DELETE_DEBT, SET_DEBT, UPDATE_DEBT } from "../actions/types";

const initialState = {
  debt: null, // Single base-case debt object
  debtSchedule: [], // The calculated debt repayment schedule from the component
  noi: 129527,
  purchaseCost: 2072000,
  amortization: 10,
  dscr: 2.0,
  cashSweep: 0.5,
  interestRate: 6,
  maximumDebt: 0,
};

const debtReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEBT_DATA:
      return {
        ...initialState, // Ensure all variables are initialized
        ...action.payload,
      };
    case CREATE_DEBT:
    case SET_DEBT:
      return {
        ...state,
        debt: action.payload,
      };

    case UPDATE_DEBT:
      return {
        ...state,
        ...action.payload, // Merge updated values
      };

    case DELETE_DEBT:
      return initialState;

    case CLEAR_DEBTS:
      return initialState;

    default:
      return state;
  }
};

export default debtReducer;
