import React, { useRef, useState, useEffect } from "react";

import { Button } from "../../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import Market from "../../../assets/landingpage/Market.svg";
import List from "../../../assets/landingpage/List.svg";
import Agreement from "../../../assets/landingpage/Agreement.svg";
import Price from "../../../assets/landingpage/Price.svg";
import Upload from "../../../assets/landingpage/Upload.svg";
import Connect from "../../../assets/landingpage/Connect.svg";
import Arrow1 from "../../../assets/landingpage/Arrow-1.svg";
import Arrow2 from "../../../assets/landingpage/Arrow-2.svg";
import styled from "styled-components";

const ImageEl = ({ Icon1, Icon2, name, top = 183, left = 80, translate = -50 }) => {
  const StyledDiv = styled.div`
    position: relative;
    display: inline-block;
    z-index: 0;

    &::after {
      content: ""; /* Add text to confirm rendering */
      position: absolute;
      top: ${top}%; /* Center it vertically */
      left: ${left}%; /* Place it to the right of the parent */
      transform: translateY(${translate}%); /* Adjust for vertical centering */
      width: 13rem; /* Adjust width */
      height: 12rem; /* Adjust height */

      background-image: url(${Icon2}); /* Ensure this resolves */
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 2; /* Ensure it's above other elements */
    }
  `;
  return (
    <StyledDiv className="flex items-center z-10 relative min-h-[50%] min-w-[30%]">
      <img src={Icon1} alt={name} className=" min-[96px]:max-h-28 min-[96px]:w-auto ml-auto z-22" />
    </StyledDiv>
  );
};

const Feature102 = React.forwardRef(({ sellers, buyers }, ref) => {
  const [isScrollAreaEnabled, setScrollAreaEnabled] = useState(false);

  const handleResize = () => {
    // Check if the screen width matches the "laptop" size
    const isLaptop = window.matchMedia("(min-width: 768px)").matches;
    setScrollAreaEnabled(isLaptop);
  };

  useEffect(() => {
    // Run on component mount
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const col = buyers && sellers ? 2 : 1;
  return (
    <section ref={ref} id="Features" className="py-16 px-6">
      <div className="w-full">
        <div className="mx-auto flex max-w-screen-md flex-col justify-center gap-7 md:text-center">
          <h2 className="text-2xl md:text-4xl">Achieve the Best Outcome for Your Business</h2>
          <p className="text-sm text-muted-foreground md:text-base">We simplify the process of buying or selling a business, helping you choose the right opportunity tailored to your needs</p>
        </div>
        <div className="mx-auto mt-14 flex max-w-screen-lg flex-col gap-4 lg:px-16">
          <Tabs defaultValue={buyers ? "buy" : "sell"} className="w-full">
            <TabsList className={`grid w-full grid-cols-${col}`}>
              {buyers && <TabsTrigger value="buy">Buyers</TabsTrigger>}
              {sellers && <TabsTrigger value="sell">Sellers</TabsTrigger>}
            </TabsList>
            <TabsContent value="buy">
              <div className="flex flex-col items-center justify-between min-[960px]:flex-row min-[960px]:gap-10">
                <div className="flex gap-4 min-[960px]:max-w-md">
                  <div className="flex flex-col items-center justify-between gap-1">
                    <span className="h-20 shrink-0"></span>
                    <span className="flex size-10 shrink-0 items-center justify-center rounded-full border bg-muted/50 font-mono text-lg cursor-pointer hover:bg-secondary">1</span>
                    <span className="h-20 w-[3px] shrink-0 bg-gradient-to-b from-transparent to-primary opacity-70"></span>
                  </div>
                  <div className="flex flex-col justify-center gap-5 px-0 min-[960px]:gap-6 min-[960px]:p-4">
                    <h3 className="text-xl min-[960px]:text-2xl">Market Mapping</h3>
                    <p className="text-sm text-muted-foreground min-[960px]:text-base">We provide you with a detailed breakdown and opportunity scoring of your investable universe</p>
                  </div>
                </div>
                {isScrollAreaEnabled && <ImageEl Icon1={Market} Icon2={Arrow1} name={"Market"} left={70} top={175} />}
              </div>
              <div className="flex flex-col items-center justify-between min-[960px]:flex-row min-[960px]:gap-10">
                <div className="flex gap-4 min-[960px]:max-w-md">
                  <div className="relative flex flex-col items-center justify-between gap-1">
                    <span className="absolute -top-8 mx-auto h-8 w-[3px] shrink-0 bg-primary opacity-70"></span>
                    <span className="absolute -bottom-8 mx-auto h-8 w-[3px] shrink-0 bg-primary opacity-70"></span>
                    <span className="h-20 w-[3px] shrink-0 bg-primary opacity-70"></span>
                    <span className="flex size-10 shrink-0 items-center justify-center rounded-full border bg-muted/50 font-mono text-lg cursor-pointer hover:bg-secondary">2</span>
                    <span className="h-20 w-[3px] shrink-0 bg-primary opacity-70"></span>
                  </div>
                  <div className="flex flex-col justify-center gap-5 px-0 min-[960px]:gap-6 min-[960px]:p-4">
                    <h3 className="text-xl min-[960px]:text-2xl">Build and Refine Your Shortlist</h3>

                    <p className="text-sm text-muted-foreground min-[960px]:text-base">We price and audit opportunities and provide you with a cashflow forecast for each opportunity</p>
                  </div>
                </div>

                {isScrollAreaEnabled && <ImageEl Icon1={List} Icon2={Arrow2} name={"List"} left={6} />}
              </div>
              <div className="flex flex-col items-center justify-between min-[960px]:flex-row min-[960px]:gap-10">
                <div className="flex gap-4 min-[960px]:max-w-md">
                  <div className="flex flex-col items-center justify-between gap-1">
                    <span className="h-20 w-[3px] shrink-0 bg-gradient-to-t from-transparent to-primary opacity-70"></span>
                    <span className="flex size-10 shrink-0 items-center justify-center rounded-full border bg-muted/50 font-mono text-lg cursor-pointer hover:bg-secondary">3</span>
                    <span className="h-20 shrink-0"></span>
                  </div>
                  <div className="flex flex-col justify-center gap-5 px-0 min-[960px]:gap-6 min-[960px]:p-4">
                    <h3 className="text-xl min-[960px]:text-2xl">Connect with Advisors</h3>

                    <p className="text-sm text-muted-foreground min-[960px]:text-base">We connect you with advisors to close your trasaction</p>
                  </div>
                </div>
                {isScrollAreaEnabled && <img src={Agreement} alt="Agreement" className="z-10  min-[96px]:max-h-28 min-[96px]:w-auto" />}
              </div>
            </TabsContent>
            <TabsContent value="sell">
              <div className="flex flex-col items-center justify-between min-[960px]:flex-row min-[960px]:gap-10">
                <div className="flex gap-4 min-[960px]:max-w-md">
                  <div className="flex flex-col items-center justify-between gap-1">
                    <span className="h-20 shrink-0"></span>
                    <span className="flex size-10 shrink-0 items-center justify-center rounded-full border bg-muted/50 font-mono text-lg cursor-pointer hover:bg-secondary">1</span>
                    <span className="h-20 w-[3px] shrink-0 bg-gradient-to-b from-transparent to-primary opacity-70"></span>
                  </div>
                  <div className="flex flex-col justify-center gap-5 px-0 min-[960px]:gap-6 min-[960px]:p-4">
                    <h3 className="text-xl min-[960px]:text-2xl">Get a Pricing Estimate</h3>
                    <p className="text-sm text-muted-foreground min-[960px]:text-base">With just a few clicks, receive a range of pricing estimates for your business</p>
                  </div>
                </div>
                {isScrollAreaEnabled && <ImageEl Icon1={Price} Icon2={Arrow1} name={"Price"} left={70} top={175} />}
              </div>
              <div className="flex flex-col items-center justify-between min-[960px]:flex-row min-[960px]:gap-10">
                <div className="flex gap-4 min-[960px]:max-w-md">
                  <div className="relative flex flex-col items-center justify-between gap-1">
                    <span className="absolute -top-8 mx-auto h-8 w-[3px] shrink-0 bg-primary opacity-70"></span>
                    <span className="absolute -bottom-8 mx-auto h-8 w-[3px] shrink-0 bg-primary opacity-70"></span>
                    <span className="h-20 w-[3px] shrink-0 bg-primary opacity-70"></span>
                    <span className="flex size-10 shrink-0 items-center justify-center rounded-full border bg-muted/50 font-mono text-lg cursor-pointer hover:bg-secondary">2</span>
                    <span className="h-20 w-[3px] shrink-0 bg-primary opacity-70"></span>
                  </div>
                  <div className="flex flex-col justify-center gap-5 px-0 min-[960px]:gap-6 min-[960px]:p-4">
                    <h3 className="text-xl min-[960px]:text-2xl">Upload your business documentation</h3>

                    <p className="text-sm text-muted-foreground min-[960px]:text-base">We analyze your business and produce sales documentation and a transaction dashboard</p>
                  </div>
                </div>
                {isScrollAreaEnabled && <ImageEl Icon1={Upload} Icon2={Arrow2} name={"Upload"} left={6} />}
              </div>
              <div className="flex flex-col items-center justify-between min-[960px]:flex-row min-[960px]:gap-10">
                <div className="flex gap-4 min-[960px]:max-w-md">
                  <div className="flex flex-col items-center justify-between gap-1">
                    <span className="h-20 w-[3px] shrink-0 bg-gradient-to-t from-transparent to-primary opacity-70"></span>
                    <span className="flex size-10 shrink-0 items-center justify-center rounded-full border bg-muted/50 font-mono text-lg cursor-pointer hover:bg-secondary">3</span>
                    <span className="h-20 shrink-0"></span>
                  </div>
                  <div className="flex flex-col justify-center gap-5 px-0 min-[960px]:gap-6 min-[960px]:p-4">
                    <h3 className="text-xl min-[960px]:text-2xl">Connect with Brokers and Buyers</h3>

                    <p className="text-sm text-muted-foreground min-[960px]:text-base">Access our network of trusted brokers and buyers to ensure you achieve the best deal for your business</p>
                  </div>
                </div>
                {isScrollAreaEnabled && <img src={Connect} alt="Connect" className="z-10  min-[96px]:max-h-28 min-[96px]:w-auto" />}
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </section>
  );
});

export default Feature102;
