import { ArrowDown, ArrowRight, ArrowUp, CheckCircle, Circle, CircleOff, HelpCircle, Timer } from "lucide-react";

export const labels = [
  {
    value: "documentation",
    label: "Documentation",
  },
];

export const statuses = [
  {
    value: "uploading",
    label: "Uploading",
    icon: Circle,
  },
  {
    value: "uploaded",
    label: "Uploaded",
    icon: HelpCircle,
  },
  {
    value: "pending",
    label: "Pending Upload",
    icon: Timer,
  },
  {
    value: "validated",
    label: "Validated",
    icon: CheckCircle,
  },
  {
    value: "not-applicable",
    label: "N/A",
    icon: CircleOff,
  },
];

export const priorities = [
  {
    label: "Low",
    value: "low",
    icon: ArrowDown,
  },
  {
    label: "Medium",
    value: "medium",
    icon: ArrowRight,
  },
  {
    label: "High",
    value: "high",
    icon: ArrowUp,
  },
];
