import {
  ADD_FINANCIAL_STATEMENT,
  UPDATE_FINANCIAL_STATEMENT,
  FETCH_FINANCIAL_STATEMENTS,
  DELETE_FINANCIAL_STATEMENT,
  CLEAR_FINANCIAL_STATEMENTS,
  SET_FINANCIAL_STATEMENTS_LOADING,
  // SET_FINANCIAL_STATEMENT_TYPE, // New action type
} from "../actions/types";

const initialState = {
  activeFinancialStatement: null, // Single active financial statement
  statementType: null, // Type of financial statement (e.g., "Balance Sheet" or "Income Statement")
  loading: false, // Track loading state
};

const financialStatementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FINANCIAL_STATEMENTS_LOADING:
      return {
        ...state,
        loading: action.payload, // Set loading state
      };

    case FETCH_FINANCIAL_STATEMENTS:
      return {
        ...state,
        activeFinancialStatement: action.payload.activeFinancialStatement, // Set the fetched statement as active
        statementType: action.payload.statementType || state.statementType, // Set the type if provided

        loading: false, // Set loading to false after fetching
      };

    case ADD_FINANCIAL_STATEMENT:
      return {
        ...state,
        activeFinancialStatement: action.payload, // Set the added statement as active
        statementType: action.payload.type || state.statementType, // Set the type if provided
        loading: false, // Set loading to false after adding
      };

    case UPDATE_FINANCIAL_STATEMENT:
      // Update only if the active statement matches the updated one
      if (state.activeFinancialStatement?.id === action.payload.id) {
        return {
          ...state,
          activeFinancialStatement: action.payload,
          statementType: action.payload.type || state.statementType, // Update the type if provided
          loading: false, // Set loading to false after updating
        };
      }
      return state;

    case DELETE_FINANCIAL_STATEMENT:
      // Clear the active statement if it's the one being deleted
      if (state.activeFinancialStatement?.id === action.payload) {
        return {
          ...state,
          activeFinancialStatement: null,
          statementType: null, // Clear the type
          loading: false, // Set loading to false after deleting
        };
      }
      return state;

    case CLEAR_FINANCIAL_STATEMENTS:
      return {
        ...state,
        activeFinancialStatement: null, // Clear the active statement
        statementType: null, // Clear the type
        loading: false, // Set loading to false after clearing
      };

    default:
      return state;
  }
};

export default financialStatementsReducer;
