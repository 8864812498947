"use client";

import { BadgeCheck, Bell, ChevronsUpDown, CreditCard, LogOut, Sparkles, Send, Globe } from "lucide-react";

import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from "../ui/sidebar";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../redux/store";
import { setLanguage } from "../../redux/actions/authAction";
interface User {
  name: string;
  email: string;
  avatar: string;
}

interface NavUserProps {
  user: User;
  signout: () => void;
}

export function NavUser({ user, signout }: NavUserProps) {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const { isMobile } = useSidebar();
  const { i18n } = useTranslation();
  const { t } = useTranslation("translation");

  // const [language, setLanguage] = useState(i18n.language);
  const language = useSelector((state: any) => state.auth.language); // Get language from Redux

  // Function to toggle between English and German
  const toggleLanguage = () => {
    const newLang = language === "en" ? "de" : "en";

    i18n.changeLanguage(newLang); // ✅ Update i18n
    dispatch(setLanguage(newLang)); // ✅ Dispatch Redux action
  };
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton size="lg" className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground">
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarImage src={user.avatar} alt={user.name} />
                <AvatarFallback className="rounded-lg">{user.name[0]}</AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{user.name}</span>
                <span className="truncate text-xs">{user.email}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="cursor-pointer w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg" side={isMobile ? "bottom" : "right"} align="end" sideOffset={4}>
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarImage src={user.avatar} alt={user.name} />
                  <AvatarFallback className="rounded-lg">{user.name[0]}</AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{user.name}</span>
                  <span className="truncate text-xs">{user.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {/* <DropdownMenuGroup>
              <DropdownMenuItem>
                <Send />
                Feedback
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator /> */}
            <DropdownMenuGroup>
              {/* Language Toggle */}
              <DropdownMenuItem className="cursor-pointer" onClick={toggleLanguage}>
                <Globe />
                {/* @ts-ignore */}
                {t(language === "en" ? "nav.switch_to_german" : "nav.switch_to_english")}
              </DropdownMenuItem>
            </DropdownMenuGroup>
            {/* <DropdownMenuSeparator /> */}
            <DropdownMenuItem className="cursor-pointer" onClick={signout}>
              <LogOut />
              {/* @ts-ignore */}
              {t("nav.logout")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
