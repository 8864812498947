// src/store/reducers/expansionReducer.js
import { FETCH_EXPANSION_DATA, SET_EXPANSION_DATA, UPDATE_EXPANSION_DATA, CLEAR_EXPANSION_DATA } from "../actions/types";

const initialState = {
  investmentYears: 1,
  capitalInvestment: 1500000,
  revenueRampUp: 1,
  costRampUp: 1,
  currentRevenue: 1000000,
  currentCost: 500000,
  depreciationYears: 10,
  workingCapital: 10,
  taxRate: 25,
  lifeOfInvestment: 100,
  rampUpYears: 10,
  cashflow: [],
  unleveredIRR: 0,
  payback: 0,
  yearsToMaxValue: null,
  margin: 50,
};

const expansionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXPANSION_DATA:
      return {
        ...initialState, // Ensure all variables are initialized
        ...action.payload,
      };
    case SET_EXPANSION_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_EXPANSION_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case CLEAR_EXPANSION_DATA:
      return initialState;

    default:
      return state;
  }
};

export default expansionReducer;
