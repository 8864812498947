import { Copy } from "lucide-react";

import { Button } from "../../../components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import ValForm from "./ValForm";
import React, { useState } from "react";

export default function DialogCloseButton({ headertitle, headerdescription, triggercomp, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {/* Trigger Component */}
      <DialogTrigger asChild>{triggercomp}</DialogTrigger>

      {/* Dialog Content */}
      <DialogContent className="sm:max-w-md max-h-[90%] min-w-[40%] overflow-auto">
        <DialogHeader>
          <DialogTitle>{headertitle}</DialogTitle>
          <DialogDescription>{headerdescription}</DialogDescription>
        </DialogHeader>

        {/* Dialog Body */}

        {/* Dialog Footer */}
        {/* Pass handleClose as a prop to children if it is a React component */}
        {React.isValidElement(children) ? React.cloneElement(children, { handleClose }) : children}
      </DialogContent>
    </Dialog>
  );
}
