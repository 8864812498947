"use client";

import * as React from "react";
import { BookOpen, Bot, Command, Frame, LifeBuoy, Map, PieChart, Send, Settings2, SquareTerminal, House, FileUp, Tag, ScanSearch, PiggyBank, ListChecks, MapPinHouse, ArrowBigUpDash, TrendingUpDown, Gauge, Search, Filter, Hospital, MessageCircleQuestion } from "lucide-react";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { NavMain } from "./nav-main";
import { NavProjects } from "./nav-projects";
import { NavSecondary } from "./nav-secondary";
import { NavUser } from "./nav-user";
import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "../ui/sidebar";
// import logoImage2 from "../../assets/logo/logo.svg";
import { TeamSwitcher } from "./team-switcher";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "../../redux/store";
import { useDispatch } from "react-redux";
import { firebaseSignOutAction, microsoftSignin } from "../../redux/actions/authAction";
import { toast } from "../../hooks/use-toast";
import { toast as othertoast } from "sonner";
import { ScrollArea } from "../ui/scroll";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../router/NotificationsProvider";
import { db } from "../../firebase";
import { and, collection, or, query, where } from "firebase/firestore";
import { clearTransactions, fetchTransactions, setTransaction } from "../../redux/actions/transactionAction";

export function AppSidebar({ logo, ...props }: React.ComponentProps<typeof Sidebar> & { logo?: string }) {
  const location = useLocation();
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const transactions = useSelector((state: any) => state.transactions.transactions);
  const { addQueryListener, observedData, removeListener } = useNotifications();

  const transactionId = transaction?.id;

  const [uploadStatus, setUploadStatus] = React.useState(transaction?.uploadStatus);
  const [uploadMarket, setUploadMarket] = React.useState(transaction?.regionalBusinessAnalysisCompleted);
  const state = store.getState();
  const email = state?.auth?.user?.email;
  const name = !email ? "" : email.split(".")[0][0].toUpperCase() + email.split(".")[0].slice(1);
  const { t } = useTranslation();

  const latestTransactionRef = React.useRef(transaction);
  React.useEffect(() => {
    latestTransactionRef.current = transaction;
  }, [transaction]);

  React.useEffect(() => {
    if (transaction) {
      setUploadStatus(transaction?.uploadStatus);
    }
  }, [transactionId]);
  const fn = async (updatedDeal) => {
    const tr = await dispatch(fetchTransactions());
    if (transaction.id === updatedDeal.id) {
      const newTransaction = tr.find((e) => e.id === latestTransactionRef.current?.id);
      if (!newTransaction) return;

      // Even if the data is the same, ensure you produce a new object
      dispatch(setTransaction({ ...newTransaction }));
    }
  };
  React.useEffect(() => {
    if (transactionId) {
      const key = `deals_${transaction.organisationId}`;
      // removeListener(key);

      console.log(`📡 Subscribing to uploadStatus for ${transactionId}`);
      const dealsRef = collection(db, "deals");
      const q = query(dealsRef, and(where("organisationId", "==", transaction.organisationId), or(where("uploadStatus", "==", "completed"), where("businessChecklistUploaded", "==", true), where("financialAuditUploaded", "==", true), where("regionalBusinessAnalysisCompleted", "==", true))));

      addQueryListener({
        key: key,
        query: q,
        onSnapshotCallback: (docs) => {
          // Each doc is { id, uploadStatus, ... } because we map doc.id below
          console.log("Deals updated:", docs);
          // const currentTransaction = latestTransactionRef.current;
          // console.log("currentTransaction", currentTransaction);
          console.log("transaction is this", transaction);

          // Find the one matching our current transactionId
          const updatedDeal = docs.find((d: any) => d.id === transaction.id);
          console.log("updatedDeal", updatedDeal);
          if (updatedDeal) {
            console.log(`🔄 Found updated deal: ${updatedDeal.id}, status: ${updatedDeal.uploadStatus}`);
            // Update local state if we find the matching deal
            setUploadStatus(updatedDeal.uploadStatus);
            setUploadMarket(updatedDeal.regionalBusinessAnalysisCompleted);
            fn(updatedDeal);
            console.log("executing the fn");
          }
        },
        toastConfig: (docs, snapshot) => {
          // 1. Get doc changes
          const changes = snapshot.docChanges();
          console.log("docChanges() length:", changes, changes.length);
          console.log("doc length:", docs, docs.length);
          // 2. Build an array of toasts
          const toasts = [];
          // const changes = snapshot.docChanges()
          changes.forEach((change) => {
            console.log("change", change);
            if (change.type === "modified") {
              const docData = { id: change.doc.id, ...change.doc.data() };
              const comp = transactions.find((e) => e.id === docData.id);
              console.log("docData:", docData);
              console.log("comp:", comp);

              // If docData.uploadStatus changed to "completed"
              // or docData.businessChecklistUploaded is newly true, etc.
              if (docData?.uploadStatus === "completed") {
                othertoast(
                  // @ts-ignore
                  t("toasts.analysis_completed.title", { name: docData.name }),
                  {
                    // @ts-ignore
                    description: t("toasts.analysis_completed.description", { name: docData.name }),
                  },
                );
              }
              if (docData?.businessChecklistUploaded) {
                othertoast(
                  // @ts-ignore
                  t("toasts.checklist_uploaded.title", { name: docData.name }),
                  {
                    // @ts-ignore
                    description: t("toasts.checklist_uploaded.description", { name: docData.name }),
                  },
                );
              }
              if (docData?.financialAuditUploaded) {
                othertoast(
                  // @ts-ignore
                  t("toasts.audit_available.title", { name: docData.name }),
                  {
                    // @ts-ignore
                    description: t("toasts.audit_available.description", { name: docData.name }),
                  },
                );
              }
              if (docData?.regionalBusinessAnalysisCompleted) {
                othertoast(
                  // @ts-ignore
                  t("toasts.market_analysis_completed.title", { name: docData.name }),
                  {
                    // @ts-ignore
                    description: t("toasts.market_analysis_completed.description", { name: docData.name }),
                  },
                );
              }
            }
          });

          return null; // Return an array of toast objects
        },
      });
    }
  }, [transactionId, addQueryListener]);

  // Update UI when uploadStatus changes. Need to check the whole transaction list to make sure we update the current one (if the change has occured in the current one)
  // const getDealsListener = React.useCallback(() => {
  //   const dataKey = `deals_${transaction.organisationId}`;
  //   return observedData[dataKey] ?? [];
  // }, [observedData]);
  // const allDealsForOrg = getDealsListener();
  // React.useEffect(() => {
  //   if (!getDealsListener !== undefined) {

  //   }
  //   console.log("All deals for org in component:", allDealsForOrg);
  // }, [allDealsForOrg]);

  // React.useEffect(() => {
  //   if (transactionId) {
  //     const key = `deals_${transaction.organisationId}`;

  //     if (observedData[key] !== undefined) {
  //       console.log(`🔄 UploadStatus changed: ${observedData[key]}`);
  //       setUploadStatus(observedData[key]); // ✅ Re-render Sidebar when uploadStatus updates
  //     }
  //   }
  // }, [observedData, transactionId]);

  const userData = {
    user: {
      name: name,
      email: email,
      avatar: "",
    },
  };
  const data = {
    teams: [
      {
        name: "Select Deal",
        logo: Hospital,
        plan: "Current Deal",
      },
    ],
    navMain: [
      {
        //@ts-ignore
        title: t("sidebar.seller_docs"),
        url: "#",
        icon: BookOpen,
        isActive: true && transaction,
        items: [
          {
            //@ts-ignore
            title: t("sidebar.upload_documents"),
            url: "/upload",
            icon: FileUp,
            isActive: true,
          },
          {
            //@ts-ignore
            title: t("sidebar.business_checklist"),
            url: "/checklist",
            icon: ListChecks,
            isActive: true,
          },
        ],
      },

      {
        //@ts-ignore

        title: t("sidebar.scenarios"),
        url: "#",
        icon: MessageCircleQuestion,
        isActive: uploadMarket && transaction,
        items: [
          {
            //@ts-ignore
            title: t("sidebar.debt_schedule"),
            url: "/debt",
            icon: PiggyBank,
            isActive: true,
          },
          {
            //@ts-ignore
            title: t("sidebar.expansion_plans"),
            url: "/capexplan",
            icon: ArrowBigUpDash,
            isActive: true,
          },
          {
            //@ts-ignore
            title: `${t("sidebar.market_analysis")}`,
            url: "/market",
            icon: MapPinHouse,
            isActive: transaction?.regionalBusinessAnalysisCompleted,
          },
        ],
      },
      {
        //@ts-ignore
        title: uploadStatus === "pending" ? t("sidebar.audit_pending") : t("sidebar.audit"),
        url: "#",
        icon: Search,
        isActive: uploadStatus !== "pending" && transaction,
        items: [
          {
            //@ts-ignore
            title: t("sidebar.financial_audit"),
            url: "/audit",
            icon: ScanSearch,
            isActive: uploadStatus !== "pending" && transaction,
          },
          {
            //@ts-ignore
            title: t("sidebar.cashflow_projection"),
            url: "/cashflow",
            icon: TrendingUpDown,
            isActive: uploadStatus !== "pending" && transaction,
          },
          {
            //@ts-ignore
            title: t("sidebar.pricing"),
            url: "/valuation",
            icon: Tag,
            isActive: uploadStatus !== "pending" && transaction,
          },
        ],
      },
    ],
    navSecondary: [
      {
        //@ts-ignore
        title: t("sidebar.support"),
        url: "#",
        icon: LifeBuoy,
      },
      {
        //@ts-ignore
        title: t("sidebar.feedback"),
        url: "#",
        icon: Send,
      },
    ],
    projects: [
      {
        //@ts-ignore
        name: t("sidebar.pipeline"),
        url: "/pipeline",
        icon: Filter,
        isActive: true,
      },
      {
        //@ts-ignore
        name: t("sidebar.overview"),
        url: "/overview",
        icon: House,
        isActive: transaction,
      },
    ],
  };
  const handleSignOut = async () => {
    console.log("Running sign out");
    try {
      await dispatch(firebaseSignOutAction());
      toast({ title: "Signed out successfully" });
      navigate("/sign-in");
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Sign out failed",
        description: "Unable to sign out, please try again.",
      });
      console.error("Error signing out:", err);
    }
  };

  return (
    <Sidebar variant="inset" {...props}>
      <ScrollArea className="flex flex-col h-full">
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton size="lg" asChild>
                <a href="/buyers">
                  {logo ? (
                    <div className="flex items-center">
                      <img src={logo} className="w-24 mr-32" alt="logo" />
                    </div>
                  ) : (
                    <>
                      <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                        <Command className="size-4" />
                      </div>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">Acme Inc</span>
                        <span className="truncate text-xs">Enterprise</span>
                      </div>
                    </>
                  )}
                </a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
          <SidebarMenu>
            <TeamSwitcher />
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
          <NavProjects
            projects={data.projects}
            name={
              //@ts-ignore
              t("sidebar.summary_opportunities")
            }
            transaction={transaction}
          />
          <NavMain items={data.navMain} transaction={transaction} />
          {/* <NavSecondary items={data.navSecondary} className="mt-auto" /> */}
        </SidebarContent>
      </ScrollArea>
      <SidebarFooter>
        <NavUser signout={handleSignOut} user={userData.user} />
      </SidebarFooter>
    </Sidebar>
  );
}
