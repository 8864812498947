"use client";
import React from "react";
import AutoScroll from "embla-carousel-auto-scroll";
import { useRef } from "react";

import { Avatar, AvatarImage } from "../../../components/ui/avatar";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import { Carousel, CarouselContent, CarouselItem } from "../../../components/ui/carousel";
// import { User } from "lucide-react";
import userImage from "../../../assets/landingpage/wic_255.svg"; // Make sure the path to your logo is correct
import manImage_1 from "../../../assets/landingpage/02.png";
import manImage_2 from "../../../assets/landingpage/04.png";
const testimonials1 = [
  {
    name: "John Kim",
    role: "New Business Owner",
    avatar: manImage_1,
    content: "The team is extremely knowledgeable. their communication skills are excellent. They provided us with a valuation for each opportunity evaluated almost immediately. I cannot recommend them enough",
  },
  {
    name: "Damien Jones",
    role: "Prospective buyer",
    avatar: manImage_2,
    content: "The team has outstanding capabilities, they are genuinely unique. Their skill set is rare and they were efficient, communicative and understood my problem far better than I did. I couldn't recommend them more highly.",
  },
  {
    name: "Leigh",
    role: "New Business Owner",
    avatar: manImage_1,
    content: "I already had a specific business in mind before joining. It allowed me to uncover issues that would have cost me $50,000. The platform reviewed the accounting and prepared a list of issues for the seller.",
  },
  {
    name: "John Kim",
    role: "New Business Owner",
    avatar: manImage_2,
    content: "The team is extremely knowledgeable. their communication skills are excellent. They provided us with a valuation for each opportunity evaluated almost immediately. I cannot recommend them enough",
  },
  {
    name: "Damien Jones",
    role: "Prospective buyer",
    avatar: manImage_1,
    content: "The team has outstanding capabilities, they are genuinely unique. Their skill set is rare and they were efficient, communicative and understood my problem far better than I did. I couldn't recommend them more highly.",
  },
  {
    name: "Leigh",
    role: "New Business Owner",
    avatar: manImage_2,
    content: "I already had a specific business in mind before joining. It allowed me to uncover issues that would have cost me $50,000. The platform reviewed the accounting and prepared a list of issues for the seller.",
  },
];
const testimonials2 = [
  {
    name: "John Kim",
    role: "New Business Owner",
    avatar: manImage_1,
    content: "The team is extremely knowledgeable. their communication skills are excellent. They provided us with a valuation for each opportunity evaluated almost immediately. I cannot recommend them enough",
  },
  {
    name: "Damien Jones",
    role: "Prospective buyer",
    avatar: manImage_2,
    content: "The team has outstanding capabilities, they are genuinely unique. Their skill set is rare and they were efficient, communicative and understood my problem far better than I did. I couldn't recommend them more highly.",
  },
  {
    name: "Leigh",
    role: "New Business Owner",
    avatar: manImage_1,
    content: "I already had a specific business in mind before joining. It allowed me to uncover issues that would have cost me $50,000. The platform reviewed the accounting and prepared a list of issues for the seller.",
  },
  {
    name: "John Kim",
    role: "New Business Owner",
    avatar: manImage_2,
    content: "The team is extremely knowledgeable. their communication skills are excellent. They provided us with a valuation for each opportunity evaluated almost immediately. I cannot recommend them enough",
  },
  {
    name: "Damien Jones",
    role: "Prospective buyer",
    avatar: manImage_1,
    content: "The team has outstanding capabilities, they are genuinely unique. Their skill set is rare and they were efficient, communicative and understood my problem far better than I did. I couldn't recommend them more highly.",
  },
  {
    name: "Leigh",
    role: "New Business Owner",
    avatar: manImage_2,
    content: "I already had a specific business in mind before joining. It allowed me to uncover issues that would have cost me $50,000. The platform reviewed the accounting and prepared a list of issues for the seller.",
  },
];

const Testimonial7 = React.forwardRef((props, ref) => {
  const plugin1 = useRef(
    AutoScroll({
      startDelay: 500,
      speed: 0.7,
    }),
  );

  const plugin2 = useRef(
    AutoScroll({
      startDelay: 500,
      speed: 0.7,
      direction: "backward",
    }),
  );
  return (
    <section ref={ref} className="py-16 px-6">
      <div className=" flex flex-col items-center gap-6">
        <h2 className="mb-2 text-center text-3xl font-semibold lg:text-5xl">Discover Our Successful Pilots</h2>
        <p className="text-muted-foreground lg:text-lg">Over 20 clients have experienced success and satisfaction with our solution</p>
        {/* <Button className="mt-6">Start Your Free Trial</Button> */}
      </div>
      <div className="">
        <div className="mt-16 space-y-4">
          <Carousel
            opts={{
              loop: true,
            }}
            plugins={[plugin1.current]}
            onMouseLeave={() => plugin1.current.play()}
          >
            <CarouselContent>
              {testimonials1.map((testimonial, index) => (
                <CarouselItem key={index} className="basis-auto">
                  <Card className="max-w-96 select-none p-6">
                    <div className="mb-4 flex gap-4">
                      <Avatar className="size-9 rounded-full ring-1 ring-input">
                        <AvatarImage src={testimonial.avatar} alt={testimonial.name} />
                      </Avatar>
                      <div className="text-sm">
                        <p className="font-medium">{testimonial.name}</p>
                        <p className="text-muted-foreground">{testimonial.role}</p>
                      </div>
                    </div>
                    <q>{testimonial.content}</q>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
          <Carousel
            opts={{
              loop: true,
            }}
            plugins={[plugin2.current]}
            onMouseLeave={() => plugin2.current.play()}
          >
            <CarouselContent>
              {testimonials2.map((testimonial, index) => (
                <CarouselItem key={index} className="basis-auto">
                  <Card className="max-w-96 select-none p-6">
                    <div className="mb-4 flex gap-4">
                      <Avatar className="size-9 rounded-full ring-1 ring-input">
                        <AvatarImage src={testimonial.avatar} alt={testimonial.name} />
                      </Avatar>
                      <div className="text-sm">
                        <p className="font-medium">{testimonial.name}</p>
                        <p className="text-muted-foreground">{testimonial.role}</p>
                      </div>
                    </div>
                    <q>{testimonial.content}</q>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </div>
      </div>
    </section>
  );
});

export default Testimonial7;
