import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import './App.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
// if (process.env.REACT_APP_NODE_ENV === "PROD") {
//   console.log = function () {};
//   console.warn = function () {};
//   console.error = function () {};
// }

const originalConsoleWarn = console.warn;
console.warn = function (message, ...args) {
  if (message.includes("Loading aborted")) {
    return;
  }
  originalConsoleWarn(message, ...args);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
