"use client";
import React, { useState } from "react";

import { Row } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";

import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { toast } from "sonner";

import { statuses as labels } from "../data/data";
import { taskSchema } from "../data/schema";
import { Textarea } from "../../../components/ui/textarea";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions, updateTransactionProperty } from "../../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { DialogComponent } from "./buttonaction";
import { useDocumentStatus } from "../../../hooks/useDocuments";
// import { addListener, removeListener } from "@reduxjs/toolkit";
// import { useNotifications } from "../../../router/NotificationsProvider";
import { useNotifications } from "../../../router/NotificationsProvider";
import { useTranslation } from "react-i18next";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions<TData>({ row }: DataTableRowActionsProps<TData>) {
  const task = taskSchema.parse(row.original);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const transactions = useSelector((state: any) => state.transactions?.transactions);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isdialogOpen, setIsdialogOpen] = useState(false);
  const { t } = useTranslation(); // ✅ Use translation hook

  const dialogContent = {
    // @ts-ignore
    title: t("rowActions.addDealTitle"),
    // @ts-ignore
    description: t("rowActions.addDealDescription"),
  };

  const [curtr, setcurTr] = useState(null);

  const handleAction = async (action: any, label) => {
    console.log(row);
    const append = false;
    const remove = false;
    setIsDropdownOpen(false);

    const tr = await dispatch(updateTransactionProperty(task.id, { pipelineStatus: label }));
    console.log(tr);
    // dispatch(updateTransaction);
    // @ts-ignore
    toast(t("rowActions.statusUpdated"), {
      // @ts-ignore
      description: t("rowActions.statusChanged", { status: label }),
    });
  };

  const handleDialogClose = () => {
    setIsdialogOpen(false);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setIsDropdownOpen(false);
    const t = transactions.find((e) => e.id === task.id);
    console.log(t);
    setcurTr(t);
    setIsdialogOpen(true);
  };

  return (
    <>
      <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
            <MoreHorizontal />
            <span className="sr-only">
              {
                // @ts-ignore
                t("rowActions.openMenu")
              }
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {/* <DropdownMenuItem onClick={() => handleAction("upload")}>Status Update</DropdownMenuItem> */}
          {/* <DropdownMenuItem>Make a copy</DropdownMenuItem> */}
          <DropdownMenuItem onClick={handleEdit}>
            {
              // @ts-ignore
              t("rowActions.edit")
            }
          </DropdownMenuItem>
          {/* <DropdownMenuSeparator /> */}
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              {
                // @ts-ignore
                t("rowActions.statusUpdate")
              }
            </DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup value={task.pipelineStatus}>
                {labels.map((label) => (
                  <DropdownMenuRadioItem key={label.value} value={label.value} onClick={() => handleAction(row, label.value)}>
                    {label.label}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogComponent isOpen={isdialogOpen && !isDropdownOpen} onClose={handleDialogClose} dialogContent={dialogContent} selectedDealType={"sell"} transaction={curtr} />
    </>
  );
}
