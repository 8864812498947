import { AppSidebar } from "../../components/components/app-sidebar";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import Cards from "./components/cards";
import CardBuy from "./components/tab_s";
import CardAsk from "./components/tab_b";
import MapCard from "./components/map";
import CapitalStructure from "./components/capitalstructure";
import YearCashflow from "./components/yearcashflow";
import { CashCard } from "../CashflowScreen/components/Cashflow";
import ProgressPrice from "./ProgressPrice";
import { Button } from "../../components/ui/button";
import { toast } from "sonner";
import { useState } from "react";
export default function Overview() {
  const [loading, setLoading] = useState(false);
  const data = {
    Strength: [
      "Attractive IRR of 35%, even close to the asking price.",
      "Financing in place for 1.3 million out of the 2 million ask price.",
      "Cashflow projections show resilience.",
      "Located in a region with high median age and good population growth, indicating strong demand for healthcare services.",
    ],
    Weakness: ["The owner will not stay through, leading to potential transitional challenges.", "Accounting is a mess, posing financial and operational clarity risks.", "Hospitals are difficult to manage due to legal and government work complexities."],
    Opportunity: ["Opportunity to add another radiology department, creating additional value and revenue streams."],
    Threat: ["A lot of competition in the surrounding area, potentially impacting market share and pricing power."],
  };
  const handleAddtobasket = () => {
    setLoading(true);
    toast("Data Uploading", {
      description: `${"Deal added to the user pipeline"}`,
    });

    // Simulate a loading process
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Timeout of 2 seconds
  };

  const handleReject = () => {
    setLoading(true);
    toast("Deal Rejected", {
      description: `${"Fetching the next transaction"}`,
    });

    // Simulate a loading process
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Timeout of 2 seconds
  };

  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {/* <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>Overview</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <Cards loading={loading} />
        <div className="grid auto-rows-min gap-4 grid-cols-2">
          <ProgressPrice title={"Estimated Purchase Price"} loading={loading} />
          <CardBuy title={"Ask Price"} content={"Total Ask: $2,166,000"} comp={data.Strength} loading={loading} />
          <div className="col-span-2 w-full flex justify-evenly">
            <Button onClick={handleReject} variant="ghost">
              Do not Pursue
            </Button>
            <Button onClick={handleAddtobasket}>Add to Pipeline</Button>
          </div>
          <CardBuy title={"Strength"} div={false} content={"Total Ask: $3,166,000"} comp={data.Strength} loading={loading} />
          <CardBuy title={"Weaknesses"} div={false} content={"Total Ask: $3,166,000"} comp={data.Weakness} loading={loading} />
          <CardBuy title={"Opportunities"} div={false} content={"Total Ask: $3,166,000"} comp={data.Opportunity} loading={loading} />
          <CardBuy title={"Threats"} div={false} content={"Total Ask: $3,166,000"} comp={data.Threat} loading={loading} />
        </div>
        <div className="min-h-[100vh] flex-1 rounded-xl bg-muted/50 md:min-h-min" />
      </div>
    </SidebarInset>
  );
}
