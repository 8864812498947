import React from "react";
import { store, persistor } from "./redux/store"; // Make sure to import persistor here
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import AppRouter from "./router/AppRouter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "./components/ui/toaster";
import { Toaster as Sonner } from "./components/ui/sonner";
import { NotificationsProvider } from "./router/NotificationsProvider";

const App = () => {
  return (
    <React.StrictMode>
      <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Provider store={store}>
          {/* Wrap AppRouter with PersistGate to ensure the store is rehydrated before rendering */}
          <PersistGate loading={null} persistor={persistor}>
            <NotificationsProvider>
              <AppRouter />
              <Toaster />
              <Sonner />
            </NotificationsProvider>
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </React.StrictMode>
  );
};

export default App;
