"use client";

import React from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell } from "recharts";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../../../components/ui/charts";

// Generate mock data for the stacked bar chart
const generateData = () => {
  const years = ["2022", "2023", "2024", "2025", "2026", "2027"];
  return years.map((year) => {
    const isForecast = parseInt(year) > 2024;
    const personnel = Math.floor(Math.random() * 30000) + 10000; // Personnel costs between $10,000 - $40,000
    const otherCosts = Math.floor(Math.random() * 20000) + 5000; // Other costs between $5,000 - $25,000
    return {
      year,
      personnel,
      otherCosts,
      isForecast,
    };
  });
};

const data = generateData();

const chartConfig = {
  personnel: {
    label: "Adjustments",
    color: "#8884d8", // Blue for Personnel
  },
  otherCosts: {
    label: "Costs",
    color: "#82ca9d", // Green for Other Costs
  },
} satisfies ChartConfig;

// Custom Tooltip for Costs
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white shadow-lg rounded p-2 text-sm">
        <p className="font-bold">Year: {label}</p>
        {payload.map((entry: any, index: number) => (
          <div key={`tooltip-item-${index}`} className="flex items-center space-x-2">
            <div
              className="w-3 h-3 rounded-sm"
              style={{
                backgroundColor: entry.payload.isForecast ? entry.color : entry.color,
              }}
            />
            <p>
              {entry.name}: <span className="font-medium">${new Intl.NumberFormat("en-US").format(Math.round(entry.value))}</span> ({entry.payload.isForecast ? "Forecast" : "Actual"})
            </p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export function Costs({ actual_costs, act_adj }) {
  const data = actual_costs.map((e, i) => {
    return {
      year: e.year,
      otherCosts: Math.abs(e["Costs"]),
      personnel: Math.abs(act_adj[i]["Ebitda Adjustments"]),
      isForecast: e.isForecasted,
    };
  });
  return (
    <ChartContainer config={chartConfig} className="w-full h-64">
      <ResponsiveContainer>
        <BarChart data={data} accessibilityLayer>
          <XAxis dataKey="year" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
          <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false} domain={["auto", "auto"]} tickFormatter={(value) => `$${new Intl.NumberFormat("en-US").format(Math.round(value))}`} />
          <Tooltip content={<CustomTooltip />} />
          {/* Personnel Costs */}
          {/* Other Costs */}
          <Bar dataKey="personnel" name="Adjustments" stackId="a" radius={[0, 0, 4, 4]} fill={chartConfig.personnel.color} />
          <Bar dataKey="otherCosts" name="Costs" stackId="a" radius={[4, 4, 0, 0]} fill={chartConfig.otherCosts.color} />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
