import React, { useRef } from "react";
import { Workbook, WorkbookInstance } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css";

const format = `#,##0_);(#,##0);-`;

function formatNumber(number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
  });

  if (number < 0) {
    return `(${formatter.format(Math.abs(number))})`;
  }
  return formatter.format(number);
}

// Step 1: Build rowIndexByName + parse numeric
function toAlpha(colIndex: number): string {
  let result = "";
  let n = colIndex;
  while (n >= 0) {
    result = String.fromCharCode((n % 26) + 65) + result;
    n = Math.floor(n / 26) - 1;
  }
  return result;
}

function cellRef(rowIndex: number, colIndex: number): string {
  return `${toAlpha(colIndex)}${rowIndex + 1}`;
}

function parseGermanNumber(valueStr: string | number): number {
  if (typeof valueStr === "number") {
    return valueStr; // Return directly if it's already a number
  }

  // Convert to string and clean unwanted characters
  const cleanedStr = valueStr.toString().replace(/[^\d,.-]/g, ""); // Remove non-numeric and non-separator characters

  // Check for trailing decimals (identified by the last separator being ',' or '.')
  const hasTrailingDecimals = /[.,]\d{1,2}$/.test(cleanedStr);

  let formattedStr;
  if (hasTrailingDecimals) {
    // Replace ',' with '.' for decimal interpretation, and remove other separators
    formattedStr = cleanedStr.replace(/\./g, "").replace(",", ".");
  } else {
    // No trailing decimals, remove all separators
    formattedStr = cleanedStr.replace(/[.,]/g, "");
  }

  const parsedNumber = parseFloat(formattedStr);
  return isNaN(parsedNumber) ? 0 : parsedNumber;
}

interface FortuneSheetExampleProps {
  yearLabel?: string; // e.g. "2023"
  fs: any;
  type?: any;
  lang?: any;
  onValidate: (validateFunction: () => void) => void; // Add this prop to expose the validate function
}
function addIdsToModel(model: any[]) {
  return model.map((item) => {
    if (!item.id) {
      item.id = "item_" + Math.random().toString(36).substring(2, 9);
    }
    return item;
  });
}

const fields = {
  "income-statement": [
    "Accounting costs",
    "Administrative Cost",
    "Advertising",
    "Bank Fees",
    "Cost of Sales",
    "Depreciation",
    "Donations",
    "Distribution Costs",
    "EBIT",
    "EBITDA",
    "Adjusted EBITDA",
    "Expenses",
    "Interest Payment",
    "Insurance Expense",
    "Maintenance Expense",
    "Other Costs",
    "Pension Expense",
    "Personnel",
    "Revenue",
    "Tax",
    "Uniforms",
    "Utilities",
  ],
  "balance-sheet": ["Accumulated depreciation", "Current assets", "Current liability", "Long term debt", "Other Long term asset", "Property Plant and Equipment", "Retained earnings", "Total Assets", "Total Liability", "Total Equity", "Total Liability and Equity", "Shareholder loan"],
};
const roles = {
  "income-statement": ["adjustment-to-ebitda", "adjusted-total", "expense", "hybrid", "revenue", "total"],
  "balance-sheet": ["assets", "liabilities", "total"],
};

const FortuneSheetExample: React.FC<FortuneSheetExampleProps> = ({ fs, type, lang, onValidate }) => {
  // Build rowIndexByName for referencing in formulas
  const { model, year } = fs;
  const YEARS = [String(year)];
  const ref = useRef<WorkbookInstance>(null);

  const rowIndexByName: Record<string, number> = {};
  const modelWithIds = addIdsToModel(model);
  modelWithIds.forEach((item, i) => {
    rowIndexByName[item.name] = i;
  });

  const celldata: any[] = [];
  const calcChain: any[] = [];
  const dataVerification: {} = {};
  const rowOffset = 4;
  const dataval = fields[type];
  const dataval2 = roles[type];
  React.useEffect(() => {
    ref.current?.calculateFormula();
  }, [ref]);
  // We'll place the year label in row=2 => col=4 + iYear ?

  function extendDataVerification(dataVerification, row, column, category, hint = "Select category of input") {
    const rowIndex = row; // Row index from celldata
    const colIndex = column; // Column index from celldata
    const key = `${rowIndex}_${colIndex}`; // Generate key in format 'row_column'
    const rangeTxt = cellRef(rowIndex, colIndex); // Generate Excel-style range text (e.g., A1, B2)

    // Add or extend the dataVerification object
    dataVerification[key] = {
      type: "dropdown", // Example: Default type
      type2: "",
      rangeTxt: rangeTxt,
      value1: `${category}`, // Assuming 'v' contains the value
      value2: "",
      validity: "",
      remote: false,
      prohibitInput: true,
      hintShow: true,
      hintValue: hint,
      checked: false,
    };
  }

  const handleValidation = () => {
    console.log(data);
    const info = sheetmodel;
    // console.log(info);
    return info;
    // Perform validation logic
  };
  // 4) Insert year headings in row=2 => e.g. "2023" => col=4 => E2
  YEARS.forEach((year, yIndex) => {
    celldata.push({
      r: 3, // row=1 => second row
      c: 4 + yIndex, // col=4 => "E" if yIndex=0
      v: {
        v: year,
        m: year,
        ct: { fa: "@", t: "s" },
        // bg: "#f0f0f0",

        bl: "1",
        ff: 1,
      },
    });
  });
  celldata.push({
    r: rowOffset - 1,
    c: 1,
    v: {
      m: `Title`, // indentation via spaces
      v: `Title`,
      ct: { fa: "@", t: "s" },

      bl: "1",
      ff: 1, // We'll define ff=1 => Arial (we might also define a mapping below)
    },
  });
  celldata.push({
    r: rowOffset - 1,
    c: 2,
    v: {
      m: `Category`,
      v: `Category`,
      ct: { fa: "@", t: "s" },

      bl: "1",
      ff: 1, // We'll define ff=1 => Arial (we might also define a mapping below)
    },
  });
  celldata.push({
    r: rowOffset - 1,
    c: 3,
    v: {
      m: `Type`,
      v: `Type`,
      ct: { fa: "@", t: "s" },

      bl: "1",
      ff: 1, // We'll define ff=1 => Arial (we might also define a mapping below)
    },
  });
  // For each item => place it in row i+3
  // row offset = 3 => so if i=0 => row=3
  model.forEach((item, i) => {
    const actualRow = i + rowOffset; // start from row 3 => 4th row visually
    // Indentation logic
    let indentLevel = 0;
    if (item.parentName) {
      // Could do a quick approach: indent by 1 for each parent
      // More advanced approach might recursively find ancestors
      indentLevel = 1;
    }

    celldata.push({
      r: actualRow,
      c: 1,
      v: {
        m: `${" ".repeat(indentLevel * 4)}${item.name}`, // indentation via spaces
        v: item.name,
        lo: 0,
        ct: { fa: "@", t: "s" },
        bg: item.terms?.length > 0 ? "#f3f4f6" : "#fff", // first 3 columns => A,B,C => col index=0,1,2 => we do B => c=1 => f0f0f0
        bl: item.terms?.length > 0 ? "1" : "0",
        ff: 1, // We'll define ff=1 => Arial (we might also define a mapping below)
      },
    });
    celldata.push({
      r: actualRow,
      c: 2,
      v: {
        m: item.category,
        v: item.category,
        lo: 0,
        ct: { fa: "@", t: "s" },
        bg: item.terms?.length > 0 ? "#f3f4f6" : "#fff", // first 3 columns => A,B,C => col index=0,1,2 => we do B => c=1 => f0f0f0
        bl: item.terms?.length > 0 ? "1" : "0",
        ff: 1, // We'll define ff=1 => Arial (we might also define a mapping below)
      },
    });
    extendDataVerification(dataVerification, actualRow, 2, dataval);

    celldata.push({
      r: actualRow,
      c: 3,
      v: {
        m: item.role,
        v: item.role,
        lo: 0,
        ct: { fa: "@", t: "s" },
        bg: item.terms?.length > 0 ? "#f3f4f6" : "#fff", // first 3 columns => A,B,C => col index=0,1,2 => we do B => c=1 => f0f0f0
        bl: item.terms?.length > 0 ? "1" : "0",
        ff: 1, // We'll define ff=1 => Arial (we might also define a mapping below)
      },
    });
    if (type === "income-statement") {
      extendDataVerification(dataVerification, actualRow, 3, dataval2, "For adjustments that are can be positive and negative, select hybrid or EBITDA Adjustment");
    } else {
      // if (type === "income-statement") {
      extendDataVerification(dataVerification, actualRow, 3, dataval2);
      // }
    }

    // (B) Filler cells in columns C and D => c=2..3 => so there's no visual gap
    // const rowOffset = 3;
    const dataStartCol = 4; // Column for first year's data
    const numYears = YEARS.length;

    // Set the background color for header area (row 0 -> row 3; col 0 -> col 4 + numYears - 1)

    YEARS.forEach((year, yIndex) => {
      const col = 4 + yIndex; // e.g. E if yIndex=0
      // Parse numeric but do NOT flip sign if "subtraction" is used. Instead:
      // We will store negative in the cell if role = "expense".
      let numericValue = parseGermanNumber(item.value ?? "0");
      if (item.role === "expense" && numericValue !== 0) {
        // store as negative
        numericValue = -Math.abs(numericValue);
      }

      // 5) If it has terms => build a formula referencing child rows
      if (item.terms?.length > 0) {
        let formula = "=";
        item.terms.forEach((term, idx2) => {
          // find row of child
          const childRow = Number(rowIndexByName[term.name]);
          const sign = term.operation === "subtraction" ? "-" : idx2 === 0 ? "" : "+";
          // we also might want to handle negative if child is expense
          formula += `${"+"}${cellRef(childRow + 4, col)}`;
          // because child is also offset by +3 row, col=2 => 'C'
        });

        // highlight total rows => e.g. grey background
        celldata.push({
          r: actualRow,
          c: col,
          v: {
            f: formula,
            ct: { fa: format, t: "n" },
            v: numericValue,
            lo: 0,
            m: formatNumber(numericValue),
            // highlight total row => item.role === 'total'
            bg: "#f3f4f6",
            bl: "1",
            ff: 1, // font family index => see mapping
          },
        });
        calcChain.push({
          r: actualRow,
          c: col,
          id: "1", // Assuming single sheet with ID 0
          // func: [true, formatNumber(numericValue), formula], //Formula information, including formula calculation results and formula string
          color: "w", //"w"：use Depth-First-Search "b":Normal search
          parent: null,
          chidren: {},
          times: 0,
        });
      } else {
        // normal numeric row
        // If item is expense => negative, we already did above
        // If item is total => no terms => no formula => just put numeric?
        let isTotalLike = item.role === "total";
        celldata.push({
          r: actualRow,
          c: col,
          v: {
            v: numericValue,
            m: formatNumber(numericValue),
            lo: 0,
            ct: { fa: format, t: "n" },
            // bg: isTotalLike ? "#d9d9d9" : "#f0f0f0",
            ff: 1, // set to Arial
          },
        });
      }
      celldata.push({
        r: actualRow,
        c: 4 + YEARS.length + 1,
        v: {
          ct: { fa: format, t: "n" },
          v: item.id,
          m: item.id,
          // highlight total row => item.role === 'total'
          ff: 1, // font family index => see mapping
        },
      });
    });
  });

  // 6) Additional: color column A => c=0 => #f0f0f0 => we can do that via config below
  // Actually we want the first 3 columns => A,B,C => col=0,1,2 => #f0f0f0
  // We partially did that above for the row data, but let's do it for config

  // 7) We'll define config for column widths, row heights, etc.
  // FortuneSheet uses "columnlen" -> { colIndex: number } in config
  // rowlen -> { rowIndex: number } to define row height
  const colhidden = String(4 + YEARS.length + 1);
  const config: any = {
    columnlen: {
      0: 10, // col A = 10px
      1: 250, // col B = 250px for labels
      2: 150, // col C = 90px for numeric
    },

    rowlen: {},
    colhidden: { [colhidden]: 0 },
    authority: { sheet: 1 },
  };

  // Mark rowlen=110 for each row i+3
  for (let i = 0; i <= model.length; i++) {
    config.rowlen[i + 3] = 55; // 110px height
  }

  // Also set row=0..1..2 if you want them smaller or bigger? Up to you
  // We'll skip that or do config.rowlen[1] = 40 etc.

  // 8) Building the final sheet object
  const sheetData = [
    {
      id: "1",
      name: "Sheet1",
      row: model.length + 10, // extra space
      column: 4 + YEARS.length + 1,
      showGridLines: 0,
      celldata,
      calcChain,
      config,
      dataVerification,
      // Optional: default text style
      defaultFontFamily: "Arial", // FortuneSheet prop (some older versions might differ)
    },
  ];

  const [data, setData] = React.useState(sheetData);
  const [sheetmodel, setSheetmodel] = React.useState(model);

  React.useEffect(() => {
    // Expose the validate function when the component mounts
    if (onValidate) {
      onValidate(handleValidation);
    }
  }, [data, onValidate]);

  const onChange = React.useCallback((updatedData) => {
    // ref.current?.calculateFormula();
    setData(updatedData); // Update the spreadsheet data dynamically
    console.log("Spreadsheet data updated:", updatedData);
    setSheetmodel(rebuildModelFromSheet(modelWithIds, updatedData[0], rowOffset, 4 + YEARS.length + 1));
  }, []);
  const settings = {
    data: data,
    lang: "en", // or "de" if you prefer
    onChange: onChange,
    showSheetTabs: false,
  };

  return (
    <div style={{ width: "90%", height: "80vh", fontFamily: "Arial" }}>
      <Workbook ref={ref} {...settings} />
    </div>
  );
};
type UpdatedItem = {
  id?: string;
  name: string;
  category: string;
  value: string;
  role: string;
  terms: For[];
};
function rebuildModelFromSheet(originalModel, updatedSheet, rowOffset, ID_COL) {
  // 1) Build a map of IDs so we know which ones were originally present
  const originalIds = new Set(originalModel.map((item) => item.id));
  const newItems: UpdatedItem[] = []; // Declare newItems as an array of UpdatedItem

  // 2) Quick access to updated cells
  const rows = updatedSheet.data || [];
  const totalRows = updatedSheet.row;

  for (let r = rowOffset; r < totalRows; r++) {
    const rowCells = rows[r] || [];
    if (!rowCells.some((c) => c && typeof c === "object")) {
      // skip empty row
      continue;
    }

    // read the hidden ID
    const idCell = rowCells[ID_COL] || {};
    const rowId = idCell.v; // might be undefined

    // read name (col=1)
    const nameCell = rowCells[1] || {};
    const newName = nameCell.v || "";

    // read category (col=2)
    const catCell = rowCells[2] || {};
    const newCat = catCell.v || "";
    const roleCell = rowCells[3] || {};
    const newRole = roleCell.v || "";
    // read numeric or formula (col=4)
    const valCell = rowCells[4] || {};
    const formula = valCell.f; // e.g. "=+E5-E6"
    const computedValue = valCell.v || 0;

    // parse formula => build terms
    let terms: For[] = [];
    if (formula) {
      terms = parseFormulaToTerms(formula, rows, ID_COL);
    }

    // if this row is truly blank besides formula => skip? (optional)

    // decide the role => if we have an original item with this id => keep its role or deduce from category
    let role = "revenue";
    // let originalItem = originalModel.find((o) => o.id === rowId);

    role = newRole;

    // 3) Build the new item
    const updatedItem = {
      id: rowId || "item_" + Math.random().toString(36).substring(2, 9), // if no id => new
      name: newName,
      category: newCat,
      value: String(computedValue), // or parse back to "1.234,56" if needed
      role,
      terms,
    };

    newItems.push(updatedItem);
  }

  // 4) any ID left in originalIds => deleted
  const deletedIds = [...originalIds];

  // done => newItems is your updated model
  // plus we have a list of deletedIds
  return { newItems, deletedIds };
}

/** parseFormulaToTerms => find references, read hidden ID of each reference row, figure out + or - */

type For = {
  name: string;
  operation: string;
};

function parseFormulaToTerms(formulaStr, rows, ID_COL) {
  // strip leading =
  const f = formulaStr.replace(/^=/, "");
  // gather tokens like +E5 or -F6
  const tokens = f.match(/[+\-][A-Z]+\d+/g) || [];

  const results: For[] = [];
  tokens.forEach((token) => {
    const sign = token[0]; // + or -
    const ref = token.slice(1); // e.g. "E5"
    const { rowIndex, colIndex } = convertRefToRC(ref);

    // read that row's hidden id
    const rowCells = rows[rowIndex] || [];
    const idCell = rowCells[ID_COL] || {};
    const childId = idCell.v; // might be undefined if truly new

    // if we also want the child's name, read col=1 => rowCells[1].v
    let operation = sign === "+" ? "addition" : "subtraction";

    // push into results
    results.push({
      // if you want to store { id: childId }
      // or if your original design uses name => read rowCells[1].v
      name: rowCells[1]?.v || "",
      operation: operation,
    });
  });
  return results;
}

function inferRoleFromCategory(catStr) {
  if (catStr.toLowerCase().includes("revenue")) return "revenue";
  if (catStr.toLowerCase().includes("expense")) return "expense";
  if (catStr.toLowerCase().includes("net income") || catStr.toLowerCase().includes("zwischenergebnis")) {
    return "total";
  }
  return "expense"; // fallback
}

/**
 * Convert a cell reference like "E5" => { row: 4, col: 4 }
 * for 0-based indexing.
 * This is a minimal demonstration that assumes single letters, etc.
 */
function cellRefToRowCol(cellRef) {
  // separate letters from digits, e.g. "E" vs. "5"
  const match = cellRef.match(/([A-Z]+)(\d+)/);
  if (!match) return { row: 0, col: 0 };

  const colLetters = match[1];
  const rowDigits = match[2];
  const colIndex = lettersToIndex(colLetters);
  const rowIndex = parseInt(rowDigits, 10) - 1; // FortuneSheet is 0-based for row
  return { row: rowIndex, col: colIndex };
}

/**
 * Convert 'A' => 0, 'B' => 1, ..., 'E' => 4, etc.
 */
function lettersToIndex(letters) {
  let sum = 0;
  for (let i = 0; i < letters.length; i++) {
    sum *= 26;
    sum += letters.charCodeAt(i) - 65 + 1;
  }
  return sum - 1; // 0-based
}

/**
 * Convert a JavaScript number to your German string format,
 * optionally forcing negative values to remain positive but
 * you could also handle trailing dash.
 */
function numberToGermanString(num, expenseMode = false) {
  // If it's an expense in your original approach, you might store "absolute"
  // or a trailing dash. Tweak as desired:
  const isNegative = (expenseMode && num < 0) || num < 0;
  const absVal = Math.abs(num);

  // We'll do basic 2-decimal. A more robust approach would use `Intl.NumberFormat("de-DE")`.
  const intPart = Math.floor(absVal).toString();
  const decPart = Math.round((absVal - Math.floor(absVal)) * 100)
    .toString()
    .padStart(2, "0");

  // Insert thousand separators.
  // Real usage => use `Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 })`
  const re = /(\d+)(\d{3})/;
  let thousands = intPart;
  while (re.test(thousands)) {
    thousands = thousands.replace(re, "$1.$2");
  }

  let result = `${thousands},${decPart}`;
  // If you want a trailing dash for negative “Zinsen und ähnliche Aufwendungen”
  // you could handle that here:
  if (isNegative) {
    result += "-";
  }
  return result;
}
function convertRefToRC(ref: string) {
  // e.g. "E5" => col='E', row='5'
  // use a regex to separate the letters and digits
  const match = ref.match(/^([A-Z]+)(\d+)$/);
  if (!match) {
    return { rowIndex: 0, colIndex: 0 };
  }
  const colLetters = match[1];
  const rowDigits = match[2];

  // Convert colLetters => number (A=0, B=1, ..., Z=25, AA=26, etc.)
  const colIndex = lettersToIndex(colLetters);

  // Convert rowDigits => 1-based => subtract 1 for 0-based
  const rowIndex = Number(rowDigits) - 1;

  return { rowIndex, colIndex };
}
export default FortuneSheetExample;
