import { backendClient } from "../../api/backend";
import { azureStorageConfig } from "../../authConfig";
import {
  ADD_DOCUMENT_TO_DOCUMENTS,
  ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS,
  CLEAR_DOCUMENTS,
  DELETE_DOCUMENT_FROM_TRANSACTION,
  FETCH_DOCUMENT,
  FETCH_DOCUMENTS,
  REMOVE_DOCUMENT_FROM_DOCUMENTS,
  SET_DOCUMENTS,
  SET_DOCUMENTS_TRANSACTION_ID,
  UPDATE_DOCUMENT_PROPERTY,
  UPLOAD_DOCUMENTS,
  SET_ACTIVE_PDF,
  SET_CITATION_HIGHLIGHT,
  SET_PDF_LOADING_TASK,
} from "./types";
// import { LOAD_TRANSACTION } from './types';

export const setActivePdf = (activePdf) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ACTIVE_PDF,
      payload: activePdf,
    });
  };
};
export const setPdfLoadingTask = (loadingTask) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PDF_LOADING_TASK,
      payload: loadingTask,
    });
  };
};

export const setExploreCitation = (citation) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CITATION_HIGHLIGHT,
      payload: citation,
    });
  };
};

export const addDocuments = (transactionId, uploadedFiles, metadata, is_add_document) => {
  return async (dispatch, getState) => {
    console.log("INPUT_1", transactionId, uploadedFiles, metadata);
    const result = await backendClient.addDocuments(transactionId, uploadedFiles, metadata);
    console.log("resultsACTION_DOCUMENT", result);
    // dispatch({
    //   type: ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS,
    //   payload: { updatedDocument: result, transactionId },
    // });
    return result;
    // let documents = [];

    // for (const document of uploadedFiles) {
    //   const result = await backendClient.uploadDocuments(document, metadata);
    //   documents.push(result);
    // }

    // // get download URL
    // const updatedDocuments = await getDocumentsWithUrls(documents);

    // if (!is_add_document) {
    //   dispatch({
    //     type: UPLOAD_DOCUMENTS,
    //     payload: updatedDocuments,
    //   });
    // }
    // return updatedDocuments;
  };
};

// const createPublicBlobUrl = (blobName, sasToken) => {
//   return `https://${yourStorageAccountName}.blob.core.windows.net/${yourContainerName}/${blobName}?${sasToken}`;
// };

const getDocumentsWithUrls = async (documents) => {
  const containerName = azureStorageConfig.containerName;
  const updatedDocuments = [];
  for (const document of documents) {
    // Extract filename from URL
    const filename = document.url.split("/").pop();
    const filename_2 = document.table_url.split("/").pop();
    // Get new URL with SAS token
    const response = await backendClient.getDocumentSas(containerName, filename);
    const response_2 = await backendClient.getDocumentSas(containerName, filename_2, true);
    const newUrl = response.blob_url_with_sas;
    const newtable = response_2.blob_url_with_sas;
    updatedDocuments.push({ ...document, url: newUrl, table_url: newtable });
  }
  return updatedDocuments;
};

const getDocumentWithUrl = async (document) => {
  const containerName = azureStorageConfig.containerName;
  // Extract filename from URL
  const filename = document.url.split("/").pop();
  const filename_2 = document.table_url.split("/").pop();

  // Get new URL with SAS token
  const response = await backendClient.getDocumentSas(containerName, filename);
  const response_2 = await backendClient.getDocumentSas(containerName, filename_2, true);

  const newUrl = response.blob_url_with_sas;
  const newtable = response_2.blob_url_with_sas;

  return {
    ...document,
    url: newUrl,
    table_url: newtable,
  };
};

export const addDocumentToDocuments = (document) => {
  return async (dispatch, getState) => {
    const updatedDocument = await getDocumentWithUrl(document);
    dispatch({
      type: ADD_DOCUMENT_TO_DOCUMENTS,
      payload: updatedDocument,
    });
  };
};

export const addDocumentToDocumentsWithTransactions = (document, transactionId) => {
  return async (dispatch, getState) => {
    const updatedDocument = await getDocumentWithUrl(document);
    console.log("updatedDocument", updatedDocument);
    dispatch({
      type: ADD_DOCUMENT_TO_DOCUMENTS_WITH_TRANSACTIONS,
      payload: { updatedDocument, transactionId },
    });
  };
};

export const getDocuments = () => {
  return async (dispatch, getState) => {
    const documents = getState().documents.documents;
    return documents;
  };
};

export const fetchDocuments = (transactionId, page = null, pageSize = null) => {
  return async (dispatch, getState) => {
    const documents = await backendClient.fetchDocuments(transactionId, page, pageSize);
    // console.log("documents fetched", documents);
    //// set documents blob URL
    // const updatedDocuments = await getDocumentsWithUrls(documents);
    const updatedDocuments = documents;
    console.log("updatedDocXXuments", updatedDocuments);

    dispatch({
      type: FETCH_DOCUMENTS,
      payload: { transactionId, documents: updatedDocuments },
    });
    return updatedDocuments;
  };
};
export const fetchLocalDocuments = (transactionId, documents) => {
  return async (dispatch, getState) => {
    // console.log("documents fetched", documents);
    //// set documents blob URL
    const updatedDocuments = documents.map((doc, index) => {
      const url = URL.createObjectURL(doc);
      return {
        id: String(index),
        url: url,
        created_at: doc.lastModified,
        name: doc.name,
      };
    });
    console.log("updatedDocXXuments", updatedDocuments);

    dispatch({
      type: FETCH_DOCUMENTS,
      payload: { transactionId, documents: updatedDocuments },
    });
    return updatedDocuments;
  };
};

export const removeDocumentFromDocuments = (documentId) => {
  return async (dispatch) => {
    dispatch({
      type: REMOVE_DOCUMENT_FROM_DOCUMENTS,
      payload: documentId,
    });
  };
};

export const updateDocumentProperty = (documentId, propertyName, newValue, append, remove, dontGetUrl) => {
  return async (dispatch) => {
    let updatedDocument;
    if (propertyName === "transactions_ids") {
      updatedDocument = await backendClient.updateDocumentProperty(documentId, propertyName, newValue, append, remove);
    } else {
      updatedDocument = await backendClient.updateDocumentProperty(documentId, propertyName, newValue, append, remove);
    }
    if (!dontGetUrl) {
      updatedDocument = await getDocumentWithUrl(updatedDocument);
    }
    dispatch({
      type: UPDATE_DOCUMENT_PROPERTY,
      payload: updatedDocument,
    });
  };
};
export const setDocumentNa = (transaction_id, type, document_id) => {
  return async (dispatch) => {
    const updatedDocument = await backendClient.setDocumentNa(transaction_id, type, document_id);
    dispatch({
      type: UPDATE_DOCUMENT_PROPERTY,
      payload: updatedDocument,
    });
  };
};
export const delDocument = (transaction_id, type, document_id) => {
  return async (dispatch) => {
    await backendClient.deleteDocument(transaction_id, type, document_id);
    // dispatch({
    //   type: UPDATE_DOCUMENT_PROPERTY,
    //   payload: updatedDocument,
    // });
  };
};

export const setDocumentsTransactionId = (transactionId) => {
  return async (dispatch, getState) => {
    const documents = getState().documents.documents;
    dispatch({
      type: SET_DOCUMENTS_TRANSACTION_ID,
      payload: { transactionId, documents },
    });
  };
};

export const setDocuments = (documents) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DOCUMENTS,
      payload: documents,
    });
  };
};

export const fetchDocument = (documentId) => {
  return async (dispatch, getState) => {
    let document = await backendClient.fetchDocument(documentId);
    ///// Set documents blob URL
    document = await getDocumentWithUrl(document);
    console.log("FETCHED----", document);
    dispatch({
      type: FETCH_DOCUMENT,
      payload: document,
    });
    return document;
  };
};

export const clearDocuments = () => {
  return async (dispatch, getState) => {
    console.log("clearDocuments action dispatched");

    dispatch({
      type: CLEAR_DOCUMENTS,
    });
  };
};

export const deleteDocumentFromTransaction = (transactionId, documentsIds) => {
  return async (dispatch, getState) => {
    // const toto = ["b1b900e7-ab99-4b15-b8ca-41aa2e9b1b3e"]
    const results = await backendClient.deleteDocuments({ transactionId, documentsIds });
    // console.log("toitoti", transactionId, documentsIds)
    dispatch({
      type: DELETE_DOCUMENT_FROM_TRANSACTION,
      payload: documentsIds,
    });
    return results;
  };
};
