import React, { FC, useState, useRef } from "react";
import { CellWrapper, useCellContext } from "@silevis/reactgrid";

export interface ChevronCellProps {
  text: string;
  indent: number; // how many levels to indent
  isExpanded: boolean;
  hasChildren: boolean;
  onExpandedToggled: () => void; // called when user toggles expand/collapse
  isTotal: boolean;
}

const ChevronCell: FC<ChevronCellProps> = ({ text, indent, isExpanded, hasChildren, onExpandedToggled, isTotal }) => {
  const ctx = useCellContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // For typical numeric or text formatting, we might do something else,
  // but for demonstration, we just show `text` as-is.

  // For Excel-like copy/paste, we provide these two callbacks:
  const handleStringValueRequested = () => text;
  const handleStringValueReceived = (newText) => {
    // If you want to make the text user-editable, you'd pass an onValueChanged() here
    // For now, we won't update parent's state in this example.
    // If you do want it editable, pass a callback from the parent.
  };

  return (
    <CellWrapper
      onStringValueRequested={handleStringValueRequested}
      onStringValueReceived={handleStringValueReceived}
      className="font-semibold"
      style={{
        backgroundColor: `${hasChildren || isTotal ? "#f3f4f6" : "#fff"}`,
        // paddingLeft: `${indent * 2}rem`, // each indent is e.g. 2rem
        textAlign: "left",
        outline: "none",
        cursor: "default",
      }}
    >
      <div
        className="truncate"
        style={{
          display: "inline-flex",
          alignItems: "center",
          width: "95%",
          justifyContent: "flex-start",
          gap: "3px",
          overflow: "hidden", // Ensure content doesn't overflow
          textOverflow: "ellipsis", // Add ellipsis for overflow
          whiteSpace: "nowrap", // Prevent text from wrapping
        }}
      >
        {hasChildren ? (
          <span
            className=""
            style={{
              display: "inline-block",
              transform: isExpanded ? "rotate(270deg)" : "rotate(0deg)",
              cursor: "pointer",
              marginRight: "4px",
            }}
            onPointerDown={(e) => {
              e.stopPropagation();
              onExpandedToggled();
            }}
          >
            ❯
          </span>
        ) : (
          // Just spacing if no children
          <span style={{ width: "1rem", display: "inline-block" }} />
        )}

        <span className="truncate">{text}</span>
      </div>
    </CellWrapper>
  );
};

export default ChevronCell;
