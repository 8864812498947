import { backendClient } from "../../api/backend";
import { ADD_FINANCIAL_STATEMENT, UPDATE_FINANCIAL_STATEMENT, FETCH_FINANCIAL_STATEMENTS, DELETE_FINANCIAL_STATEMENT, CLEAR_FINANCIAL_STATEMENTS, SET_FINANCIAL_STATEMENTS_LOADING } from "./types";

// Set loading state
export const setFinancialStatementsLoading = (isLoading) => {
  return (dispatch) => {
    dispatch({
      type: SET_FINANCIAL_STATEMENTS_LOADING,
      payload: isLoading,
    });
  };
};

// Fetch all financial statements
export const fetchFinancialStatements = (transactionId, type, statement_id) => {
  return async (dispatch) => {
    dispatch(setFinancialStatementsLoading(true));
    try {
      const activeFinancialStatement = await backendClient.fetchFinancialStatementById(transactionId, type, statement_id);
      console.log(activeFinancialStatement);
      dispatch({
        type: FETCH_FINANCIAL_STATEMENTS,
        payload: { activeFinancialStatement, statementType: type },
      });
      return activeFinancialStatement;
    } catch (error) {
      console.error("Error fetching financial statements:", error);
    } finally {
      dispatch(setFinancialStatementsLoading(false));
    }
  };
};

// Add a new financial statement
export const addFinancialStatement = (transactionId, statementData) => {
  return async (dispatch) => {
    dispatch(setFinancialStatementsLoading(true));
    try {
      const newStatement = await backendClient.addFinancialStatement(transactionId, statementData);
      dispatch({
        type: ADD_FINANCIAL_STATEMENT,
        payload: newStatement,
      });
      return newStatement;
    } catch (error) {
      console.error("Error adding financial statement:", error);
    } finally {
      dispatch(setFinancialStatementsLoading(false));
    }
  };
};

// Update an existing financial statement
export const updateFinancialStatement = (transactionId, type, statementId, updatedData) => {
  return async (dispatch) => {
    dispatch(setFinancialStatementsLoading(true));
    try {
      const activeFinancialStatement = await backendClient.updateFinancialStatement(transactionId, type, statementId, updatedData);
      dispatch({
        type: UPDATE_FINANCIAL_STATEMENT,
        payload: activeFinancialStatement,
      });
      return activeFinancialStatement;
    } catch (error) {
      console.error("Error updating financial statement:", error);
    } finally {
      dispatch(setFinancialStatementsLoading(false));
    }
  };
};

// Delete a financial statement
export const deleteFinancialStatement = (statementId) => {
  return async (dispatch) => {
    dispatch(setFinancialStatementsLoading(true));
    try {
      await backendClient.deleteFinancialStatement(statementId);
      dispatch({
        type: DELETE_FINANCIAL_STATEMENT,
        payload: statementId,
      });
    } catch (error) {
      console.error("Error deleting financial statement:", error);
    } finally {
      dispatch(setFinancialStatementsLoading(false));
    }
  };
};

// Clear all financial statements
export const clearFinancialStatements = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_FINANCIAL_STATEMENTS,
    });
  };
};
