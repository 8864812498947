import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import deTranslation from "./locales/de.json";
const savedLang = localStorage.getItem("language") || "en"; // ✅ Load persisted language

const resources = {
  en: { translation: enTranslation },
  de: { translation: deTranslation },
};

i18n.use(initReactI18next).init({
  resources,
  lng: savedLang, // ✅ Set persisted language
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

export default i18n;
