// import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { TooltipIcon } from "../ProgressPrice";
import React from "react";

const CapitalStructure = ({ transaction, purchasePrice, newDebt, covenants, dscr, ltv, minDSCR, minLTV }) => {
  const debtP = Math.round((newDebt / purchasePrice) * 100);
  const eqP = 100 - debtP;
  const eq = purchasePrice - newDebt;
  console.log(dscr, minDSCR, ltv, minLTV);
  const { t } = useTranslation();
  return (
    <Card className="col-span-1 flex flex-col w-full p-4 space-y-6 shadow-md">
      {/* Header */}
      <div className="flex items-center justify-between">
        <CardTitle>
          {
            // @ts-ignore
            t("overview.capital_structure.title")
          }
        </CardTitle>
        {covenants && (
          <div className="flex space-x-4">
            <div className="text-sm font-semibold">
              <div className="text-gray-500 flex gap-2 items-center">
                {
                  // @ts-ignore
                  t("overview.capital_structure.ltv")
                }
                <TooltipIcon
                  content={
                    // @ts-ignore
                    t("overview.capital_structure.ltv_tooltip")
                  }
                />
              </div>
              <span className={`block text-xl ${minLTV * 100 < 60 ? "text-green-500" : "text-red-500"}`}>{(minLTV * 100).toFixed(0)}%</span>
            </div>
            <div className="text-sm font-semibold">
              <div className="text-gray-500 flex gap-2 items-center">
                <span className="text-md">
                  {
                    // @ts-ignore
                    t("overview.capital_structure.dscr")
                  }
                </span>
                <TooltipIcon
                  content={
                    // @ts-ignore
                    t("overview.capital_structure.dscr_tooltip")
                  }
                />
              </div>
              <span className={`block text-lg ${minDSCR > 1.8 ? "text-green-500" : "text-red-500"}`}>{minDSCR.toFixed(2)}x</span>
            </div>
          </div>
        )}
      </div>

      {/* Down Payment and Debt */}
      {/* <div className="grid grid-cols-2 gap-0 flex-grow"> */}
      <div className="grid grid-cols-2 gap-2 flex-grow">
        <div className="col-span-1 grid grid-rows-2 gap-2">
          <CardContent className="bg-gray-100 p-4 rounded-lg">
            <div className="flex items-center gap-2">
              <p className="text-sm font-medium text-gray-500">
                {
                  // @ts-ignore
                  t("overview.capital_structure.down_payment")
                }
              </p>
              <TooltipIcon
                content={
                  // @ts-ignore
                  t("overview.capital_structure.down_payment_tooltip")
                }
              />
            </div>
            <div className="flex gap-2 items-center">
              <p className="text-2xl font-bold">${eq.toLocaleString()}</p>
              <p className="text-sm font-semibold text-gray-600">{eqP}%</p>
            </div>
          </CardContent>
          <CardContent className="bg-gray-300 p-4 rounded-lg">
            <div className="flex items-center gap-2">
              <p className="text-sm font-medium text-gray-500">
                {
                  // @ts-ignore
                  t("overview.capital_structure.debt")
                }
              </p>
              <TooltipIcon
                content={
                  // @ts-ignore
                  t("overview.capital_structure.debt_tooltip")
                }
              />
            </div>
            <div className="flex gap-2 items-center">
              <p className="text-2xl font-bold">${newDebt.toLocaleString()}</p>
              <p className="text-sm font-semibold text-gray-600">{debtP}%</p>
            </div>
          </CardContent>
        </div>

        {/* Acquisition Cost */}
        <CardContent className="col-span-1 flex flex-col justify-start gap-2 bg-black text-white p-4 rounded-lg h-full">
          <div className="flex items-center gap-2">
            <p className="text-sm font-medium">
              {
                // @ts-ignore
                t("overview.capital_structure.acquisition_cost")
              }
            </p>
            <TooltipIcon
              content={
                // @ts-ignore
                t("overview.capital_structure.acquisition_cost_tooltip")
              }
              cs="text-white hover:text-white"
            />
          </div>
          <p className="text-2xl font-bold">${purchasePrice.toLocaleString()}</p>
        </CardContent>
      </div>
    </Card>
  );
};

export default CapitalStructure;
