import React from "react";

// Example imports from shadcn-ui (adjust to match your setup)
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Badge } from "../../../components/ui/badge";
import { Separator } from "../../../components/ui/separator";
import { Label } from "../../../components/ui/label";

// Example imports from a hypothetical "lucid-react" icons library
import { UploadIcon, PencilIcon, MapPinIcon, Link, Link2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function AnalyzeBusiness() {
  const navigate = useNavigate();

  return (
    <div className="space-y-6 px-4 py-8 w-[60%] flex flex-col justify-center items-center mx-auto">
      {/* Page Heading */}
      <div className="flex flex-col space-y-4">
        <div className="">
          <h1 className="text-2xl font-bold">Analyze Business</h1>
          <p className="text-muted-foreground">Enter a business URL or choose another method to start</p>
        </div>

        {/* Quick Analysis Card */}
        <Card className="w-full">
          <CardHeader>
            <div className="flex items-center gap-2">
              <Link className="h-5 w-5" />
              <CardTitle>Quick Analysis</CardTitle>
              <Badge variant="secondary">Recommended</Badge>
            </div>
            <CardDescription>Paste a listing from a market place link below (e.g. dealstream), and duedeal will get started instantly.</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <Label htmlFor="companyUrl">Company URL</Label>
            <div className="flex items-center gap-6">
              <Input id="companyUrl" placeholder="https://example.com" type="url" />
              <Button className="w-full sm:w-auto" onClick={() => navigate("/sign-up")}>
                <Link2 className="h-5 w-5" />
                Analyze
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Separator / Alternative Methods */}
      <div className="flex items-center justify-center gap-2 text-sm text-muted-foreground w-full">
        <Separator className="flex-1" />
        OR LOG IN TO USE ANY OF THE FOLLOWING
        <Separator className="flex-1" />
      </div>

      {/* Additional Methods (File Upload, Manual Entry, Place Search) */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <Card className="flex flex-col items-start space-y-2 p-4 opacity-50 cursor-pointer">
          <div className="flex items-center gap-2">
            <UploadIcon className="h-5 w-5" />
            <CardTitle>File Upload</CardTitle>
          </div>
          <CardDescription>Upload a file to create a proposal</CardDescription>
        </Card>

        <Card className="flex flex-col items-start space-y-2 p-4 opacity-50 cursor-pointer">
          <div className="flex items-center gap-2">
            <PencilIcon className="h-5 w-5" />
            <CardTitle>Manual Entry</CardTitle>
          </div>
          <CardDescription>Fill in details manually</CardDescription>
        </Card>

        <Card className="flex flex-col items-start space-y-2 p-4 opacity-50 cursor-pointer">
          <div className="flex items-center gap-2">
            <MapPinIcon className="h-5 w-5" />
            <CardTitle>Off market deals</CardTitle>
          </div>
          <CardDescription>Search unlisted market opportunities</CardDescription>
        </Card>
      </div>
    </div>
  );
}
