"use client";

import React from "react";
import { ArrowRight, TrendingUp } from "lucide-react";

import { Area, AreaChart, LineChart, Line, XAxis, CartesianGrid, Tooltip } from "recharts";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../../../components/ui/charts";
import { Button } from "../../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../../utils/utils";

// Utility to calculate the normal distribution values
const generateNormalDistribution = (mu, sigma, count = 100) => {
  const data = [];
  const step = (3 * sigma) / count; // Spread over 3 std deviations
  for (let x = mu - 3 * sigma; x <= mu + 3 * sigma; x += step) {
    const y = (1 / (sigma * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((x - mu) / sigma, 2));
    if (x >= 0) {
      data.push({ valuation: x, probability: y });
    }
  }
  return data;
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { valuation, probability } = payload[0].payload;
    return (
      <div className="bg-white shadow-md rounded p-2 text-sm">
        <p className="font-medium text-gray-800">Pricing: ${new Intl.NumberFormat("en-US").format(valuation.toFixed(2))}</p>
        {/* <p className="text-gray-600">Probability: {probability.toFixed(5)}</p> */}
      </div>
    );
  }
  return null;
};

export default function ValChart({ range }) {
  console.log(range);
  const { lowerBound, upperBound } = range;

  // const lowerBound = 500;
  // const upperBound = 1500;
  const mu = (upperBound + lowerBound) / 2;
  const sigma = (upperBound - lowerBound) / 4; // Approximation for 1 std dev
  const chartData = generateNormalDistribution(mu, sigma);
  const chartConfig = {
    valuation: {
      label: "Valuation",
      color: "hsl(var(--chart-1))",
    },
  };
  const navigate = useNavigate();
  const t = isMobile();
  console.log(t);
  return (
    <Card>
      <CardHeader>
        <CardTitle>What your business could be worth</CardTitle>
        <CardDescription>Want to get a better estimate ?</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig} className={`h-48 ${t ? "w-full" : "w-full"}`}>
          <AreaChart
            // width={600}
            // height={150}
            data={chartData}
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="valuation" tickLine={false} axisLine={false} tickMargin={8} tickFormatter={(value) => `$${new Intl.NumberFormat("en-US").format(Math.round(value))}`} />
            <Tooltip content={<CustomTooltip />} />
            <Area
              dataKey="probability" // Correct dataKey for Y-axis
              type="monotone" // Smooth curve
              fill="hsl(var(--chart-1))"
              fillOpacity={0.4}
              stroke="hsl(var(--chart-1))"
              strokeWidth={2}
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
      <CardFooter>
        <div className={`flex w-full items-start ${t ? "justify-end" : "justify-between"} gap-2 text-sm`}>
          {!t && (
            <div className="grid gap-2">
              <div className="flex items-center gap-2 font-medium leading-none">
                Valuation range: ${new Intl.NumberFormat("en-US").format(lowerBound)} - ${new Intl.NumberFormat("en-US").format(upperBound)}* <TrendingUp className="h-4 w-4" />
              </div>
              <div className="flex items-center gap-2 leading-none text-muted-foreground">*: Pricing estimate</div>
            </div>
          )}

          <Button onClick={() => navigate("/sign-up")} className={t ? `flex text-sm` : ``}>
            Get Accurate Estimate <ArrowRight className="ml-2 size-4" />
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
