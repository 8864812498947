import { ArrowDown, ArrowRight, ArrowUp, CheckCircle, Circle, CircleOff, HelpCircle, Timer } from "lucide-react";

export const labels = [
  {
    value: "downside",
    label: "Downside",
  },
  {
    value: "upside",
    label: "Upside",
  },
  {
    value: "clarification",
    label: "Clarification",
  },
];

export const statuses = [
  {
    value: "todo",
    label: "To do",
    icon: Circle,
  },
  {
    value: "in progress",
    label: "In Progress",
    icon: Timer,
  },
  {
    value: "done",
    label: "Done",
    icon: CheckCircle,
  },
  {
    value: "not-applicable",
    label: "N/A",
    icon: CircleOff,
  },
];

export const priorities = [
  {
    label: "Low",
    value: "low",
    icon: ArrowDown,
  },
  {
    label: "Medium",
    value: "medium",
    icon: ArrowRight,
  },
  {
    label: "High",
    value: "high",
    icon: ArrowUp,
  },
];
