// we have a system where :
// . We import files. Each files are linkedin to a "transaction".
// . A transaction contains : id, title, industry, country, description and date.
// . When we upload files, user is asked to fill form with title, industry, description and date in order to create a transaction.
// . Transaction will be stored in PGSQL - AWS
// . Transaction can also be loaded from PGSQL - AWS.
// create transactionAction and transactionReducer

import { backendClient } from "../../api/backend";
import {
  CLEAR_TRANSACTIONS,
  CREATE_TRANSACTION,
  DELETE_TRANSACTIONS,
  FETCH_TRANSACTION,
  FETCH_TRANSACTIONS,
  REMOVE_TRANSACTION_DOCUMENT_ID,
  SET_TRANSACTION,
  SET_TRANSACTIONS,
  SET_TRANSACTION_ID,
  UPDATE_TRANSACTION_DOCUMENTS_IDS,
  UPDATE_TRANSACTION_DOCUMENT_UPLOADED,
  UPDATE_TRANSACTION_PROPERTY,
} from "./types";

export const createTransaction = (transaction) => {
  return async (dispatch, getState) => {
    const response = await backendClient.createTransaction(transaction);
    const transactions = await backendClient.fetchTransactions();
    const mod_transactions = transactions.map((t) => {
      return {
        ...t.multiples,
        ...t.deal,
      };
    });
    const createdTransaction = mod_transactions.find((e) => e.id === response.id);
    console.log("createdTransaction", createdTransaction);
    dispatch({
      type: CREATE_TRANSACTION,
      payload: createdTransaction,
    });
    return createdTransaction;
  };
};

export const setTransactions = (transactions) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_TRANSACTIONS,
      payload: transactions,
    });
  };
};
export const setTransaction = (transaction) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_TRANSACTION,
      payload: transaction,
    });
  };
};
export const setTransactionId = (transactionId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_TRANSACTION_ID,
      payload: transactionId,
    });
  };
};

export const clearTransactions = (transaction) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CLEAR_TRANSACTIONS,
    });
  };
};

export const removeTransactionDocumentId = (documentId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: REMOVE_TRANSACTION_DOCUMENT_ID,
      payload: documentId,
    });
  };
};

export const deleteTransactions = (transactionIds) => {
  return async (dispatch, getState) => {
    await backendClient.deleteTransactions(transactionIds);
    dispatch({
      type: DELETE_TRANSACTIONS,
      payload: transactionIds,
    });
  };
};

export const updateTransactionDocumentsIds = (transactionId, documentId) => {
  return async (dispatch, getState) => {
    const transactions = getState().transactions.transactions;
    let transaction = transactions.find((transaction) => transaction.id === transactionId);
    console.log("updateTransactionDocumentsIds transaction", transaction);
    transaction.documents_ids.push(documentId);

    dispatch({
      type: UPDATE_TRANSACTION_DOCUMENTS_IDS,
      payload: transaction,
    });
    return transaction.id;
  };
};

export const updateTransactionProperty = (transactionId, updates) => {
  return async (dispatch) => {
    let updatedTransaction;

    // Sending all updates at once without changing the function logic
    updatedTransaction = await backendClient.updateTransactionProperty(transactionId, updates);

    console.log(updatedTransaction);
    dispatch({
      type: UPDATE_TRANSACTION_PROPERTY,
      payload: {
        ...updatedTransaction.multiples,
        ...updatedTransaction.deal,
      },
    });
  };
};

export const updateTransactionDocumentUploaded = (transaction, value) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TRANSACTION_DOCUMENT_UPLOADED,
      payload: value,
    });
  };
};

export const fetchTransactions = (page, pageSize) => {
  return async (dispatch, getState) => {
    const transactions = await backendClient.fetchTransactions();
    // Get current transaction from store and go through transactions
    // tr.find((e) => e.id === transaction.id)
    const mod_transactions = transactions.map((t) => {
      return {
        ...t.multiples,
        ...t.deal,
      };
    });
    const sorted_transactions = mod_transactions.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    console.log(sorted_transactions);

    dispatch({
      type: FETCH_TRANSACTIONS,
      // Return Transaction and Transactions in an object
      payload: sorted_transactions,
    });

    return sorted_transactions;
  };
};
//

export const fetchTransaction = (transactionId, add_to_transactions) => {
  return async (dispatch, getState) => {
    const transaction = await backendClient.fetchTransaction(transactionId);
    dispatch({
      type: FETCH_TRANSACTION,
      payload: { transaction, add_to_transactions },
    });
    return transaction;
  };
};
