import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import logoImage from "../../../assets/landingpage/logo.png"; // Make sure the path to your logo is correct
import logoImage2 from "../../../assets/landingpage/logo.svg"; // Make sure the path to your logo is correct

const Footer7 = ({ onSectionOneClick, onSectionTwoClick, onSectionThreeClick, onSectionFourClick }) => {
  const sections = [
    {
      title: "Product",
      links: [
        { name: "Overview", href: "#", onClick: onSectionOneClick },
        { name: "Waitlist", href: "#", onClick: onSectionTwoClick },
        { name: "Features", href: "#", onClick: onSectionThreeClick },
        { name: "Testimonials", href: "#", onClick: onSectionFourClick },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About", href: "#", onClick: () => {} },
        { name: "Team", href: "#", onClick: () => {} },
        { name: "Blog", href: "#", onClick: () => {} },
      ],
    },
  ];
  // {
  //   title: "Resources",
  //   links: [
  //     { name: "Help", href: "#" },
  //     { name: "Sales", href: "#" },
  //     { name: "Advertise", href: "#" },
  //     { name: "Privacy", href: "#" },
  //   ],
  // },
  // ];
  return (
    <section className="pt-16 pb-8">
      <div className="">
        <footer>
          <div className="flex flex-col items-center justify-between gap-10 text-center lg:flex-row lg:text-left  px-6">
            <div className="flex w-full max-w-96 shrink flex-col items-center justify-between gap-6 lg:items-start">
              <div>
                <span className="flex items-center justify-center gap-4 lg:justify-start">
                  <img src={logoImage2} alt="logo" className="h-11" />
                  {/* <p className="text-3xl font-semibold">DueDeal</p> */}
                </span>
                <p className="mt-6 text-sm text-muted-foreground">Advisory for Business Buyers and Sellers</p>
              </div>
              <ul className="flex items-center space-x-6 text-muted-foreground">
                <li className="font-medium hover:text-primary">
                  <a href="#">
                    <FaInstagram className="size-6" />
                  </a>
                </li>
                <li className="font-medium hover:text-primary">
                  <a href="#">
                    <FaFacebook className="size-6" />
                  </a>
                </li>
                <li className="font-medium hover:text-primary">
                  <a href="#">
                    <FaTwitter className="size-6" />
                  </a>
                </li>
                <li className="font-medium hover:text-primary">
                  <a href="https://www.linkedin.com/company/104538355/admin/dashboard/">
                    <FaLinkedin className="size-6" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="grid grid-cols-3 gap-6 lg:gap-20">
              {sections.map((section, sectionIdx) => (
                <div key={sectionIdx}>
                  <h3 className="mb-6 font-bold">{section.title}</h3>
                  <ul className="space-y-4 text-sm text-muted-foreground">
                    {section.links.map((link, linkIdx) => (
                      <li key={linkIdx} className="font-medium hover:text-primary">
                        <a
                          onClick={() => {
                            link.onClick();
                            console.log("clicke");
                          }}
                          href={link.href}
                        >
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-20 flex flex-col justify-between gap-4 border-t pt-8 text-center text-sm font-medium text-muted-foreground lg:flex-row lg:items-center lg:text-left  px-6">
            <p>© 2024 DueDeal. All rights reserved.</p>
            <ul className="flex justify-center gap-4 lg:justify-start">
              <li className="hover:text-primary">
                <a href="#"> Terms and Conditions</a>
              </li>
              <li className="hover:text-primary">
                <a href="#"> Privacy Policy</a>
              </li>
              {/* hello */}
            </ul>
          </div>
        </footer>
      </div>
    </section>
  );
};

export default Footer7;
