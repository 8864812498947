import { ArrowDownRight, ArrowRight } from "lucide-react";
import React, { useState } from "react";

import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";

import hero from "../../../assets/landingpage/Hero-1_alt.jpg";
import styled from "styled-components";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious, useCarousel } from "../../../components/ui/carousel";
import ValForm from "./ValForm";
import DialogCloseButton from "./Dialog";
import ValChart from "./Valuation";
import { isMobile } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";

const Hero1 = React.forwardRef(({ onSectionTwoClick }, ref) => {
  const [range, setRange] = useState({ lowerBound: 500, upperBound: 1500 });
  const t = isMobile();
  const navigate = useNavigate();
  return (
    <section ref={ref} className="lg:py-16 px-6 py-12 sm:pt-0">
      <Carousel className="w-full rounded-md object-cover" opts={{ loop: true }}>
        <div className="w-full">
          <div className={`grid items-center ${t ? "gap-2" : "gap-8"}  lg:grid-cols-2 sm:grid-cols-1`}>
            <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
              <BadgeWithScrollNext setRange={setRange} />
              <h1 className="my-6 text-pretty text-4xl font-bold lg:text-6xl">Find the right small business for you</h1>
              <p className="mb-8 max-w-xl text-muted-foreground lg:text-xl">We Find, Evaluate and Audit Small Business Opportunities</p>
              <div className="flex w-full flex-col justify-center gap-2 sm:flex-row lg:justify-start">
                <Button
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                  className="w-full sm:w-auto"
                >
                  Evaluate Business Opportunities
                </Button>
                <ButtonWithScrollNext setRange={setRange} />
              </div>
            </div>

            <CarouselContent className="flex items-center">
              <CarouselItem key={0} className={t ? "inline-flex max-w-max" : ""}>
                <div className={t ? "max-h-96 rounded-md" : "max-h-96 w-full rounded-md object-cover"}>
                  <img src={hero} alt="placeholder hero" className="max-h-96 rounded-md object-contain" />
                  {/* <ValChart /> */}
                </div>
              </CarouselItem>
              <CarouselItem key={1} className={t ? "inline-flex max-w-max" : ""}>
                <div className={t ? "max-h-96 w-full rounded-md" : "max-h-96 w-full rounded-md object-cover"}>
                  {/* <img src={hero} alt="placeholder hero" className="max-h-96 w-full rounded-md object-cover" /> */}
                  {/* <div className="max-h-96 w-full rounded-md object-cover"></div> */}
                  <ValChart range={range} />
                </div>
              </CarouselItem>
            </CarouselContent>
          </div>
        </div>
      </Carousel>
    </section>
  );
});

const BadgeWithScrollNext = ({ setRange }) => {
  const { scrollNext, scrollSet } = useCarousel();

  return (
    <DialogCloseButton
      headertitle="Business Pricing Estimation"
      headerdescription="Get a pricing estimate for your business."
      triggercomp={
        <Badge variant="outline" className="cursor-pointer hover:bg-secondary/80">
          <Badge className="py-0 px-1 rounded-lg cursor-pointer">New</Badge>
          <div className="ml-1">Check out new company valuation tool</div>
          <ArrowRight className="ml-2 size-4" />
        </Badge>
      }
    >
      <ValForm specialclose={true} setRange={setRange} scrollNext={scrollSet} />
    </DialogCloseButton>
  );
};
const ButtonWithScrollNext = ({ setRange }) => {
  const { scrollNext, scrollSet } = useCarousel();

  return (
    <DialogCloseButton
      headertitle="Business Pricing Estimation"
      headerdescription="Get a pricing estimate for your business."
      triggercomp={
        <Button variant="outline" className="w-full sm:w-auto">
          Request a Business Valuation quote
          <ArrowDownRight className="ml-2 size-4" />
        </Button>
      }
    >
      <ValForm specialclose={true} setRange={setRange} scrollNext={scrollSet} />
    </DialogCloseButton>
  );
};

export default Hero1;
