import { AlertCircle } from "lucide-react";

import { Alert, AlertTitle, AlertDescription, AlertAction, AlertClose } from "../../../components/ui/alert";
import { Button } from "../../../components/ui/button";
import { X } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
export default function AlertDestructive() {
  const { t } = useTranslation();
  const [dismiss, setDismiss] = useState(true);
  return dismiss ? (
    <Alert
      variant="destructive"
      actions={
        <>
          <AlertAction onClick={() => setDismiss(false)}>
            {
              // @ts-ignore
              t("alert.dismiss_button")
            }
          </AlertAction>
        </>
      }
    >
      <AlertCircle className="h-4 w-4" />
      <div className="flex flex-col justify-center -mb-1">
        <AlertTitle>
          {
            // @ts-ignore
            t("alert.missing_documents_title")
          }
        </AlertTitle>
        <AlertDescription className="inline">
          {
            // @ts-ignore
            t("alert.missing_documents_description")
          }
        </AlertDescription>
      </div>
    </Alert>
  ) : null;
}
