import React, { useEffect } from "react";
// import { Routes, Route, useNavigate } from "react-router-dom";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from "react-router-dom";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/actions/authAction";
import Sellers from "../screens/LandingPageSMB/Sellers";
import SignInScreen from "../screens/SignInScreen/SignInScreen";

import Buyers from "../screens/LandingPageSMB/Buyers";
import Advisors from "../screens/LandingPageSMB/Advisors";
import SignUpScreen from "../screens/SignInScreen/SignUpScreen";
import Overview from "../screens/OverviewScreen/Overview";
import Audit from "../screens/AuditScreen/Audit";
import Upload from "../screens/UploadDocScreen/Upload";
import Valuation from "../screens/ValuationScreen/Valuation";
import Cashflow from "../screens/CashflowScreen/Cashflow";
import Checklist from "../screens/ChecklistScreen/Checklist";
import Debt from "../screens/Debtscreen/Debt";
import Market from "../screens/MarketScreen/Market";
import CapexPlan from "../screens/CapexPlanScreen/CapexPlan";
import Summary from "../screens/SummaryScreen/Overview";
import Pipeline from "../screens/PipelineScreen/Pipeline";
import Select from "../screens/SelectScreen/SelectPage";
import Validation from "../screens/ValidationScreen/Validation";

import { AppSidebar } from "../components/components/app-sidebar";
import { SidebarProvider } from "../components/ui/sidebar";
import logoImage2 from "../assets/landingpage/logo.svg";
// import AuthenticationPage from "../screens/SigninScreen2/page";
// This component must be used inside the component tree that is a child of <Router>
import { store } from "../redux/store";
import { ScrollArea } from "../components/ui/scroll";
import { setTransaction } from "../redux/actions/transactionAction";
import { fetchCashflowData, fetchDebt, fetchPurchasePrice, updatecashgraph, updateMinideal } from "../redux/actions/cashflowAction";
import Spinner from "../components/ui/Spinner";
import { NotificationsProvider } from "./NotificationsProvider";

const NavBar = ({ children, logo }) => {
  const dispatch = useDispatch();

  const transaction = useSelector((state) => state.transactions.transaction);
  const minideal = useSelector((state) => state.cashflow.miniDeal);
  const cashflow = useSelector((state) => state.cashflow.tableData.cashFlow);

  const [loading, setLoading] = React.useState(!!transaction);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  // console.log(loading);
  // console.log(transaction);
  // console.log(transaction.uploadStatus);
  // console.log(minideal);
  // console.log(cashflow);
  // Function to check internet connection status
  React.useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const isMinidealEmpty = Object.keys(minideal).length === 0;
  const cond1 = isMinidealEmpty && transaction?.uploadStatus !== "completed";
  const cond2 = cashflow && transaction?.uploadStatus === "completed";
  const fetchAndSetTransactionData = React.useCallback(
    async (transactionData) => {
      setLoading(true);
      if (!transactionData) {
        // setLoading(false);
        return;
      }
      const isMinidealEmpty = Object.keys(minideal).length === 0;
      // const iscashflowEmpty = Object.keys(cashflow).length === 0;
      if (!isMinidealEmpty && transaction?.uploadStatus !== "completed") {
        console.log("Minideal is still uploading, waiting.");
        setLoading(false);
        return; // Don't set loading false yet; keep spinner active
      }

      if (cashflow && transaction?.uploadStatus === "completed") {
        console.log("this runs ");
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        await dispatch(fetchDebt(transactionData.id));
        await dispatch(fetchPurchasePrice(transactionData.id));
        await dispatch(fetchCashflowData(transactionData.id));
        await dispatch(updateMinideal(transactionData.revenue, transactionData.ebitda, transactionData.cashFlow, transactionData.earningsMultiple, transactionData.revenueMultiple, transactionData.uploadStatus));

        if (transactionData?.uploadStatus !== "completed") {
          await dispatch(updatecashgraph());
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, minideal, cashflow, transaction],
  );

  React.useEffect(() => {
    if (transaction) {
      fetchAndSetTransactionData(transaction);
    }
  }, [transaction, minideal, cashflow]);

  console.log(cond1);
  console.log(cond2);

  return (
    <ScrollArea className="flex flex-col h-full w-full">
      <SidebarProvider>
        <AppSidebar logo={logo} />
        {(loading || !transaction) && !cond1 && !cond2 ? (
          <div className="flex flex-col mt-60 mx-auto h-full">
            <Spinner size={72} />
          </div>
        ) : isOnline ? (
          children
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-center text-red-500">
            <p className="text-lg font-semibold">No Internet Connection</p>
            <p className="text-sm">Please check your connection and try again.</p>
          </div>
        )}
      </SidebarProvider>
    </ScrollArea>
  );
};

const NavigationBarWrapper = ({ children }) => {
  const location = useLocation();
  const state = store.getState();
  const isAuthenticated = state.auth.isAuthentificated;

  // const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();

  const pathsWhereNavigationBarShouldBeShown = [
    "/dashboard",
    "/deals",
    "/deals/hypothesis",
    "/data-import",
    "/data-import/transactions",
    "/data-import/transactions/:transactionId", // Dynamic paths should be handled differently if necessary
    "/data-import/transactions/explore",
    "/hypothesis",
    "/keyword-pairs",
    "/chat",
    "/chat/:conversationId", // Dynamic paths should be handled differently if necessary
    "/settings",
    "/demo",
    "/keyword",
    "/duediligencetemplate",
    "/questionnairetemplate",
    "/questionnairetemplate/:questionnaireId",
    "/duediligence",
    "/questionnaire",
    "/questionnaire/:workstreamId",
    "/table",
    "/table/:transactionId",
    "/overview",
    "/valuation",
    "/audit",
    "/upload",
    "/upload/:transactionId",
    "/checklist",
    "/cashflow",
    "/debt",
    "/market",
    "/capexplan",
    "/summary",
    "/pipeline",

    "/validation",
    "/validation/:docId",
    // Add any other paths as needed
  ];

  const shouldShowNavigationBar = (isAuthenticated || 1) && pathsWhereNavigationBarShouldBeShown.some((path) => location.pathname === path || location.pathname.startsWith(path + "/"));
  return shouldShowNavigationBar ? <NavBar logo={logoImage2}>{children}</NavBar> : children;
};

const HandleAuthCode = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (hash.includes("code=")) {
      // Extract the code from the hash
      const code = new URLSearchParams(hash.substring(1)).get("code");
      if (code) {
        // Here, you could dispatch this code to your global state, or perform an API call
        // For example, exchanging the code for an access token

        // Then navigate to the intended route
        navigate("/data-import", { replace: true });
      }
    }
  }, [location, navigate]);

  // This component does not render anything, or it could render a loading indicator
  return null;
};

const AppRouter = () => {
  const state = store.getState();
  const isAuthenticated = state.auth.isAuthentificated;
  // console.log("isAuthenticated", isAuthenticated);
  const dispatch = useDispatch();
  const accounts = [];

  const isLoading = useSelector((state) => state.auth.isLoading); // Adjust according to your Redux setup
  // console.log("isLoading", isLoading);
  // console.log("accounts", accounts);

  // if user authenticated, get user info
  useEffect(() => {
    if (isAuthenticated && accounts?.length > 0) {
      // console.log(accounts);
      // return;
      dispatch(getUser(accounts[0]?.idTokenClaims?.oid));
    }
  }, [isAuthenticated]);

  return (
    <Router>
      {/* {showNavigationBar && <NavigationBar selected={"data-import"} />} */}
      {/* <TokenAcquirer /> */}

      {isAuthenticated ? (
        <>
          <NavigationBarWrapper>
            <Routes>
              <Route path="/" element={<Navigate to="/buyers" replace />} />
              {/* <Route path="/sellers" element={<Sellers />} /> */}
              <Route path="/buyers" element={<Buyers />} />
              {/* <Route path="/advisors" element={<Advisors />} /> */}
              <Route path="/overview" element={<Overview />} />
              <Route path="/audit" element={<Audit />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/upload/:transactionId" element={<Select />} />
              <Route path="/validation/:docId" element={<Validation />} />
              <Route path="/valuation" element={<Valuation />} />
              <Route path="/cashflow" element={<Cashflow />} />
              <Route path="/checklist" element={<Checklist />} />
              <Route path="/debt" element={<Debt />} />
              <Route path="/market" element={<Market />} />
              <Route path="/capexplan" element={<CapexPlan />} />
              <Route path="/summary" element={<Summary />} />
              <Route path="/pipeline" element={<Pipeline />} />
              <Route path="/sign-in" element={<SignInScreen />} />
              <Route path="/sign-up" element={<SignUpScreen />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </NavigationBarWrapper>
        </>
      ) : (
        <>
          <NavigationBarWrapper>
            <Routes>
              <Route path="/" element={<Navigate to="/buyers" replace />} />
              {/* <Route path="/sellers" element={<Sellers />} /> */}
              <Route path="/buyers" element={<Buyers />} />
              {/* <Route path="/advisors" element={<Advisors />} /> */}
              <Route path="/sign-in" element={<SignInScreen />} />
              <Route path="/sign-up" element={<SignUpScreen />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              {/* <Route path="/overview" element={<Overview />} />
              <Route path="/audit" element={<Audit />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/valuation" element={<Valuation />} />
              <Route path="/cashflow" element={<Cashflow />} />
              <Route path="/checklist" element={<Checklist />} />
              <Route path="/debt" element={<Debt />} />
              <Route path="/market" element={<Market />} />
              <Route path="/capexplan" element={<CapexPlan />} />
              <Route path="/summary" element={<Summary />} />
              <Route path="/pipeline" element={<Pipeline />} /> */}
            </Routes>
          </NavigationBarWrapper>
        </>
      )}
    </Router>
    // </NotificationsProvider>
  );
};

export default AppRouter;
