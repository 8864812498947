"use client";
import { useState } from "react";
import { Row } from "@tanstack/react-table";
import { MoreHorizontal, MoveRight } from "lucide-react";

import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { useDispatch, useSelector } from "react-redux";
import { setActivePdf, setPdfLoadingTask } from "../../../redux/actions/documentsAction";
import { usePdfFocus } from "../../../components/pdf-viewer/pdfContext";
import { labels } from "./data/data";
import { taskSchema } from "./data/schema";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { findDocument, getDocumentById } from "../../../utils/utils";
import * as pdfjsLib from "pdfjs-dist/webpack";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  // onAction: (action: string, rowData: TData) => void; // Callback function to handle actions
}

export function DataTableRowActions<TData>({ row }: DataTableRowActionsProps<TData>) {
  const loadingTask = useSelector((state: any) => state.documents.loadingTask);
  const documents = useSelector((state: any) => state.documents.documents);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const { setPdfFocusState } = usePdfFocus();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const task = taskSchema.parse(row.original);

  console.log(row);
  const handleTableClick = async (pageNumber, documentId) => {
    console.log(pageNumber, documentId, "table");
    if (!documentId) return;
    const foundDoc = documents.flatMap((transaction) => transaction.documents).find((doc) => String(doc.id) === documentId);
    console.log("foundDoc", foundDoc);
    if (!foundDoc) {
      console.error("Document not found");
      return;
    }

    try {
      if (loadingTask?.destroy) {
        await loadingTask.destroy();
      }

      const newLoadingTask = pdfjsLib.getDocument(foundDoc.url);
      await dispatch(setPdfLoadingTask(newLoadingTask));

      const document = getDocumentById(documents, documentId);
      await dispatch(setActivePdf(document));

      await new Promise((resolve) => setTimeout(resolve, 1000));

      await newLoadingTask.promise;

      const citationData = {
        documentId,
        pageNumber: pageNumber,
        citation: undefined,
      };

      setPdfFocusState(citationData);
    } catch (error) {
      console.error("Failed to load PDF:", error);
      // TODO: Handle error (e.g., show error notification)
    }
  };
  return (
    <>
      <Button
        variant={"ghost"}
        onClick={() => {
          handleTableClick(String(Number(row.id) + 1), String(0));
        }}
      >
        <MoveRight />
      </Button>
    </>
  );
}
