import { TrendingUp } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import * as Slider from "@radix-ui/react-slider";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { any, z } from "zod";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage, FormDescription } from "../../components/ui/form";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import IRR from "../CapexPlanScreen/_irrResult";
import Payback from "../CapexPlanScreen/_payback";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../redux/store";
import { toast } from "sonner";
import { cn } from "../../lib/utils";

import { updateDebt, updatePurchasePrice } from "../../redux/actions/cashflowAction";
import { useTranslation } from "react-i18next";
const calculateUnleveredIRR = (cashFlows) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Create arrays for values and dates
  const values = cashFlows.map((cf) => Number(cf.freeCashFlow));
  const dates = cashFlows.map((cf) => (cf.year - cashFlows[0].year) * 365);

  // Step 3: Call the IRR function
  return IRR(values, 0.1) * 100;
};
const calculatePaybackPeriod = (cashFlows, investment) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Extract free cash flows into an array
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));
  const pb = Payback(cashFlowValues, 0);
  return pb;
};
const calculateCashOnCashReturn = (cashFlows) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Extract free cash flows into an array
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));

  // Step 3: Calculate the total cash flow if they are positive
  const totalCashFlow = cashFlowValues.reduce((acc, value) => (value > 0 ? acc + value : acc), 0);

  // Step 4: Calculate the investment cashflow (sum of negative cash flows)

  const investmentCashFlow = cashFlowValues.reduce((acc, value) => (value < 0 ? acc + value : acc), 0);

  // Step 5: Calculate the cash on cash return

  return totalCashFlow / Math.abs(investmentCashFlow);
};
function mapCashFlow(data: any, investment: any, exitValue: any) {
  // Extract years and sort to determine the range
  const years = data.map((item) => item.year).sort((a, b) => a - b);
  const firstYear = years[0];
  const lastYear = years[years.length - 1];

  // Prepare the transformed list
  const mappedCashFlows: any = [];

  // Add the investment year (1 year before first year)
  mappedCashFlows.push({
    year: firstYear - 1,
    freeCashFlow: investment,
    isForecasted: false, // Investment typically not forecasted
  });

  // Map existing data
  data.forEach((item) => {
    mappedCashFlows.push({
      year: item.year,
      freeCashFlow: item["Free Cash Flow"],
      isForecasted: item.isForecasted,
    });
  });

  // Add the exit year (1 year after last year)
  mappedCashFlows.push({
    year: lastYear + 1,
    freeCashFlow: exitValue,
    isForecasted: false, // Exit typically not forecasted
  });

  return mappedCashFlows;
}

export function TooltipIcon({ content, cs = "" }: { content: string; cs?: string }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Info className={cn("h-4 w-4 text-gray-500 hover:text-black", cs)} />
          </span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p className="color-white">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

interface StatItemProps {
  title: string;
  tooltipContent: string;
  value: string | number;
  suffix?: string; // Optional suffix like %, $, x
}

const StatItem: React.FC<StatItemProps> = ({ title, tooltipContent, value, suffix = "" }) => {
  return (
    <div className="flex flex-col text-left">
      <div className="flex items-center space-x-2">
        <CardDescription className="text-sm font-medium">{title}</CardDescription>
        <TooltipIcon content={tooltipContent} />
      </div>
      <div className="text-base sm:text-lg 2lg:text-xl font-bold">
        {value}
        {suffix}
      </div>
    </div>
  );
};

function getLastYearValue(data) {
  if (data.length === 0) {
    throw new Error("The data array is empty.");
  }

  // Sort the data by year in ascending order
  const sortedData = data.sort((a, b) => a.year - b.year);

  // Get the last year object (largest year)
  const lastYearObject = sortedData[sortedData.length - 1];

  return lastYearObject["Revenue"];
}
function getLastYearDebt(data) {
  if (data.length === 0) {
    throw new Error("The data array is empty.");
  }

  // Sort the data by year in ascending order
  const sortedData = data.sort((a, b) => a.year - b.year);

  // Get the last year object (largest year)
  const lastYearObject = sortedData[sortedData.length - 1];

  return lastYearObject["Debt Ending Balance"];
}

export default function ProgressPrice({ lowerLimit, upperLimit, price, ebitda, earnings, transaction, newDebt, fcf, fcfe, rev_pro, pricing, debtending }) {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();

  // State variables for dynamic values
  const [purchasePrice, setPurchasePrice] = useState(pricing.purchasePrice || price); // Default purchase price
  const [multiple, setmultiple] = useState(pricing.multiple || transaction.revenueMultiple); // Default purchase price
  const [lowerBound, setLowerBound] = useState(lowerLimit);
  const [upperBound, setUpperBound] = useState(upperLimit);
  const [trackWidth, setTrackWidth] = useState(0); // Track width state
  const { t } = useTranslation(); // ✅ Use translation hook
  // Calculated metrics based on purchase price
  // console.log(debtending, getLastYearDebt(debtending));
  const cashf = mapCashFlow(fcf, -purchasePrice, getLastYearValue(rev_pro) * multiple);
  const irr = calculateUnleveredIRR(cashf); // Example calculation
  const pb = calculatePaybackPeriod(cashf, 0).toFixed(2);

  const cashonCashReturn = calculateCashOnCashReturn(cashf);
  const totalPotentialProfit = purchasePrice / ebitda;
  const equityMultiple = purchasePrice / earnings;
  const FormSchema = z.object({
    purchasePrice: z.number().refine((value) => value >= lowerBound, {
      message: `Value must be at least $${lowerBound.toLocaleString()}.`,
    }),
  });
  const resetState = () => {
    setPurchasePrice(price);
    setmultiple(transaction.revenueMultiple);
  };
  // Initialize the form
  const form = useForm({
    resolver: zodResolver(FormSchema),
    mode: "onChange", // Validate dynamically on change
    defaultValues: {
      purchasePrice: purchasePrice,
      exitMultiple: multiple,
    },
  });
  // Handle slider change
  const handleSliderChange = (value) => {
    setPurchasePrice(value[0]);
    form.setValue("purchasePrice", value[0], { shouldValidate: true });
  };
  const trackRef = useRef<HTMLDivElement>(null); // Typed ref for Slider.Track

  // Calculate and update the width of the Slider.Range
  useEffect(() => {
    if (trackRef.current) {
      const trackElement = trackRef.current;
      setTrackWidth(trackElement.offsetWidth);
    }
  }, []);

  const onSubmit = (data) => {
    console.log("Valid Data:", data);
  };
  const handleErasePrice = async () => {
    const debtdata = {};
    console.log("debtdata", debtdata);
    await dispatch(updatePurchasePrice(transaction.id, debtdata));
    resetState();
    toast("Deal Updated", {
      description: `The original deal price has been restored`,
    });
  };
  const handleSavePrice = async () => {
    const pricedata = {
      multiple,
      purchasePrice,
    };
    await dispatch(updatePurchasePrice(transaction.id, pricedata));

    toast("Price Updated", {
      description: `The new pricing will now be reflected on the deal`,
    });
  };
  return (
    <Card className="col-span-4 max-w-full">
      {/* Header */}
      <CardHeader>
        <CardTitle>
          {
            // @ts-ignore
            t("progressPrice.title")
          }
        </CardTitle>
        <CardDescription>
          <div className="flex">
            <div className="w-[70%]">
              {" "}
              {
                // @ts-ignore
                t("progressPrice.estimate", { lower: lowerBound.toLocaleString(), upper: upperBound.toLocaleString() })
              }
            </div>
            <div className="ml-auto">
              <Button
                variant={"ghost"}
                onClick={() => {
                  handleErasePrice();
                }}
              >
                {
                  //  @ts-ignore
                  t("progressPrice.removeScenario")
                }
              </Button>
              <Button
                onClick={() => {
                  handleSavePrice();
                }}
              >
                {
                  // @ts-ignore
                  t("progressPrice.saveScenario")
                }
              </Button>
            </div>
          </div>
        </CardDescription>
      </CardHeader>

      <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card className="col-span-2 p-8 grid grid-cols-2 gap-4 shadow-none border-none">
          <div className="col-span-2">
            <div className="flex flex-grow flex-wrap items-center w-full space-x-2">
              <div className="flex-grow flex justify-between ">
                <StatItem
                  title={
                    // @ts-ignore
                    t("overview.stats.irr.title")
                  }
                  tooltipContent={
                    // @ts-ignore
                    t("overview.stats.irr.tooltip")
                  }
                  value={irr.toFixed(0)}
                  suffix="%"
                />
                <StatItem
                  title={
                    // @ts-ignore
                    t("overview.stats.payback.title")
                  }
                  tooltipContent={
                    // @ts-ignore
                    t("overview.stats.payback.tooltip")
                  }
                  value={`${pb.toLocaleString()} `}
                  suffix="years"
                />
                <StatItem
                  title={
                    // @ts-ignore
                    t("overview.stats.ask_price.title")
                  }
                  tooltipContent={
                    // @ts-ignore
                    t("overview.stats.ask_price.tooltip")
                  }
                  value={transaction.askPrice ? transaction.askPrice.toLocaleString() : "N/A"}
                  suffix="$"
                />
                <StatItem
                  title={
                    // @ts-ignore
                    t("overview.stats.equity_contribution.title")
                  }
                  tooltipContent={
                    // @ts-ignore
                    t("overview.stats.equity_contribution.tooltip")
                  }
                  value={`${Math.round(purchasePrice - newDebt).toLocaleString()}`}
                />
              </div>
              <div className="flex-grow flex justify-between">
                <StatItem
                  title={
                    // @ts-ignore
                    t("overview.stats.debt_raised.title")
                  }
                  tooltipContent={
                    // @ts-ignore
                    t("overview.stats.debt_raised.tooltip")
                  }
                  value={`${Math.round(newDebt).toLocaleString()}`}
                />
                <StatItem
                  title={
                    // @ts-ignore
                    t("overview.stats.entry_ebitda.title")
                  }
                  tooltipContent={
                    // @ts-ignore
                    t("overview.stats.entry_ebitda.tooltip")
                  }
                  value={`${totalPotentialProfit.toFixed(1)}`}
                  suffix="x"
                />

                <StatItem
                  title={
                    // @ts-ignore
                    t("overview.stats.entry_revenue.title")
                  }
                  tooltipContent={
                    // @ts-ignore
                    t("overview.stats.entry_revenue.tooltip")
                  }
                  value={equityMultiple.toFixed(1)}
                  suffix="x"
                />
                <StatItem
                  title={
                    // @ts-ignore
                    t("overview.stats.money_on_money_multiple.title")
                  }
                  tooltipContent={
                    // @ts-ignore
                    t("overview.stats.money_on_money_multiple.tooltip")
                  }
                  value={cashonCashReturn.toFixed(1)}
                  suffix="x"
                />
              </div>
            </div>
          </div>
        </Card>
        {/* Left Section: Purchase Price */}
        <Card className="col-span-2 p-4 bg-muted text-muted-foreground flex flex-col space-y-4 justify-center gap-2">
          <CardContent className="flex flex-col items-center justify-center">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex items-start justify-between">
                {/* Purchase Price Field */}
                <div className="w-[60%]">
                  <FormField
                    control={form.control}
                    name="purchasePrice"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>
                          {
                            // @ts-ignore
                            t("progressPrice.purchasePrice")
                          }
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            inputMode="numeric"
                            value={field.value !== undefined ? `$${field.value.toLocaleString()}` : ""}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                              if (/^\d*$/.test(rawValue)) {
                                const numericValue = Number(rawValue);
                                field.onChange(numericValue); // Update form value
                                setPurchasePrice(numericValue); // Sync local state
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage>{fieldState.error?.message}</FormMessage> {/* Show error dynamically */}
                      </FormItem>
                    )}
                  />

                  <div className="flex gap-2 items-center w-full space-y-2">
                    {/* Lower Bound */}
                    <div className="mt-1 text-xs text-gray-600">${lowerBound.toLocaleString()}</div>

                    {/* Slider */}
                    <Slider.Root value={[purchasePrice]} min={lowerBound} max={upperBound} step={10000} onValueChange={handleSliderChange} className="relative mt-0 flex items-center w-full h-6">
                      {/* Track */}
                      <Slider.Track ref={trackRef} className="relative bg-gray-200 h-2 rounded-full w-full">
                        {/* Range */}
                        <Slider.Range className="absolute bg-black h-2 rounded-full" style={{ width: `${trackWidth}px` }} />
                      </Slider.Track>
                      {/* Thumb */}
                      <Slider.Thumb className="SliderThumb" asChild>
                        <div className="absolute flex items-center justify-center w-4 h-4 bg-white border-2 border-black rounded-full -translate-x-[50%] -translate-y-[50%]" />
                      </Slider.Thumb>
                    </Slider.Root>

                    {/* Upper Bound */}
                    <div className="text-xs text-gray-600">${upperBound.toLocaleString()}</div>
                  </div>
                </div>
                {/* exitMultiple */}
                <div className="mt-2">
                  <FormField
                    control={form.control}
                    name="exitMultiple"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex gap-2 mt-0">
                          {
                            // @ts-ignore
                            t("progressPrice.revenueExitMultiple")
                          }
                          <TooltipIcon
                            content={
                              // @ts-ignore
                              t("progressPrice.revenueExitMultipleTooltip")
                            }
                          />
                        </FormLabel>
                        <FormControl>
                          <Input type="number" step="0.1" value={multiple.toFixed(1)} onChange={(e) => setmultiple(Number(e.target.value))} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </form>
            </Form>
          </CardContent>
        </Card>

        {/* Right Section: Metrics */}
      </CardContent>
    </Card>
  );
}
