import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import { Skeleton } from "../../../components/ui/skeleton";

export function CardItem({ title, icon, value, description, loading }) {
  return (
    <Card className="transition-colors hover:bg-muted hover:cursor-pointer">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{loading ? <Skeleton className="h-4 w-24" /> : title}</CardTitle>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="h-4 w-4 text-muted-foreground" dangerouslySetInnerHTML={{ __html: icon }} />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{loading ? <Skeleton className="h-8 w-20" /> : value}</div>
        <p className="text-xs text-muted-foreground">{loading ? <Skeleton className="h-4 w-36" /> : description}</p>
      </CardContent>
    </Card>
  );
}

export default function Cards({ loading }) {
  const cardData = [
    {
      title: "Earnings",
      icon: '<path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />',
      value: "$12,234",
      description: "+20.1% from last year",
    },
    {
      title: "Return",
      icon: `
        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
        <circle cx="9" cy="7" r="4" />
        <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
      `,
      value: "30%",
      description: "Based on 3 companies",
    },
    {
      title: "Industry",
      icon: `
        <rect width="20" height="14" x="2" y="5" rx="2" />
        <path d="M2 10h20" />
      `,
      value: "Healthcare",
      description: "Hospitals",
    },
    {
      title: "Location",
      icon: '<path d="M22 12h-4l-3 9L9 3l-3 9H2" />',
      value: "Düsseldorf",
      description: "City in Germany",
    },
  ];

  return (
    <>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        {cardData.map((card, index) => (
          <CardItem key={index} loading={loading} title={card.title} icon={card.icon} value={card.value} description={card.description} />
        ))}
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7"></div>
    </>
  );
}
