import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import DashboardPage from "./DashboardPage";
import Cards from "./components/cards";
import DebtPricing from "./DebtPricing";
import { useState, useEffect, useMemo } from "react";
import AlertDestructive from "./components/alert";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import { extractFinancialItem } from "../../utils/utils";
import { fetchDebt } from "../../redux/actions/cashflowAction";
import { useTranslation } from "react-i18next";
const extractLast = (cashflow, itemname) => {
  if (cashflow.length === 0) return null;
  const item = extractFinancialItem(cashflow, itemname, null, false);
  const last = item[item.length - 1];
  // console.log(last)
  return last;
};

export default function Debt() {
  const dispatch = useDispatch();
  const minideal = useSelector((state) => state.cashflow.miniDeal);

  const debt = useSelector((state) => state.cashflow.debt);
  // console.log(minideal);
  const [purchaseCost, setPurchaseCost] = useState(2072000);
  const [amortization, setAmortization] = useState(debt?.amortization || minideal.amortization || 20);
  const [dscr, setDscr] = useState(debt?.dscr || minideal.dscr || 2.0);
  const [cashSweep, setCashSweep] = useState(debt?.cashSweep || 0);
  const [maximumDebt, setMaximumDebt] = useState(0);
  const [customDebt, setCustomDebt] = useState(debt?.customDebt || 0);
  const [cashAlert, setcashAlert] = useState(true);
  const transaction = useSelector((state) => state.transactions?.transaction);
  const cashflow = useSelector((state) => state.cashflow.tableData.cashFlow);

  const LTM_CFADS = cashflow ? extractLast(cashflow, "Free Cash Flow")["Free Cash Flow"] : null;
  const interest = cashflow ? extractLast(cashflow, "Payable Interest Percentage")["Payable Interest Percentage"] : null;
  // console.log(interest);
  const [noi, setNoi] = useState(debt?.noi || LTM_CFADS || (minideal.projections && minideal.projections.length > 0 && Number(minideal?.projections[0]?.cashFlow)) || transaction.cashFlow);
  // console.log(noi , debt.noi , LTM)
  const [interestRate, setInterestRate] = useState(debt?.interestRate || minideal?.interestRate || interest * 100 || 16);
  // console.log(LTM_CFADS);
  // console.log(cashflow);
  // console.log(data);
  const fetch = async () => {
    const debt = await dispatch(fetchDebt(transaction.id));
    console.log(debt);
  };
  useEffect(() => {
    fetch();
  }, [transaction]);

  const calculateMaximumDebt = (fcf, amortization, dscr, interestRate) => {
    let totalDebt = 0;
    const cfads_arr = [];
    for (let year = 0; year < amortization; year++) {
      const currentNOI = Math.max(Number(fcf[year]), 0);
      // CFADS for the period is the current period's cash flow divided by the DSCR
      const cfads = currentNOI / dscr;
      // Discount the CFADS back to present value. (year + 1) because we start at year 0.
      const discountFactor = Math.pow(1 + interestRate / 100, year + 1);
      totalDebt += cfads / discountFactor;
      console.log(cfads);
      console.log("year", year, cfads / discountFactor, totalDebt);
      cfads_arr.push(cfads);
    }
    console.log(cfads_arr);
    return Math.round(totalDebt);
  };

  // Update maximum debt whenever any dependency changes (including fcf)
  let fcf = [];
  if (cashflow) {
    fcf = extractFinancialItem(cashflow, "Free Cash Flow", null, true).map((e) => {
      const n = Number(e["Free Cash Flow"]);
      return n;
      // return Math.abs(n) * 100;
    });
  } else {
    fcf = [noi];
  }
  console.log(fcf);
  // Assume amortization is defined and is an array
  const targetLength = amortization;
  // If fcf is shorter than amortization, extend it with its last element
  if (fcf.length < targetLength) {
    // Make sure there is at least one element to copy.
    const lastValue = fcf.length > 0 ? fcf[fcf.length - 1] : 0; // or any default value you prefer
    while (fcf.length < targetLength) {
      fcf.push(lastValue);
    }
  }
  // If fcf is longer than amortization, slice it
  else if (fcf.length > targetLength) {
    fcf = fcf.slice(0, targetLength);
  }
  console.log(fcf.length);
  useEffect(() => {
    if (fcf && fcf.length > 0) {
      const debt = calculateMaximumDebt(fcf, amortization, dscr, interestRate);
      setMaximumDebt(debt);
    }
  }, [noi, amortization, dscr, interestRate, customDebt, transaction]);

  /**
   * Calculate the debt repayment schedule using the FCF for each period.
   * For each period, we use the corresponding fcf value instead of a fixed NOI.
   */
  const calculateDebtRepayment = () => {
    // Use custom debt if provided; otherwise, start with the maximum debt calculated above.
    // setcashAlert(false);
    let debtBalance = customDebt === 0 ? maximumDebt : customDebt;
    const schedule = [];

    // If there is no free cash flow data, return an empty schedule.
    if (!fcf || fcf.length === 0) return [];

    // Loop through each period. We assume that amortization equals fcf.length.
    for (let year = 0; year < amortization; year++) {
      const currentNOI = Math.max(fcf[year]);
      console.log(currentNOI);
      const debtService = currentNOI / dscr;
      const interestPayment = debtBalance * (interestRate / 100);
      const principalPayment = Math.max(Math.min(debtService - interestPayment, debtBalance), 0);
      debtBalance -= principalPayment;
      const cashAvailable = currentNOI - interestPayment - principalPayment;
      const cashSweepPayment = Math.min(cashAvailable * cashSweep, debtBalance);
      const dividends = cashAvailable - cashSweepPayment;

      // Subtract the cash sweep payment from the remaining debt balance
      debtBalance -= cashSweepPayment;

      // Push the schedule for the current period (displaying year as 1-indexed)
      // if (dividends < 0) setcashAlert(true);
      schedule.push({
        year: year + 1,
        noi: currentNOI.toFixed(2),
        debtService: debtService.toFixed(2),
        interestPayment: interestPayment.toFixed(2),
        principalPayment: principalPayment.toFixed(2),
        cashSweepPayment: cashSweepPayment.toFixed(2),
        dividends: dividends.toFixed(2),
        debtBalance: debtBalance.toFixed(2),
      });

      // If the debt is fully repaid, exit the loop early.
      if (debtBalance <= 0) break;
    }

    return schedule;
  };
  const schedule = useMemo(calculateDebtRepayment, [fcf, amortization, dscr, cashSweep, interestRate, maximumDebt, customDebt, transaction]);
  const negdiv = Math.min(...schedule.map((e) => Number(e.dividends)));
  console.log(negdiv);
  console.log(schedule);
  const { t } = useTranslation();
  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {/* <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {
                    // @ts-ignore
                    t("debt.breadcrumb")
                  }
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        {/* <Cards /> */}
        {maximumDebt < customDebt && <AlertDestructive setCustomDebt={setCustomDebt} />}
        {customDebt > 0 && customDebt < maximumDebt && <AlertDestructive setCustomDebt={setCustomDebt} buttonText={t("alert.set_maximum_debt")} title={t("alert.custom_debt_title")} description={t("alert.custom_debt_description")} variant={0} />}
        {negdiv < 0 && customDebt == 0 && <AlertDestructive setCustomDebt={setCustomDebt} buttonText={t("alert.go_to_cashflow")} title={t("alert.custom_debt_title_2")} description={t("alert.custom_debt_description_2")} gotocash={1} />}
        <div className="grid auto-rows-min gap-y-4 gap-x-4 md:grid-cols-2">
          <div className="col-span-2">
            <DebtPricing
              noi={noi}
              setNoi={setNoi}
              purchaseCost={purchaseCost}
              setPurchaseCost={setPurchaseCost}
              amortization={amortization}
              setAmortization={setAmortization}
              dscr={dscr}
              setDscr={setDscr}
              cashSweep={cashSweep}
              setCashSweep={setCashSweep}
              interestRate={interestRate}
              setInterestRate={setInterestRate}
              maximumDebt={maximumDebt}
              setMaximumDebt={setMaximumDebt}
              calculateMaximumDebt={calculateMaximumDebt}
              schedule={schedule}
              customDebt={customDebt}
              setCustomDebt={setCustomDebt}
              transaction={transaction}
              minideal={minideal}
              debt={debt}
              LTM_CFADS={LTM_CFADS}
              cashflow={cashflow}
              fcf={fcf}
            />
          </div>

          {/* Two Dashboard Pages in a single row */}
          <div className="grid grid-cols-2 gap-4 col-span-2">
            <DashboardPage
              title={
                // @ts-ignore
                t("debt.forecasted_outstanding")
              }
              overview={true}
              schedule={schedule}
            />
            <DashboardPage
              title={
                // @ts-ignore
                t("debt.forecasted_repayment")
              }
              overview={false}
              schedule={schedule}
            />
          </div>
        </div>
      </div>
    </SidebarInset>
  );
}
