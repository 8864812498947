function calculatePaybackPeriod(cashFlows, investment) {
  let cumulativeCashFlow = -investment; // Start with the initial investment as a negative value
  let yearsBeforeBreakEven = 0;

  // Loop through each year's cash flow
  for (let i = 0; i < cashFlows.length; i++) {
    cumulativeCashFlow += cashFlows[i]; // Add cash flow for the year

    if (cumulativeCashFlow >= 0) {
      // Calculate the payback period
      const unrecoveredAmount = cumulativeCashFlow - cashFlows[i];
      const paybackPeriod = yearsBeforeBreakEven + Math.abs(unrecoveredAmount) / cashFlows[i];
      return paybackPeriod; // Return the payback period
    }

    yearsBeforeBreakEven++; // Increment years before break-even
  }

  // If break-even is never achieved
  return NaN;
}

export default calculatePaybackPeriod;
