import React, { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../../../components/ui/charts";

// Chart configuration for debt repayment breakdown
const chartConfig = {
  interestPayment: {
    label: "Interest Payment",
    color: "#8884d8", // Blue
  },
  principalPayment: {
    label: "Principal Payment",
    color: "#82ca9d", // Green
  },
  cashSweepPayment: {
    label: "Cash Sweep Payment",
    color: "#ffc658", // Yellow
  },
  dividends: {
    label: "Dividends",
    color: "#ff8042", // Orange
  },
} satisfies ChartConfig;

// Custom Tooltip for Debt Repayment Breakdown
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white shadow-lg rounded p-2 text-sm">
        <p className="font-bold">{label}</p>
        {payload.map((entry: any, index: number) => (
          <div key={`tooltip-item-${index}`} className="flex items-center space-x-2">
            <div
              className="w-3 h-3 rounded-sm"
              style={{
                backgroundColor: entry.color,
              }}
            />
            <p>
              {entry.name}: <span className="font-medium">${new Intl.NumberFormat("en-US").format(Math.round(entry.value))}</span>
            </p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export function DebtRepaymentChart({ schedule }: { schedule: any[] }) {
  // Data preparation for stacked bar chart
  const chartData = useMemo(() => {
    return schedule.map((row) => ({
      year: `Year ${row.year}`,
      interestPayment: parseFloat(row.interestPayment),
      principalPayment: parseFloat(row.principalPayment),
      cashSweepPayment: parseFloat(row.cashSweepPayment),
      dividends: parseFloat(row.dividends),
    }));
  }, [schedule]);

  return (
    <ChartContainer config={chartConfig} className="w-full h-64">
      <ResponsiveContainer>
        <BarChart data={chartData} accessibilityLayer>
          <XAxis dataKey="year" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
          <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false} domain={["auto", "auto"]} tickFormatter={(value) => `$${new Intl.NumberFormat("en-US").format(Math.round(value))}`} />
          <Tooltip content={<CustomTooltip />} />
          {/* Interest Payment */}
          <Bar dataKey="interestPayment" name="Interest Payment" stackId="a" fill={chartConfig.interestPayment.color} />
          {/* Principal Payment */}
          <Bar dataKey="principalPayment" name="Principal Payment" stackId="a" fill={chartConfig.principalPayment.color} />
          {/* Cash Sweep Payment */}
          <Bar dataKey="cashSweepPayment" name="Cash Sweep Payment" stackId="a" fill={chartConfig.cashSweepPayment.color} />
          {/* Dividends */}
          <Bar dataKey="dividends" name="Dividends" stackId="a" fill={chartConfig.dividends.color} />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
