"use client";
import { cn } from "../../lib/utils";

import { ChevronRight, type LucideIcon } from "lucide-react";
import { BookOpen, Bot, Command, Frame, LifeBuoy, Map, PieChart, Send, Settings2, SquareTerminal, House, FileUp, Tag, ScanSearch, PiggyBank, ListChecks, MapPinHouse, ArrowBigUpDash, TrendingUpDown, Gauge } from "lucide-react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../ui/collapsible";
import { SidebarGroup, SidebarGroupLabel, SidebarMenu, SidebarMenuAction, SidebarMenuButton, SidebarMenuItem, SidebarMenuSub, SidebarMenuSubButton, SidebarMenuSubItem } from "../ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { useTranslation } from "react-i18next";

export function TooltipIcon({ content, children }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">{children}</span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p className="color-white">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
export function NavMain({
  items,
  transaction,
}: {
  items: {
    title: string;
    url: string;
    icon: LucideIcon;
    isActive?: boolean;
    items?: {
      title: string;
      url: string;
      icon: LucideIcon;
      isActive?: boolean;
    }[];
  }[];
  transaction: any;
}) {
  const location = useLocation();
  const isSelected = (path) => location.pathname === path || location.pathname.split("/")[1] === path.split("/")[1];
  const [openStates, setOpenStates] = React.useState({});

  React.useEffect(() => {
    setOpenStates({});
  }, [transaction]);

  const handleToggle = (key) => {
    setOpenStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  const { t } = useTranslation(); // i18n Hook for translations

  return (
    <SidebarGroup>
      <SidebarGroupLabel>
        {
          //@ts-ignore
          t("nav.opportunity_details")
        }
      </SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => (
          <Collapsible open={openStates[item.title] ?? item.isActive} onOpenChange={() => handleToggle(item.title)} key={item.title} asChild>
            <SidebarMenuItem>
              <SidebarMenuButton asChild tooltip={item.title}>
                <CollapsibleTrigger asChild>
                  <Link to={item.url} style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
                    <item.icon />
                    <span>{item.title}</span>
                  </Link>
                </CollapsibleTrigger>
                {/* <a href={item.url}>
                  <item.icon />
                  <span>{item.title}</span>
                </a> */}
              </SidebarMenuButton>
              {item.items?.length ? (
                <>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuAction className="data-[state=open]:rotate-90">
                      <ChevronRight />
                      <span className="sr-only">Toggle</span>
                    </SidebarMenuAction>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.items?.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuSubButton className={cn(isSelected(subItem.url) && "bg-sidebar-accent text-sidebar-accent-foreground ")} asChild>
                            <Link
                              to={item.isActive && subItem.isActive ? subItem.url : "#"}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                                color: item.isActive && subItem.isActive ? "inherit" : "gray",
                                pointerEvents: item.isActive && subItem.isActive ? "auto" : "none",
                                opacity: item.isActive && subItem.isActive ? 1 : 0.5,
                                cursor: item.isActive && subItem.isActive ? "pointer" : "not-allowed",
                              }}
                            >
                              <subItem.icon />
                              <span>{subItem.title}</span>
                            </Link>
                            {/* <a href={subItem.url}>
                              <span>{subItem.title}</span>
                            </a> */}
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </>
              ) : null}
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
