"use client";

import { ColumnDef } from "@tanstack/react-table";

import { Badge } from "../../../components/ui/badge";
import { Checkbox } from "../../../components/ui/checkbox";

import { labels, priorities, statuses } from "../data/data";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";

export const getColumns = (t: Function): ColumnDef<Task>[] => [
  {
    accessorKey: "name",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.opportunity")} />,
    cell: ({ row }) => {
      // console.log(row);
      const label = row.original.country && row.original.country === "" ? false : row.original.country;
      // console.log(label);

      return (
        <div className="flex space-x-2">
          {label && <Badge variant="outline">{label}</Badge>}
          <span className="max-w-[500px] truncate font-medium">{row.getValue("name")}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "pipelineStatus",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.status")} />,
    cell: ({ row }) => {
      const status = statuses.find((status) => status.value === row.getValue("pipelineStatus"));

      if (!status) {
        return null;
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "city",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.location")} />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[100px] items-center">
          {/* {status.icon && <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />} */}
          <span>{row.getValue("city")}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "industry",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.industry")} />,
    cell: ({ row }) => {
      const label = labels.find((label) => label.value === row.original.industry);
      // if (!label) {
      //   return null;
      // }
      return (
        <div className="flex w-[100px] items-center">
          {label?.icon && <label.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
          <span>{row.getValue("industry")}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "estPrice",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.estPrice")} />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[100px] items-center">
          {/* {status.icon && <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />} */}
          <span>{row.getValue("estPrice")}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "askPrice",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.askPrice")} />,
    cell: ({ row }) => {
      return (
        <div className="flex w-[100px] items-center">
          {/* {status.icon && <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />} */}
          <span>{Number(row.getValue("askPrice")).toLocaleString()}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },

  {
    id: "actions",
    header: ({ column }) => <DataTableColumnHeader column={column} title={t("columns.actions")} />,
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];
