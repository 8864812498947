import React, { useEffect, useState, useRef } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "../../../hooks/use-toast";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Label } from "../../../components/ui/label";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../../components/ui/form";
import { Command, CommandInput, CommandItem, CommandGroup, CommandList, CommandEmpty } from "../../../components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
// Define schema with zod
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../../lib/utils";

import { DialogFooter, DialogClose, DialogPrimitive } from "../../../components/ui/dialog";
import { backendClient } from "../../../api/backend";

const Spinner = ({ size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" className="mr-2 h-4 w-4 animate-spin">
    <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
  </svg>
);

const FormSchema = z.object({
  industry: z.string().min(1, { message: "Please select an Industry." }),
  revenue: z
    .number({ invalid_type_error: "Revenue cannot be empty." }) // Handle missing fields
    .positive({ message: "Revenue must be a positive number." }),
  cashflow: z
    .number({ invalid_type_error: "Earnings cannot be empty." }) // Handle missing fields
    .positive({ message: "Earnings must be a positive number." }),
});

export default function ValForm({ specialclose, setRange, scrollNext, handleClose = () => {} }) {
  const [industries, setIndustries] = useState([]); // Default industry
  const [isCommandOpen, setIsCommandOpen] = useState(false);
  const commandRef = useRef(null); // Ref for detecting outside clicks
  const buttonRef = useRef(null); // Ref for the trigger button
  const popoverRef = useRef(null); // Ref for the PopoverContent
  const [isLoading, setIsLoading] = useState(false);
  console.log(industries);
  const [popoverWidth, setPopoverWidth] = useState("auto"); // State for width
  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      industry: "", // Set the default value for the industry
      revenue: "",
      cashflow: "",
    },
  });

  useEffect(() => {
    // Fetch industries from the backend
    const fetchIndustries = async () => {
      try {
        // const response = await fetch("/api/industries"); // Replace with your API endpoint
        // const data = await response.json();
        const data = await backendClient.get_industries();
        // console.log(data);
        const transformedData = Object.keys(data)
          .sort() // Sort categories alphabetically
          .map((key) => ({
            category: key,
            subcategories: data[key].sort().map((subcategory, index) => ({
              id: index,
              name: subcategory,
            })),
          }));
        // console.log(transformedData);
        setIndustries(transformedData);
        // console.log(data["industries"]);
      } catch (error) {
        console.error("Failed to fetch industries", error);
      }
    };

    fetchIndustries();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (commandRef.current && !commandRef.current.contains(event.target)) {
        setIsCommandOpen(false); // Close the Command box
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  async function onSubmit(data) {
    setIsLoading(true);

    try {
      toast({
        title: "Thank you, your estimate should be available shortly!",
      });
      const { earningsMultiple, revenueMultiple } = selected.name;
      const earningsPrice = earningsMultiple * data.cashflow;
      const revenuePrice = revenueMultiple * data.revenue;
      console.log(earningsMultiple, revenueMultiple, earningsPrice, revenuePrice);
      // Calculate min and max values
      const lowerBound = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0)); // Round down
      const upperBound = Math.floor(Math.max(earningsPrice, revenuePrice)); // Round down
      // const response = await backendClient.get_broad_valuation(data);
      setRange({ lowerBound, upperBound });
      scrollNext(1);
    } catch (error) {
      console.error("Error occurred while fetching valuation:", error);

      toast({
        title: "An error occurred",
        description: "Please try again later or contact support if the issue persists.",
        status: "error", // Use a status like "error" for different visual styling
      });
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }
  // console.log(industries);
  useEffect(() => {
    const updatePopoverWidth = () => {
      if (buttonRef.current) {
        const buttonWidth = buttonRef.current.getBoundingClientRect().width;
        setPopoverWidth(`${buttonWidth}px`); // Update popover width dynamically
      }
    };

    // Call initially and also on window resize
    updatePopoverWidth();
    window.addEventListener("resize", updatePopoverWidth);

    return () => {
      window.removeEventListener("resize", updatePopoverWidth);
    };
  }, []);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  console.log(selected);
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
        {/* Industry Selection */}
        <FormField
          control={form.control}
          name="industry"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Industry</FormLabel>
              <Popover open={open} onOpenChange={setOpen} modal={false}>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      ref={buttonRef} // Attach ref to the button
                      variant="outline"
                      role="combobox"
                      className={cn("w-full justify-between", !field.value && "text-muted-foreground")}
                    >
                      {
                        field.value || "Select industry"
                        // ? industries
                        //     .flatMap((group) => group.subcategories) // Flatten all subcategories into a single array
                        //     .find((industry) => industry.name === field.value)?.name
                      }
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent
                  ref={popoverRef} // Attach ref to the PopoverContent
                  style={{ width: popoverWidth }} // Dynamically set the width
                  className="p-0 overflow-y-auto"
                  align="start"
                >
                  <Command>
                    <CommandInput placeholder="Search industry..." />
                    <CommandList className="overflow-y-auto">
                      <CommandEmpty>No industries found.</CommandEmpty>
                      {industries.map((group) => (
                        <CommandGroup heading={group.category} key={group.category}>
                          {group.subcategories.map((industry) => (
                            <CommandItem
                              key={industry.id || industry.name?.name}
                              onSelect={() => {
                                field.onChange(industry.name?.name);
                                setOpen(false); // Update selected value
                                setSelected(industry);
                              }}
                            >
                              {industry.name?.name}
                              <Check className={cn("ml-auto", industry.name?.name === field.value ? "opacity-100" : "opacity-0")} />
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      ))}
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormDescription>Choose the industry relevant to the evaluation.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Revenue Input */}
        <FormField
          control={form.control}
          name="revenue"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Revenue</FormLabel>
              <FormControl>
                <Input
                  type="text" // Use text to allow formatting
                  inputMode="numeric" // Ensure numeric keyboard on mobile
                  placeholder="Enter revenue amount"
                  value={field.value !== undefined ? field.value.toLocaleString() : ""} // Format value with commas
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                    if (/^\d*$/.test(rawValue)) {
                      field.onChange(rawValue === "" ? undefined : Number(rawValue)); // Update value
                    }
                  }}
                />
              </FormControl>
              <FormDescription>Provide the total revenue amount for the business.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="cashflow"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Earnings</FormLabel>
              <FormControl>
                <Input
                  type="text" // Use text to allow formatting
                  inputMode="numeric" // Ensure numeric keyboard on mobile
                  placeholder="Enter earnings amount"
                  value={field.value !== undefined ? field.value.toLocaleString() : ""} // Format value with commas
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                    if (/^\d*$/.test(rawValue)) {
                      field.onChange(rawValue === "" ? undefined : Number(rawValue)); // Update value
                    }
                  }}
                />
              </FormControl>
              <FormDescription>Provide the total earnings amount for the business.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Submit Button */}
        {!specialclose ? (
          <Button disabled={isLoading} type="submit" className="mt-6 w-full">
            {isLoading ? <Spinner size={18} /> : "Submit"}
          </Button>
        ) : (
          <DialogFooter className="sm:justify-end">
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Close
              </Button>
            </DialogClose>
            {/* <DialogClose asChild> */}
            <Button disabled={isLoading} type="submit" className="sm:justify-end">
              {isLoading ? <Spinner size={18} /> : "Submit"}
            </Button>
            {/* </DialogClose> */}
          </DialogFooter>
        )}
      </form>
    </Form>
  );
}
