import { TrendingUp } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Overview } from "./components/overview";
import { DebtRepaymentChart } from "./components/costs";
import { useTranslation } from "react-i18next";

export default function DashboardPage({ title, overview, schedule }) {
  const { t } = useTranslation();
  return (
    <>
      <Card className="max-w-full">
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardDescription>
            {
              // @ts-ignore
              t("debt.over_forecast_window")
            }
          </CardDescription>
        </CardHeader>
        <CardContent className="pl-2">{overview ? <Overview schedule={schedule} /> : <DebtRepaymentChart schedule={schedule} />}</CardContent>
        <CardFooter>
          <div className="flex w-full items-start justify-between gap-2 text-sm">
            <div className="grid gap-2">
              <div className="flex items-center gap-2 font-medium leading-none">
                {
                  // @ts-ignore
                  t("debt.estimated_repayment_profile")
                }{" "}
                <TrendingUp className="h-4 w-4" />
              </div>
              <div className="flex items-center gap-2 leading-none text-muted-foreground">
                {
                  // @ts-ignore
                  t("debt.assuming_constant_cashflows")
                }
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}
