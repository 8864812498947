import { AlertCircle } from "lucide-react";

import { Alert, AlertTitle, AlertDescription, AlertAction, AlertClose } from "../../../components/ui/alert";
import { Button } from "../../../components/ui/button";
import { X } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../components/ui/input";
import { useDispatch } from "react-redux";
import { addToNewsletter } from "../../../redux/actions/authAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { toast } from "sonner";

export default function AlertDestructive({ setCustomDebt, buttonText, title, description, variant = 1, gotocash = false }) {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();

  const [dismiss, setDismiss] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    if (isValidEmail(email)) {
      // Here you would typically make an API request to your backend to handle the email sending
      // Example: axios.post('/api/send-email', { email, date: new Date() })
      const message = await dispatch(addToNewsletter(email));
      console.log("message", message);

      // Assuming the email was sent successfully
      toast(message.message, {
        description:
          // @ts-ignore
          t("alert.newsletter_success"),
      });

      setIsLoading(false);
    } else {
      alert("Please enter a valid email.");
      setIsLoading(false);
    }
    setEmail("");
  };
  const handlecash = () => {
    if (gotocash) {
      navigate("/cashflow");
    }
  };
  return dismiss ? (
    <Alert
      className="mt-4"
      variant={variant ? "destructive" : "default"}
      actions={
        <>
          <Input
            placeholder={
              // @ts-ignore
              t("overview_.alert.enter_email_placeholder")
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <AlertAction
            onClick={() => {
              handleSubmit();
            }}
          >
            {
              // @ts-ignore
              isLoading ? t("overview_.alert.supporting_button") : t("overview_.alert.support_button")
            }
          </AlertAction>
        </>
      }
    >
      {variant > 0 && <AlertCircle className="h-4 w-4" />}
      <div className="flex flex-col justify-center -mb-1">
        <AlertTitle>
          {
            // @ts-ignore
            t(title || "alert.too_much_debt_title")
          }
        </AlertTitle>
        <AlertDescription className="inline">
          {
            // @ts-ignore
            t(description || "alert.too_much_debt_description")
          }
        </AlertDescription>
      </div>
    </Alert>
  ) : null;
}
