import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import { DialogComponent } from "../../PipelineScreen/components/buttonaction";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNotifications } from "../../../router/NotificationsProvider";

const ButtonAction = () => {
  const { t } = useTranslation();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const [dis, setDis] = useState(transaction.businessChecklistUploaded);
  const [isOpen, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  // const DialogContent = {
  //   title: t("buttonAction.dialog.title"),
  //   description: t("buttonAction.dialog.description"),
  // };
  const { getObservedDocs } = useNotifications(); // Get notifications hook
  useEffect(() => {
    setDis(transaction.businessChecklistUploaded);
  }, [transaction]);
  const row = {
    original: {
      id: "businesschecklist",
      title: "Business checklist",
      type: "business-checklist",
    },
  };
  // @ts-ignore
  // const documentData = useDocumentStatus(`/deals/${transaction.id}/${row.original.type}s`, row.original.id, "status");
  const transactionId = transaction?.id;
  const businessChecklistKey = `/deals/${transactionId}/businessChecklistUploaded`;

  // Subscribe to `businessChecklistUploaded`
  // useEffect(() => {
  //   if (transactionId) {
  //     console.log(`📡 Subscribing to businessChecklistUploaded for ${transactionId}`);
  //     addListener("/deals", transactionId, "businessChecklistUploaded");
  //   }
  // }, [transactionId, addListener]);

  const getDealsListener = React.useCallback(() => {
    const dataKey = `deals_${transaction.organizationId}`;
    return getObservedDocs[dataKey] ?? [];
  }, [getObservedDocs]);
  const allDealsForOrg = getDealsListener();
  React.useEffect(() => {
    console.log("All deals for org in component:", allDealsForOrg);
  }, [allDealsForOrg]);
  // Update button state when `businessChecklistUploaded` updates
  useEffect(() => {
    if (!transactionId) return;

    const matchedDeal = allDealsForOrg.find((deal) => deal.id === transactionId);
    if (matchedDeal) {
      console.log(`🔄 Updating local checklist state: ${matchedDeal.businessChecklistUploaded}`);
      setDis(!!matchedDeal.businessChecklistUploaded);
    }
  }, [transactionId, allDealsForOrg]);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        disabled={dis} // Disable if checklist is uploaded
      >
        {
          // @ts-ignore
          t("buttonActionCheck.uploadChecklist")
        }
      </Button>
      {/* <DialogComponent isOpen={isOpen} onClose={onClose} selectedDealType={"upload"} dialogContent={DialogContent} /> */}
      <DrawerUploaderDemo row={row} isDrawerOpen={isOpen} setDrawerOpen={setOpen} />
    </>
  );
};

export default ButtonAction;
