import { ADD_TO_NEWSLETTER, AUTH_SUCCESS, GET_USER, LOGOUT_SUCCESS, SET_ACCESS_TOKEN, SET_IS_LOADING } from "./types";
import { loginRequest } from "../../authConfig";
import { backendClient } from "../../api/backend";
import { signInWithEmailAndPassword, signOut as firebaseSignOut, confirmPasswordReset as firebaseConfirmPasswordReset, verifyPasswordResetCode as firebaseVerifyPasswordResetCode, UserCredential, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { onIdTokenChanged } from "firebase/auth";
import { SET_LANGUAGE } from "./types";
import i18n from "../../i18n";
import {
  FIREBASE_AUTH_START,
  FIREBASE_AUTH_SUCCESS,
  FIREBASE_AUTH_ERROR, // if you want to reuse this
} from "./types";
export const microsoftSignin = (instance) => {
  return async (dispatch, getState) => {
    const response = await instance.loginPopup({
      scopes: ["user.read"], // Modify scopes as needed
    });

    // Save the token or a flag indicating successful login to local storage
    localStorage.setItem("userLoggedIn", "true");
    localStorage.setItem("authResponse", JSON.stringify(response));
    await dispatch(setAccessToken(response.accessToken));
    // If you need to store the token, use response.accessToken

    let user = await dispatch(getUser(response.uniqueId));
    console.log("ISOOSMOO", user);
    if (user?.message === "User not found.") {
      console.log("creating user..", response);
      const userData = {
        id: response.uniqueId,
        name: response.account.name,
        email: response.account.username,
        tenantId: response.tenantId,
        organization_id: response.account.username.split("@")[1].split(".")[0],
      };
      // Create or update the user in your backend
      user = await backendClient.createUser(userData);
    }
    // Prepare user data for backend

    dispatch({
      type: AUTH_SUCCESS,
      payload: { isAuthentificated: true, user: user },
    });
  };
};

export const setLanguage = (language) => (dispatch) => {
  i18n.changeLanguage(language); // ✅ Change i18n language
  localStorage.setItem("language", language); // ✅ Persist in localStorage

  dispatch({
    type: SET_LANGUAGE,
    payload: language,
  });
};

export const addToNewsletter = (email) => {
  return async (dispatch, getState) => {
    // Create or update the user in your backend
    const response = await backendClient.addToNewsletter(email);
    dispatch({
      type: ADD_TO_NEWSLETTER,
      payload: response,
    });
    return response;
  };
};

export const getUser = (user_id) => {
  return async (dispatch, getState) => {
    // Create or update the user in your backend
    const user = await backendClient.getUser(user_id);
    dispatch({
      type: GET_USER,
      payload: user,
    });
    return user;
  };
};

export const setIsLoading = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_IS_LOADING,
      payload: value,
    });
  };
};

export const setAccessToken = (token) => {
  return async (dispatch, getState) => {
    const expirationTime = Math.floor(Date.now() / 1000) + 3600; // Add 1 hour
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: { accessToken: token, tokenExpirationTime: expirationTime },
    });

    // Optionally update localStorage
    localStorage.setItem("accessToken", token);
    localStorage.setItem("tokenExpirationTime", expirationTime.toString());
  };
};

export const logOut = () => {
  return async (dispatch, getState) => {
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("authResponse");
    // Dispatch an action to update your app's state
    dispatch({
      type: LOGOUT_SUCCESS,
      // other payload if necessary
    });
  };
};

const logout = () => {
  // Clear local storage or session storage
};

// export const firebaseRegister = (email, password, displayName, license) => {
//   return async (dispatch) => {
//     try {
//       // start loading
//       dispatch(setIsLoading(true));

//       // 1) Register new user in your backend
//       await registerUser(email, password, displayName, license);

//       // 2) Firebase sign in with email/password
//       const userCredential = await signInWithEmailAndPassword(auth, email, password);

//       // Dispatch success (could reuse AUTH_SUCCESS or create a new type)
//       dispatch({
//         type: AUTH_SUCCESS,
//         payload: {
//           isAuthentificated: true,
//           user: userCredential.user,
//         },
//       });
//     } catch (error) {
//       console.error("Error registering user:", error);
//       // optionally dispatch an error action
//       dispatch({
//         type: FIREBASE_AUTH_ERROR,
//         payload: error?.message,
//       });
//     } finally {
//       // end loading
//       dispatch(setIsLoading(false));
//     }
//   };
// };

//

export const firebaseSignIn = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const token = await userCredential.user.getIdToken();
      const expirationTime = Math.floor(Date.now() / 1000) + 3600; // Add 1 hour (token lifespan)
      // console.log(userCredential);
      const user = await backendClient.fetchUser(token);
      console.log(user);
      dispatch({
        type: AUTH_SUCCESS,
        payload: {
          isAuthentificated: user.isAdminVerified,
          user: userCredential.user,
          accessToken: token,
          tokenExpirationTime: expirationTime,
        },
      });
      localStorage.setItem("accessToken", token);
      localStorage.setItem("tokenExpirationTime", expirationTime.toString());
      await dispatch(setAccessToken(token));
      return user.isAdminVerified;
    } catch (error) {
      console.error("Error signing in:", error);
      // optionally dispatch an error action
      dispatch({
        type: FIREBASE_AUTH_ERROR,
        payload: error?.message,
      });
      throw error; // so the UI can handle e.g. "Invalid username or password"
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const firebaseSignOutAction = () => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await firebaseSignOut(auth);

      // Clear localStorage if needed
      localStorage.removeItem("userLoggedIn");
      localStorage.removeItem("authResponse");

      dispatch({
        type: LOGOUT_SUCCESS,
      });
    } catch (error) {
      console.error("Error signing out:", error);
      dispatch({
        type: FIREBASE_AUTH_ERROR,
        payload: error?.message,
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const firebaseVerifyPasswordResetCode_ = (actionCode) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const email = await firebaseVerifyPasswordResetCode(auth, actionCode);

      // Could dispatch an action if needed, or just return the email
      return email;
    } catch (error) {
      console.error("Error verifying password reset code:", error);
      dispatch({
        type: FIREBASE_AUTH_ERROR,
        payload: error?.message,
      });
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const firebaseConfirmPasswordReset_ = (actionCode, newPassword) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await firebaseConfirmPasswordReset(auth, actionCode, newPassword);
    } catch (error) {
      console.error("Error confirming password reset:", error);
      dispatch({
        type: FIREBASE_AUTH_ERROR,
        payload: error?.message,
      });
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
export const monitorTokenChanges = () => {
  return async (dispatch) => {
    onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken(true); // Force token refresh if needed
        const expirationTime = Math.floor(Date.now() / 1000) + 3600; // Add 1 hour

        dispatch({
          type: AUTH_SUCCESS,
          payload: {
            isAuthentificated: true,
            user,
            token,
            tokenExpirationTime: expirationTime,
          },
        });

        // Optionally update localStorage/sessionStorage
        localStorage.setItem("accessToken", token);
        localStorage.setItem("tokenExpirationTime", expirationTime.toString());
      } else {
        dispatch({
          type: LOGOUT_SUCCESS,
        });

        // Clear stored token and expiration time
        localStorage.removeItem("accessToken");
        localStorage.removeItem("tokenExpirationTime");
      }
    });
  };
};
