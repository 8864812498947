"use client";

import * as React from "react";
import { toast } from "sonner";
import { Button } from "../ui/button";
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from "../ui/drawer";
import { FileUploader } from "../file_upload/file-uploader";
import { useLocation, useNavigate } from "react-router-dom";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { clearDocuments, fetchLocalDocuments } from "../../redux/actions/documentsAction";
import { useDispatch, useSelector } from "react-redux";
import { backendClient } from "../../api/backend";
import { useTranslation } from "react-i18next";
import { useDocumentStatus } from "../../hooks/useDocuments";
interface DrawerUploaderDemoProps {
  row: any; // Replace with the actual type of `row` if available
  isDrawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  title?: string;
  description?: string;
  showFileUploader?: boolean;
  customContent?: React.ReactNode; // Accepts JSX elements or null
  handleAction?: (row: any) => void;
  answer?: string;
  loading?: boolean;
}

export default function DrawerUploaderDemo({ row, isDrawerOpen, setDrawerOpen, title, description, showFileUploader = true, customContent = null, handleAction = () => {}, answer = "", loading = false }: DrawerUploaderDemoProps) {
  const navigate = useNavigate(); // <== useNavigate for routing
  const location = useLocation();
  const [files, setFiles] = React.useState<File[]>([]);
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const { t } = useTranslation<any>(); // Use i18n translation hook

  const handleCancel = () => {
    // Perform cancel logic here
  };
  // console.log(row.original);

  // useDocumentStatus(`/deals/${transaction.id}/${row.original.type}s`, row.original.id, "status");
  const handleSubmit = async () => {
    if (showFileUploader) {
      if (files.length > 0) {
        // // If a file is uploaded, navigate to the Table component
        // if (row?.original.type !== "business-checklist")
        await dispatch(clearDocuments());

        await dispatch(fetchLocalDocuments(row.original.id, files));
        setDrawerOpen(false);
        // }
        navigate(`/upload/${row.original.id}`, {
          state: {
            file: files,
            previous_screen: location.pathname,

            // You can pass additional data here, e.g.:
            row: row?.original,
            upload: true,
          },
        });
      } else {
        // If no file, do your normal behavior
        //@ts-ignore

        toast(t("drawer.no_file_uploaded"), {
          //@ts-ignore

          description: t("drawer.select_document"),
        });
      }
    } else {
      setDrawerOpen(false);
      await handleAction(row);
    }
  };

  return (
    <Drawer open={isDrawerOpen} onOpenChange={setDrawerOpen}>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader>
            <DrawerTitle>
              {/* //@ts-ignore */}
              {
                //@ts-ignore
                t("drawer.upload")
              }{" "}
              {row?.original?.title || ""}
            </DrawerTitle>
            <DrawerDescription>
              {" "}
              {
                // @ts-ignore
                description ?? t("drawer.description")
              }
            </DrawerDescription>
          </DrawerHeader>
          <div className="p-4">{showFileUploader ? <FileUploader maxFileCount={1} maxSize={32 * 1024 * 1024} onValueChange={setFiles} /> : customContent}</div>
          <DrawerFooter>
            <Button onClick={handleSubmit}>
              {
                //@ts-ignore

                loading ? t("drawer.submitting") : t("drawer.submit")
              }
            </Button>
            <DrawerClose asChild>
              <Button onClick={handleCancel} variant="outline">
                {/* //@ts-ignore */}
                {
                  //@ts-ignore
                  t("drawer.cancel")
                }
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
