import { ArrowDown, ArrowRight, ArrowUp, CheckCircle, Circle, CircleOff, HelpCircle, Timer, Briefcase, NotebookPen, Leaf, ScanSearch, Scale, Footprints, Icon } from "lucide-react";

export const labels = [
  {
    value: "Operations and Sales",
    label: "Operations and Sales",
    icon: Briefcase,
  },
  {
    value: "Company Management",
    label: "Company Management",
    icon: NotebookPen,
  },

  {
    value: "ESG",
    label: "ESG",
    icon: Leaf,
  },
  {
    value: "Financial Details",
    label: "Financial Details",
    icon: ScanSearch,
  },
  {
    value: "Legal",
    label: "Legal",
    icon: Scale,
  },
  {
    value: "Operations",
    label: "Operations",
    icon: Footprints,
  },
];

export const statuses = [
  {
    value: "todo",
    label: "To do",
    icon: Circle,
  },
  {
    value: "in progress",
    label: "In Progress",
    icon: Timer,
  },
  {
    value: "done",
    label: "Done",
    icon: CheckCircle,
  },
  {
    value: "not-applicable",
    label: "N/A",
    icon: CircleOff,
  },
];

export const priorities = [
  {
    label: "Low",
    value: "low",
    icon: ArrowDown,
  },
  {
    label: "Medium",
    value: "medium",
    icon: ArrowRight,
  },
  {
    label: "High",
    value: "high",
    icon: ArrowUp,
  },
];
