import React, { FC, useState, useRef } from "react";
import { CellWrapper, useCellContext } from "@silevis/reactgrid";

export interface Term {
  name: string;
  operation: "addition" | "subtraction";
}

export interface TotalCellProps {
  text: string; // e.g. "Zwischenergebnis"
  indent: number; // how many levels to indent (for nested totals)
  terms: Term[]; // array of { name, operation }
  isExpanded: boolean; // if you're showing a chevron
  hasChildren: boolean; // if you want to show a chevron icon
  onExpandedToggled?: () => void;
  // optional: callback for updating terms
  onTermsChanged?: (newTerms: Term[]) => void;
}

// This cell displays the "total" row, and on edit shows a terms editor
const TotalCell: FC<TotalCellProps> = ({ text, indent, terms = [], isExpanded, hasChildren, onExpandedToggled, onTermsChanged }) => {
  const ctx = useCellContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [localTerms, setLocalTerms] = useState<Term[]>(terms);

  const inputRef = useRef<HTMLInputElement>(null);

  // For ReactGrid copy/paste
  const handleStringValueRequested = () => text;
  const handleStringValueReceived = (newText) => {
    // If you want to rename the total or something on paste
  };

  // We can define a minimal "fill handle" style or logic, but that often requires
  // advanced event handling. Below is a conceptual placeholder only.
  const [isSelecting, setIsSelecting] = useState(false);

  return (
    <CellWrapper
      onStringValueRequested={handleStringValueRequested}
      onStringValueReceived={handleStringValueReceived}
      style={{
        paddingLeft: `${indent * 2}rem`,
        backgroundColor: "#f3f4f6",
        textAlign: "left",
        cursor: "default",
        // Maybe highlight if selected?
        outline: ctx.isFocused ? "1px solid #999" : "none",
      }}
      // === ENTER EDIT MODE on DoubleClick or F2 ===
      onDoubleClick={() => {
        if (ctx.isFocused) setIsEditMode(true);
      }}
      onKeyDown={(e) => {
        if (!isEditMode && (e.key === "Enter" || e.key === "F2")) {
          e.stopPropagation();
          setIsEditMode(true);
        }
        if (e.key === "Escape") {
          setIsEditMode(false);
        } else if (e.key === "Enter") {
          setIsEditMode(false);
        }
        // Toggle expand if space pressed
        if (!isEditMode && e.key === " " && hasChildren && onExpandedToggled) {
          e.preventDefault();
          onExpandedToggled();
        }
      }}
    >
      {isEditMode ? (
        // ===================== EDIT MODE UI =====================
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: "0.25rem",
            minWidth: "200px",
          }}
        >
          {/* Example header */}
          <div style={{ fontWeight: 600 }}>{text}</div>
          {/* Display each term: user can edit operation or name */}
          {localTerms.map((term, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                alignItems: "center",
                background: isSelecting ? "#e2e8f0" : "transparent",
              }}
              onMouseDown={() => setIsSelecting(true)}
              onMouseUp={() => setIsSelecting(false)}
            >
              <select
                value={term.operation}
                onChange={(e) => {
                  const newOp = e.target.value as "addition" | "subtraction";
                  setLocalTerms((prev) => {
                    const copy = [...prev];
                    const term = copy[idx];
                    if (term && term.name && newOp) {
                      copy[idx] = { ...term, operation: newOp, name: term.name }; // Ensure `name` is a string
                    }
                    return copy;
                  });
                }}
              >
                <option value="addition">+</option>
                <option value="subtraction">-</option>
              </select>
              <input
                type="text"
                style={{ marginLeft: "8px" }}
                value={term.name}
                onChange={(e) => {
                  const newName = e.target.value;
                  setLocalTerms((prev) => {
                    const copy = [...prev];
                    const term = copy[idx]; // Assign to a variable for clarity
                    if (term && term.operation) {
                      copy[idx] = { ...term, operation: term.operation, name: newName };
                    }
                    return copy;
                  });
                }}
              />
            </div>
          ))}
          {/* Example "add row" or "done" */}
          {/* <button
            style={{ marginTop: "8px", alignSelf: "flex-end" }}
            onClick={() => {
              // update parent if needed
              onTermsChanged?.(localTerms);
              setIsEditMode(false);
            }}
          >
            Done
          </button> */}
        </div>
      ) : (
        // ===================== READ-ONLY UI =====================
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            width: "95%",
            justifyContent: "flex-start",
            gap: "3px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 600,
          }}
        >
          {/* Show chevron if "hasChildren" or some logic */}
          {hasChildren && onExpandedToggled ? (
            <span
              style={{
                display: "inline-block",
                transform: isExpanded ? "rotate(270deg)" : "rotate(0deg)",
                cursor: "pointer",
                marginRight: "4px",
              }}
              onPointerDown={(e) => {
                e.stopPropagation();
                onExpandedToggled();
              }}
            >
              ❯
            </span>
          ) : (
            // blank placeholder if no chevron
            <span style={{ width: "1rem", display: "inline-block" }} />
          )}
          {text}
        </div>
      )}
    </CellWrapper>
  );
};

export default TotalCell;
