import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { z } from "zod";

import { columns } from "./columns";
import { DataTable } from "./DataTable";
import { taskSchema } from "./data/schema";
import Spinner from "../../../components/ui/Spinner";
import { addDocuments, clearDocuments } from "../../../redux/actions/documentsAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";

type Task = {
  id: string;
  title: string;
  status: string;
};

// Define the schema for an array of tasks
const tasksSchema = z.array(taskSchema);

// Fetch tasks from the server
async function getTasks(): Promise<Task[]> {
  try {
    const response = await fetch("/tasks.json");
    if (!response.ok) {
      throw new Error(`Failed to fetch tasks: ${response.statusText}`);
    }

    const tasks = await response.json();
    return tasksSchema.parse(tasks);
  } catch (error) {
    console.error("Error fetching or parsing tasks:", error);
    throw new Error("Could not load tasks.");
  }
}

export default function TaskPage({ numpages, row, upload, rowSelection, setRowSelection }) {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const [tasks, setTasks] = useState<Task[]>([]); // Manage tasks state
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        let data = await getTasks();

        // If upload is true, map over numpages and add default tasks
        if (upload) {
          const defaultTasks = Array.from({ length: numpages }, (_, i) => ({
            id: `page-${i + 1}`,
            title: `Page ${i + 1}`,
            status: "notselected",
          }));
          data = [...defaultTasks];
        }

        setTasks(data); // Set tasks to state
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTasks(); // Call async function
  }, [upload, numpages]); // Add dependencies

  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <DataTable data={tasks} columns={columns} rowSelection={rowSelection} setRowSelection={setRowSelection} />
    </>
  );
}
