import React, { useState, useMemo } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "../../../components/ui/table";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../components/ui/form";
import { useTranslation } from "react-i18next";

const NPVSchema = z.object({
  discountRate: z.number({ invalid_type_error: "Discount rate must be a number." }).nonnegative({ message: "Discount rate cannot be negative." }),
});

// Table Row Data Interface
interface TableRowData {
  label: string;
  values: (string | number)[]; // Row values
  isHighlighted?: boolean; // Highlighted row
  rowType?: "default" | "mini-total" | "large-total"; // Row style
  isNegative?: boolean; // Negative value styling
}

const DiscountedCashFlow = ({ initialCashFlowData }) => {
  const [discountRate, setDiscountRate] = useState<number>(0);

  const tableHeadData = Array.from({ length: initialCashFlowData.length > 0 ? initialCashFlowData[0]!.values.length : 0 }, (_, index) => ({
    year: `Year ${index}`,
    date: `Dec ${2024 + index}`,
  }));
  const { discountedCashFlow, npv, discountRates, discountedCashflows } = useMemo(() => {
    let npvSum = 0;
    const discountRateRow: string[] = [];
    const discountedCashflowsRow: number[] = [];
    const discounted = initialCashFlowData.map((row) => {
      const values = row.values.map((value, index) => {
        if (typeof value === "number") {
          const rate = Math.pow(1 + discountRate / 100, index);
          const discountedValue = value / rate;
          npvSum += discountedValue;

          discountRateRow[index] = rate.toFixed(2); // Store discount rates
          discountedCashflowsRow[index] = Math.round(value / rate); // Product of cash flow and discount rate
          return Math.round(discountedValue);
        }
        return value;
      });
      return { ...row, values };
    });

    return {
      discountedCashFlow: discounted,
      npv: Math.round(npvSum),
      discountRates: discountRateRow,
      discountedCashflows: discountedCashflowsRow,
    };
  }, [discountRate, initialCashFlowData]);
  const { t } = useTranslation();
  return (
    <Card className="w-full shadow-lg">
      <CardHeader>
        <CardTitle>
          {
            // @ts-ignore
            t("discountedCashFlow.title")
          }
        </CardTitle>{" "}
      </CardHeader>

      <CardContent className="p-4 pt-0">
        <NPVForm discountRate={discountRate} setDiscountRate={setDiscountRate} npv={npv}>
          <ScrollArea type="always" className="w-full flex-1 pb-2">
            <Table className="w-full">
              <TableHeader>
                <TableRow className="border-none hover:bg-transparent">
                  <TableHead className="sticky left-0 bg-white font-semibold min-w-[200px] text-black border-none">
                    {" "}
                    {
                      // @ts-ignore
                      t("discountedCashFlow.discountedCashFlow")
                    }
                  </TableHead>
                  {tableHeadData.map((item, index) => (
                    <TableHead key={index} className="text-center min-w-[120px] text-black border-none hover:bg-transparent">
                      <div className="flex flex-col items-end">
                        <span className="font-semibold truncate">{item.year}</span>
                        <span className=" truncate text-black">{item.date}</span>
                      </div>
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {discountedCashFlow.map((row, rowIndex) => (
                  <Row key={rowIndex} row={row} />
                ))}
                {/* New Row for Discount Rates */}
                <Row row={{ label: "Discount Rates", values: discountRates }} />
                {/* Discounted Cashflows Row */}
                <Row row={{ label: "Discounted Cashflows", values: discountedCashflows, rowType: "large-total" }} />
              </TableBody>
            </Table>
            <ScrollBar orientation="horizontal" className="w-full rounded-full" />
          </ScrollArea>
        </NPVForm>
      </CardContent>
    </Card>
  );
};

const Row = ({ row }: { row: TableRowData }) => {
  const rowClasses = {
    default: "hover:bg-gray-100 border-none",
    "mini-total": "font-semibold border-t border-b border-gray-300",
    "large-total": "bg-gray-100 font-bold border-none",
  };
  return (
    <TableRow className={`group transition-colors duration-75 ${rowClasses[row.rowType || "default"]}`}>
      <TableCell className={`sticky left-0 min-w-[300px] font-medium z-10 bg-white group-hover:bg-gray-100 ${row.rowType === "large-total" ? "bg-gray-100" : ""}`}>{row.label}</TableCell>
      {row.values.map((value, index) => (
        <TableCell key={index} className={`text-right min-w-[150px] ${Number(value) < 0 ? "text-red-500" : "text-neutral-800"}`}>
          {value !== "" && typeof value === "number" ? value.toLocaleString() : value}
        </TableCell>
      ))}
    </TableRow>
  );
};

const NPVForm = ({ discountRate, setDiscountRate, npv, children }: { discountRate: number; setDiscountRate: (rate: number) => void; npv: number; children: React.ReactNode }) => {
  const form = useForm({
    resolver: zodResolver(NPVSchema),
    defaultValues: { discountRate },
  });
  const { t } = useTranslation();
  return (
    <Form {...form}>
      <form className="space-y-6 p-4 pb-0">
        <FormField
          control={form.control}
          name="discountRate"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel className="text-neutral-400 text-xs font-semibold">
                {
                  // @ts-ignore
                  t("discountedCashFlow.discountRate")
                }
              </FormLabel>
              <FormControl>
                <div className="max-w-[150px] relative">
                  <Input
                    className="max-w-[150px] " // Extra padding for the "%" symbol
                    type="text"
                    placeholder=""
                    value={field.value === 0 ? "" : field.value} // Show empty if value is 0
                    onChange={(e) => {
                      const value = e.target.value.trim().replace(/[^0-9.]/g, ""); // Remove non-numeric input
                      const rate = value === "" ? 0 : parseFloat(value); // Default to 0 if empty
                      setDiscountRate(rate); // Update local state
                      field.onChange(rate); // Keep only the numeric value in React Hook Form
                    }}
                  />
                  <span className="absolute right-1/2 top-1/2 transform -translate-y-1/2 text-gray-500 text-sm pointer-events-none">%</span>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="relative flex flex-col gap-3">
          {/* Table */}
          <div className="w-full">{children}</div>

          {/* NPV Display */}
          <div className="flex flex-col justify-center items-start mr-auto my-4">
            <Label className="text-neutral-400 text-xs font-semibold">
              {
                // @ts-ignore
                t("discountedCashFlow.netPresentValue")
              }
            </Label>
            <div className="flex items-center gap-1">
              <span className="text-sm font-bold text-neutral-800">$</span>
              <span className={`text-3xl font-bold ${Number(npv) < 0 ? "text-red-500" : "text-neutral-800"}`}>{npv.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default DiscountedCashFlow;
