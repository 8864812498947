"use client";

import * as React from "react";
import { ChevronsUpDown, Plus, Check } from "lucide-react";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "../../components/ui/dropdown-menu";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from "../../components/ui/sidebar";
import { Link } from "react-router-dom";
import { Command, CommandInput, CommandItem, CommandGroup, CommandList, CommandEmpty } from "../../components/ui/command";
import { cn } from "../../lib/utils";
import { fetchTransactions, setTransaction } from "../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/ui/Spinner";
import { BookOpen, Bot, Frame, LifeBuoy, Map, PieChart, Send, Settings2, SquareTerminal, House, FileUp, Tag, ScanSearch, PiggyBank, ListChecks, MapPinHouse, ArrowBigUpDash, TrendingUpDown, Gauge, Search, Filter, Hospital, MessageCircleQuestion, HandCoins } from "lucide-react";
// import { setIsLoading } from "@/redux/actions/authAction";
import { store } from "../../redux/store";
import { clearDocuments } from "../../redux/actions/documentsAction";
import { ScrollArea } from "../ui/scroll";
import { clearCashflow, clearMinideal, fetchCashflowData, fetchDebt, fetchPurchasePrice, updatecashgraph, updateMinideal } from "../../redux/actions/cashflowAction";
import { useTranslation } from "react-i18next";
import { labels } from "../../screens/PipelineScreen/data/data";
interface Team {
  deal?: any;
  name: string;
  logo: React.ElementType;
  plan: string;
  id: string;
}
interface Transaction {
  name: string;
  id: string;
  // Add other properties as needed
}

interface TransactionsState {
  transaction?: Transaction | null;
}

interface AppState {
  transactions: TransactionsState;
}

const teams: Team[] = [{ deal: null, name: "Select Deal", logo: Hospital, plan: "Current Deal", id: "0" }];

export function TeamSwitcher() {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const { isMobile } = useSidebar();
  const [Open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  // const state = store.getState();
  const transaction = useSelector((state: any) => state.transactions.transaction);
  const [activeTeam, setActiveTeam] = React.useState(
    transaction
      ? teams.map((e, i) => {
          return { ...e, name: transaction.name, id: transaction.id };
        })[0]
      : teams[0],
  );
  const [deals, setDeals] = React.useState(
    transaction
      ? teams.map((e, i) => {
          const icon = labels.find((e) => {
            return e.label === transaction.industry;
          })?.icon;
          return { ...e, name: transaction.name, id: transaction.id, logo: icon ?? e.logo };
        })
      : teams,
  );

  React.useEffect(() => {
    setActiveTeam(
      transaction
        ? teams.map((e, i) => {
            const icon = labels.find((e) => {
              return e.label === transaction.industry;
            })?.icon;
            return { ...e, name: transaction.name, id: transaction.id, logo: icon ?? e.logo };
          })[0]
        : teams[0],
    );
  }, [transaction]);
  const fetchAndSetTransactionData = async (transactionData: any) => {
    await dispatch(setTransaction(transactionData));
    await dispatch(clearMinideal());
    await dispatch(clearCashflow());
    await dispatch(updateMinideal(transactionData.revenue, transactionData.ebitda, transactionData.cashFlow, transactionData.earningsMultiple, transactionData.revenueMultiple, transactionData.uploadStatus));
    // await dispatch(fetchDebt(transactionData.id));
    // await dispatch(fetchPurchasePrice(transactionData.id));
    // await dispatch(fetchCashflowData(transactionData.id));

    // if (transactionData.uploadStatus !== "completed") {
    //   await dispatch(updatecashgraph());
    // }
    // await dispatch(setTransaction(transactionData));
  };
  React.useEffect(() => {
    const initializeTransaction = async () => {
      try {
        const fetchedTransactions = await dispatch(fetchTransactions());
        if (fetchedTransactions.length > 0) {
          await fetchAndSetTransactionData(fetchedTransactions[0]);
        }
      } catch (err) {
        console.error("Failed to fetch transactions on initial load", err);
      }
    };

    if (!transaction) {
      initializeTransaction();
    }
  }, [dispatch, transaction]);
  const { t } = useTranslation();

  const fetchDeals = React.useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(clearDocuments());
      const deals = await dispatch(fetchTransactions());
      const mapping = deals.map((deal, i) => {
        const icon = labels.find((e) => {
          return e.label === deal.industry;
        })?.icon;
        return {
          deal,
          name: deal.name,
          logo: icon,
          plan: "Current Deal",
          id: deal.id,
        };
      });
      setDeals(mapping);
      console.log(mapping);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch]);
  // React.useEffect(() => {
  //   fetchDeals();
  // }, [fetchDeals]);
  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    if (isOpen) {
      fetchDeals(); // Fetch deals whenever the menu is opened
    }
  };
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu open={Open} onOpenChange={handleOpenChange}>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton size="lg" className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground">
              <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">{activeTeam && <activeTeam.logo className="size-4" />}</div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{activeTeam && activeTeam.name}</span>
                <span className="truncate text-xs">{activeTeam && activeTeam.plan}</span>
              </div>
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg" align="start" side={isMobile ? "bottom" : "right"} sideOffset={4}>
            {/* <DropdownMenuLabel className="text-xs text-muted-foreground">Opportunities</DropdownMenuLabel> */}
            {loading ? (
              <div className="flex justify-center p-4">
                <Spinner size={72} />
              </div>
            ) : error ? (
              <div className="p-4 text-red-500">{error}</div>
            ) : (
              <>
                <Command>
                  <CommandInput
                    placeholder={
                      //@ts-ignore
                      t("teamSwitcher.search_pipeline")
                    }
                  />
                  <CommandList className="overflow-y-auto">
                    <CommandEmpty>
                      {
                        //@ts-ignore
                        t("teamSwitcher.no_industries_found")
                      }
                    </CommandEmpty>
                    <CommandGroup
                      heading={
                        //@ts-ignore
                        t("teamSwitcher.opportunities")
                      }
                    >
                      {deals.map((team, index) => (
                        <CommandItem
                          key={team.id}
                          className="cursor-pointer "
                          onSelect={async () => {
                            setLoading(true);
                            await fetchAndSetTransactionData(team.deal);

                            setActiveTeam(team);
                            setOpen(false);
                            setLoading(false);
                          }}
                          // dataValue={team.id}
                        >
                          <div className="flex size-6 items-center justify-center rounded-sm border">
                            <team.logo className="size-4 shrink-0" />
                          </div>
                          {team.name}
                          <div className="hidden">{team.id}</div>
                          <Check className={cn("ml-auto", activeTeam?.id === team?.id ? "opacity-100" : "opacity-0")} />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
                <DropdownMenuSeparator />
                <DropdownMenuItem className="gap-2 p-2 cursor-pointer">
                  <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                    <Plus className="size-4" />
                  </div>
                  <Link to={"/pipeline"} onClick={() => setOpen(false)} className="font-medium text-muted-foreground ">
                    {
                      //@ts-ignore
                      t("teamSwitcher.add_new_deal")
                    }
                  </Link>
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
