import { Book, Menu, Sunset, Trees, Zap } from "lucide-react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../components/ui/accordion";
import { Button, buttonVariants } from "../../../components/ui/button";
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger, navigationMenuTriggerStyle } from "../../../components/ui/navigation-menu";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../../../components/ui/sheet";
import { cn } from "../../../lib/utils";
import { OpenCalendar } from "../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../../../assets/landingpage/logo.png"; // Make sure the path to your logo is correct
import logoImage2 from "../../../assets/landingpage/logo.svg"; // Make sure the path to your logo is correct
import { useState } from "react";

const Navbar1 = ({ highlight }) => {
  const navigate = useNavigate();
  return (
    <section className="sticky top-0 z-50 bg-white py-4 px-6">
      <div className="w-full">
        <nav className="hidden justify-between lg:flex">
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              <img src={logoImage2} className="w-24 mr-32" alt="logo" />
            </div>
            <div className="flex items-center">
              <Link
                to="/buyers" // Empty string or use "/" for the root route
                className={cn("text-muted-foreground", navigationMenuTriggerStyle, buttonVariants({ variant: "ghost" }), highlight === "buyers" && "bg-accent text-accent-foreground")}
              >
                Home
              </Link>
              {/* <Link
                to="/advisors" // Empty string or use "/" for the root route
                className={cn("text-muted-foreground", navigationMenuTriggerStyle, buttonVariants({ variant: "ghost" }), highlight === "advisors" && "bg-accent text-accent-foreground")}
              >
                Advisors
              </Link>
              <Link
                to="/sellers" // Empty string or use "/" for the root route
                className={cn("text-muted-foreground", navigationMenuTriggerStyle, buttonVariants({ variant: "ghost" }), highlight === "sellers" && "bg-accent text-accent-foreground")}
              >
                Sellers
              </Link> */}
              <Link
                to="" // Empty string or use "/" for the root route
                className={cn(
                  "text-muted-foreground",
                  navigationMenuTriggerStyle,
                  buttonVariants({
                    variant: "ghost",
                  }),
                )}
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigation behavior
                  const scrollContainer = document.getElementById("scrollable-box"); // Get the Box by id
                  // console.log(scrollContainer);
                  scrollContainer.scrollTo({
                    top: 0,
                    behavior: "smooth", // Smooth scrolling
                  });
                  // console.log("click");
                }}
              >
                Blog
              </Link>

              <a
                className={cn(
                  "text-muted-foreground",
                  navigationMenuTriggerStyle,
                  buttonVariants({
                    variant: "ghost",
                  }),
                )}
                href="https://calendly.com/othman-kabbaj--/intro-duedeal" // Fallback link for SEO and accessibility
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation
                  OpenCalendar(); // Call your function
                }}
              >
                Contact
              </a>
            </div>
          </div>
          <div className="flex gap-2">
            <Button variant={"outline"} onClick={() => navigate("/sign-in")}>
              Log in
            </Button>
            <Button onClick={() => navigate("/sign-up")}>Sign up</Button>
          </div>
        </nav>
        <div className="block lg:hidden">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <img src={logoImage2} className="w-24 mr-32" alt="logo" />

              {/* <span className="text-xl font-bold">Shadcn Blocks</span> */}
            </div>
            <Sheet>
              <SheetTrigger asChild>
                <Button variant={"outline"} size={"icon"}>
                  <Menu className="size-4" />
                </Button>
              </SheetTrigger>
              <SheetContent className="overflow-y-auto flex flex-col gap-0">
                <SheetHeader>
                  <SheetTitle>
                    <div className="flex items-center gap-2">
                      <img src={logoImage2} className="w-24" alt="logo" />
                      {/* <span className="text-xl font-bold">Shadcn Blocks</span> */}
                    </div>
                  </SheetTitle>
                </SheetHeader>
                <div className="my-8 flex flex-col gap-4">
                  <a href="/sellers" className="font-semibold">
                    Sellers
                  </a>
                  <a href="/buyers" className="font-semibold">
                    Buyers
                  </a>
                  <a href="/advisors" className="font-semibold">
                    Advisors
                  </a>
                  <a href="#" className="font-semibold">
                    Blog
                  </a>
                  <a
                    href="https://calendly.com/othman-kabbaj--/intro-duedeal"
                    className="font-semibold"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      OpenCalendar(); // Call your function
                    }}
                  >
                    Contact
                  </a>
                </div>
                <div className="border-t pt-4 mt-auto">
                  <div className="mt-2 flex flex-col gap-3">
                    <Button variant={"outline"} onClick={() => navigate("/sign-in")}>
                      Log in
                    </Button>
                    <Button onClick={() => navigate("/sign-up")}>Sign up</Button>
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar1;
