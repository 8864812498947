import {
  ArrowDown,
  ArrowRight,
  ArrowUp,
  CheckCircle,
  Circle,
  CircleOff,
  HelpCircle,
  Timer,
  Briefcase,
  NotebookPen,
  Leaf,
  ScanSearch,
  Scale,
  Footprints,
  Icon,
  FileArchive,
  ChefHat,
  Bone,
  Scissors,
  ShoppingCart,
  Wrench,
  Car,
  ShowerHead,
  Package,
  Fuel,
  Trash,
  CarFront,
  Anchor,
  Truck,
  HeartPulse,
  Bike,
  Hospital,
  Dumbbell,
  Home,
  Hand,
  Stethoscope,
  Paintbrush,
  Sun,
  Eraser,
  WashingMachine,
  Key,
  Bug,
  Hammer,
  Building,
  Zap,
  Forklift,
  Thermometer,
  Baby,
  School,
  Palette,
  GlassWater,
  LoaderPinwheel,
  Dices,
  LandPlot,
  Fish,
  Music,
  Calculator,
  Banknote,
  DollarSign,
  TrendingUp,
  Shield,
  Gavel,
  Wheat,
  Beer,
  Wine,
  Utensils,
  Coffee,
  IceCream,
  CupSoda,
  Globe,
  Code,
  MonitorSmartphone,
  Boxes,
  FlaskConical,
  Shirt,
  Cpu,
  Sofa,
  Cog,
  Trees,
  Newspaper,
  Film,
  Box,
  Signpost,
  Warehouse,
  ShoppingBag,
  Store,
  Flower,
  Gem,
  BookOpen,
  Sprout,
  HandCoins,
  Pill,
  Cigarette,
  Candy,
  Smartphone,
  ShieldX,
  Clipboard,
} from "lucide-react";

export const labels = [
  { value: "Dog Daycare and Boarding Businesses", label: "Dog Daycare and Boarding Businesses", icon: Bone },
  { value: "Pet Grooming Businesses", label: "Pet Grooming Businesses", icon: Scissors },
  { value: "Pet Stores and Supply Businesses", label: "Pet Stores and Supply Businesses", icon: ShoppingCart },
  { value: "Auto Repair and Service Shops", label: "Auto Repair and Service Shops", icon: Wrench },
  { value: "Car Dealerships", label: "Car Dealerships", icon: Car },
  { value: "Car Washes", label: "Car Washes", icon: ShowerHead },
  { value: "Equipment Rental and Dealers", label: "Equipment Rental and Dealers", icon: Package },
  { value: "Gas Stations", label: "Gas Stations", icon: Fuel },
  { value: "Junk and Salvage Yards", label: "Junk and Salvage Yards", icon: Trash },
  { value: "Limo and Passenger Transportation Businesses", label: "Limo and Passenger Transportation Businesses", icon: CarFront },
  { value: "Marine/Boat Service and Dealers", label: "Marine/Boat Service and Dealers", icon: Anchor },
  { value: "Moving and Shipping Businesses", label: "Moving and Shipping Businesses", icon: Truck },
  { value: "Towing Companies", label: "Towing Companies", icon: Truck },
  { value: "Truck Stops", label: "Truck Stops", icon: Truck },
  { value: "Trucking Companies", label: "Trucking Companies", icon: Truck },
  { value: "Assisted Living and Nursing Homes", label: "Assisted Living and Nursing Homes", icon: HeartPulse },
  { value: "Dance, Pilates, and Yoga Studios", label: "Dance, Pilates, and Yoga Studios", icon: Bike },
  { value: "Dental Practices", label: "Dental Practices", icon: Hospital },
  { value: "Gyms and Fitness Centers", label: "Gyms and Fitness Centers", icon: Dumbbell },
  { value: "Hair Salons and Barber Shops", label: "Hair Salons and Barber Shops", icon: Scissors },
  { value: "Home Health Care Businesses", label: "Home Health Care Businesses", icon: Home },
  { value: "Massage Businesses", label: "Massage Businesses", icon: Hand },
  { value: "Medical Practices", label: "Medical Practices", icon: Stethoscope },
  { value: "Nail Salons", label: "Nail Salons", icon: Paintbrush },
  { value: "Spas", label: "Spas", icon: Home },
  { value: "Tanning Salons", label: "Tanning Salons", icon: Sun },
  { value: "Cleaning Businesses", label: "Cleaning Businesses", icon: Eraser },
  { value: "Commercial Laundry Businesses", label: "Commercial Laundry Businesses", icon: WashingMachine },
  { value: "Dry Cleaners", label: "Dry Cleaners", icon: WashingMachine },
  { value: "Locksmith Businesses", label: "Locksmith Businesses", icon: Key },
  { value: "Pest Control Businesses", label: "Pest Control Businesses", icon: Bug },
  { value: "Building Material and Hardware Stores", label: "Building Material and Hardware Stores", icon: Hammer },
  { value: "Concrete Businesses", label: "Concrete Businesses", icon: Building },
  { value: "Electrical and Mechanical Contracting Businesses", label: "Electrical and Mechanical Contracting Businesses", icon: Zap },
  { value: "Heavy Construction Businesses", label: "Heavy Construction Businesses", icon: Forklift },
  { value: "HVAC Businesses", label: "HVAC Businesses", icon: Thermometer },
  { value: "Landscaping and Yard Service Businesses", label: "Landscaping and Yard Service Businesses", icon: Leaf },
  { value: "Plumbing Businesses", label: "Plumbing Businesses", icon: Wrench },
  { value: "Day Care and Child Care Centers", label: "Day Care and Child Care Centers", icon: Baby },
  { value: "Preschools", label: "Preschools", icon: School },
  { value: "Schools", label: "Schools", icon: School },
  { value: "Art Galleries", label: "Art Galleries", icon: Palette },
  { value: "Banquet Halls", label: "Banquet Halls", icon: GlassWater },
  { value: "Bowling Alleys", label: "Bowling Alleys", icon: LoaderPinwheel },
  { value: "Casinos", label: "Casinos", icon: Dices },
  { value: "Golf Courses and Services", label: "Golf Courses and Services", icon: LandPlot },
  { value: "Marinas and Fishing", label: "Marinas and Fishing", icon: Fish },
  { value: "Nightclubs and Theaters", label: "Nightclubs and Theaters", icon: Music },
  { value: "Accounting and Tax Practices", label: "Accounting and Tax Practices", icon: Calculator },
  { value: "Banking and Loan Businesses", label: "Banking and Loan Businesses", icon: Banknote },
  { value: "Check Cashing Businesses", label: "Check Cashing Businesses", icon: DollarSign },
  { value: "Financial Services Businesses", label: "Financial Services Businesses", icon: TrendingUp },
  { value: "Insurance Agencies", label: "Insurance Agencies", icon: Shield },
  { value: "Legal Services and Law Firms", label: "Legal Services and Law Firms", icon: Gavel },
  { value: "Medical Billing Businesses", label: "Medical Billing Businesses", icon: Clipboard },
  { value: "Bakeries", label: "Bakeries", icon: Wheat },
  { value: "Bars, Pubs and Taverns", label: "Bars, Pubs and Taverns", icon: Beer },
  { value: "Breweries", label: "Breweries", icon: Wine },
  { value: "Catering Companies", label: "Catering Companies", icon: Utensils },
  { value: "Coffee Shops and Cafes", label: "Coffee Shops and Cafes", icon: Coffee },
  { value: "Food Trucks", label: "Food Trucks", icon: Truck },
  { value: "Ice Cream and Frozen Yogurt Shops", label: "Ice Cream and Frozen Yogurt Shops", icon: IceCream },
  { value: "Juice Bars", label: "Juice Bars", icon: CupSoda },
  { value: "Restaurants", label: "Restaurants", icon: Utensils },
  { value: "Websites and Ecommerce Businesses", label: "Websites and Ecommerce Businesses", icon: Globe },
  { value: "IT and Software Service Businesses", label: "IT and Software Service Businesses", icon: Code },
  { value: "Software and App Companies", label: "Software and App Companies", icon: MonitorSmartphone },
  { value: "Delivery, Freight and Service Routes", label: "Delivery, Freight and Service Routes", icon: Truck },
  { value: "Durable Goods Wholesalers and Distributors", label: "Durable Goods Wholesalers and Distributors", icon: Package },
  { value: "Nondurable Goods Wholesalers and Distributors", label: "Nondurable Goods Wholesalers and Distributors", icon: Boxes },
  { value: "Auto, Boat and Aircraft Manufacturers", label: "Auto, Boat and Aircraft Manufacturers", icon: Car },
  { value: "Chemical and Related Product Manufacturers", label: "Chemical and Related Product Manufacturers", icon: FlaskConical },
  { value: "Clothing and Fabric Manufacturers", label: "Clothing and Fabric Manufacturers", icon: Shirt },
  { value: "Electronic and Electrical Equipment Manufacturers", label: "Electronic and Electrical Equipment Manufacturers", icon: Cpu },
  { value: "Food and Related Product Manufacturers", label: "Food and Related Product Manufacturers", icon: Utensils },
  { value: "Furniture and Fixture Manufacturers", label: "Furniture and Fixture Manufacturers", icon: Sofa },
  { value: "Glass, Stone, and Concrete Manufacturers", label: "Glass, Stone, and Concrete Manufacturers", icon: Building },
  { value: "Industrial and Commercial Machinery Manufacturers", label: "Industrial and Commercial Machinery Manufacturers", icon: Cog },
  { value: "Lumber and Wood Products Manufacturers", label: "Lumber and Wood Products Manufacturers", icon: Trees },
  { value: "Machine Shops and Tool Manufacturers", label: "Machine Shops and Tool Manufacturers", icon: Wrench },
  { value: "Medical Device and Product Manufacturers", label: "Medical Device and Product Manufacturers", icon: Stethoscope },
  { value: "Metal Product Manufacturers", label: "Metal Product Manufacturers", icon: Shield },
  { value: "Packaging Businesses", label: "Packaging Businesses", icon: Package },
  { value: "Paper Manufacturers and Printing Businesses", label: "Paper Manufacturers and Printing Businesses", icon: Newspaper },
  { value: "Production Companies", label: "Production Companies", icon: Film },
  { value: "Rubber and Plastic Products Manufacturers", label: "Rubber and Plastic Products Manufacturers", icon: Box },
  { value: "Sign Manufacturers and Businesses", label: "Sign Manufacturers and Businesses", icon: Signpost },
  { value: "Property Management Businesses", label: "Property Management Businesses", icon: Building },
  { value: "Storage Facilities and Warehouses", label: "Storage Facilities and Warehouses", icon: Warehouse },
  { value: "Bike Shops", label: "Bike Shops", icon: Bike },
  { value: "Clothing and Accessory Stores", label: "Clothing and Accessory Stores", icon: ShoppingBag },
  { value: "Convenience Stores", label: "Convenience Stores", icon: Store },
  { value: "Dollar Stores", label: "Dollar Stores", icon: DollarSign },
  { value: "Flower Shops", label: "Flower Shops", icon: Flower },
  { value: "Furniture and Furnishings Stores", label: "Furniture and Furnishings Stores", icon: Sofa },
  { value: "Grocery Stores and Supermarkets", label: "Grocery Stores and Supermarkets", icon: ShoppingCart },
  { value: "Health Food and Nutrition Businesses", label: "Health Food and Nutrition Businesses", icon: Leaf },
  { value: "Jewelry Stores", label: "Jewelry Stores", icon: Gem },
  { value: "Liquor Stores", label: "Liquor Stores", icon: Wine },
  { value: "Magazines and Newspapers", label: "Magazines and Newspapers", icon: BookOpen },
  { value: "Nursery and Garden Centers", label: "Nursery and Garden Centers", icon: Sprout },
  { value: "Pawn Shops", label: "Pawn Shops", icon: HandCoins },
  { value: "Pharmacies", label: "Pharmacies", icon: Pill },
  { value: "Smoke Shops", label: "Smoke Shops", icon: Cigarette },
  { value: "Vending Machine Businesses", label: "Vending Machine Businesses", icon: Candy },
  { value: "Websites and Ecommerce Businesses", label: "Websites and Ecommerce Businesses", icon: Globe },
  { value: "Cell Phone and Computer Repair and Service Businesses", label: "Cell Phone and Computer Repair and Service Businesses", icon: Smartphone },
  { value: "Funeral Homes", label: "Funeral Homes", icon: ShieldX },
];

export const statuses = [
  {
    value: "bidding",
    label: "Bidding",
    icon: Circle,
  },
  {
    value: "success",
    label: "Success",
    icon: CheckCircle,
  },
  {
    value: "lost",
    label: "Lost",
    icon: CircleOff,
  },
  {
    value: "archived",
    label: "Archived",
    icon: FileArchive,
  },
  {
    value: "not-started",
    label: "Not Started",
    icon: CircleOff,
  },
];

export const priorities = [
  {
    label: "Low",
    value: "low",
    icon: ArrowDown,
  },
  {
    label: "Medium",
    value: "medium",
    icon: ArrowRight,
  },
  {
    label: "High",
    value: "high",
    icon: ArrowUp,
  },
];
