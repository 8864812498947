import { TrendingUp } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import * as Slider from "@radix-ui/react-slider";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage, FormDescription } from "../../components/ui/form";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import { cn } from "../../lib/utils";
import { Skeleton } from "../../components/ui/skeleton";

export function TooltipIcon({ content, cs = "" }: { content: string; cs?: string }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Info className={cn("h-4 w-4 text-gray-500 hover:text-black", cs)} />
          </span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p className="color-white">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

interface StatItemProps {
  title: string;
  tooltipContent: string;
  value: string | number;
  suffix?: string; // Optional suffix like %, $, x
  loading?: boolean;
}

const StatItem: React.FC<StatItemProps> = ({ title, tooltipContent, value, suffix = "", loading }) => {
  return (
    <div className="flex flex-col text-left">
      <div className="flex items-center space-x-2">
        <CardDescription className="text-sm font-medium">{loading ? <Skeleton className="h-4 w-24" /> : title}</CardDescription>
        {!loading && <TooltipIcon content={tooltipContent} />}
      </div>
      <div className="text-base sm:text-lg 2lg:text-xl font-bold">
        {loading ? <Skeleton className="h-4 w-36" /> : value}
        {!loading && suffix}
      </div>
    </div>
  );
};

export default function ProgressPrice({ title, loading }) {
  // State variables for dynamic values
  const [purchasePrice, setPurchasePrice] = useState(1950000); // Default purchase price
  const [lowerBound, setLowerBound] = useState(780000);
  const [upperBound, setUpperBound] = useState(3120000);
  const [trackWidth, setTrackWidth] = useState(0); // Track width state

  // Calculated metrics based on purchase price
  const irr = Math.round((35 * purchasePrice) / 1950000); // Example calculation
  const payback = 3;
  const TotalDebt = 1300000;
  const equityContribution = purchasePrice - TotalDebt;
  const FormSchema = z.object({
    purchasePrice: z.number().refine(
      (value) => value >= lowerBound && value <= upperBound,
      (value) => (value < lowerBound ? { message: `Value must be at least $${lowerBound.toLocaleString()}.` } : { message: `Value must not exceed $${upperBound.toLocaleString()}.` }),
    ),
  });
  // Initialize the form
  const form = useForm({
    resolver: zodResolver(FormSchema),
    mode: "onChange", // Validate dynamically on change
    defaultValues: {
      purchasePrice: purchasePrice,
    },
  });
  // Handle slider change
  const handleSliderChange = (value) => {
    setPurchasePrice(value[0]);
    form.setValue("purchasePrice", value[0], { shouldValidate: true });
  };
  const trackRef = useRef<HTMLDivElement>(null); // Typed ref for Slider.Track

  // Calculate and update the width of the Slider.Range
  useEffect(() => {
    if (trackRef.current) {
      const trackElement = trackRef.current;
      setTrackWidth(trackElement.offsetWidth);
    }
  }, []);

  const onSubmit = (data) => {
    console.log("Valid Data:", data);
  };
  return (
    <Card className="col-span-1 max-w-full">
      {/* Header */}
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {/* <CardDescription>
          We estimate the purchase price to be between ${lowerBound.toLocaleString()} and ${upperBound.toLocaleString()}{" "}
        </CardDescription> */}
      </CardHeader>

      <CardContent className="flex flex-col gap-4">
        <Card className="shadow-none border-none p-0">
          <CardContent className="flex flex-wrap justify-start items-start gap-4 p-0">
            <div className="flex-grow flex justify-between min-w-[45%]">
              <StatItem loading={loading} title="IRR" tooltipContent="Internal Rate of Return (IRR) represents the expected annualized return on the investment, adjusted for time value of money. A higher IRR indicates a more attractive investment." value={irr} suffix="%" />
              <StatItem loading={loading} title="Payback Period" tooltipContent="The Payback Period measures the time required to recover the initial investment, providing insight into risk and liquidity of the investment." value={payback} suffix="years" />
            </div>
            <div className="flex-grow flex justify-between">
              <StatItem loading={loading} title="Total Debt" tooltipContent="Total Debt refers to the amount of borrowed capital used to finance the investment, which needs to be repaid over time." value={`$${TotalDebt.toLocaleString()}`} />
              <StatItem loading={loading} title="Net investment" tooltipContent="Net Investment reflects the equity contribution or out-of-pocket amount required to fund the project, after accounting for financing." value={`$${equityContribution.toLocaleString()}`} suffix="" />
            </div>
          </CardContent>
        </Card>
        <Card className="p-4 bg-muted text-muted-foreground flex flex-col space-y-4">
          <CardContent>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
                {/* Purchase Price Field */}
                <FormField
                  control={form.control}
                  name="purchasePrice"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>Purchase Price</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          inputMode="numeric"
                          value={field.value !== undefined ? `$${field.value.toLocaleString()}` : ""}
                          onChange={(e) => {
                            const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                            if (/^\d*$/.test(rawValue)) {
                              const numericValue = Number(rawValue);
                              field.onChange(numericValue); // Update form value
                              setPurchasePrice(numericValue); // Sync local state
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage>{fieldState.error?.message}</FormMessage> {/* Show error dynamically */}
                    </FormItem>
                  )}
                />
              </form>
            </Form>
            <div className="flex gap-2 items-center w-full space-y-2">
              {/* Lower Bound */}
              <div className="mt-1 text-xs text-gray-600">${lowerBound.toLocaleString()}</div>

              {/* Slider */}
              <Slider.Root value={[purchasePrice]} min={lowerBound} max={upperBound} step={10000} onValueChange={handleSliderChange} className="relative mt-0 flex items-center w-full h-6">
                {/* Track */}
                <Slider.Track ref={trackRef} className="relative bg-gray-200 h-2 rounded-full w-full">
                  {/* Range */}
                  <Slider.Range className="absolute bg-black h-2 rounded-full" style={{ width: `${trackWidth}px` }} />
                </Slider.Track>
                {/* Thumb */}
                <Slider.Thumb className="SliderThumb" asChild>
                  <div className="absolute flex items-center justify-center w-6 h-6 bg-white rounded border-2 border-gray-100  shadow -translate-x-[50%] -translate-y-[50%]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 text-black-500">
                      <path d="m9 7-5 5 5 5"></path>
                      <path d="m15 7 5 5-5 5"></path>
                    </svg>
                  </div>
                </Slider.Thumb>
              </Slider.Root>

              {/* Upper Bound */}
              <div className="text-xs text-gray-600">${upperBound.toLocaleString()}</div>
            </div>
          </CardContent>
        </Card>

        {/* Right Section: Metrics */}
      </CardContent>
    </Card>
  );
}
