import React, { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../../components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { backendClient } from "../../../api/backend";
import { toast } from "sonner";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";

const WebLinkSchema = z.object({
  // You could also have more fields if needed
  link: z.string().url("Please enter a valid URL"),
});

interface WebLinkDialogProps {
  open: boolean;
  onClose: () => void;
  onTransactionReady: (transaction: any) => void;
  // Callback to pass fetched transaction back to parent
}

export function WebLinkDialog({ open, onClose, onTransactionReady }: WebLinkDialogProps) {
  const transaction = useSelector((state: any) => state.transactions?.transaction);

  const form = useForm({
    resolver: zodResolver(WebLinkSchema),
    defaultValues: {
      link: "",
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = form;

  // On submit:
  // 1) Call your backend with the link
  // 2) Pass the fetched transaction back up
  async function onSubmit(values: { link: string }) {
    try {
      // Show some “loading” or toast
      toast("Fetching transaction...");
      // For example, your backend might have an endpoint like getTransactionFromLink
      // const transaction = await backendClient.getTransactionFromLink(values.link);
      console.log("Fetching transaction from link:", values.link);

      // If successful, call the parent’s callback
      const deal = await backendClient.importTransaction(values.link);
      if (deal) {
        deal.source = "Buyer";
        // deal.new = true;
      }

      // Go through all the items in deal if they are "undefined" set them to null
      for (const key in deal) {
        if (deal[key] === "undefined") {
          deal[key] = null;
        }
      }

      // if cashflow exists on deal, rename it cashFlow

      if (deal.cashflow) {
        deal.cashFlow = deal.cashflow;
        delete deal.cashflow;
      }
      if (deal.askingPrice) {
        deal.askPrice = deal.askingPrice;
        delete deal.askingPrice;
      }

      // onTransactionReady(transaction);
      onTransactionReady(deal);
      toast.success("Transaction fetched successfully!");
      // Then close the dialog
    } catch (err) {
      console.error("Failed to fetch transaction:", err);
      toast.error("Error fetching transaction");
    }
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Enter Web Link</DialogTitle>
          <DialogDescription>Paste a URL so we can fetch the deal data. from the link</DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              control={control}
              name="link"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Web Link</FormLabel>
                  <FormControl>
                    <Input placeholder="https://example.com/deal123" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-6">
              <DialogClose asChild>
                <Button variant="ghost">Cancel</Button>
              </DialogClose>
              <Button type="submit" disabled={!isValid || isSubmitting}>
                {isSubmitting ? "Fetching..." : "Fetch Deal"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
