import { TrendingUp } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Overview } from "./components/overview";
import { Costs } from "./components/costs";
import { useTranslation } from "react-i18next";

export default function DashboardPage({ title, overview, actual_rev, actual_ebitda, actual_costs, act_adj }) {
  let forecastedItems;

  let forecastedCount;
  const { t } = useTranslation(); // ✅ Use translation hook

  // Extract years and find min/max
  let years;
  let minYear;
  let maxYear;

  if (actual_rev && actual_rev.length > 0) {
    forecastedItems = actual_rev.filter((e) => e.isForecasted);

    forecastedCount = forecastedItems.length;

    // Extract years and find min/max
    years = actual_rev.map((e) => e.year);
    minYear = Math.min(...years);
    maxYear = Math.max(...years);
  } else {
    forecastedItems = actual_costs.filter((e) => e.isForecasted);

    forecastedCount = forecastedItems.length;

    // Extract years and find min/max
    years = actual_costs.map((e) => e.year);
    minYear = Math.min(...years);
    maxYear = Math.max(...years);
  }

  return (
    <>
      <Card className="max-w-full">
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{`${
            // @ts-ignore
            t("dashboard.period", { minYear, maxYear })
          }`}</CardDescription>
        </CardHeader>
        <CardContent className="pl-2">{overview ? <Overview actual_rev={actual_rev} actual_ebitda={actual_ebitda} /> : <Costs actual_costs={actual_costs} act_adj={act_adj} />}</CardContent>
        <CardFooter>
          <div className="flex w-full items-start justify-between gap-2 text-sm">
            <div className="grid gap-2">
              <div className="flex items-center gap-2 font-medium leading-none">
                {`${
                  // @ts-ignore
                  t("dashboard.forecast", { forecastedCount })
                }`}{" "}
                <TrendingUp className="h-4 w-4" />
              </div>
              <div className="flex items-center gap-2 leading-none text-muted-foreground">
                {" "}
                {
                  // @ts-ignore
                  t("dashboard.assumption")
                }
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}
