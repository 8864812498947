import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Logo from "../../assets/logo/logo.png"; // Assuming the logo is in the public/assets/logo directory
import MicrosoftLogo from "../../assets/signin/microsoft.png"; // Path to the Microsoft logo
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
// import { useDispatch } from "react-redux";
import { firebaseSignIn, microsoftSignin } from "../../redux/actions/authAction";
import { colors } from "../../utils/colors"; // Assuming colors are usable here directly or adapted for MUI theming
import { isMobile, windowHeight, windowWidth } from "../../utils/utils";
import TermsModal from "./components/TermsModal";
import { DISCLAIMER_URL } from "../../utils/constants";
import ModalPdfView from "../../components/ModalPdfView";
import { FcGoogle } from "react-icons/fc";

import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { Input } from "../../components/ui/input";
import logoImage2 from "../../assets/landingpage/logo.svg"; // Make sure the path to your logo is correct
import { useToast } from "../../hooks/use-toast";
import { useDispatch } from "react-redux";
import { addToNewsletter } from "../../redux/actions/authAction";
import { Popover, PopoverTrigger, PopoverContent } from "../../components/ui/popover";
import { Command, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem } from "../../components/ui/command";
import { FaApple, FaFacebook, FaMicrosoft } from "react-icons/fa";
import Spinner from "../../components/ui/Spinner";
import { ChevronsUpDown, Check } from "lucide-react";
import { backendClient } from "../../api/backend";

const Signup4 = () => {
  const { toast } = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // State for the reason selection (one of the four options)
  const [reason, setReason] = useState("");
  const [otherReasonText, setOtherReasonText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleGoogleSignUp = async () => {
    // Replace with your Google sign‑up logic (e.g. dispatch(googleSignUp()))
    console.log("Google sign‑up clicked");
  };
  const handleUsernameSignUp = async () => {
    // setIsLoading(true);
    try {
      // When "Other" is chosen, use the entered text; otherwise use the chosen reason.
      const selectedReason = reason === "Other" ? otherReasonText : reason;
      console.log("Signing up with", { email, password, reason: selectedReason });
      const response = await backendClient.registerUser({ email, password, reason: selectedReason });
      console.log("response", response);
      await dispatch(firebaseSignIn(email, password));
      return response;
      // toast({ title: "We've received your request," });
    } catch (error) {
      console.error("Sign‑up error:", error);
      return null;
    } finally {
      // setIsLoading(false);
    }
  };
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    setIsLoading(true);

    // Validate email
    if (!isValidEmail(email)) {
      alert("Please enter a valid email.");
      setIsLoading(false);
      return;
    }

    // Validate password is not empty
    if (!password) {
      alert("Password cannot be empty.");
      setIsLoading(false);
      return;
    }

    // Validate that a reason has been selected.
    if (!reason) {
      alert("Please select a reason for using the app.");
      setIsLoading(false);
      return;
    }
    if (isValidEmail(email)) {
      // Here you would typically make an API request to your backend to handle the email sending
      // Example: axios.post('/api/send-email', { email, date: new Date() })
      const response = await handleUsernameSignUp();
      const message = await dispatch(addToNewsletter(email));
      console.log("message", message);

      // Assuming the email was sent successfully
      if (response) {
        toast({
          title: "Registration successful",
          description: "We will contact you within the next few days.",
        });
      } else {
        toast({
          title: "User already exists",
          description: "Please try again with a different email. or login with the existing email.",
        });
      }

      setIsLoading(false);
    } else {
      alert("Please enter a valid email.");
      setIsLoading(false);
    }
    setEmail("");
    setPassword("");
    setReason("");
  };
  return (
    <section className="relative py-16">
      <div className="">
        <div className="flex flex-col gap-4">
          <div className="mx-auto w-full max-w-sm rounded-md p-6">
            <div className="mb-6 flex flex-col items-center text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="400px" height="400px" viewBox="0 0 800 800" className="absolute -top-24 -z-10 mx-auto text-muted-foreground/30">
                <rect fill="transparent" width="800" height="800" />
                <g fill-opacity="1">
                  <circle fill="transparent" cx="400" cy="400" r="400" strokeWidth="1" stroke="currentColor" opacity={0.5} />
                  <circle fill="transparent" cx="400" cy="400" r="300" strokeWidth="1" stroke="currentColor" opacity={0.6} />
                  <circle fill="transparent" cx="400" cy="400" r="200" strokeWidth="1" stroke="currentColor" opacity={0.7} />
                  <circle fill="transparent" cx="400" cy="400" r="100" strokeWidth="1" stroke="currentColor" opacity={0.8} />
                </g>
              </svg>

              <img src={logoImage2} alt="logo" className="mb-1 h-10 w-auto" />
              <p className="mb-2 text-2xl font-bold">Create an account</p>
              <p className="text-muted-foreground">Start your 30-day free trial.</p>
            </div>
            <div>
              <div className="grid gap-4">
                <Input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <Input placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />

                {/* ---------- Popover for Reason Selection ---------- */}
                <Popover open={open} onOpenChange={setOpen} modal={false}>
                  <PopoverTrigger asChild>
                    <Button variant="outline" role="combobox" className={`w-full justify-between ${!reason && "text-muted-foreground"}`}>
                      {reason || "Select reason for using the app"}
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="p-0 overflow-y-auto" align="start">
                    <Command>
                      <CommandInput placeholder="Search reason..." />
                      <CommandList className="overflow-y-auto">
                        <CommandEmpty>No reasons found.</CommandEmpty>
                        <CommandGroup heading="Reasons">
                          <CommandItem onSelect={() => setReason("Buying a business")}>
                            Buying a business
                            <Check className={`ml-auto ${reason === "Buying a business" ? "opacity-100" : "opacity-0"}`} />
                          </CommandItem>
                          <CommandItem onSelect={() => setReason("Selling a business")}>
                            Selling a business
                            <Check className={`ml-auto ${reason === "Selling a business" ? "opacity-100" : "opacity-0"}`} />
                          </CommandItem>
                          <CommandItem onSelect={() => setReason("I'm a broker")}>
                            I'm a broker
                            <Check className={`ml-auto ${reason === "I'm a broker" ? "opacity-100" : "opacity-0"}`} />
                          </CommandItem>
                          <CommandItem onSelect={() => setReason("Other")}>
                            Other (specify)
                            <Check className={`ml-auto ${reason === "Other" ? "opacity-100" : "opacity-0"}`} />
                          </CommandItem>
                          {reason === "Other" && (
                            <CommandItem>
                              <Input placeholder="Please specify" value={otherReasonText} onChange={(e) => setOtherReasonText(e.target.value)} />
                            </CommandItem>
                          )}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <Button className="mt-2 w-full" type="submit" onClick={handleSubmit} disabled={isLoading}>
                  {isLoading ? <Spinner size={18} /> : "Get Started"}
                </Button>

                <div className="flex items-center gap-4">
                  <span className="h-px w-full bg-input"></span>
                  <span className="text-xs text-muted-foreground">OR</span>
                  <span className="h-px w-full bg-input"></span>
                </div>
                <Button variant="outline" disabled className="w-full">
                  <FcGoogle className="mr-2 size-5" />
                  Sign up with Google
                </Button>
                {/*
                <Button variant="outline" className="w-full">
                  <FaMicrosoft className="mr-2 size-5" />
                  Sign up with Microsoft
                </Button>
                <Button variant="outline" className="w-full">
                  <FaApple className="mr-2 size-5" />
                  Sign up with Apple
                </Button> */}
              </div>
              <div className="mx-auto mt-8 flex justify-center gap-1 text-sm text-muted-foreground">
                <p>Already have an account?</p>
                <Link to="/sign-in" className="font-medium text-primary">
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SignUpScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { instance } = useMsal();
  const [checked, setChecked] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle sign in logic here
    // navigate("data-import");
  };

  const handleMicrosoftLogin = async () => {
    await dispatch(microsoftSignin(instance));
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return <Signup4 />;
};

export default SignUpScreen;
