import { api_subscription_key, backendUrl } from "../config";
// import { api_subscription_key } from "../config";
import { store } from "../redux/store";
import { fromBackendDocumentToFrontend } from "./utils/documents";
import { auth } from "../firebase"; // Assuming you have Firebase auth initialized
import { onAuthStateChanged } from "firebase/auth";

function decodeJwt(token) {
  try {
    const base64Url = token.split(".")[1]; // Get the payload part
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
}

async function waitForAuthState() {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    });
  });
}

// const backendUrl = "http://duedil-api.azure-api.net/duedil-app"
class BackendClient {
  async refreshTokenIfNeeded() {
    const state = store.getState();
    const token = state.auth.accessToken;
    const expirationTime = state.auth.tokenExpirationTime;
    const currentTime = Math.floor(Date.now() / 1000);

    console.log("Checking if token needs refreshing...");
    console.log(`Current Time: ${currentTime}, Expiration Time: ${expirationTime}`);
    console.log("Checking condition:", expirationTime, currentTime >= expirationTime - 600);

    if (expirationTime && currentTime >= expirationTime - 600) {
      console.log("Inside the refresh condition");

      let user = auth.currentUser;
      if (!user) {
        console.log("Waiting for Firebase Auth state...");
        user = await waitForAuthState();
      }

      if (!user) {
        console.log("User is still null after waiting. Cannot refresh token.");
        return null; // Handle re-authentication if needed
      }

      try {
        console.log("Refreshing token...");
        const newToken = await user.getIdToken(true);
        console.log("Token refreshed:", newToken);

        const decodedToken = decodeJwt(newToken);
        const newExpirationTime = decodedToken.exp;

        store.dispatch({
          type: "SET_ACCESS_TOKEN",
          payload: { accessToken: newToken, tokenExpirationTime: newExpirationTime },
        });

        console.log("Dispatched updated token and expiration time.");
        return newToken;
      } catch (error) {
        console.error("Error refreshing token:", error);
        return null;
      }
    }

    console.log("Token is still valid, returning current token.");
    return token;
  }

  async getHeader(content) {
    const token = await this.refreshTokenIfNeeded(); // Ensure token is fresh

    const state = store.getState();
    // const token = state.auth.accessToken;
    const organization_id = state.auth.user?.organization_id;
    const user_id = state.auth.user?.id;
    console.log("header for othman", state.auth);

    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    // headers.append("Ocp-Apim-Subscription-Key", api_subscription_key); // Add this line
    if (content === "json" || content === undefined) {
      headers.append("Content-Type", "application/json");
    }
    // console.log("headers", headers);
    // console.log("headers", Object.fromEntries(headers.entries()));

    return { user_id, organization_id, headers };
  }

  async get(endpoint, content) {
    const url = backendUrl + endpoint;
    const { organization_id, headers } = await this.getHeader(content);
    const options = {
      method: "GET",
      headers,
    };

    try {
      console.log(url);
      console.log(options);
      const res = await fetch(url, options);
      console.log("res", res.status === 404);
      if (res.status === 404) {
        return null;
      } else if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res;
    } catch (error) {
      // this.enqueueSnackbar("Something went wrong. Try again!", { variant: "error" });
      throw error; // Re-throw the error if you need further error handling up the chain
    }
  }

  async post(endpoint, body, qp = false) {
    const url = qp ? endpoint : backendUrl + endpoint;
    const { headers } = await this.getHeader();

    console.log(body);
    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    };

    try {
      const res = await fetch(url, options);
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res;
    } catch (error) {
      // this.enqueueSnackbar("Something went wrong. Try again!", { variant: "error" });
      throw error; // Re-throw the error if you need further error handling up the chain
    }
  }

  async put(endpoint, body) {
    const url = backendUrl + endpoint;
    const { headers } = await this.getHeader();
    console.log(body);
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers,
        body: JSON.stringify(body),
      });
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res;
    } catch (error) {
      // this.enqueueSnackbar("Something went wrong. Try again!", { variant: "error" });
      throw error; // Re-throw the error if you need further error handling up the chain
    }
  }

  async delete(endpoint) {
    const url = backendUrl + endpoint;
    const { headers } = await this.getHeader("delete");
    const options = {
      method: "DELETE",
      headers,
    };

    try {
      const res = await fetch(url, options);
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res;
    } catch (error) {
      // this.enqueueSnackbar("Something went wrong. Try again!", { variant: "error" });
      throw error; // Re-throw the error if you need further error handling up the chain
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// TRANSACTIONS ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async createTransaction(transaction) {
    const endpoint = "/v1/deals/";
    let payload = transaction;
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }
  async importTransaction(link) {
    const endpoint = `/v1/deals/import?uri=${link}`;

    const res = await this.post(endpoint, {});
    try {
      const data = await res.json();
      // if data is empty object return null
      if (Object.keys(data).length === 0) {
        return null;
      }

      return data;
    } catch {
      return null;
    }
  }

  async deleteTransactions(transactionIds) {
    const endpoint = `api/transaction/bulk-delete/`;
    let payload = transactionIds;
    const res = await this.post(endpoint, payload);
    // const url = backendUrl + endpoint;
    // const res = await fetch(url, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(payload),
    // });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res;
  }

  async updateTransactionProperty(transactionId, updates) {
    console.log("updateTransactionProperty", transactionId, updates);

    const endpoint = `/v1/deals/${transactionId}/`;

    // Prepare the payload dynamically with multiple properties
    const payload = {
      ...updates, // Spreading updates to include multiple properties dynamically
    };

    console.log("payload", payload);
    const res = await this.put(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async fetchTransaction(id) {
    const endpoint = `api/transaction/${id}`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }

  async fetchTransactions() {
    const endpoint = `/v1/deals`;
    console.log("Getting deals");
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }
  async createWorkstreams(transaction_id) {
    const endpoint = `api/transaction/create_workstreams/`;
    let payload = { transaction_id };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async countTransactions() {
    const endpoint = `api/transaction/count/`;
    let payload = {};
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }
  async get_error_file() {
    const endpoint = `api/transaction/error_documenttransaction/`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// KEYWORDS ///////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async countKeywords() {
    const endpoint = `api/keyword/count/`;
    let payload = {};
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async countKeyKeywords() {
    const endpoint = `api/keyword/key-count/`;
    let payload = {};
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  // creating keyword
  async creatingKeyword(keyword) {
    const endpoint = `api/keyword/`;
    let payload = keyword;
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// HYPOTHESIS ///////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  async countHypothesis() {
    const endpoint = `api/hypothesis/count/`;
    let payload = {};
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async valueCountTransaction(field_name) {
    const endpoint = `api/transaction/field/value_counts/`;
    let payload = { field_name };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async reExtractOneHypothesis(transaction_id, user_id, hypothesis_id) {
    const endpoint = "api/hypothesis/extract-single-hypothesis/";
    let payload = { transaction_id, user_id, hypothesis_id };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async extractHypothesis(transactionsIds, user_id, reset = false) {
    const endpoint = "api/hypothesis/extract-hypothesis/";
    console.log(transactionsIds);
    let payload = { transactionsIds, user_id, reset };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }
  async getPeers(hypotheses) {
    const endpoint = "api/hypothesis/get_peers/";
    console.log("getPEEERS", hypotheses);
    let payload = { hypotheses };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async fetchHypothesis(transaction_id) {
    const endpoint = `api/hypothesis/${transaction_id}`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }
  async fetchHypothesis_type(hyp_type) {
    const params = new URLSearchParams({ hyp_type });
    const endpoint = `api/hypothesis/?${params}`;
    const res = await this.get(endpoint);
    // const endpoint = `api/hypothesis/${hyp_type}`;
    // const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }

  async deleteHypothesis(hypothesisIds) {
    const endpoint = `api/hypothesis/bulk-delete/`;
    let payload = hypothesisIds;
    const res = await this.post(endpoint, payload);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res;
  }

  async updateHypothesis(hypothesis_id, hypothesis_data) {
    // console.log("updateTransactionProperty", transactionId, propertyName, newValue, append, remove);
    const endpoint = `api/hypothesis/update_hypothesis/${hypothesis_id}`;
    const payload = hypothesis_data;
    const res = await this.post(endpoint, payload);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// KEYWORD //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async createKeyword(keyword) {
    const endpoint = "api/keyword/";
    let payload = keyword;
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async getKeywords(page, pageSize) {
    const endpoint = `api/keyword/get_keywords/`;
    let payload = { page, pageSize };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async getKeyword(id) {
    const endpoint = `api/keyword/${id}`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }

  // write async getSyncrhonized with for this endpoint
  async getSynchronized() {
    const endpoint = "api/keyword/get_synchronized/";
    let payload = {};
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async startSynchronize() {
    const endpoint = "api/keyword/synchronize/";
    let payload = {};
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async updateKeyword(id, keywordData) {
    const endpoint = `api/keyword/update_keyword/${id}`;
    const res = await this.post(endpoint, keywordData); // Assuming that update is done via POST, adjust if necessary
    const data = await res.json();
    return data;
  }

  async deleteKeywords(keywordIds) {
    const endpoint = `api/keyword/bulk-delete/`;
    let payload = keywordIds;
    const res = await this.post(endpoint, payload);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// EXPLORE /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async explore(exploreData) {
    const endpoint = "api/explore/";
    let payload = exploreData;
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// DOCUMENTS /////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async updateDocumentProperty(documentId, propertyName, newValue, append, remove) {
    const endpoint = `api/document/update_document/`;
    const payload = { document_id: documentId, property_name: propertyName, new_value: newValue, append: append, remove: remove };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }
  async setDocumentNa(transaction_id, type, document_id) {
    const endpoint = `/v1/deals/${transaction_id}/${type}/${document_id}/not-applicable`;
    const payload = {};
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }
  async deleteDocument(transaction_id, type, document_id) {
    const endpoint = `/v1/deals/${transaction_id}/${type}/${document_id}`;
    const res = await this.delete(endpoint);
    // const data = await res.json();
    return { id: "Not Applicable" };
  }

  // async getDocumentSas(containerName, blobName) {
  //   const endpoint = `api/document/generate-sas/${containerName}/${blobName}`;
  //   const res = await this.get(endpoint);
  //   const data = await res.json();
  //   return data;
  // }
  async getDocumentSas(containerName, blobName, table = false) {
    const endpoint = `api/document/generate-sas`;
    const body = { containerName, blobName, table };
    const res = await this.post(endpoint, body);
    const data = await res.json();
    return data;
  }

  async deleteDocuments(transactionIdDocumentsIds) {
    const endpoint = `api/document/bulk-delete/`;
    let payload = transactionIdDocumentsIds;
    const res = await this.post(endpoint, payload);

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res;
  }

  async countDocuments() {
    const endpoint = `api/document/count/`;
    let payload = {};
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// CONVERSATIONS //////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async updateMessageLike(message_id, value, text) {
    const endpoint = `api/conversation/update-message-like/`; // Adjust the endpoint as per your API route

    const payload = {
      message_id: message_id,
      value: value,
      text: text,
    };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async createConversation(transactionId_documentsIds, user_id) {
    const endpoint = "api/conversation/";
    let payload = { transaction_documents: transactionId_documentsIds, user_id: user_id, source: "deals" };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data.id;
  }

  async deleteConversations(conversationIds, user_id) {
    const endpoint = `api/conversation/bulk-delete/`;
    let payload = { conversationIds: conversationIds, user_id: user_id };
    const res = await this.post(endpoint, payload);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res;
  }

  async fetchConversation(id, user_id) {
    const endpoint = `api/conversation/getOne/${id}?user_id=${user_id}`;
    const res = await this.get(endpoint);
    const data = await res.json();

    return data;
  }

  async fetchConversations(user_id, page = 1, pageSize = 10) {
    const endpoint = `api/conversation/get_conversations/`;
    let payload = { user_id, page, pageSize };
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async countConversations() {
    // async countConversations() {
    const endpoint = `api/conversation/count/`;
    let payload = { page: 0, pageSize: 1 };
    // console.log(payload);
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    return data;
  }

  async fetchDocuments(transactionId, page = null, pageSize = null) {
    console.log("fetchDocuments", transactionId, page, pageSize);
    let endpoint = `/v1/deals/${transactionId}/documents`;
    let payload = {};
    console.log("transactionId", transactionId);

    // Add transactionId to the payload if it is not null
    if (transactionId !== null) {
      payload.transactionId = transactionId;
    }
    // Add page to the payload if it is not null
    if (page !== null) {
      payload.page = page;
    }
    // Add pageSize to the payload if it is not null
    if (pageSize !== null) {
      payload.pageSize = pageSize;
    }

    const res = await this.get(endpoint);
    // console.log(res);
    const data = await res.json();
    console.log(data);
    // const docs = fromBackendDocumentToFrontend(data);
    return data;
  }

  async fetchDocument(document_id) {
    const endpoint = `api/document/${document_id}`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }

  async uploadDocument(file, metadata) {
    const endpoint = `api/document/upload/`;
    const formData = new FormData();
    formData.append("file", file);
    if (metadata && Object.keys(metadata).length !== 0) {
      formData.append("metadata", JSON.stringify(metadata));
      formData.append("with_metadata", "true");
    }
    // const headers = await this.getHeader("json");
    const state = store.getState();
    const token = state.auth.accessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Ocp-Apim-Subscription-Key": api_subscription_key,
      // "Content-Type": "multipart/form-data"
    };
    const url = backendUrl + endpoint;

    const res = await fetch(url, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  }

  async uploadDocuments(files, metadatas, transactionData) {
    const endpoint = `api/document/upload_files/`;
    const formData = new FormData();
    console.log("Files to upload:", files);
    console.log("Metadata:", metadatas);
    console.log("Transaction data:", transactionData);

    // Correctly append files and their corresponding metadata
    files.forEach((file, index) => {
      // Sanitize the file name by removing characters that may cause URL issues
      const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9.\-_]/g, "");
      const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });

      formData.append(`files`, sanitizedFile);

      if (metadatas && metadatas[index]) {
        formData.append(`metadatas`, JSON.stringify(metadatas[index]));
      }
    });

    console.log("Formatted FormData", formData);

    // Append transactionData to the formData
    let transactionData_ = transactionData;
    transactionData_.organization_id = store.getState().auth.user.organization_id;
    console.log("transactionData_", transactionData_);
    if (transactionData && Object.keys(transactionData).length !== 0) {
      formData.append("transaction_data", JSON.stringify(transactionData));
    }

    // Additional flag to indicate multiple files upload if needed
    formData.append("with_metadata", "true");

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    // Setup headers, excluding "Content-Type" to let the browser set it along with the boundary
    const state = store.getState();
    const token = state.auth.accessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Ocp-Apim-Subscription-Key": api_subscription_key,
    };
    const url = backendUrl + endpoint;

    const res = await fetch(url, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  }

  async addDocuments(transaction_id, file, metadatas) {
    const endpoint = `/v1/deals/${transaction_id}/documents?`;
    const formData = new FormData();

    formData.append("file", file);

    const queryParams = new URLSearchParams();

    if (metadatas) {
      for (const [key, value] of Object.entries(metadatas)) {
        if (Array.isArray(value)) {
          // Append array values as repeated query parameters
          value.forEach((item) => queryParams.append(key, item));
        } else {
          queryParams.append(key, value);
        }
      }
    }

    const token = await this.refreshTokenIfNeeded(); // Ensure token is fresh
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = backendUrl + endpoint + queryParams.toString();
    console.log(url);
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// USERS //////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async addToNewsletter(email) {
    const endpoint = "/v1/newsletters/subscribers";
    let payload = { email };
    const res = await this.post(endpoint, payload);
    return await res.json();
  }

  async createUser(userData) {
    const endpoint = "api/user/";
    const url = backendUrl + endpoint;

    const state = store.getState();
    const token = state.auth.accessToken;
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append("Ocp-Apim-Subscription-Key", api_subscription_key); // Add this line
    headers.append("Content-Type", "application/json");

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(userData),
    };

    try {
      const res = await fetch(url, options);
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json();
    } catch (error) {
      throw error;
    }
  }

  async getUser(userId) {
    const endpoint = `api/user/${userId}`; // Adjust the endpoint to match your backend route
    const res = await this.get(endpoint);
    if (!res) {
      return null;
    }
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return res.json();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// VALUATION //////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  async get_industries() {
    const endpoint = `/v1/deals/industries`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }
  async get_state(country) {
    const endpoint = `/v1/countries/${country}/states`;
    const res = await this.get(endpoint);
    try {
      const data = await res.json();
      return data;
    } catch {
      console.log("error");
      return [];
    }
  }
  async get_municipalities(country, state) {
    const endpoint = `/v1/countries/${country}/states/${state}/municipalities`;
    const res = await this.get(endpoint);
    try {
      const data = await res.json();
      return data;
    } catch {
      console.log("error");
      return [];
    }
  }
  async get_cities(country, state, municipality) {
    const endpoint = `/v1/countries/${country}/states/${state}/municipalities/${municipality}/cities`;
    const res = await this.get(endpoint);
    try {
      const data = await res.json();
      return data;
    } catch {
      console.log("error");
      return [];
    }
  }
  async get_broad_valuation(params) {
    const endpoint = `valuation/get-broad-valuation/`;
    const query = new URLSearchParams(params).toString();
    const res = await this.get(`${endpoint}?${query}`);
    const data = await res.json();
    return data;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// WORKSTREAM ////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async fetchWorkstream(transaction_id, skip, limit) {
    console.log(transaction_id, skip, limit);
    const params = new URLSearchParams({ transaction_id, skip, limit });
    const endpoint = `api/workstream/get_workstreams/?${params}`;
    console.log("Running request");
    const res = await this.get(endpoint);
    console.log(res);
    const data = await res.json();
    return data;
  }

  async fetchWorkstreamById(workstream_id) {
    const endpoint = `/api/workstream/get_workstream/${workstream_id}`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }

  async createWorkstream(workstreamData) {
    const endpoint = "/api/workstream/add_workstream/";
    const res = await this.post(endpoint, workstreamData);
    const data = await res.json();
    return data;
  }

  async deleteWorkstream(workstream_id) {
    const params = new URLSearchParams({ workstream_id });
    const endpoint = `/api/workstream/delete_workstream/?${params}`;
    const res = await this.delete(endpoint);
    return res;
  }

  async updateWorkstream(workstream_id, workstreamData) {
    const endpoint = `/api/workstream/update_workstream/${workstream_id}`;
    const res = await this.post(endpoint, workstreamData);
    const data = await res.json();
    return data;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// TEMPLATEWORKSTREAM  /////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async fetchTemplateWorkstream(industry, organization_id) {
    try {
      const params = new URLSearchParams({ industry, organization_id });
      const endpoint = `api/templateworkstream/get_template_workstreams/?${params}`;
      const res = await this.get(endpoint);
      return await res.json();
    } catch (error) {
      throw new Error(`Error fetching template workstreams: ${error.message}`);
    }
  }

  async fetchTemplateWorkstreamById(template_workstream_id) {
    try {
      const endpoint = `api/templateworkstream/get_template_workstream/${template_workstream_id}`;
      const res = await this.get(endpoint);
      return await res.json();
    } catch (error) {
      throw new Error(`Error fetching template workstream by ID: ${error.message}`);
    }
  }

  async createTemplateWorkstream(template_workstream) {
    try {
      const endpoint = `api/templateworkstream/add_template_workstream/`;
      const res = await this.post(endpoint, template_workstream);
      return await res.json();
    } catch (error) {
      throw new Error(`Error creating template workstream: ${error.message}`);
    }
  }

  async updateTemplateWorkstream(template_workstream_id, template_workstream) {
    try {
      const endpoint = `api/templateworkstream/update_template_workstreams/${template_workstream_id}`;
      const payload = template_workstream;
      console.log(JSON.stringify(payload));
      const res = await this.post(endpoint, template_workstream);
      return await res.json();
    } catch (error) {
      throw new Error(`Error updating template workstream: ${error.message}`);
    }
  }

  async deleteTemplateWorkstream(template_workstream_id) {
    try {
      const endpoint = `api/templateworkstream/delete_template_workstream/${template_workstream_id}`;
      const res = await this.delete(endpoint);
      return res;
    } catch (error) {
      throw new Error(`Error deleting template workstream: ${error.message}`);
    }
  }

  async countTemplateWorkstreams(industry, organization_id) {
    try {
      const params = new URLSearchParams({ industry, organization_id });
      const endpoint = `api/templateworkstream/count/?${params}`;
      const res = await this.get(endpoint);
      return await res.json();
    } catch (error) {
      throw new Error(`Error counting template workstreams: ${error.message}`);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// TEMPLATEQuestionnaires  /////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async createTemplateQuestionnaire(questionnaireData) {
    try {
      const res = await this.post(`api/templatequestionnaire/create_template_questionnaire/`, questionnaireData);
      return await res.json();
    } catch (error) {
      throw new Error(`Error creating template questionnaire: ${error.message}`);
    }
  }

  async getTemplateQuestionnaires(templateWorkstreamId) {
    try {
      const res = await this.get(`api/templatequestionnaire/get_template_questionnaires/?template_workstream_id=${templateWorkstreamId}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error fetching template questionnaires: ${error.message}`);
    }
  }

  async updateTemplateQuestionnaire(id, questionnaireData) {
    try {
      const res = await this.post(`api/templatequestionnaire/update_template_questionnaires/${id}`, questionnaireData);
      return await res.json();
    } catch (error) {
      throw new Error(`Error updating template questionnaire: ${error.message}`);
    }
  }

  async deleteTemplateQuestionnaire(id) {
    try {
      const res = await this.delete(`api/templatequestionnaire/delete_template_questionnaires/${id}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error deleting template questionnaire: ${error.message}`);
    }
  }

  async bulkUpdateTemplateQuestionnaires(updateDataList) {
    try {
      const res = await this.post(`api/templatequestionnaire/bulk_update/`, updateDataList);
      return await res.json();
    } catch (error) {
      throw new Error(`Error bulk updating template questionnaires: ${error.message}`);
    }
  }

  async bulkDeleteTemplateQuestionnaires(ids) {
    try {
      const res = await this.post(`api/templatequestionnaire/bulk_delete/`, { questionaire_ids: ids });
      return await res.json();
    } catch (error) {
      throw new Error(`Error bulk deleting template questionnaires: ${error.message}`);
    }
  }

  async countQuestions(questionnaireId) {
    try {
      const res = await this.get(`api/templatequestionnaire/count/?questionnaire_id=${questionnaireId}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error counting questions: ${error.message}`);
    }
  }

  async fetchTemplateQuestions(template_questionnaire_id) {
    const endpoint = `api/templatequestion/get_template_questions/?template_questionnaire_id=${template_questionnaire_id}`;
    const res = await this.get(endpoint);
    return await res.json();
  }

  // Create a new template question
  async createTemplateQuestion(questionData) {
    const endpoint = `api/templatequestion/create_template_questions/`;
    const res = await this.post(endpoint, questionData);
    return await res.json();
  }

  // Update an existing template question
  async updateTemplateQuestion(questionId, questionData) {
    const endpoint = `api/templatequestion/update_template_questions/${questionId}`;
    const res = await this.post(endpoint, questionData);
    return await res.json();
  }

  // Delete a template question
  async deleteTemplateQuestions(questionIds) {
    const endpoint = `api/templatequestion/bulk_delete/`; // Use the appropriate endpoint
    const res = await this.post(endpoint, questionIds); // Send the array of question IDs as the body
    return res;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////// Questionnaire and Questions  /////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async createQuestionnaire(questionnaireData) {
    try {
      const res = await this.post(`api/questionnaire/create_questionnaire/`, questionnaireData);
      return await res.json();
    } catch (error) {
      throw new Error(`Error creating questionnaire: ${error.message}`);
    }
  }
  async getQuestionnaires(workstreamId) {
    try {
      const res = await this.get(`api/questionnaire/get_questionnaires/?_workstream_id=${workstreamId}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error fetching questionnaires: ${error.message}`);
    }
  }
  async updateQuestionnaire(id, questionnaireData) {
    try {
      const res = await this.post(`api/questionnaire/update_questionnaires/${id}`, questionnaireData);
      return await res.json();
    } catch (error) {
      throw new Error(`Error updating questionnaire: ${error.message}`);
    }
  }
  async deleteQuestionnaire(id) {
    try {
      const res = await this.delete(`api/questionnaire/delete_questionnaires/${id}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error deleting questionnaire: ${error.message}`);
    }
  }
  async bulkDeleteQuestionnaires(ids) {
    try {
      const res = await this.post(`api/questionnaire/bulk_delete/`, { questionaire_ids: ids });
      return await res.json();
    } catch (error) {
      throw new Error(`Error bulk deleting questionnaires: ${error.message}`);
    }
  }
  async countQuestionsreal(questionnaireId) {
    try {
      const res = await this.get(`api/questionnaire/count/?questionnaire_id=${questionnaireId}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error counting questions: ${error.message}`);
    }
  }
  async createQuestion(questionData) {
    try {
      const res = await this.post(`api/question/create_questions/`, questionData);
      return await res.json();
    } catch (error) {
      throw new Error(`Error creating question: ${error.message}`);
    }
  }
  async getQuestions(questionnaireId) {
    try {
      const res = await this.get(`api/question/get_questions/?_questionnaire_id=${questionnaireId}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error fetching questions: ${error.message}`);
    }
  }
  async updateQuestion(questionId, questionData) {
    try {
      const res = await this.post(`api/question/update_questions/${questionId}`, questionData);
      return await res.json();
    } catch (error) {
      throw new Error(`Error updating question: ${error.message}`);
    }
  }
  async deleteQuestion(questionId) {
    try {
      const res = await this.delete(`api/question/delete_questions/${questionId}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error deleting question: ${error.message}`);
    }
  }
  async bulkDeleteQuestions(questionIds) {
    try {
      const res = await this.post(`api/question/bulk_delete/`, { questions_id: questionIds });
      return await res.json();
    } catch (error) {
      throw new Error(`Error bulk deleting questions: ${error.message}`);
    }
  }
  async countQuestionsByAction(action, workstreamId) {
    try {
      const res = await this.get(`api/question/count_by_action/?action=${action}&_workstream_id=${workstreamId}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error counting questions by action: ${error.message}`);
    }
  }
  async countQuestionsByStatus(status, workstreamId) {
    try {
      const res = await this.get(`api/question/count_by_status/?status=${status}&_workstream_id=${workstreamId}`);
      return await res.json();
    } catch (error) {
      throw new Error(`Error counting questions by status: ${error.message}`);
    }
  }
  async validateQuestion(questionId, questionData) {
    try {
      const res = await this.post(`api/question/validate_question/${questionId}`, questionData);
      const validatedQuestion = await res.json();

      // Handle the validated question and any generated sub-questions if necessary
      return validatedQuestion;
    } catch (error) {
      throw new Error(`Error validating question: ${error.message}`);
    }
  }

  // Financial Statements

  async fetchFinancialStatements(transaction_id, skip = 0, limit = 10) {
    const params = new URLSearchParams({ transaction_id, skip, limit });
    const endpoint = `/api/financial_statement/get_statements/?${params}`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }

  // Fetch a single financial statement by its ID
  async fetchFinancialStatementById(transactionId, type, statement_id) {
    const endpoint = `/v1/deals/${transactionId}/${type}/${statement_id}`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }

  // Create a new financial statement
  async createFinancialStatement(statementData) {
    const endpoint = `/api/financial_statement/add_statement/`;
    const res = await this.post(endpoint, statementData);
    const data = await res.json();
    return data;
  }

  // Update an existing financial statement
  async updateFinancialStatement(transactionId, type, statement_id, statementData) {
    const endpoint = `/v1/deals/${transactionId}/${type}/${statement_id}/`;
    const res = await this.put(endpoint, statementData); // Assuming POST is used for updates
    const data = await res.json();
    return data;
  }

  // Delete a financial statement by its ID
  async deleteFinancialStatement(statement_id) {
    const params = new URLSearchParams({ statement_id });
    const endpoint = `/api/financial_statement/delete_statement/?${params}`;
    const res = await this.delete(endpoint);
    return res;
  }

  // BUSINESS CHECKLIST
  async addbuschecklist(transaction_id, file, metadatas) {
    const endpoint = `/v1/deals/${transaction_id}/business-checklist?`;
    const formData = new FormData();

    formData.append("file", file);

    const queryParams = new URLSearchParams();

    if (metadatas) {
      for (const [key, value] of Object.entries(metadatas)) {
        if (Array.isArray(value)) {
          // Append array values as repeated query parameters
          value.forEach((item) => queryParams.append(key, item));
        } else {
          queryParams.append(key, value);
        }
      }
    }

    const token = await this.refreshTokenIfNeeded(); // Ensure token is fresh
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = backendUrl + endpoint + queryParams.toString();
    console.log(url);
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return;
  }
  async fetchChecklist(transaction_id) {
    const endpoint = `/v1/deals/${transaction_id}/business-checklist`;
    const res = await this.get(endpoint);
    const data = await res.json();
    // console.log(data);
    return data;
  }
  async answerChecklistQuestion(transactionId, businessChecklistQuestionId, answer) {
    const endpoint = `/v1/deals/${transactionId}/business-checklist/${businessChecklistQuestionId}/answer`;
    const payload = {
      answer: answer,
    };
    console.log("payload", payload);
    const res = await this.put(endpoint, payload);
    // const text = await res.text();
    // console.log(text);
    const data = await res.json();
    return data;
  }
  async setStatusChecklistQuestion(transactionId, businessChecklistQuestionId, status) {
    const endpoint = `/v1/deals/${transactionId}/business-checklist/${businessChecklistQuestionId}/set-status?status=${status}`;
    const payload = {};
    console.log("payload", payload);
    const res = await this.put(endpoint, payload);
    // const text = await res.text();
    // console.log(text);
    const data = await res.json();
    return data;
  }
  async deleteBusQuestion(transactionId, businessChecklistQuestionId) {
    const endpoint = `/v1/deals/${transactionId}/business-checklist/${businessChecklistQuestionId}`;
    const res = await this.delete(endpoint);
    // const data = await res.json();
    return { status: "Success" };
  }
  async fetchAudit(transaction_id) {
    const endpoint = `/v1/financial-audit?dealId=${transaction_id}`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }
  async answerAudit(transactionId, financialAuditQuestionId, answer) {
    const endpoint = `/v1/financial-audit/${financialAuditQuestionId}/answer?dealId=${transactionId}`;
    const payload = {
      answer: answer,
    };
    console.log("payload", payload);
    const res = await this.put(endpoint, payload);
    // const text = await res.text();
    // console.log(text);
    // const data = await res.json();
    return { success: true };
  }
  async setStatusAuditQuestion(transactionId, financialAuditQuestionId, status) {
    const endpoint = `/v1/financial-audit/${financialAuditQuestionId}/set-status?dealId=${transactionId}&status=${status}`;
    console.log(endpoint);
    const payload = {};
    console.log("payload", payload);
    const res = await this.put(endpoint, payload);
    // const text = await res.text();
    // console.log(text);
    // const data = await res.json();
    return { success: true };
  }
  async deleteAudit(transactionId, financialAuditQuestionId) {
    const endpoint = `/v1/financial-audit/${financialAuditQuestionId}?dealId=${transactionId}`;
    const res = await this.delete(endpoint);
    // const data = await res.json();
    return { status: "Success" };
  }

  // MARKET ANALYSIS

  async fetchMunicipalityData(transactionId) {
    const endpoint = `/v1/deals/${transactionId}/market-analysis`;
    const res = await this.get(endpoint);
    console.log(res);
    const data = await res.json();
    return data;
  }
  async getsimilarbusiness(transactionId) {
    const endpoint = `/v1/deals/${transactionId}/similar-places`;
    const res = await this.get(endpoint);
    const data = await res.json();
    return data;
  }
  async getImg(transactionId, placeId, photoReference) {
    const endpoint = `/v1/deals/${transactionId}/similar-places/${placeId}/photo/${photoReference}`;
    const res = await this.get(endpoint);
    const blob = await res.blob(); // ✅ Convert response to an image blob
    return URL.createObjectURL(blob); // ✅ Return a URL for the image
  }
  async fetchLocation(api_key, address) {
    const formattedAddress = address.replace(/\s+/g, "+");
    const url = `https://geocode.maps.co/search?q=${formattedAddress}&api_key=${api_key}`;
    console.log(url);
    try {
      const res = await fetch(url);

      if (res.status === 404) {
        return null; // Address not found
      } else if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error fetching location:", error);
      throw error; // Re-throw for further handling if needed
    }
  }

  flatten = (arr) => arr.flat(Infinity);

  async nextSearch(city) {
    console.log("start next search");

    const headers = {
      "User-Agent": "MyApplication/1.0 (https://yourwebsite.com; contact@yourwebsite.com)",
      Accept: "text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8",
      "Accept-Language": "en-US,en;q=0.5",
      Referer: "https://yourwebsite.com",
      Connection: "keep-alive",
      // "Access-Control-Allow-Origin": "*",
    };

    try {
      // Fetch city ID from Nominatim API
      const nominatimResponse = await fetch(`https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(city)}&format=jsonv2`, { headers });
      const nominatimData = await nominatimResponse.json();

      if (!nominatimData.length) {
        throw new Error("City not found");
      }

      const cityId = nominatimData[0].osm_id;
      console.log(cityId);

      // Fetch city coordinates from OpenStreetMap
      const coordiResponse = await fetch(`https://polygons.openstreetmap.fr/get_geojson.py?id=${cityId}`, { headers });
      console.log(coordiResponse.status);

      const coordiContent = await coordiResponse.text();

      if (coordiContent.trim() === "None") {
        // If no polygon data, fetch bounding box
        const boundingBoxResponse = await fetch(`https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(city)}&format=jsonv2`);
        const boundingBoxData = await boundingBoxResponse.json();

        if (!boundingBoxData.length) {
          throw new Error("Bounding box not found");
        }

        const boundingBox = boundingBoxData[0].boundingbox;
        return { city, gridData: boundingBox };
      }

      // Process polygon coordinates
      const coordiJson = JSON.parse(coordiContent);

      // Extract coordinates
      let coor;
      if (coordiJson.coordinates) {
        // coor = this.flatten(this.flatten(coordiJson.coordinates));
        coor = this.flatten(coordiJson.coordinates);
      } else if (coordiJson.geometries && coordiJson.geometries[0]?.coordinates) {
        // coor = this.flatten(this.flatten(coordiJson.geometries[0].coordinates[0][0]));
        coor = this.flatten(coordiJson.geometries[0].coordinates[0][0]);
      } else {
        return {};
      }

      return { city, gridData: coor };
    } catch (error) {
      console.error("Error fetching city data:", error);
      throw error;
    }
  }

  // CASHFLOW BACKEND
  async fetchCashflow(transactionId) {
    try {
      // const endpoint_2 = `/v1/deals/${transactionId}/cash-flows`;
      const endpoint_2 = `/v1/deals/${transactionId}/cash-flows/snapshots?version=0`;
      const res_2 = await this.get(endpoint_2);
      const data_2 = await res_2.json();
      console.log(data_2);
      if (!data_2[0]) {
        const endpoint = `/v1/deals/${transactionId}/cash-flows`;
        const res = await this.get(endpoint);
        const data = await res.json();
        console.log(data);
        return data;
      }
      return data_2[0].snapshot;
    } catch (error) {
      console.error("Error fetching cash flow:", error);
      return {};
    }
  }
  async updateCashflow(transactionId, payload) {
    try {
      const endpoint = `/v1/deals/${transactionId}/cash-flows/snapshots`;
      const res = await this.post(endpoint, payload);
      console.log(res);
      const data = await res.json();
      console.log(data);
      return data;
    } catch (error) {
      console.error("Error fetching cash flow:", error);
      return {};
    }
  }
  async fetchDebt(transactionId) {
    try {
      const endpoint = `/v1/deals/${transactionId}/debt-schedule`;
      const res = await this.get(endpoint);
      console.log(res);
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error fetching debt schedule:", error);
      return {};
    }
  }

  async fetchPurchasePrice(transactionId) {
    try {
      const endpoint = `/v1/deals/${transactionId}/purchase-price`;
      const res = await this.get(endpoint);
      console.log(res);
      const data = await res.json();
      return data;
    } catch (error) {
      console.error("Error fetching purchase price:", error);
      return {};
    }
  }
  async updateDebt(transactionId, payload) {
    const endpoint = `/v1/deals/${transactionId}/debt-schedule`;
    const res = await this.post(endpoint, payload);
    console.log(res);
    const data = await res.json();
    return data;
  }
  async updatedebtCF(transactionId, payload) {
    const endpoint = `/v1/deals/${transactionId}/cash-flows/_with_debt_schedule`;
    const res = await this.post(endpoint, payload);
    const data = await res.json();
    console.log(data);
    return data;
  }

  async updatePurchasePrice(transactionId, payload) {
    const endpoint = `/v1/deals/${transactionId}/purchase-price`;
    const res = await this.post(endpoint, payload);
    console.log(res);
    const data = await res.json();
    return data;
  }

  async registerUser(payload) {
    const endpoint = `/v1/users/register`;

    const res = await this.post(endpoint, payload);
    console.log(res);
    const data = await res.json();
    return data;
  }

  async fetchUser(token) {
    const endpoint = `/v1/users`;
    const url = backendUrl + endpoint;
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    const options = {
      method: "GET",
      headers,
    };
    try {
      console.log(url);
      console.log(options);
      const res = await fetch(url, options);
      console.log("res", res.status === 404);
      if (res.status === 404) {
        return null;
      } else if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json();
    } catch (error) {
      // this.enqueueSnackbar("Something went wrong. Try again!", { variant: "error" });
      throw error; // Re-throw the error if you need further error handling up the chain
    }
  }
}

export const backendClient = new BackendClient();
