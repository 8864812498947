import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Skeleton } from "../../../components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import React, { useState } from "react";

export function CardItem({ title, icon, value, description, loading }) {
  return (
    <Card className="transition-colors hover:bg-muted hover:cursor-pointer">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium"> {loading ? <Skeleton className="h-4 w-24" /> : title}</CardTitle>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="h-4 w-4 text-muted-foreground" dangerouslySetInnerHTML={{ __html: icon }} />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold"> {loading ? <Skeleton className="h-8 w-20" /> : value}</div>
        <p className="text-xs text-muted-foreground">{loading ? <Skeleton className="h-4 w-36" /> : description}</p>
      </CardContent>
    </Card>
  );
}
const FormLis = ({ comp, loading }) => {
  return (
    <div>
      <ul className="mt-4 flex gap-2 flex-col">
        {loading
          ? Array(3)
              .fill("")
              .map((_, i) => (
                <li className="flex gap-2" key={i}>
                  <Skeleton className="h-6 w-6" />
                  <Skeleton className="h-4 w-48" />
                </li>
              ))
          : comp.map((e, i) => (
              <li className="flex gap-2" key={i}>
                <span className="flex size-6 shrink-0 items-center justify-center rounded-xl bg-black font-mono text-xs text-primary text-white">{i + 1}</span>
                <p className="text-sm text-muted-foreground">{e}</p>
              </li>
            ))}
      </ul>
    </div>
  );
};
const CardTab = ({ title, content, comp, div = true, loading }) => {
  const [isScrollAreaEnabled, setScrollAreaEnabled] = useState(false);

  return (
    <Card className="col-span-1 flex flex-col max-w-full max-h-full ">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent className="h-full flex items-center flex-grow">
        <Card className="p-2 bg-muted text-muted-foreground flex flex-col space-y-4 w-full">
          <CardContent className="w-full">
            {/* <CardDescription className="font-bold text-left">Net Sale Price</CardDescription>
              <CardDescription className="text-right">$4,295,241</CardDescription>

              <CardDescription className="font-bold text-left">Loan Balance at Exit</CardDescription>
              <CardDescription className="text-right text-red-500">-$1,129,241</CardDescription> */}

            {div ? (
              loading ? (
                <Skeleton className="h-10 w-full mt-4 bg-zinc-800 " />
              ) : (
                <div className="col-span-2 text-center bg-black text-white font-bold py-2 mt-4 rounded-lg ">{content}</div>
              )
            ) : loading ? (
              <Skeleton className="h-10 w-full mt-4 bg-zinc-800 " />
            ) : (
              <FormLis comp={comp} loading={loading} />
            )}
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default CardTab;
