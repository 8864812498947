"use client";

import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar } from "recharts";
import { ChartConfig, ChartContainer, ChartLegend, ChartLegendContent } from "../../../components/ui/charts";
import { Separator } from "../../../components/ui/separator";

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label, config }: any) => {
  if (active && payload && payload.length && config) {
    return (
      <div className="bg-white border border-gray-200 shadow-lg p-2 rounded-md flex flex-col">
        <p className="font-bold text-gray-700 mb-1">{label}</p>
        {payload.map((entry: any, index: number, array: any) => {
          const chartItem = config[entry.dataKey]; // Safe access
          const first_sep = index !== 0;
          const last_sep = index + 1 !== array.length;
          return chartItem ? (
            <div key={index} className="flex items-center text-sm">
              {chartItem.isTotal ? (
                <div className="flex flex-col gap-1 w-full">
                  {first_sep && <Separator className="ml-auto mt-1" />}
                  <div className="flex items-center justify-start gap-3">
                    <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: entry.color }}></div>
                    <p className="text-gray-600 pb-[4px]">
                      {chartItem.label}: <span className="font-medium">{entry.value.toLocaleString()}</span>
                    </p>
                  </div>
                  {last_sep && <Separator className="ml-auto " />}
                </div>
              ) : (
                <p className="text-gray-600">
                  {chartItem.label}: <span className="font-medium">{entry.value.toLocaleString()}</span>
                </p>
              )}
            </div>
          ) : null;
        })}
      </div>
    );
  }
  return null;
};

// Reusable Chart Component
interface PopulationChartProps {
  data: Array<any>;
  chartConfig: {
    [key: string]: { label: string; color: string; isTotal?: boolean };
  };
  title: string;
  height?: string;
}

const PopulationChart: React.FC<PopulationChartProps> = ({ data, chartConfig, title, height = "h-[95%]" }) => {
  return (
    <div className={`w-full ${height === "h-[95%]" ? "h-80" : ""}`}>
      <div className="text-center w-full mb-2 font-semibold">{title}</div>
      <ChartContainer config={chartConfig} className={`w-full ${height}`}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <XAxis dataKey="year" tickLine={false} axisLine={false} fontSize={12} stroke="#888888" />
            <YAxis
              domain={["auto", "auto"]}
              tickLine={false}
              axisLine={false}
              fontSize={12}
              stroke="#888888"
              tickFormatter={(value) => `${new Intl.NumberFormat("en-US").format(Math.round(value))}`}
              orientation="right" // Y-axis on the right-hand side
            />
            <Tooltip content={<CustomTooltip config={chartConfig} />} />
            <ChartLegend content={<ChartLegendContent />} />

            {Object.keys(chartConfig).map((key) => {
              const configItem = chartConfig[key];
              if (configItem) {
                return <Bar key={key} type="monotone" dataKey={key} name={configItem.label} fill={configItem.color} strokeWidth={2} radius={[4, 4, 0, 0]} />;
              }
              return null; // Fallback in case chartConfig[key] is undefined
            })}
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>
    </div>
  );
};

export default PopulationChart;
