import { Link, useLocation } from "react-router-dom";
import { AppSidebar } from "../../components/components/app-sidebar";
import DrawerUploaderDemo from "../../components/components/dialog-uploader";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "../../components/ui/resizable";
import DemoPage from "./components/Select";
import React from "react";
import { useTranslation } from "react-i18next";
export default function SelectPage() {
  const { t } = useTranslation();
  const location = useLocation();

  const parentScreen = location.state?.previous_screen;
  const parentScreenName = parentScreen
    ? parentScreen
        .split("/")
        .filter(Boolean)
        .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
    : "Othman Figure this out";
  console.log(location.state);
  console.log(parentScreen);
  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem className="hidden md:block">
                <Link to={parentScreen}>
                  <BreadcrumbLink>{parentScreenName}</BreadcrumbLink>
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" />
              <BreadcrumbItem>
                <BreadcrumbPage>{t("selector.select_pages")}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <div className="grid auto-rows-min gap-4 md:grid-cols-1">
          <DemoPage />
        </div>
      </div>
    </SidebarInset>
  );
}
