import React, { useEffect, useState } from "react";
import { z } from "zod";

import { getColumns } from "./columns";
import { DataTable } from "./DataTable";
import Spinner from "../../../components/ui/Spinner";
import { backendClient } from "../../../api/backend";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export type Task = {
  id: string;
  title: string;
  status: string;
  label: string;
  priority: string;
  valuation: string;
  answer: string;
  formattedval: string;
};

export default function TaskPage() {
  const transaction = useSelector((state: any) => state.transactions?.transaction);
  const { t } = useTranslation();

  const [tasks, setTasks] = useState<Task[]>([]); // Manage tasks state
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state
  const [sel, setSel] = useState(0);
  const [val, setVal] = useState(null);
  console.log("val", val);
  // Declare async function inside useEffect
  const fetchTasks = async () => {
    try {
      const audit = await backendClient.fetchAudit(transaction.id);
      console.log(audit);
      const list = audit.map((check) => {
        return {
          ...check,
          title: check.question
            .split(" ")
            .map((e) => {
              // Try parsing the element as a number
              const num = parseFloat(e);

              // Check if it's a valid number and format it
              if (!isNaN(num) && isFinite(num) && e.length !== 4) {
                return num.toLocaleString();
              }

              // Return the original string if not a number
              return e;
            })
            .join(" "),
          label: check.item,
          valuation: String(check.valuation).replace("$", "").toLocaleString(),
          formattedval: check.valuation < 0 ? `(${Math.abs(Math.floor(check.valuation)).toLocaleString()})` : Math.floor(check.valuation).toLocaleString(),
          answer: check.answer ? check.answer : "",
          // priority: check.type,
        };
      });
      // const data = await getTasks();
      setTasks(list); // Set tasks to state
    } catch (err) {
      // @ts-ignore
      setError(err instanceof Error ? err.message : t("taskPage.error", { message: "An unknown error occurred" }));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTasks(); // Call async function
  }, [transaction]);
  const handleAction = async (rowData: Task, actionType: string) => {
    console.log(`Action "${actionType}" triggered for`, rowData);
    if (!transaction) return;

    await fetchTasks();
    // Add your logic for handling actions
  };
  const columns = getColumns(t,handleAction);

  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <div className="text-red-500 text-center">
          {
            // @ts-ignore
            t("taskPage.error", { message: error })
          }
        </div>
        ;
      </div>
    );
  }

  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">
              {" "}
              {
                // @ts-ignore
                sel > 0 ? t("taskPage.purchase_price_change", { value: Number(val).toLocaleString() }) : t("taskPage.financial_audit")
              }
            </h2>
            <p className="text-muted-foreground">
              {" "}
              {
                // @ts-ignore
                sel ? t("taskPage.negotiation_based", { count: sel, value: Number(val).toLocaleString() }) : t("taskPage.audit_description")
              }
            </p>
          </div>
          <div className="flex items-center space-x-2">{/* <UserNav /> */}</div>
        </div>
        <DataTable data={tasks} columns={columns} setVal={setVal} setSel={setSel} />
      </div>
    </>
  );
}
