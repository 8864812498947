import { useEffect, useState, useRef } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import * as Slider from "@radix-ui/react-slider";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Info } from "lucide-react";
import { useTranslation } from "react-i18next";

const CapPricingSchema = z.object({
  investmentYears: z.number().min(1, "Investment years must be at least 1."),
  lifeOfInvestment: z.number().min(1, "Investment years must be at least 1."),
  capitalInvestment: z.number().min(1000, "Capital Investment must be at least 1,000."),
  totalInvestment: z.number().min(1000, "Total investment must be at least 1,000."),

  revenueRampUp: z.number().min(1, "Revenue ramp-up must be greater than 1."),
  costRampUp: z.number().min(1, "Cost ramp-up must be greater than 1."),
  workingCapital: z.number().min(0).max(1, "Working Capital must be between 0 and 1."),
  taxRate: z.number().min(0).max(100, "Tax rate must be between 0 and 100."),
  revenue: z.number().min(0, "Revenue cannot be negative."),
  margin: z.number().min(0).max(1, "Margin must be between 0 and 1."),
});

interface CapPricingProps {
  investmentYears: number;
  setInvestmentYears: (value: number) => void;
  lifeOfInvestment: number;
  setLifeOfInvestment: (value: number) => void;
  capitalInvestment: number;
  setCapitalInvestment: (value: number) => void;
  revenueRampUp: number;
  setRevenueRampUp: (value: number) => void;
  revenue: number;
  setRevenue: (value: number) => void;
  margin: number;
  setMargin: (value: number) => void;
  costRampUp: number;
  setCostRampUp: (value: number) => void;
  workingCapital: number;
  setWorkingCapital: (value: number) => void;
  taxRate: number;
  setTaxRate: (value: number) => void;
  cashflow: any[];
  unleveredIRR: number;
  payback: number;
  calculateCashflows: () => void;
}

interface RowItemProps {
  label: string;
  value: string;
  tooltipContent?: string;
  isHighlighted?: boolean; // For rows like Total Cash Received, Invested, etc.
  isNegative?: boolean; // For rows like Loan Balance or Debt
  highlightColor?: string; // Optional custom highlight color
  textColor?: string; // Optional custom highlight color
}

const RowItem: React.FC<RowItemProps> = ({
  label,
  value,
  tooltipContent,
  isHighlighted = false,
  isNegative = false,
  highlightColor = "bg-gray-100", // Default highlight color
  textColor = "text-black", // Default highlight color
}) => {
  return (
    <div className={`flex justify-between items-center px-2 h-8 ${isHighlighted ? `${highlightColor} rounded-md` : ""}`}>
      {/* Row Label with Tooltip */}
      <div className={`flex gap-2 items-center text-sm  ${isHighlighted ? `${textColor} font-semibold` : "text-gray-500 font-medium"}`}>
        <span className="truncate">{label}</span>
        {tooltipContent && <TooltipIcon content={tooltipContent} />}
      </div>

      {/* Row Value */}
      <p className={`text-sm font-semibold ${isNegative ? "text-red-500" : isHighlighted ? textColor : "text-black"}`}>{value}</p>
    </div>
  );
};
export function TooltipIcon({ content }: { content: string }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Info className="h-4 w-4 text-gray-500 hover:text-black" />
          </span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p className="color-white">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default function CapPricing({
  investmentYears,
  setInvestmentYears,
  lifeOfInvestment,
  setLifeOfInvestment,
  capitalInvestment,
  setCapitalInvestment,
  revenueRampUp,
  setRevenueRampUp,
  costRampUp,
  setCostRampUp,
  workingCapital,
  setWorkingCapital,
  taxRate,
  setTaxRate,
  cashflow,
  unleveredIRR,
  payback,
  calculateCashflows,
  revenue,
  setRevenue,
  margin,
  setMargin,
}: CapPricingProps) {
  const [totalInvestment, setTotalInvestment] = useState(capitalInvestment * investmentYears);
  const [trackWidth, setTrackWidth] = useState(0); // Track width state
  const lowerBound = 0;
  const upperBound = 10000000;
  const form = useForm({
    resolver: zodResolver(CapPricingSchema),
    defaultValues: { investmentYears, capitalInvestment, revenueRampUp, costRampUp, workingCapital, taxRate, revenue, margin, totalInvestment, lifeOfInvestment },
  });
  const { t } = useTranslation();

  const onSubmit = () => {
    calculateCashflows();
  };

  useEffect(() => {
    // Update annual investment when total investment or investment years change
    setCapitalInvestment(totalInvestment / investmentYears);
  }, [totalInvestment, investmentYears]);
  const handleSliderChange = (value) => {
    setTotalInvestment(value[0]);
    form.setValue("totalInvestment", value[0], { shouldValidate: true });
  };
  const trackRef = useRef<HTMLDivElement>(null); // Typed ref for Slider.Track
  useEffect(() => {
    if (trackRef.current) {
      const trackElement = trackRef.current;
      setTrackWidth(trackElement.offsetWidth);
    }
  }, []);
  console.log(cashflow);
  return (
    <Card className="p-6 max-w-full">
      <CardHeader>
        {/* <CardTitle>Capital Pricing Calculator</CardTitle> */}
        <CardTitle>
          {
            // @ts-ignore
            t("capPricing.title")
          }
        </CardTitle>

        <CardDescription>
          <div className="flex">
            <div className="w-[70%]">
              {
                // @ts-ignore
                t("capPricing.description")
              }
            </div>
            <div className="ml-auto">
              {/* <Button
                onClick={() => {
                  // handleNextStep();
                }}
              >
                Save scenario
              </Button> */}
            </div>
          </div>
        </CardDescription>
      </CardHeader>

      <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card className="p-4 pb-2 bg-muted text-muted-foreground flex flex-col space-y-4 justify-center gap-2 px-2">
          <CardContent className="flex flex-col items-center justify-center">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                {/* Investment Years */}
                <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-1 gap-y-4">
                  {/* Revenue */}
                  <FormField
                    control={form.control}
                    name="revenue"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex gap-2 ">
                          {
                            // @ts-ignore
                            t("capPricing.annualRevenue")
                          }
                          <TooltipIcon
                            content={
                              // @ts-ignore
                              t("capPricing.annualRevenueTooltip")
                            }
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            inputMode="numeric"
                            value={field.value !== undefined ? `$${field.value.toLocaleString()}` : ""}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                              if (/^\d*$/.test(rawValue)) {
                                const numericValue = Number(rawValue);
                                field.onChange(numericValue); // Update form value
                                setRevenue(numericValue); // Sync local state
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Cost Ramp-Up */}
                  <FormField
                    control={form.control}
                    name="margin"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex gap-2 ">
                          {
                            // @ts-ignore
                            t("capPricing.annualMargin")
                          }
                          <TooltipIcon
                            content={
                              // @ts-ignore
                              t("capPricing.annualMarginTooltip")
                            }
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            inputMode="numeric"
                            value={field.value !== undefined ? `${field.value.toLocaleString()}` : ""}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                              if (/^\d*$/.test(rawValue)) {
                                const numericValue = Number(rawValue);
                                field.onChange(numericValue); // Update form value
                                setMargin(numericValue); // Sync local state
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Tax Rate */}
                  <FormField
                    control={form.control}
                    name="taxRate"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex gap-2 ">
                          {
                            // @ts-ignore
                            t("capPricing.taxRate")
                          }
                          <TooltipIcon
                            content={
                              // @ts-ignore
                              t("capPricing.taxRateTooltip")
                            }
                          />
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            inputMode="numeric"
                            value={field.value !== undefined ? `${field.value.toLocaleString()}` : ""}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                              if (/^\d*$/.test(rawValue)) {
                                const numericValue = Number(rawValue);
                                field.onChange(numericValue); // Update form value
                                setTaxRate(numericValue); // Sync local state
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {/* Investment Slider */}

                <Card className="p-4 bg-muted text-muted-foreground flex flex-col space-y-4 justify-center gap-2 w-full col-span-2 xl:col-span-3 flex-grow">
                  <CardContent className="flex flex-col items-center justify-center gap-3 w-full">
                    <div className="flex justify-between min-w-24 border-muted bg-gray-100 border-[1px] p-1 text-md font-bold">
                      <FormField
                        control={form.control}
                        name="totalInvestment"
                        render={({ field, fieldState }) => (
                          <FormItem>
                            <FormLabel className="flex gap-2 ">
                              {
                                // @ts-ignore
                                t("capPricing.totalInvestment")
                              }
                              <TooltipIcon
                                content={
                                  // @ts-ignore
                                  t("capPricing.totalInvestmentTooltip")
                                }
                              />
                            </FormLabel>
                            <FormControl>
                              <Input
                                type="text"
                                inputMode="numeric"
                                value={field.value !== undefined ? `$${field.value.toLocaleString()}` : ""}
                                onChange={(e) => {
                                  const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                                  if (/^\d*$/.test(rawValue)) {
                                    const numericValue = Number(rawValue);
                                    field.onChange(numericValue); // Update form value
                                    setTotalInvestment(numericValue); // Sync local state
                                  }
                                }}
                              />
                            </FormControl>
                            <FormMessage>{fieldState.error?.message}</FormMessage> {/* Show error dynamically */}
                          </FormItem>
                        )}
                      />

                      {/* <span className="mr-4">Capital Allocation</span>
                      <span>${totalInvestment.toLocaleString()}</span> */}
                    </div>
                    <div className="flex gap-2 items-center w-full space-y-2">
                      {/* Lower Bound */}
                      <div className="mt-1 text-xs text-gray-600">${lowerBound.toLocaleString()}</div>
                      <Slider.Root defaultValue={[totalInvestment]} min={0} max={10000000} step={10000} onValueChange={handleSliderChange} className="relative flex items-center h-6 w-full">
                        <Slider.Track ref={trackRef} className="relative bg-gray-200 h-2 w-full rounded-full">
                          <Slider.Range className="absolute bg-black h-2 rounded-full" style={{ width: `${trackWidth}px` }} />
                        </Slider.Track>
                        <Slider.Thumb className="SliderThumb" asChild>
                          <div className="absolute flex items-center justify-center w-4 h-4 bg-white border-2 border-black rounded-full -translate-x-[50%] -translate-y-[50%]" />
                        </Slider.Thumb>
                      </Slider.Root>
                      <div className="text-xs text-gray-600">${upperBound.toLocaleString()}</div>
                    </div>
                  </CardContent>
                </Card>
              </form>
            </Form>
          </CardContent>
        </Card>

        {/* Results */}
        <Card className="p-4 text-muted-foreground flex flex-col ustify-center gap-2">
          <CardHeader className="p-0">
            <CardTitle>
              {
                // @ts-ignore
                t("capPricing.resultsTitle")
              }
            </CardTitle>
            <CardDescription>
              {
                // @ts-ignore
                t("capPricing.resultsDescription")
              }
            </CardDescription>
          </CardHeader>
          <CardContent className="p-2 flex flex-col mt-2 flex-grow  justify-between">
            <RowItem
              label={
                // @ts-ignore
                t("capPricing.paybackPeriod")
              }
              value={`${payback.toFixed(2)} years`}
              tooltipContent={
                // @ts-ignore
                t("capPricing.paybackPeriodTooltip")
              }
            />
            <RowItem
              label={
                // @ts-ignore
                t("capPricing.totalInvestment")
              }
              value={`${totalInvestment.toLocaleString()}`}
              tooltipContent={
                // @ts-ignore
                t("capPricing.totalInvestmentTooltip")
              }
            />
            <RowItem
              label={
                // @ts-ignore
                t("capPricing.operationalMargin")
              }
              value={`${margin.toFixed(2)}%`}
              tooltipContent={
                // @ts-ignore
                t("capPricing.operationalMarginTooltip")
              }
            />
            <RowItem
              label={
                // @ts-ignore
                t("capPricing.yearlyRunRateCashflow")
              }
              value={`${Number(cashflow[cashflow.length - 2]?.freeCashFlow).toLocaleString()}`}
              tooltipContent={
                // @ts-ignore
                t("capPricing.yearlyRunRateCashflowTooltip")
              }
            />
            <RowItem
              label={
                // @ts-ignore
                t("capPricing.ROI")
              }
              value={`${unleveredIRR.toFixed(2)}%`}
              tooltipContent={
                // @ts-ignore
                t("capPricing.ROITooltip")
              }
              isHighlighted
            />
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
