import { AlertCircle } from "lucide-react";

import { Alert, AlertTitle, AlertDescription, AlertAction, AlertClose } from "../../../components/ui/alert";
import { Button } from "../../../components/ui/button";
import { X } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
export default function AlertDestructive({ setCustomDebt, buttonText, title, description, variant = 1, gotocash = false }) {
  const [dismiss, setDismiss] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handlecash = () => {
    if (gotocash) {
      navigate("/cashflow");
    }
  };
  return dismiss ? (
    <Alert
      variant={variant ? "destructive" : "default"}
      actions={
        <>
          <AlertAction
            onClick={() => {
              setCustomDebt(0);
              handlecash();
            }}
          >
            {
              // @ts-ignore
              buttonText || t("alert.set_maximum_debt")
            }
          </AlertAction>
        </>
      }
    >
      {variant > 0 && <AlertCircle className="h-4 w-4" />}
      <div className="flex flex-col justify-center -mb-1">
        <AlertTitle>
          {
            // @ts-ignore
            t(title || "alert.too_much_debt_title")
          }
        </AlertTitle>
        <AlertDescription className="inline">
          {
            // @ts-ignore
            t(description || "alert.too_much_debt_description")
          }
        </AlertDescription>
      </div>
    </Alert>
  ) : null;
}
