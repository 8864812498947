import React, { useEffect, useState } from "react";
import { z } from "zod";

import { getColumns } from "./columns";
import { DataTable } from "./DataTable";
import { toast } from "sonner";

import { taskSchema } from "../data/schema";
import Spinner from "../../../components/ui/Spinner";
import EmptyPipeline from "./nodeal";
import { DropdownMenuaction } from "./buttonaction";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../../redux/store";
import { useTranslation } from "react-i18next";

type Task = {
  id: string;
  name: string;
  askPrice: number;
  estPrice: string;
  industry: string;
  country: string;
  pipelineStatus: string;
  city: string;
  seller: string;
};

const addEstimatedPrice = (deals) => {
  return deals.map((deal) => {
    // Calculate possible prices based on earnings and revenue
    const earningsPrice = deal.earningsMultiple * deal.cashFlow;
    const revenuePrice = deal.revenueMultiple * deal.revenue;

    // Calculate min and max values
    const minPrice = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0)); // Round down
    const maxPrice = Math.floor(Math.max(earningsPrice, revenuePrice)); // Round down
    const c = deal.city ? deal.city : "new york";
    // Add estPrice field
    return {
      ...deal,
      estPrice: `${minPrice.toLocaleString()} - ${maxPrice.toLocaleString()}`,
      city: c,
    };
  });
};

export default function TaskPage() {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const state = store.getState();
  const transactions = useSelector((state: any) => state.transactions?.transactions);
  const [tasks, setTasks] = useState<Task[]>([]); // Manage tasks state
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state
  const [sel, setSel] = useState(0);
  const [val, setVal] = useState(null);
  console.log(state);
  const { t } = useTranslation();
  const columns = getColumns(t);
  // console.log("val", val);
  useEffect(() => {
    // Update tasks whenever `transactions` changes
    if (transactions) {
      setTasks(addEstimatedPrice(transactions));
    }
  }, [transactions]);
  useEffect(() => {
    // Declare async function inside useEffect
    const fetchTasks = async () => {
      try {
        const deals = await dispatch(fetchTransactions());
        // console.log(deals);
        // const data = await getTasks();
        setTasks(addEstimatedPrice(deals)); // Set tasks to state
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTasks(); // Call async function
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
        <div className="flex items-center justify-between space-y-2">
          <div className="w-[70%]">
            <h2 className="text-2xl font-bold tracking-tight">
              {
                // @ts-ignore
                t("pipeline.title")
              }
            </h2>
            <p className="text-muted-foreground">
              {
                // @ts-ignore
                t("pipeline.description")
              }
            </p>
          </div>
          {tasks.length !== 0 && (
            <div className="flex justify-end w-full ">
              <DropdownMenuaction />
            </div>
          )}
        </div>
        {tasks.length === 0 ? (
          <EmptyPipeline />
        ) : (
          <>
            <DataTable data={tasks} columns={columns} setVal={setVal} setSel={setSel} />
          </>
        )}
      </div>
    </>
  );
}
