import { useTranslation } from "react-i18next";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";

export function CardItem({ title, icon, value, description }) {
  return (
    <Card className="transition-colors hover:bg-muted hover:cursor-pointer">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="h-4 w-4 text-muted-foreground" dangerouslySetInnerHTML={{ __html: icon }} />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{value}</div>
        <p className="text-xs text-muted-foreground">{description}</p>
      </CardContent>
    </Card>
  );
}

export default function Cards({ revenue, costs, ebitda, earnings, revenueGrowth, costsGrowth, ebitdaGrowth, earningsGrowth }) {
  // Helper function to format values with fallback
  const formatValue = (value, prefix = "$") => (value !== null && value !== undefined ? `${prefix}${Number(value.toFixed(0)).toLocaleString()}` : "n.a.");
  const { t } = useTranslation();
  const formatGrowth = (growth) =>
    growth !== null && growth !== undefined
      ? `${growth.toFixed(0)}% ${
          // @ts-ignore
          t("cards.fromLastYear")
        }`
      : "n.a.";
  const cardData = [
    {
      // @ts-ignore
      title: t("cards.revenue"),
      icon: '<path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />',
      value: formatValue(revenue),
      description: formatGrowth(revenueGrowth),
    },
    {
      // @ts-ignore
      title: t("cards.costs"),
      icon: `
      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
      <circle cx="9" cy="7" r="4" />
      <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
      `,
      value: formatValue(costs),
      description: formatGrowth(costsGrowth),
    },
    {
      // @ts-ignore
      title: t("cards.ebitda"),
      icon: `
      <rect width="20" height="14" x="2" y="5" rx="2" />
      <path d="M2 10h20" />
      `,
      value: formatValue(ebitda),
      description: formatGrowth(ebitdaGrowth),
    },
    {
      // @ts-ignore
      title: t("cards.earnings"),
      icon: '<path d="M22 12h-4l-3 9L9 3l-3 9H2" />',
      value: formatValue(earnings),
      description: formatGrowth(earningsGrowth),
    },
  ];

  return (
    <>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        {cardData.map((card, index) => (
          <CardItem key={index} title={card.title} icon={card.icon} value={card.value} description={card.description} />
        ))}
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7"></div>
    </>
  );
}
