import React, { FC, useState, useRef } from "react";
import { CellWrapper, useCellContext } from "@silevis/reactgrid";

export interface ValueCellProps {
  value: number;
  onValueChanged: (newValue: number) => void;
  allowSeparators?: boolean;
  style?: React.CSSProperties;
}

// Number formatting function (German-style formatting)
const formatNumber = (num: number): string => {
  const numberFormat = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let formattedNumber = numberFormat.format(Math.abs(num));

  return num < 0 ? `(${formattedNumber})` : formattedNumber;
};

const ValueCell: FC<ValueCellProps> = ({ value, onValueChanged, allowSeparators = true, style }) => {
  const ctx = useCellContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempValue, setTempValue] = useState(value.toString()); // Store raw input value
  const inputRef = useRef<HTMLInputElement>(null);
  const [isCleared, setIsCleared] = useState(false); // Track if the cell was cleared

  const numberSeparators = [".", ","];

  const handleStringValueRequested = () => tempValue;

  const handleStringValueReceived = (newText: string) => {
    const numValue = newText === "" ? NaN : Number(newText);
    onValueChanged(numValue);
  };

  return (
    <CellWrapper
      onStringValueRequested={handleStringValueRequested}
      onStringValueReceived={handleStringValueReceived}
      onDoubleClick={() => {
        setTempValue(value.toString()); // Store initial numeric value
        setIsEditMode(true);
        setIsCleared(false);
        setTimeout(() => inputRef.current?.focus()); // Ensure focus on input
      }}
      onKeyDown={(e) => {
        if (e.key === "Delete") {
          setIsEditMode(true);
          setIsCleared(true);
          setTempValue("0"); // Replace number with 0 when Delete is pressed
          setTimeout(() => inputRef.current?.focus()); // Ensure focus
        } else if (e.key === "F2") {
          setTempValue(value.toString()); // Store initial numeric value
          setIsEditMode(true);
          setIsCleared(false);
          setTimeout(() => inputRef.current?.focus()); // Ensure focus on input}
        } else if (!isEditMode && (e.key.match(/[0-9]/) || e.key === "-")) {
          setTempValue(e.key); // Clear cell and show only typed key
          setIsEditMode(true);
          setIsCleared(true);
          setTimeout(() => inputRef.current?.focus()); // Ensure focus
        } else if (!isEditMode && e.key === "Enter") {
          e.stopPropagation();
          setTempValue(value.toString()); // Preserve value when F2 is pressed
          setIsEditMode(true);
          setIsCleared(false);
          setTimeout(() => inputRef.current?.focus()); // Ensure focus
        }
      }}
      style={style}
    >
      {isEditMode ? (
        <input
          className="rg-input"
          value={tempValue}
          ref={inputRef}
          onChange={(e) => {
            let newValue = e.currentTarget.value.replace(allowSeparators ? /[^0-9,.-]/g : /[^0-9-]/g, "");

            // If cell was cleared, just use the new input
            if (isCleared) {
              setTempValue(newValue);
            } else {
              // Ensure negative sign is only at the beginning
              if (newValue.includes("-") && newValue.indexOf("-") > 0) {
                newValue = newValue.replace("-", ""); // Remove incorrect placements
              }
              setTempValue(newValue);
            }
          }}
          onBlur={() => {
            const finalValue = parseFloat(tempValue.replace(/,/g, ".")); // Convert to number
            if (!isNaN(finalValue)) {
              onValueChanged(finalValue);
            }
            setIsEditMode(false);
            setIsCleared(false);
          }}
          onPointerDown={(e) => e.stopPropagation()} // Allow text selection
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setIsEditMode(false); // Cancel edit mode
              setTempValue(value.toString()); // Restore original input value
              setIsCleared(false);
            } else if (e.key === "Enter") {
              setIsEditMode(false);
              setTempValue(value.toString()); // Keep original value
              setIsCleared(false);
            } else if (e.key === "ArrowUp") {
              e.stopPropagation();
              inputRef.current?.setSelectionRange(0, 0); // Move cursor to the beginning
            } else if (e.key === "ArrowDown") {
              e.stopPropagation();
              const length = tempValue.length;
              inputRef.current?.setSelectionRange(length, length); // Move cursor to the end
            } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
              e.stopPropagation(); // Allow default cursor navigation
            }
          }}
        />
      ) : (
        formatNumber(value) // Display formatted value when not in edit mode
      )}
    </CellWrapper>
  );
};

export default ValueCell;
