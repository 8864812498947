"use client";
import { cn } from "../../lib/utils";
import * as React from "react";

import { Folder, MoreHorizontal, Share, Trash2, ChevronsUpDown, Check, type LucideIcon } from "lucide-react";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { SidebarGroup, SidebarGroupLabel, SidebarMenu, SidebarMenuAction, SidebarMenuButton, SidebarMenuItem, useSidebar } from "../ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "../../components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { Button } from "../../components/ui/button";

export function NavProjects({
  projects,
  name,
  transaction,
  // isSelected,
}: {
  projects: {
    name: string;
    url: string;
    icon: LucideIcon;
    isActive?: boolean;
  }[];
  name: string;
  transaction: any;
  // isSelected?: string;
}) {
  const { isMobile } = useSidebar();
  const location = useLocation();

  const isSelected = (path) => location.pathname === path || location.pathname.split("/")[1] === path.split("/")[1];
  const [select, setSelect] = React.useState("Select Deal");
  const [deals, setdeals] = React.useState(["Select Deal"]);
  return (
    <SidebarGroup className="group-data-[collapsible=icon]:hidden">
      <SidebarGroupLabel>{name}</SidebarGroupLabel>

      <SidebarMenu>
        <SidebarMenuItem>
          {projects.map((item) => (
            <SidebarMenuItem key={item.name}>
              <SidebarMenuButton className={cn(isSelected(item.url) && "bg-sidebar-accent text-sidebar-accent-foreground ")} asChild>
                <Link
                  to={item.isActive ? item.url : "#"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: item.isActive ? "inherit" : "gray",
                    pointerEvents: item.isActive ? "auto" : "none",
                    opacity: item.isActive ? 1 : 0.5,
                    cursor: item.isActive ? "pointer" : "not-allowed",
                  }}
                >
                  <item.icon />
                  <span>{item.name}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarGroup>
  );
}
