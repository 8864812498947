import React, { useRef, useState, useEffect } from "react";

import { Box, CssBaseline } from "@mui/material";

// Update these paths to the correct locations of your images

import Hero1 from "./components/Hero1";
import Navbar1 from "./components/Navbar1";
import Testimonial7 from "./components/Testimonials";
import Feature102 from "./components/Features";
import Footer7 from "./components/Footer2";
import { TabsDemo } from "./components/Toggle";
import CTA12 from "./components/Waitlist";
import { ScrollArea } from "../../components/ui/scroll";
import AnalyzeBusiness from "./components/GetStarted";

const Buyers = () => {
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);
  const sectionFourRef = useRef(null);
  const [isScrollAreaEnabled, setScrollAreaEnabled] = useState(false);

  const handleResize = () => {
    // Check if the screen width matches the "laptop" size
    const isLaptop = window.matchMedia("(min-width: 768px)").matches;
    setScrollAreaEnabled(isLaptop);
  };

  useEffect(() => {
    // Run on component mount
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const scrollToSectionOne = () => {
    sectionOneRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSectionTwo = () => {
    sectionTwoRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSectionThree = () => {
    sectionThreeRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSectionFour = () => {
    sectionFourRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Box
        id="scrollable-box"
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isScrollAreaEnabled ? (
          <ScrollArea className="h-full w-full rounded-md border z-12">
            <Navbar1 highlight={"buyers"} />
            <Hero1 ref={sectionOneRef} onSectionTwoClick={scrollToSectionTwo} />

            <AnalyzeBusiness />
            <CTA12 ref={sectionTwoRef} />
            <Feature102 ref={sectionThreeRef} sellers={true} buyers={true} />
            <Testimonial7 ref={sectionFourRef} />
            <Footer7 onSectionOneClick={scrollToSectionOne} onSectionTwoClick={scrollToSectionTwo} onSectionThreeClick={scrollToSectionThree} onSectionFourClick={scrollToSectionFour} />
          </ScrollArea>
        ) : (
          <>
            <Navbar1 />
            <Hero1 ref={sectionOneRef} />
            <AnalyzeBusiness />
            {/* <CTA12 ref={sectionTwoRef} /> */}
            <Feature102 ref={sectionThreeRef} sellers={false} buyers={true} />
            <Testimonial7 ref={sectionFourRef} />
            <Footer7 onSectionOneClick={scrollToSectionOne} onSectionTwoClick={scrollToSectionTwo} onSectionThreeClick={scrollToSectionThree} onSectionFourClick={scrollToSectionFour} />
          </>
        )}
      </Box>
    </>
  );
};

export default Buyers;
