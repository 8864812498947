import React from "react";
import { useState } from "react";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { useToast } from "../../../hooks/use-toast";
import { useDispatch } from "react-redux";
import { addToNewsletter } from "../../../redux/actions/authAction";
import { windowWidth } from "../../../utils/utils";
import { CircularProgress } from "@material-ui/core";

const Spinner = ({ size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-4 w-4 animate-spin">
    <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
  </svg>
);
const CTA12 = React.forwardRef((props, ref) => {
  const { toast } = useToast();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    setIsLoading(true);
    if (isValidEmail(email)) {
      // Here you would typically make an API request to your backend to handle the email sending
      // Example: axios.post('/api/send-email', { email, date: new Date() })
      const message = await dispatch(addToNewsletter(email));
      console.log("message", message);

      // Assuming the email was sent successfully
      toast({
        title: message.message,
        description: "We will contact you within the next few days.",
      });

      setIsLoading(false);
    } else {
      alert("Please enter a valid email.");
      setIsLoading(false);
    }
    setEmail("");
  };

  return (
    <section ref={ref} className="py-8 px-6">
      <div className="w-full">
        <div className="flex flex-col items-center text-center">
          <h3 className="mb-3 max-w-3xl text-2xl font-semibold md:mb-4 md:text-4xl lg:mb-6">Be Part of Our Exclusive Beta</h3>
          <p className="mb-8 max-w-3xl text-muted-foreground lg:text-lg">Join our waitlist and access the product for free during the beta phase. Don’t miss this opportunity—sign up today!</p>
          <div className="w-full md:max-w-lg">
            <div className="flex flex-col justify-center gap-2 sm:flex-row">
              <Input placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <Button
                disabled={isLoading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {isLoading ? <Spinner size={18} /> : "Join the Waitlist"}
              </Button>
            </div>
            <p className="mt-2 text-left text-xs text-muted-foreground">
              View our{" "}
              <a href="#" className="underline hover:text-foreground">
                privacy policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default CTA12;
