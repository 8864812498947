import React, { useEffect, useState } from "react";
import { z } from "zod";

import { getColumns } from "./columns";
import { DataTable } from "./DataTable";
import { UserNav } from "./user-nav";
import { taskSchema } from "../data/schema";
import Spinner from "../../../components/ui/Spinner";
import ButtonAction from "./ButtonUpload";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { backendClient } from "../../../api/backend";
import { useTranslation } from "react-i18next";

type Task = {
  id: string;
  title: string;
  status: string;
  label: string;
  priority: string;
  answer: string;
};

// Define the schema for an array of tasks

export default function TaskPage() {
  const transaction = useSelector((state: any) => state.transactions?.transaction);

  const [tasks, setTasks] = useState<Task[]>([]); // Manage tasks state
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state
  const [sel, setSel] = useState(0);
  const [val, setVal] = useState(null);
  console.log("val", val);
  const { t } = useTranslation();

  const fetchTasks = async () => {
    try {
      const checklist = await backendClient.fetchChecklist(transaction.id);
      console.log(checklist);
      const list = checklist.map((check) => {
        return {
          ...check,
          title: check.question,
          label: check.type,
          priority: check.type,
        };
      });
      // const data = await getTasks();
      setTasks(list); // Set tasks to state
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Declare async function inside useEffect
    if (!transaction) return;

    fetchTasks(); // Call async function
  }, [transaction]);

  const handleAction = async (rowData: Task, actionType: string) => {
    console.log(`Action "${actionType}" triggered for`, rowData);
    if (!transaction) return;

    await fetchTasks();
    // Add your logic for handling actions
  };
  const columns = getColumns(t, handleAction);

  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
        <div className="flex items-center justify-between space-y-2">
          <div className="w-full">
            <h2 className="text-2xl font-bold tracking-tight">
              {
                // @ts-ignore
                t("checklist.title")
              }
            </h2>
            <p className="text-muted-foreground">
              {
                // @ts-ignore
                t("checklist.description")
              }
            </p>
          </div>
          <div className="flex items-center space-x-2">{/* <UserNav /> */}</div>
          <div className="flex justify-end w-full ">
            <ButtonAction />
          </div>
        </div>
        <DataTable data={tasks} columns={columns} setVal={setVal} setSel={setSel} />
      </div>
    </>
  );
}
