import { AppSidebar } from "../../components/components/app-sidebar";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import Cards from "./components/cards";
import ProgressPrice from "./ProgressPrice";
import CardTab from "./components/tab_b_s";
import MapCard from "./components/map";
import CapitalStructure from "./components/capitalstructure";
import YearCashflow from "./components/yearcashflow";
import Cashflow, { CashCard } from "../CashflowScreen/components/Cashflow";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { extractFinancialItem } from "../../utils/utils";
import { useState, useRef, useEffect } from "react";
import IRR from "../CapexPlanScreen/_irrResult";
import Payback from "../CapexPlanScreen/_payback";
import React from "react";
import Spinner from "../../components/ui/Spinner";
import { useTranslation } from "react-i18next";
import { backendClient } from "../../api/backend";
function calculateFinancialRatios(transaction, fcf, debt, purchasePrice, new_debt) {
  let covenants = false;
  let dscr = [];
  let ltv = [];
  let minDSCR, minLTV;

  if (fcf.length > 0 && transaction.uploadStatus === "completed") {
    covenants = true;

    dscr = fcf.map((cf, i) => {
      if (debt.schedule && i < debt.schedule.length) {
        return cf["Free Cash Flow"] / debt.schedule[i].debtService;
      } else {
        return "N/A";
      }
    });

    ltv = [new_debt / purchasePrice];

    // Filter out 'N/A' values and find the minimum DSCR and LTV
    const validDSCR = dscr.filter((value) => value !== "N/A");
    const validLTV = ltv.filter((value) => value !== "N/A");

    minDSCR = validDSCR.length > 0 ? Math.min(...validDSCR) : "N/A";
    minLTV = validLTV.length > 0 ? Math.min(...validLTV) : "N/A";
    if (minDSCR === "N/A") covenants = false;
  } else {
    dscr = "N/A";
    ltv = "N/A";
    minDSCR = "N/A";
    minLTV = "N/A";
    covenants = false;
  }

  return {
    covenants,
    dscr,
    ltv,
    minDSCR,
    minLTV,
  };
}
const calculateUnleveredIRR = (cashFlows) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Create arrays for values and dates
  const values = cashFlows.map((cf) => Number(cf.freeCashFlow));
  const dates = cashFlows.map((cf) => (cf.year - cashFlows[0].year) * 365);
  // Step 3: Call the IRR function
  // console.log(values);
  return IRR(values, 0.1) * 100;
};
const calculatePaybackPeriod = (cashFlows, investment) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Extract free cash flows into an array
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));
  const pb = Payback(cashFlowValues, 0);
  return pb;
};

// Calculate the cash on cash return similar to payback above :
// Cash on Cash Return = (Total Cash Flow / Total Investment)
// Total Cash Flow = Sum of all cash flows
// Total Investment = Initial Investment
const calculateCashOnCashReturn = (cashFlows) => {
  // Step 1: Sort the cash flows by year
  cashFlows.sort((a, b) => a.year - b.year);

  // Step 2: Extract free cash flows into an array
  const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));

  // Step 3: Calculate the total cash flow if they are positive
  const totalCashFlow = cashFlowValues.reduce((acc, value) => (value > 0 ? acc + value : acc), 0);

  // Step 4: Calculate the investment cashflow (sum of negative cash flows)

  const investmentCashFlow = cashFlowValues.reduce((acc, value) => (value < 0 ? acc + value : acc), 0);

  // Step 5: Calculate the cash on cash return

  return totalCashFlow / Math.abs(investmentCashFlow);
};

function mapCashFlow(data, investment, exitValue) {
  // Extract years and sort to determine the range
  const years = data.map((item) => item.year).sort((a, b) => a - b);
  const firstYear = years[0];
  const lastYear = years[years.length - 1];

  // Prepare the transformed list
  const mappedCashFlows = [];

  // Add the investment year (1 year before first year)
  mappedCashFlows.push({
    year: firstYear - 1,
    freeCashFlow: investment,
    isForecasted: false, // Investment typically not forecasted
  });

  // Map existing data
  data.forEach((item) => {
    mappedCashFlows.push({
      year: item.year,
      freeCashFlow: item["Free Cash Flow"] ?? item.freeCashFlow,
      isForecasted: item.isForecasted,
    });
  });

  // Add the exit year (1 year after last year)
  mappedCashFlows.push({
    year: lastYear + 1,
    freeCashFlow: exitValue,
    isForecasted: false, // Exit typically not forecasted
  });

  return mappedCashFlows;
}

function getLastYearValue(data) {
  if (data.length === 0) {
    throw new Error("The data array is empty.");
  }

  // Sort the data by year in ascending order
  const sortedData = data.sort((a, b) => a.year - b.year);

  // Get the last year object (largest year)
  const lastYearObject = sortedData[sortedData.length - 1];

  return lastYearObject["Revenue"];
}
function getLastYearDebt(data) {
  if (data.length === 0) {
    throw new Error("The data array is empty.");
  }

  // Sort the data by year in ascending order
  const sortedData = data.sort((a, b) => a.year - b.year);

  // Get the last year object (largest year)
  const lastYearObject = sortedData[sortedData.length - 1];

  return lastYearObject["Debt Ending Balance"];
}
const extractLast = (cashflow, itemname) => {
  if (cashflow.length === 0) return null;
  const item = extractFinancialItem(cashflow, itemname, null, false);
  const last = item[item.length - 1];
  return last;
};
export default function Overview() {
  const { t } = useTranslation();
  const transaction = useSelector((state) => state.transactions.transaction);
  const transactions = useSelector((state) => state.transactions.transactions);
  const tr = transactions.filter((e) => {
    return e.id === transaction.id;
  })[0];
  const cashflow = useSelector((state) => state.cashflow.tableData.cashFlow);
  const pricing = useSelector((state) => state.cashflow.purchasePrice) || {};
  const d = useSelector((state) => state.cashflow) || {};
  // console.log(d);
  const minideal = useSelector((state) => state.cashflow.miniDeal);
  const [multiple, setmultiple] = useState(pricing.multiple || transaction.revenueMultiple); // Default purchase price
  const debt = useSelector((state) => state.cashflow.debt);
  // if (!transaction || !cashflow || !minideal || debt) return
  const hasMinidealEntryValue = minideal.entryValue && minideal.projections;
  const status = transaction.uploadStatus === "completed" && cashflow;

  // console.log(status);

  let fcf;
  if (cashflow || hasMinidealEntryValue) {
    // console.log(cashflow, minideal.entryValue);

    fcf = status
      ? extractFinancialItem(cashflow, "Free Cash Flow", null, true)
      : hasMinidealEntryValue // ✅ Ensure `minideal.entryValue` exists before accessing `.projections`
        ? minideal.projections.map((e) => ({
            year: e.year,
            freeCashFlow: Number(e.cashFlow),
          }))
        : [];
  } else {
    fcf = [];
  }
  // console.log(fcf);
  // const hasMinidealEntryValue = minideal?.entryValue !== undefined && minideal?.entryValue !== null;
  const hasProjections = Array.isArray(minideal?.projections) && minideal.projections.length > 0;

  const rev_pro = status
    ? extractFinancialItem(cashflow, "Revenue", null, true)
    : hasProjections // ✅ Ensure projections exist before filtering/mapping
      ? minideal.projections
          .filter((e) => e.revenue) // ✅ Ensure revenue exists before accessing it
          .map((e) => ({
            year: e.year,
            Revenue: Number(e.revenue),
          }))
      : []; // ✅ Default to an empty array if projections are missing
  let new_debt = debt && debt?.maximumDebt ? (debt.customDebt !== 0 ? debt.customDebt : debt.maximumDebt) : 0 + status ? extractLast(cashflow, "Debt Starting Balance")["Debt Starting Balance"] : minideal.debt;
  console.log(minideal);
  // console.log(minideal);
  const revenue = status ? extractLast(cashflow, "Revenue")["Revenue"] : transaction.revenue;

  const ebitda = status ? extractLast(cashflow, "Adjusted EBITDA")["Adjusted EBITDA"] : transaction.ebitda;
  const old_debt = status ? extractLast(cashflow, "Debt Starting Balance")["Debt Starting Balance"] : 0;

  const earnings = status ? extractLast(cashflow, "Taxable Income")["Taxable Income"] + extractLast(cashflow, "Tax")["Tax"] : transaction.cashFlow;

  const earningsPrice = transaction.earningsMultiple * earnings || transaction.cashFlow;
  const revenuePrice = transaction.revenueMultiple * revenue || transaction.revenue;

  // Calculate min and max values
  const minPrice = Math.floor(Math.max(Math.min(earningsPrice, revenuePrice), 0)); // Round down and cap at 0
  const maxPrice = Math.floor(Math.max(earningsPrice, revenuePrice));
  const lowerBound = minPrice;
  const upperBound = maxPrice;

  // Calculate mean (mu) and standard deviation (sigma)
  const mu = (upperBound + lowerBound) / 2;
  // console.log(minideal);
  // console.log(maxPrice);
  const [purchasePrice, setPurchasePrice] = useState(pricing?.purchasePrice || minideal.entryValue || maxPrice);
  useEffect(() => {
    setPurchasePrice(pricing?.purchasePrice || minideal.entryValue || maxPrice);
  }, [transaction, minideal]);
  // Default purchase price

  const sigma = (upperBound - lowerBound) / 4; // Approximation for 1 standard deviation

  // Calculate 3 standard deviations of the mean and cap at zero
  const lowerLimit = Number(Math.max(mu - 3 * sigma, 0).toFixed(0));
  const upperLimit = Math.max(Number(transaction.askPrice || 0) * 1.5, Number((mu + 3 * sigma).toFixed(0)));
  const investmentCashFlowData = useSelector((state) => state.cashflow.investmentCashFlowData);
  // console.log(investmentCashFlowData);
  // const debtending = extractFinancialItem(cashflow, "Debt Ending Balance", null, true);

  const cashf = status ? mapCashFlow(fcf, -purchasePrice, getLastYearValue(rev_pro) * multiple) : mapCashFlow(fcf, -purchasePrice, 0);
  const irr = Math.round(calculateUnleveredIRR(cashf)); // Example
  const pb = calculatePaybackPeriod(cashf, 0).toFixed(2);

  const cashOnCashReturn = calculateCashOnCashReturn(cashf);
  const totalPotentialProfit = purchasePrice / ebitda;
  const equityMultiple = purchasePrice / revenue;
  console.log(transaction);
  const state = React.useMemo(
    () => ({
      acquisitionCost: String(transaction.askPrice),
      closingCost: "20000", // Ensure correct naming to match `State` interface
    }),
    [transaction.askPrice], // Only re-calculate when `transaction.askPrice` changes
  );

  let { covenants, dscr, ltv, minDSCR, minLTV } = calculateFinancialRatios(transaction, fcf, debt, purchasePrice, new_debt);
  // if (!transaction || !cashflow || minideal) return <Spinner />;
  const [place, setPlace] = useState([]);
  const getSimilarplace = async () => {
    const places = await backendClient.getsimilarbusiness(transaction.id);
    // console.log(places);
    setPlace(places);
  };
  useEffect(() => {
    if (!transaction) return;
    getSimilarplace();
  }, [transaction]);
  // console.log("old_debt", old_debt, "irr", irr, "purchasePrice", purchasePrice, "pb", pb, "new_debt", new_debt, "covenants", covenants, "dscr");

  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {/* <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {
                    // @ts-ignore
                    t("overview.overview")
                  }
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>

      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <Cards transaction={tr ? tr : transaction} earnings={earnings} />
        <div className="grid auto-rows-min gap-4 grid-cols-2">
          <ProgressPrice
            lowerLimit={lowerLimit}
            upperLimit={upperLimit}
            newDebt={new_debt}
            irr={irr}
            cashonCashReturn={cashOnCashReturn}
            totalPotentialProfit={totalPotentialProfit}
            equityMultiple={equityMultiple}
            multiple={multiple}
            setPurchasePrice={setPurchasePrice}
            purchasePrice={purchasePrice}
            transaction={transaction}
            pricing={pricing}
            minideal={minideal}
            pb={pb}
            old_debt={old_debt}
          />
          {/* <CardTab irr={irr} pb={pb} purchasePrice={purchasePrice} old_debt={old_debt} /> */}
          <MapCard transaction={transaction} place={place} />
          {fcf.length > 0 && (
            <>
              <YearCashflow old_debt={old_debt} new_debt={new_debt} purchasePrice={purchasePrice} payback={pb} closingCost={Number(state.closingCost)} initialInvestment={0} />
              <CapitalStructure transaction={transaction} purchasePrice={purchasePrice} newDebt={new_debt} covenants={covenants} dscr={dscr} ltv={ltv} minDSCR={minDSCR} minLTV={minLTV} />
            </>
          )}
          {investmentCashFlowData && investmentCashFlowData.length > 0 && <CashCard investmentCashFlowData={investmentCashFlowData} />}
        </div>
        <div className="min-h-[100vh] flex-1 rounded-xl bg-muted/50 md:min-h-min" />
      </div>
    </SidebarInset>
  );
}
