import { useTranslation } from "react-i18next";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card";

export function CardItem({ title, icon, value, description }) {
  return (
    <Card className="transition-colors hover:bg-muted hover:cursor-pointer">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="h-4 w-4 text-muted-foreground" dangerouslySetInnerHTML={{ __html: icon }} />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{value}</div>
        <p className="text-xs text-muted-foreground">{description}</p>
      </CardContent>
    </Card>
  );
}

export default function Cards({ transaction, earnings }) {
  const { t } = useTranslation();
  console.log(transaction);
  const cardData = [
    {
      // @ts-ignore
      title: t("overview.cards.industry.title"),
      icon: `
        <rect width="20" height="14" x="2" y="5" rx="2" />
        <path d="M2 10h20" />
      `,
      value: `${transaction.industry[0] + transaction.industry.slice(1).toLowerCase().replaceAll("_", " ")}`,
      description: `${transaction.category[0] + transaction.category.slice(1).toLowerCase().replaceAll("_", " ")}`,
    },
    {
      // @ts-ignore
      title: t("overview.cards.location.title"),
      icon: '<path d="M22 12h-4l-3 9L9 3l-3 9H2" />',
      value: `${transaction.city}`,
      // @ts-ignore
      description: t("overview.cards.location.description", { country: transaction.country }),
    },
    {
      // @ts-ignore
      title: t("overview.cards.earnings.title"),
      icon: '<path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />',
      value: `${Math.floor(Number(earnings || transaction.cashFlow)).toLocaleString()}`,
      // @ts-ignore
      description: t("overview.cards.earnings.description"),
    },
    {
      // @ts-ignore
      title: t("overview.cards.multiple.title"),
      icon: `
        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
        <circle cx="9" cy="7" r="4" />
        <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
      `,
      value: `${transaction.earningsMultiple}x`,
      // @ts-ignore
      description: t("overview.cards.multiple.description"),
    },
  ];

  return (
    <>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        {cardData.map((card, index) => (
          <CardItem key={index} title={card.title} icon={card.icon} value={card.value} description={card.description} />
        ))}
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7"></div>
    </>
  );
}
