export const transformFinancialData = (inputData: any[], state: any, year: string, acquisitionCost, closingCost) => {
  console.log(state.tableData);
  const nonForecastedYears = state.tableData.cashFlow.filter((e) => e.isForecasted === false).map((e) => e.year);
  console.log(nonForecastedYears);
  // Get the last year of non-forecasted data
  const lastNonForecastYear = Math.max(...nonForecastedYears);
  console.log(lastNonForecastYear);
  // Function to filter values starting from lastNonForecastYear
  const filterYears = (valuesByYear) => Object.fromEntries(Object.entries(valuesByYear).filter(([key]) => Number(key) > lastNonForecastYear));
  const getYears = (valuesByYear) => Object.fromEntries(Object.entries(valuesByYear).filter(([key]) => Number(key) === lastNonForecastYear));

  // Get filtered financial data
  const fcfeItem = inputData.find((item) => item.id === "Free Cash Flow to Equity");
  const debtRepaymentItem = inputData.find((item) => item.id === "Debt Starting Balance");
  console.log(debtRepaymentItem);
  const filteredFcfeValuesByYear = filterYears(fcfeItem?.valuesByYear || {});
  const fcfeValues = Object.values(filteredFcfeValuesByYear).map(Number);

  const filteredDebtValuesByYear = filterYears(debtRepaymentItem?.valuesByYear || {});
  const filteredDebtValuesByYear_2 = getYears(debtRepaymentItem?.valuesByYear || {});
  console.log(filteredDebtValuesByYear_2);
  const debtStartBalance = Number(filteredDebtValuesByYear_2[lastNonForecastYear]) || 0;

  const loanBalance = debtStartBalance.toLocaleString();

  const investmentCashFlowValues = [Number(-acquisitionCost) + Number(debtStartBalance) - Number(closingCost), ...fcfeValues].map((val, index) => (index === 0 ? val : Number(val)));

  console.log(Number(-acquisitionCost), Number(debtStartBalance), Number(-closingCost));

  return {
    investmentCashFlowData: [
      { label: "Annual Project Cash Flow", values: ["", ...fcfeValues] },
      { label: "Acquisition Costs", values: [Number(-acquisitionCost).toLocaleString(), ...Array(fcfeValues.length).fill("")], isNegative: true },
      { label: "Closing Costs", values: [`-${Number(closingCost).toLocaleString()}`, ...Array(fcfeValues.length).fill("")], isNegative: true },
      { label: "Loan Balance", values: [`${loanBalance}`, ...Array(fcfeValues.length).fill("")], isNegative: true },
      { label: "Investment Cash Flow", values: investmentCashFlowValues, rowType: "large-total", isNegative: true },
    ],
    initialCashFlowData: [{ label: "Investment Cash Flow", values: investmentCashFlowValues, isNegative: true }],
  };
};

export const initCashFlow = (inputData: any[], debtStartBalance, acquisitionCost) => {
  const fcfeItem = inputData.filter((item) => !item.forecast);
  const investmentCashFlowValues = [Number(-acquisitionCost) + Number(debtStartBalance), ...fcfeItem.map((e) => Number(e.cashFlow))];
  console.log(investmentCashFlowValues);
  return {
    investmentCashFlowData: [{ label: "Investment Cash Flow", values: investmentCashFlowValues, rowType: "large-total", isNegative: true }],
    initialCashFlowData: [{ label: "Investment Cash Flow", values: investmentCashFlowValues, isNegative: true }],
  };
};

export const calculateMaximumDebt = (noi, amortization, dscr, interestRate) => {
  let totalDebt = 0;
  for (let year = 1; year <= amortization; year++) {
    const cfads = noi / dscr;
    const discountFactor = Math.pow(1 + interestRate / 100, year);
    totalDebt += cfads / discountFactor;
  }
  return Math.round(totalDebt);
};

export const calculatePaperValuation = (revenue: any, ebitda: any, earnings: any, earningsMultiple: any, revenueMultiple: any) => {
  console.log(revenue, ebitda, earnings, earningsMultiple, revenueMultiple);
  const taxRate: any = 0.3;
  const growthRate: any = 0;
  const discountRate: any = 0.3;
  const projectionYears: any = 4;
  const amortization = 4;
  const interestRate = 16;
  const dscr = 2;
  let projections: any = [];
  let currentYear: any = new Date().getFullYear();

  let npvValue = 0;
  // console.log(earningsMultiple, earnings, revenueMultiple, revenue);
  const initialEarningsPrice = earningsMultiple * earnings;
  const initialRevenuePrice = revenueMultiple * revenue;
  console.log(initialEarningsPrice, initialRevenuePrice);
  const entryValue = Math.floor(Math.max(initialEarningsPrice, initialRevenuePrice));
  const ebitdaRatio = ebitda / revenue;
  const earningsRatio = earnings / revenue;

  for (let i = 0; i <= projectionYears; i++) {
    if (i > 0) {
      revenue *= 1 + growthRate;
    }

    const projectedEBITDA = revenue * ebitdaRatio;
    const projectedEarnings = revenue * earningsRatio;
    const tax = (projectedEarnings / (1 - taxRate)) * taxRate;
    const cashFlow = Math.max(projectedEBITDA - tax, projectedEarnings);

    npvValue += cashFlow / Math.pow(1 + discountRate, i + 1);

    projections.push({
      year: currentYear + i,
      revenue: revenue.toFixed(2),
      ebitda: Math.max(projectedEBITDA, projectedEarnings),
      earnings: projectedEarnings.toFixed(2),

      tax: tax.toFixed(2),
      cashFlow: cashFlow.toFixed(2),
    });
  }

  const earningsPrice = earningsMultiple * projections[projectionYears].earnings;
  const revenuePrice = revenueMultiple * projections[projectionYears].revenue;
  const exitValue = Math.floor(Math.max(earningsPrice, revenuePrice));
  projections.push({
    year: currentYear + projectionYears + 1,
    cashFlow: exitValue,
    forecast: true,
  });
  npvValue += exitValue / Math.pow(1 + discountRate, projectionYears + 1);

  const debt = calculateMaximumDebt(Math.max(projections[0].ebitda - projections[0].tax, projections[0].earnings, 0), amortization, dscr, interestRate);

  return {
    projections,
    exitValue: exitValue.toFixed(2),
    npvValue: npvValue.toFixed(2),
    debt: debt,
    entryValue,
    dscr,
    amortization,
    interestRate,
  };
};
