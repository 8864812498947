import { TrendingUp } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import * as Slider from "@radix-ui/react-slider";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage, FormDescription } from "../../components/ui/form";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import { cn } from "../../lib/utils";
import { updateDebt, updatePurchasePrice } from "../../redux/actions/cashflowAction";
import IRR from "../CapexPlanScreen/_irrResult";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";

export function TooltipIcon({ content, cs = "" }: { content: string; cs?: string }) {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-pointer">
            <Info className={cn("h-4 w-4 text-gray-500 hover:text-black", cs)} />
          </span>
        </TooltipTrigger>
        <TooltipContent className="max-w-96 flex flex-wrap">
          <p className="color-white">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

interface StatItemProps {
  title: string;
  tooltipContent: string;
  value: string | number;
  suffix?: string; // Optional suffix like %, $, x
}

const StatItem: React.FC<StatItemProps> = ({ title, tooltipContent, value, suffix = "" }) => {
  return (
    <div className="flex flex-col text-left">
      <div className="flex items-center space-x-2">
        <CardDescription className="text-sm font-medium">{title}</CardDescription>
        <TooltipIcon content={tooltipContent} />
      </div>
      <div className="text-base sm:text-lg 2lg:text-xl font-bold">
        {value}
        {suffix}
      </div>
    </div>
  );
};

export default function ProgressPrice({ lowerLimit, upperLimit, newDebt, irr, cashonCashReturn, totalPotentialProfit, equityMultiple, multiple, setPurchasePrice, purchasePrice, transaction, pricing, minideal, pb }) {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();

  // State variables for dynamic values
  const lowerBound = lowerLimit;
  const upperBound = upperLimit;
  // const [lowerBound, setLowerBound] = useState(lowerLimit);
  // const [upperBound, setUpperBound] = useState(upperLimit);
  const [trackWidth, setTrackWidth] = useState(0); // Track width state

  // Calculated metrics based on purchase price

  const FormSchema = z.object({
    purchasePrice: z.number().refine((value) => value >= lowerBound, {
      message: `Value must be at least $${lowerBound.toLocaleString()}.`,
    }),
  });
  // Initialize the form
  const form = useForm({
    resolver: zodResolver(FormSchema),
    mode: "onChange", // Validate dynamically on change
    defaultValues: {
      purchasePrice: purchasePrice,
    },
  });
  useEffect(() => {
    form.reset({ purchasePrice: pricing?.purchasePrice || minideal.entryValue });
  }, [transaction, minideal]);
  // Handle slider change
  const handleSliderChange = (value) => {
    setPurchasePrice(value[0]);
    form.setValue("purchasePrice", value[0], { shouldValidate: true });
  };
  const trackRef = useRef<HTMLDivElement>(null); // Typed ref for Slider.Track

  // Calculate and update the width of the Slider.Range
  useEffect(() => {
    if (trackRef.current) {
      const trackElement = trackRef.current;
      setTrackWidth(trackElement.offsetWidth);
    }
  }, [transaction]);

  const onSubmit = (data) => {
    console.log("Valid Data:", data);
  };
  const { t } = useTranslation();
  return (
    <Card className="col-span-2 max-w-full">
      {/* Header */}
      <CardHeader>
        <CardTitle>Overview</CardTitle>
        {/* <CardDescription>
          We estimate the purchase price to be between ${lowerBound.toLocaleString()} and ${upperBound.toLocaleString()}{" "}
        </CardDescription> */}
      </CardHeader>

      <CardContent className="flex flex-col gap-4">
        <Card className="shadow-none border-none p-0">
          <CardContent className="flex flex-wrap justify-start items-start gap-4 p-0">
            <div className="flex-grow flex justify-between">
              <StatItem
                title={
                  // @ts-ignore
                  t("overview.stats.irr.title")
                }
                tooltipContent={
                  // @ts-ignore
                  t("overview.stats.irr.tooltip")
                }
                value={irr.toLocaleString()}
                suffix="%"
              />
              <StatItem
                title={
                  // @ts-ignore
                  t("overview.stats.payback.title")
                }
                tooltipContent={
                  // @ts-ignore
                  t("overview.stats.payback.tooltip")
                }
                value={`${pb.toLocaleString()} `}
                suffix="years"
              />
              <StatItem
                title={
                  // @ts-ignore
                  t("overview.stats.ask_price.title")
                }
                tooltipContent={
                  // @ts-ignore
                  t("overview.stats.ask_price.tooltip")
                }
                value={transaction.askPrice ? transaction.askPrice.toLocaleString() : "N/A"}
                suffix="$"
              />
              <StatItem
                title={
                  // @ts-ignore
                  t("overview.stats.equity_contribution.title")
                }
                tooltipContent={
                  // @ts-ignore
                  t("overview.stats.equity_contribution.tooltip")
                }
                value={`${Math.round(purchasePrice - newDebt).toLocaleString()}`}
                suffix="$"
              />
            </div>
            <div className="flex-grow flex justify-between">
              <StatItem
                title={
                  // @ts-ignore
                  t("overview.stats.debt_raised.title")
                }
                tooltipContent={
                  // @ts-ignore
                  t("overview.stats.debt_raised.tooltip")
                }
                value={`${Math.round(newDebt).toLocaleString()}`}
                suffix="$"
              />
              <StatItem
                title={
                  // @ts-ignore
                  t("overview.stats.entry_ebitda.title")
                }
                tooltipContent={
                  // @ts-ignore
                  t("overview.stats.entry_ebitda.tooltip")
                }
                value={`${totalPotentialProfit.toFixed(1)}`}
                suffix="x"
              />

              <StatItem
                title={
                  // @ts-ignore
                  t("overview.stats.entry_revenue.title")
                }
                tooltipContent={
                  // @ts-ignore
                  t("overview.stats.entry_revenue.tooltip")
                }
                value={equityMultiple.toFixed(1)}
                suffix="x"
              />
              <StatItem
                title={
                  // @ts-ignore
                  t("overview.stats.money_on_money_multiple.title")
                }
                tooltipContent={
                  // @ts-ignore
                  t("overview.stats.money_on_money_multiple.tooltip")
                }
                value={cashonCashReturn.toFixed(1)}
                suffix="x"
              />
            </div>
          </CardContent>
        </Card>
        <div className="flex flex-grow w-full gap-4">
          <Card className="p-4 bg-muted text-muted-foreground flex flex-col space-y-4 flex-grow">
            <CardContent>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
                  {/* Purchase Price Field */}
                  <FormField
                    control={form.control}
                    name="purchasePrice"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>
                          {
                            // @ts-ignore
                            t("overview.purchase_price.label")
                          }
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            inputMode="numeric"
                            value={field.value !== undefined ? `$${field.value.toLocaleString()}` : ""}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace("$", "").replace(/,/g, "");
                              if (/^\d*$/.test(rawValue)) {
                                const numericValue = Number(rawValue);
                                field.onChange(numericValue); // Update form value
                                setPurchasePrice(numericValue); // Sync local state
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage>{fieldState.error?.message}</FormMessage> {/* Show error dynamically */}
                      </FormItem>
                    )}
                  />
                </form>
              </Form>
              <div className="flex gap-2 items-center w-full space-y-2 mt-5">
                {/* Lower Bound */}
                <div className="mt-1 text-xs text-gray-600">${lowerBound.toLocaleString()}</div>

                {/* Slider */}
                <Slider.Root value={[purchasePrice]} min={lowerBound} max={upperBound} step={10000} onValueChange={handleSliderChange} className="relative mt-0 flex items-center w-full h-6">
                  {/* Track */}
                  <Slider.Track ref={trackRef} className="relative bg-gray-200 h-2 rounded-full w-full">
                    {/* Range */}
                    <Slider.Range className="absolute bg-black h-2 rounded-full" style={{ width: `${trackWidth}px` }} />
                  </Slider.Track>
                  {/* Thumb */}
                  <Slider.Thumb className="SliderThumb" asChild>
                    <div className="absolute flex items-center justify-center w-4 h-4 bg-white border-2 border-black rounded-full -translate-x-[50%] -translate-y-[50%]" />
                  </Slider.Thumb>
                </Slider.Root>

                {/* Upper Bound */}
                <div className="text-xs text-gray-600">${upperBound.toLocaleString()}</div>
              </div>
            </CardContent>
          </Card>

          <Card className={`p-2 bg-muted text-muted-foreground flex flex-col space-y-4 max-w-[25%] ${irr > 30 ? "bg-green-700" : "bg-red-700 "} `}>
            <CardContent className="p-2 text-strong  m-auto flex items-center justify-center text-white font-bold">
              {irr > 30
                ? // @ts-ignore
                  t("overview.value_proposition.push_deal_forward")
                : // @ts-ignore
                  t("overview.value_proposition.look_at_competitors")}
            </CardContent>
          </Card>
        </div>

        {/* Right Section: Metrics */}
      </CardContent>
    </Card>
  );
}
