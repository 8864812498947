import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { TooltipIcon } from "../ProgressPrice";
import React from "react";

interface RowItemProps {
  label: string;
  value: string;
  tooltipContent?: string;
  isHighlighted?: boolean; // For rows like Total Cash Received, Invested, etc.
  isNegative?: boolean; // For rows like Loan Balance or Debt
  highlightColor?: string; // Optional custom highlight color
  textColor?: string; // Optional custom highlight color
}

const RowItem: React.FC<RowItemProps> = ({
  label,
  value,
  tooltipContent,
  isHighlighted = false,
  isNegative = false,
  highlightColor = "bg-gray-100", // Default highlight color
  textColor = "text-black", // Default highlight color
}) => {
  return (
    <div className={`flex justify-between items-center px-2 h-8 ${isHighlighted ? `${highlightColor} rounded-md` : ""}`}>
      {/* Row Label with Tooltip */}
      <div className={`flex gap-2 items-center text-sm  ${isHighlighted ? `${textColor} font-semibold` : "text-gray-500 font-medium"}`}>
        <span className="truncate">{label}</span>
        {tooltipContent && <TooltipIcon content={tooltipContent} />}
      </div>

      {/* Row Value */}
      <p className={`text-sm font-semibold ${isNegative ? "text-red-500" : isHighlighted ? textColor : "text-black"}`}>{value}</p>
    </div>
  );
};

const YearCashflow = ({ old_debt, new_debt, purchasePrice, payback, closingCost, initialInvestment }) => {
  const sellerConsideration = purchasePrice - old_debt;
  const totalUses = sellerConsideration + old_debt + closingCost + initialInvestment;
  const equityConsideration = purchasePrice - new_debt + closingCost;
  const totalSources = new_debt + equityConsideration;
  const { t } = useTranslation();
  return (
    <Card className="col-span-1 flex flex-col w-full p-4 space-y-4 shadow-md gap-2">
      {/* Header */}
      <div className="flex items-center justify-between mb-2">
        <CardTitle>
          {
            // @ts-ignore
            t("overview.sources_uses.title")
          }
        </CardTitle>
      </div>

      {/* Profit Breakdown */}
      <CardContent className="p-2 space-y-1">
        {/* Rows */}
        <RowItem
          label={
            // @ts-ignore
            t("overview.sources_uses.seller_consideration")
          }
          value={`$${sellerConsideration.toLocaleString()}`}
          tooltipContent={
            // @ts-ignore
            t("overview.sources_uses.seller_consideration_tooltip")
          }
        />
        <RowItem
          label={
            // @ts-ignore
            t("overview.sources_uses.debt_outstanding")
          }
          value={`$${old_debt.toLocaleString()}`}
          tooltipContent={
            // @ts-ignore
            t("overview.sources_uses.debt_outstanding_tooltip")
          }
          isNegative
        />
        <RowItem
          label={
            // @ts-ignore
            t("overview.sources_uses.closing_cost")
          }
          value={`$${closingCost.toLocaleString()}`}
          tooltipContent={
            // @ts-ignore
            t("overview.sources_uses.closing_cost_tooltip")
          }
        />
        <RowItem
          label={
            // @ts-ignore
            t("overview.sources_uses.initial_investment")
          }
          value={`$${initialInvestment.toLocaleString()}`}
          tooltipContent={
            // @ts-ignore
            t("overview.sources_uses.initial_investment_tooltip")
          }
        />
        <RowItem
          label={
            // @ts-ignore
            t("overview.sources_uses.total_uses")
          }
          value={`$${totalUses.toLocaleString()}`}
          isHighlighted
        />
        <RowItem
          label={
            // @ts-ignore
            t("overview.sources_uses.debt_raised")
          }
          value={`$${new_debt.toLocaleString()}`}
          tooltipContent={
            // @ts-ignore
            t("overview.sources_uses.debt_raised_tooltip")
          }
          isNegative
        />
        <RowItem
          label={
            // @ts-ignore
            t("overview.sources_uses.equity_consideration")
          }
          value={`$${equityConsideration.toLocaleString()}`}
          tooltipContent={
            // @ts-ignore
            t("overview.sources_uses.equity_consideration_tooltip")
          }
        />
        <RowItem
          label={
            // @ts-ignore
            t("overview.sources_uses.total_sources")
          }
          value={`$${totalSources.toLocaleString()}`}
          isHighlighted
        />
        <div className="">
          <RowItem
            label={
              // @ts-ignore
              t("overview.sources_uses.payback_ratio")
            }
            value={`${payback} ${
              // @ts-ignore
              t("overview.sources_uses.years")
            }`}
            isHighlighted
            highlightColor="bg-black mt-2"
            textColor="text-white"
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default YearCashflow;
