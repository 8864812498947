import React, { useEffect, useState } from "react";

import { AppSidebar } from "../../components/components/app-sidebar";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import MapCard from "./components/map";
import DashboardPage from "./DashboardPage";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { backendClient } from "../../api/backend";
import Spinner from "../../components/ui/Spinner";
import { ScrollArea } from "../../components/ui/scroll";
import { useTranslation } from "react-i18next";

const sortObjectsAscending = (data, key) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid input: data should be a non-empty array");
    return [];
  }

  if (!key || typeof key !== "string") {
    console.error("Invalid input: key should be a valid string");
    return data;
  }

  return [...data].sort((a, b) => {
    // Ensure comparison works with numbers and strings
    const aValue = a[key];
    const bValue = b[key];

    if (typeof aValue === "string") {
      return aValue.localeCompare(bValue);
    } else if (typeof aValue === "number") {
      return aValue - bValue;
    } else {
      console.error(`Unsupported data type for sorting key: ${key}`);
      return 0;
    }
  });
};

export default function Market() {
  const { t } = useTranslation();

  // 1) Loading / Error State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // 2) Store the entire API response in state
  const [apiData, setApiData] = useState(null);

  // If you still need tasks from some checklist:
  const transaction = useSelector((state) => state.transactions?.transaction);

  // 3) Fetch data from your backend
  const fetchAllData = async () => {
    try {
      if (!transaction?.id) return;
      // Example: fetch the main municipality data
      const municipalityData = await backendClient.fetchMunicipalityData(transaction.id);
      console.log("API data:", municipalityData);
      setApiData(municipalityData);

      // If you still fetch tasks:
    } catch (err) {
      setError(err.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  };

  // 4) useEffect to initiate the fetch on mount
  useEffect(() => {
    if (!transaction) return;
    setLoading(true);
    setError(null);
    fetchAllData();
  }, [transaction]);

  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!apiData) {
    return <div>No Data Found</div>;
  }
  // 6) Transform the data for each chart
  // -----------------------------------
  // Conditional Data Transform
  // -----------------------------------
  let populationCharts = [];
  let incomeCharts = [];
  let ageGroupsChart = null;

  // Example: condition on transaction.country
  if (transaction?.country === "Germany") {
    // -----------------------------------
    // GERMANY-SPECIFIC CHARTS
    // -----------------------------------

    // 1) Historical Population
    const populationChartData = apiData.historicalMunicipalityPopulation
      ? sortObjectsAscending(
          apiData.historicalMunicipalityPopulation?.map((item) => ({
            year: parseInt(item.Jahr, 10),
            population: item.Population_Sum_1000 * 1000, // convert from 'per 1000' to absolute
          })),
          "year",
        )
      : [];

    populationCharts = [
      {
        id: "populationChart",
        title: `Historical Population (${transaction.municipality})`,
        data: populationChartData,
        chartConfig: {
          population: {
            label: "Population",
            color: "#3b82f6",
            isTotal: true,
          },
        },
      },
    ];

    // 2) Disposable Income
    const disposableIncomeData = apiData.historicalMunicipalityDisposableIncome
      ? sortObjectsAscending(
          apiData.historicalMunicipalityDisposableIncome?.map((item) => ({
            year: parseInt(item.Jahr, 10),
            incomePerCapita: item["Verfuegbares Einkommen je EW__EUR"],
          })),
          "year",
        )
      : [];

    incomeCharts = [
      {
        id: "incomeChart",
        title: `Historical Disposable Income (${transaction.municipality})`,
        data: disposableIncomeData,
        chartConfig: {
          incomePerCapita: {
            label: "Income per Capita (EUR)",
            color: "#10b981",
            isTotal: true,
          },
        },
      },
    ];

    // 3) Population by Age Group (Pie or distribution). The original code was:
    // we filter out items that have Personen__% = 100, then map them
    // For demonstration, let's keep it as in your snippet:
    ageGroupsChart = {
      id: "ageGroupsChart",
      title: "Population by Age Group (2022)",
      data:
        apiData.statePopulationByAgeGroups
          ?.filter((item) => item["Personen__%"] !== 100)
          ?.map((item, index) => ({
            ageGroup: item["Alter (11 Altersklassen)"],
            population: item["Personen__Anzahl"],
            fill: `hsl(var(--chart-${index + 1}))`,
          })) || [],
    };
    ageGroupsChart.chartConfig = ageGroupsChart.data.reduce((config, item, index) => {
      config[item.ageGroup] = {
        label: item.ageGroup,
        color: `hsl(var(--chart-${index + 1}))`,
      };
      return config;
    }, {});
  } else if (transaction?.country === "US") {
    // -----------------------------------
    // U.S.-SPECIFIC CHARTS
    // -----------------------------------

    // 1) Historical Population:
    // Already shaped as [{ year: 2019, population: 176 }, ...]
    // You can directly feed it into your line/bar chart:
    const populationChartData = apiData.historicalMunicipalityPopulation || [];
    populationCharts = [
      {
        id: "populationChart",
        title: `Historical Population (${transaction.municipality})`,
        data: populationChartData,
        chartConfig: {
          population: {
            label: "Population",
            color: "#3b82f6",
            isTotal: true,
          },
        },
      },
    ];

    // 2) Income Past 12 Months By Household Characteristics
    // We have multiple rows per year, each with a characteristic.
    // If you want a multi-series time chart (one line per characteristic),
    // you can pivot the data. Another option is a grouped bar chart.
    // For simplicity, let's pivot so that each characteristic is its own “series”:

    const rawIncomeData = apiData.incomePast12MonthsByHouseholdCharacteristics || [];

    // 2a) Get all unique years, all unique characteristics:
    const uniqueYears = [...new Set(rawIncomeData.map((d) => d.year))].sort();
    const uniqueChars = [...new Set(rawIncomeData.map((d) => d.characteristics))];

    // 2b) Build an array of { year, [characteristic1]: value, [characteristic2]: value, ...}
    // Initialize an object keyed by year:
    const groupedByYear = {};
    uniqueYears.forEach((yr) => {
      groupedByYear[yr] = { year: yr };
    });

    rawIncomeData.forEach((item) => {
      const { year, characteristics, value } = item;
      if (!groupedByYear[year]) {
        groupedByYear[year] = { year };
      }
      groupedByYear[year][characteristics] = value;
    });

    const incomeChartData = Object.values(groupedByYear);

    // 2c) Build a chartConfig that has an entry for each characteristic
    // (give each one a unique color, etc.):
    const charColors = [
      "#EF4444",
      "#10B981",
      "#6366F1",
      "#EAB308",
      "#EC4899",
      "#14B8A6",
      "#A855F7",
      "#F97316",
      // … add more or generate colors if needed
    ];
    const chartConfig = {};
    uniqueChars.forEach((charName, idx) => {
      chartConfig[charName] = {
        label: charName,
        color: charColors[idx % charColors.length],
      };
    });

    incomeCharts = [
      {
        id: "incomeByHouseholdCharacteristic",
        title: `Income by Household Characteristics (${transaction.municipality})`,
        data: incomeChartData,
        chartConfig,
      },
    ];

    // 3) State Population by Age Groups
    // The shape is multiple years. Often, we might just pick the “latest” year
    // for a distribution chart (pie, donut, or stacked bar).
    // For example, let's pick 2023 to mirror the German approach:
    const rawAgeGroupData = apiData.statePopulationByAgeGroups || [];
    const LATEST_YEAR = 2023;

    // Filter for the latest year
    const latestAgeGroups = rawAgeGroupData.filter((d) => d.year === LATEST_YEAR);
    // Build a data array for your “pie” or distribution:
    ageGroupsChart = {
      id: "ageGroupsChart",
      title: `Population by Age Group (${LATEST_YEAR})`,
      data: latestAgeGroups.map((item, index) => ({
        ageGroup: item.ageGroup,
        population: item.value,
        fill: `hsl(var(--chart-${index + 1}))`,
      })),
    };
    // Create the chartConfig with dynamic keys
    ageGroupsChart.chartConfig = ageGroupsChart.data.reduce((config, item, index) => {
      config[item.ageGroup] = {
        label: item.ageGroup,
        color: `hsl(var(--chart-${index + 1}))`,
      };
      return config;
    }, {});
  }

  // -----------------------------------
  // 7) Render your layout with new charts
  // -----------------------------------
  console.log(ageGroupsChart);
  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {/* <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>{t("market_analysis.market_analysis_breadcrumb")}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <div className="grid auto-rows-min gap-y-4 gap-x-4 md:grid-cols-2">
          <div className="col-span-2">
            <MapCard transaction={transaction} data={apiData} />
          </div>
          {/* 1) Historical Population */}
          {populationCharts.length > 0 && (
            <div className="col-span-2">
              <DashboardPage
                title={t("market_analysis.historical_population_title")}
                description={t("market_analysis.historical_population_description", {
                  city: transaction.municipality,
                })}
                combinedCharts={populationCharts}
                graphs={1}
              />
            </div>
          )}

          {/* 2) Disposable Income (DE) or Income By Household Characteristics (US) */}
          {incomeCharts.length > 0 && (
            <div className="col-span-2">
              <DashboardPage
                title={transaction.country === "Germany" ? t("market_analysis.disposable_income_title") : "Income by Household Characteristics"}
                description={transaction.country === "Germany" ? t("market_analysis.disposable_income_description", { city: transaction.municipality }) : "Grouped or multi-series representation by year and characteristic."}
                combinedCharts={incomeCharts}
                graphs={1}
              />
            </div>
          )}

          {/* 3) Population by Age Groups */}
          {ageGroupsChart && (
            <div className="col-span-2">
              <DashboardPage
                title={transaction.country === "Germany" ? t("market_analysis.population_by_age_groups_title") : `Population by Age Groups`}
                description={transaction.country === "Germany" ? t("market_analysis.population_by_age_groups_description") : "Distribution for the latest available year."}
                combinedCharts={[ageGroupsChart]}
                graphs={1}
                pie={false}
              />
            </div>
          )}
        </div>
      </div>
    </SidebarInset>
  );
}
