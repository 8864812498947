import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Logo from "../../assets/logo/logo.png"; // Assuming the logo is in the public/assets/logo directory
import MicrosoftLogo from "../../assets/signin/microsoft.png"; // Path to the Microsoft logo
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { firebaseSignIn, microsoftSignin } from "../../redux/actions/authAction";
import { colors } from "../../utils/colors"; // Assuming colors are usable here directly or adapted for MUI theming
import { isMobile, windowHeight, windowWidth } from "../../utils/utils";
import TermsModal from "./components/TermsModal";
import { DISCLAIMER_URL } from "../../utils/constants";
import ModalPdfView from "../../components/ModalPdfView";
import { FcGoogle } from "react-icons/fc";
import { FaApple, FaFacebook, FaMicrosoft } from "react-icons/fa";

import { Checkbox } from "../../components/ui/checkbox";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { toast } from "../../hooks/use-toast";
// Icon imports if you need them:
// import { Check, ChevronsUpDown } from "lucide-react";
// import { cn } from "../../../lib/utils";
import logoImage2 from "../../assets/landingpage/logo.svg"; // Make sure the path to your logo is correct
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Eye, EyeOff } from "lucide-react";

export const signInSchema = z.object({
  email: z.string().nonempty("Email is required").email("Invalid email format"),
  password: z.string().min(3, "Password must be at least 3 characters"),
});

const Spinner = ({ size = 24 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="animate-spin">
    <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
  </svg>
);
export function SignInForm({ onSignIn }) {
  // If you want to pass a callback `onSignIn` that does the firebaseSignIn logic,
  // or you can do the dispatch logic directly here.

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // 1) Local state

  // Create form instance
  const form = useForm({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  // console.log(form);

  // 3) Handle submit
  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      // Call whatever sign-in method you have, e.g. a Redux action or direct Firebase:
      if (onSignIn) {
        await onSignIn(values.email, values.password);
      }
    } catch (error) {
      console.error("Sign-in error:", error);
      form.setError("root.serverError", {
        type: "manual",
        message: "Incorrect Username and Password combination. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      {/* React Hook Form handles onSubmit */}
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        {/* Email Field */}
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              {/* <FormLabel>Email</FormLabel> */}
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  // type="email" is optional, but can help with keyboard on mobile
                  type="email"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Password Field */}
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative">
                  <Input
                    {...field}
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"} // 2) Toggle type
                  />
                  <button type="button" onClick={() => setShowPassword((prev) => !prev)} className="absolute inset-y-0 right-3 flex items-center text-gray-500" tabIndex={-1}>
                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                  </button>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            <Checkbox id="remember" className="border-muted-foreground" />
            <label htmlFor="remember" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Remember me
            </label>
          </div>
          <a href="#" className="text-sm font-medium text-primary">
            Forgot password
          </a>
        </div>
        {/* 4) Root-level error display (if any) */}
        {form.formState.errors.root?.serverError && <p className="text-red-600 text-sm">{form.formState.errors.root.serverError.message}</p>}
        {/* Submit Button */}
        <Button type="submit" className="mt-6 w-full" disabled={isLoading}>
          {isLoading ? (
            <div className="flex items-center justify-center gap-2">
              <Spinner size={18} />
              Signing in...
            </div>
          ) : (
            "Sign In"
          )}
        </Button>
      </form>
    </Form>
  );
}

const Login3 = ({ email, setEmail, password, setPassword, handleSignIn, handleGoogleSignIn, handleMicrosoftLogin }) => {
  return (
    <section className="py-16">
      <div className="">
        <div className="flex flex-col gap-4">
          <div className="mx-auto w-full max-w-sm rounded-md p-6 shadow">
            <div className="mb-6 flex flex-col items-center">
              <img src={logoImage2} alt="logo" className="mb-1 h-10 w-auto" />
              <p className="mb-2 text-2xl font-bold">Welcome back</p>
              <p className="text-muted-foreground">Please enter your details.</p>
            </div>
            <div>
              <div className="grid gap-4">
                <SignInForm onSignIn={handleSignIn} />
              </div>
              <div className="mx-auto mt-8 flex justify-center gap-1 text-sm text-muted-foreground">
                <p>Don&apos;t have an account?</p>
                <Link to="/sign-up" className="font-medium text-primary">
                  Sign up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SignInScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSignIn = async (email, password) => {
    // event.preventDefault();
    console.log("Running sign in ");
    try {
      // Dispatch firebaseSignIn from your Redux actions
      const resp = await dispatch(firebaseSignIn(email, password));
      if (resp) {
        toast({ title: "Signed in successfully", status: "success" });
        // On success, navigate somewhere, e.g. a "dashboard" route
        navigate("/pipeline");
      } else {
        toast({ title: "Account not approved yet", description: "Our team is reviewing your account creation and should get back to you soon" });
      }
    } catch (err) {
      toast({
        variant: "destructive",
        title: "Login failed",
        description: "Unable to sign in, please try again.",
        status: "error",
      });
      console.error("Error logging in with Email/Password:", err);
      // Optionally, show an error message to user
      throw err;
    }
  };

  const handleGoogleSignIn = async () => {
    // try {
    //   // dispatch your Redux action
    //   await dispatch(firebaseSignInWithGoogle());
    //   // On success, navigate
    //   navigate("data-import");
    // } catch (err) {
    //   console.error("Google sign-in error:", err);
    // }
  };

  const handleMicrosoftLogin = async () => {
    await dispatch(microsoftSignin(instance));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return <Login3 email={email} setEmail={setEmail} password={password} setPassword={setPassword} handleSignIn={handleSignIn} handleGoogleSignIn={handleGoogleSignIn} handleMicrosoftLogin={handleMicrosoftLogin} />;
};

export default SignInScreen;
