"use client";

import React from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell } from "recharts";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../../../components/ui/charts";

const generateData = () => {
  const years = ["2022", "2023", "2024", "2025", "2026", "2027"];
  return years.map((year) => {
    const isForecast = parseInt(year) > 2024;
    return {
      year,
      revenue: Math.floor(Math.random() * 50000) + 10000, // Revenue between $10,000 - $60,000
      ebitda: Math.floor(Math.random() * 15000) + 5000, // EBITDA between $5,000 - $20,000
      isForecast,
    };
  });
};

const data = generateData();

const chartConfig = {
  revenue: {
    label: "Revenue",
    color: "#8884d8", // Blue for Revenue
  },
  ebitda: {
    label: "EBITDA",
    color: "#82ca9d", // Green for EBITDA
  },
} satisfies ChartConfig;

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white shadow-lg rounded p-2 text-sm">
        <p className="font-bold">Year: {label}</p>
        {payload.map((entry: any, index: number) => (
          <div key={`tooltip-item-${index}`} className="flex items-center space-x-2">
            <div
              className="w-3 h-3 rounded-sm"
              style={{
                backgroundColor:
                  entry.dataKey === "revenue"
                    ? entry.payload.isForecast
                      ? "#8884d8"
                      : "#82ca9d" // Revenue color
                    : entry.payload.isForecast
                      ? "#ffc658"
                      : "#ff7300", // EBITDA color
              }}
            />
            <p>
              {entry.name}: <span className="font-medium">${new Intl.NumberFormat("en-US").format(Math.round(entry.value))}</span> ({entry.payload.isForecast ? "Forecast" : "Actual"})
            </p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export function Overview({ actual_rev, actual_ebitda }) {
  console.log(actual_ebitda);
  const data = actual_rev.map((e, i) => {
    return {
      year: e.year,
      revenue: e["Revenue"],
      ebitda: actual_ebitda[i]["Adjusted EBITDA"],
      isForecast: e.isForecasted,
    };
  });
  return (
    <ChartContainer config={chartConfig} className="w-full h-64">
      <ResponsiveContainer>
        <BarChart data={data}>
          <XAxis dataKey="year" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            domain={["auto", "auto"]} // Ensure auto-scaling for the Y-Axis
            tickFormatter={(value) => `$${new Intl.NumberFormat("en-US").format(Math.round(value))}`}
          />
          <Tooltip content={<CustomTooltip />} />
          {/* Revenue Bar */}
          <Bar dataKey="revenue" name="Revenue" radius={[4, 4, 0, 0]}>
            {data.map((entry, index) => (
              <Cell
                key={`revenue-${index}`}
                fill={entry.isForecast ? "#8884d8" : "#82ca9d"} // Dynamic color
              />
            ))}
          </Bar>
          {/* EBITDA Bar */}
          <Bar dataKey="ebitda" name="EBITDA" radius={[4, 4, 0, 0]}>
            {data.map((entry, index) => (
              <Cell
                key={`ebitda-${index}`}
                fill={entry.isForecast ? "#ffc658" : "#ff7300"} // Dynamic color
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
