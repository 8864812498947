import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "../../components/ui/breadcrumb";
import { Separator } from "../../components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../../components/ui/sidebar";
import DashboardPage from "./DashboardPage";
import Cards from "./components/cards";
import CapPricing from "./CapPricing";
import { useState, useEffect, useMemo } from "react";
import IRR from "./_irrResult";
import Payback from "./_payback";
import { useTranslation } from "react-i18next";
import { set } from "react-hook-form";
export default function CapexPlan() {
  // States for inputs
  const [investmentYears, setInvestmentYears] = useState(1);
  const [capitalInvestment, setCapitalInvestment] = useState(1500000); // Annual capex
  const [revenueRampUp, setRevenueRampUp] = useState(1); // Revenue multiplier
  const [costRampUp, setCostRampUp] = useState(1); // Cost multiplier
  const [currentRevenue, setCurrentRevenue] = useState(1000000); // Current revenue base
  const [currentCost, setCurrentCost] = useState(500000); // Current cost base
  const [depreciationYears, setDepreciationYears] = useState(10);
  const [workingCapital, setWorkingCapital] = useState(10); // WC % of revenue
  const [taxRate, setTaxRate] = useState(25); // Tax rate in %
  const [lifeOfInvestment, setLifeOfInvestment] = useState(100); // Years before unwinding WC
  const [rampUpYears, setRampUpYears] = useState(10); // Years for revenue/cost ramp-up

  // Outputs
  const [cashflow, setCashflow] = useState([]);
  const [unleveredIRR, setUnleveredIRR] = useState(0);
  const [payback, setpayback] = useState(0);
  const [yearsToMaxValue, setYearsToMaxValue] = useState(null);
  const [margin, setMargin] = useState(50);
  const [mom, setMom] = useState(0);
  const calculateDepreciation = (totalInvestment, years) => totalInvestment / years;

  const calculateCashflows = () => {
    const schedule = [];
    let totalCapex = 0;
    let accumulatedDepreciation = 0;
    let revenue = 0;
    let cost = 0;
    let workingCapitalAccumulated = 0;
    let maxValue = -Infinity;
    let maxYear = null;

    for (let year = 1; year <= Math.min(100, lifeOfInvestment); year++) {
      // Capital expenditure for investment years
      const capex = year <= investmentYears ? capitalInvestment : 0;
      totalCapex += capex;

      // Revenue and Cost (set to 0 during investment years, ramp up after)

      if (year > investmentYears && year <= investmentYears + rampUpYears) {
        revenue = currentRevenue * Math.pow(revenueRampUp, year - investmentYears - 1);
        cost = currentCost * Math.pow(costRampUp, year - investmentYears - 1);
        // console.log(year, revenue, "cond 1");
      } else if (year > investmentYears + rampUpYears) {
        revenue = currentRevenue * Math.pow(revenueRampUp, rampUpYears - 1);
        cost = currentCost * Math.pow(costRampUp, rampUpYears - 1);
        // console.log(year, revenue, "cond 2");
      }

      // Depreciation (start only after investment years and accrue for depreciationYears)
      let depreciation = 0;
      if (year > investmentYears && year <= investmentYears + depreciationYears) {
        depreciation = calculateDepreciation(capitalInvestment * investmentYears, depreciationYears);
        accumulatedDepreciation += depreciation;
      }

      // Working capital adjustment (reverse at the end of lifeOfInvestment)
      let wcRequirement = (revenue * workingCapital) / 100;
      let workingCapitalChange = 0;
      let negdep = 0;
      if (year < lifeOfInvestment) {
        workingCapitalChange = wcRequirement - workingCapitalAccumulated;
        workingCapitalAccumulated = wcRequirement;
      } else if (year === lifeOfInvestment) {
        workingCapitalChange = -workingCapitalAccumulated; // Unwind WC
        workingCapitalAccumulated = 0;
      }

      // Taxable income and cash flow
      const taxableIncome = Math.max(revenue - cost - depreciation, 0);
      if (revenue - cost - depreciation < 0) {
        negdep = revenue - cost - depreciation;
      }
      const tax = taxableIncome * (taxRate / 100);
      const nopat = taxableIncome - tax;
      const freeCashFlow = nopat - capex + (depreciation + negdep) - workingCapitalChange;

      // Track maximum value
      if (freeCashFlow > maxValue) {
        maxValue = freeCashFlow;
        maxYear = year;
      }

      schedule.push({
        year,
        revenue: revenue.toFixed(2),
        cost: cost.toFixed(2),
        depreciation: depreciation.toFixed(2),
        workingCapitalChange: workingCapitalChange.toFixed(2),
        taxableIncome: taxableIncome.toFixed(2),
        tax: tax.toFixed(2),
        nopat: nopat.toFixed(2),
        capex: capex.toFixed(2),
        freeCashFlow: freeCashFlow.toFixed(2),
      });
    }

    setYearsToMaxValue(maxYear);
    // console.log("schedule", schedule);
    return schedule;
  };

  const calculateUnleveredIRR = (cashFlows) => {
    // Step 1: Sort the cash flows by year
    cashFlows.sort((a, b) => a.year - b.year);

    // Step 2: Create arrays for values and dates
    const values = cashFlows.map((cf) => Number(cf.freeCashFlow));
    const dates = cashFlows.map((cf) => (cf.year - cashFlows[0].year) * 365);

    // Step 3: Call the IRR function
    // console.log(values, dates);
    return IRR(values, 0.1) * 100;
  };

  const calculatePaybackPeriod = (cashFlows, investment) => {
    // Step 1: Sort the cash flows by year
    cashFlows.sort((a, b) => a.year - b.year);

    // Step 2: Extract free cash flows into an array
    const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));
    // console.log(cashFlowValues);
    const pb = Payback(cashFlowValues, 0);
    // console.log(pb);
    return pb;
  };
  const calculateCashOnCashReturn = (cashFlows) => {
    // Step 1: Sort the cash flows by year
    cashFlows.sort((a, b) => a.year - b.year);

    // Step 2: Extract free cash flows into an array
    const cashFlowValues = cashFlows.map((cf) => Number(cf.freeCashFlow));

    // Step 3: Calculate the total cash flow if they are positive
    const totalCashFlow = cashFlowValues.reduce((acc, value) => (value > 0 ? acc + value : acc), 0);

    // Step 4: Calculate the investment cashflow (sum of negative cash flows)

    const investmentCashFlow = cashFlowValues.reduce((acc, value) => (value < 0 ? acc + value : acc), 0);

    // Step 5: Calculate the cash on cash return

    return totalCashFlow / Math.abs(investmentCashFlow);
  };
  useEffect(() => {
    const schedule = calculateCashflows();
    setCashflow(schedule);
    const irr = calculateUnleveredIRR(schedule);
    const pb = calculatePaybackPeriod(schedule);
    const mom = calculateCashOnCashReturn(schedule);
    setUnleveredIRR(irr);
    setpayback(pb);
    setMom(mom);
  }, [investmentYears, capitalInvestment, revenueRampUp, costRampUp, depreciationYears, workingCapital, taxRate, lifeOfInvestment, currentRevenue, currentCost, rampUpYears]);

  useEffect(() => {
    // console.log("margin", margin);
    setCurrentCost(currentRevenue * ((100 - margin) / 100));
  }, [margin]);
  // console.log(currentCost, currentRevenue);
  const max_years = 10;
  const { t } = useTranslation(); // i18n Hook for translations

  return (
    <SidebarInset>
      <header className="flex h-16 shrink-0 items-center gap-2">
        <div className="flex items-center gap-2 px-4">
          <SidebarTrigger className="-ml-1" />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              {/* <BreadcrumbItem className="hidden md:block">
                <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {
                    // @ts-ignore
                    t("capex.capital_expansion")
                  }
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        {/* <Cards /> */}
        <div className="grid auto-rows-min gap-y-4 gap-x-4 md:grid-cols-2">
          <div className="col-span-2">
            <CapPricing
              investmentYears={investmentYears}
              setInvestmentYears={setInvestmentYears}
              capitalInvestment={capitalInvestment}
              setCapitalInvestment={setCapitalInvestment}
              revenueRampUp={revenueRampUp}
              setRevenueRampUp={setRampUpYears}
              costRampUp={costRampUp}
              setCostRampUp={setCostRampUp}
              workingCapital={workingCapital}
              setWorkingCapital={setWorkingCapital}
              taxRate={taxRate}
              setTaxRate={setTaxRate}
              cashflow={cashflow}
              unleveredIRR={unleveredIRR}
              payback={payback}
              calculateCashflows={calculateCashflows}
              revenue={currentRevenue}
              setRevenue={setCurrentRevenue}
              margin={margin}
              setMargin={setMargin}
              lifeOfInvestment={lifeOfInvestment}
              setLifeOfInvestment={setLifeOfInvestment}
              cashonCashReturn={mom}
            />
          </div>

          {/* Two Dashboard Pages in a single row */}
          <div className="grid grid-cols-2 gap-4 col-span-2">
            <DashboardPage
              title={
                // @ts-ignore
                t("capex.forecasted_cash_flow")
              }
              overview={true}
              schedule={cashflow.slice(0, max_years)}
            />
            <DashboardPage
              title={
                // @ts-ignore
                t("capex.forecasted_cash_balance")
              }
              overview={false}
              schedule={cashflow.slice(0, max_years)}
            />
          </div>
        </div>
      </div>
    </SidebarInset>
  );
}
