import React, { useEffect, useState } from "react";
import { z } from "zod";

import { getColumns } from "./columns";
import { DataTable } from "./DataTable";
import { UserNav } from "./user-nav";
import { taskSchema } from "../data/schema";
import Spinner from "../../../components/ui/Spinner";
import AlertDestructive from "./alert";
import DrawerUploaderDemo from "../../../components/components/dialog-uploader";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions, setTransaction } from "../../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { clearDocuments, fetchDocuments } from "../../../redux/actions/documentsAction";
import { useTranslation } from "react-i18next";
import { fetchCashflowData, fetchDebt, fetchPurchasePrice, updatecashgraph, updateMinideal } from "../../../redux/actions/cashflowAction";
import { useNotifications } from "../../../router/NotificationsProvider";
import { and, collection, or, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { toast } from "sonner";

type Task = {
  id: string;
  title: string;
  status: string;
  label: string;
  // priority: string;
};

export default function TaskPage() {
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const transaction = useSelector((state: any) => state.transactions?.transaction);
  const documents = useSelector((state: any) => state.documents);
  const [tasks, setTasks] = useState<Task[]>([]); // Manage tasks state
  const [loading, setLoading] = useState(true); // Manage loading state
  const [error, setError] = useState<string | null>(null); // Manage error state
  // console.log(transaction, "transaction");
  useEffect(() => {
    const fn = async () => {
      await dispatch(clearDocuments());
    };
    fn();
  }, [transaction]);

  // console.log("documents", documents);
  const fetchTasks = async () => {
    try {
      // const data = await getTasks();
      // console.log(documents.documents.length);
      const doc = await dispatch(fetchDocuments(transaction.id));
      const mapping = doc.map((d) => {
        return {
          ...d,
          title:
            d.type
              .split("-") // Split the type by "-"
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
              .join(" ") + // Join words with a space
            " " +
            d.year, // Append the year,
          label: "documentation",
        };
      });
      // console.log("Hello documents", documents);
      setTasks(mapping); // Set tasks to state
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // Declare async function inside useEffect
    if (!transaction) return;

    fetchTasks(); // Call async function
  }, [transaction]);
  const handleAction = async (rowData: Task, actionType: string) => {
    console.log(`Action "${actionType}" triggered for`, rowData);
    if (!transaction) return;

    await fetchTasks();
    // Add your logic for handling actions
  };
  const { t } = useTranslation();
  const columns = getColumns(t, handleAction);
  console.log(columns);
  // const { t } = useTranslation();
  const fetchAndSetTransactionData = async (transactionData: any) => {
    // await dispatch(setTransaction(transactionData));
    await dispatch(fetchDebt(transactionData.id));
    await dispatch(fetchPurchasePrice(transactionData.id));
    await dispatch(fetchCashflowData(transactionData.id));
    await dispatch(updateMinideal(transactionData.revenue, transactionData.ebitda, transactionData.cashFlow, transactionData.earningsMultiple, transactionData.revenueMultiple, transactionData.uploadStatus));

    if (transactionData.uploadStatus !== "completed") {
      await dispatch(updatecashgraph());
    }
    await dispatch(setTransaction(transactionData));
  };
  React.useEffect(() => {
    if (!transaction && transaction.uploadStatus === "completed") {
      fetchAndSetTransactionData(transaction);
    }
  }, [dispatch, transaction]);

  const transactionId = transaction.id;
  const { addQueryListener, getListeners, observedData } = useNotifications();
  React.useEffect(() => {
    if (transactionId) {
      console.log(`📡 Subscribing to uploadStatus for ${transactionId}`);
      const dealsRef = collection(db, `/deals/${transaction.id}/income-statements`);
      //    addListener(`/deals/${transactionId}/${row.original.type}s`, row.original.id, "status");
      const q = query(dealsRef, and(where("status", "==", "uploaded")));

      addQueryListener({
        key: `incomeStatements_${transaction.organisationId}`,
        query: q,
        onSnapshotCallback: (docs) => {
          // Each doc is { id, uploadStatus, ... } because we map doc.id below
          // Step 1: Create a map of old tasks by ID for quick lookup
          const taskMap = new Map(tasks.map((task) => [task.id, task.status]));

          // Step 2: Check if all documents match their previous status
          const allMatch = docs.every((doc) => taskMap.get(doc.id) === doc.status);

          // Step 3: Only update state if at least one status has changed
          if (!allMatch) {
            console.log("⚠️ Some statuses have changed, updating state...");
            fetchTasks(); // ✅ Update state only when necessary
          } else {
            console.log("✅ No status change detected, skipping state update.");
          }
        },
        toastConfig: (docs, snapshot) => {
          // We can do a similar check and show a toast
          const taskMap = new Map(tasks.map((task) => [task.id, task.status]));
          const changedDocs = docs.filter((doc: any) => taskMap.get(doc.id) !== doc.status);

          changedDocs.forEach((doc: any) => {
            // @ts-ignore
            toast(t("toasts.income_statement_ready.title"), {
              // @ts-ignore
              description: t("toasts.income_statement_ready.description"),
            });
          });

          return null;
        },
      });
    }
  }, [transactionId, addQueryListener, tasks, fetchTasks]);
  React.useEffect(() => {
    if (transactionId) {
      console.log(`📡 Subscribing to uploadStatus for ${transactionId}`);
      const dealsRef = collection(db, `/deals/${transaction.id}/balance-sheets`);
      //    addListener(`/deals/${transactionId}/${row.original.type}s`, row.original.id, "status");
      const q = query(dealsRef, and(where("status", "==", "uploaded")));

      addQueryListener({
        key: `balanceSheet_${transaction.organisationId}`,
        query: q,
        onSnapshotCallback: (docs) => {
          // Each doc is { id, uploadStatus, ... } because we map doc.id below
          // Step 1: Create a map of old tasks by ID for quick lookup
          const taskMap = new Map(tasks.map((task) => [task.id, task.status]));

          // Step 2: Check if all documents match their previous status
          const allMatch = docs.every((doc) => taskMap.get(doc.id) === doc.status);

          // Step 3: Only update state if at least one status has changed
          if (!allMatch) {
            console.log("⚠️ Some statuses have changed, updating state...");
            fetchTasks(); // ✅ Update state only when necessary
          } else {
            console.log("✅ No status change detected, skipping state update.");
          }
        },
        toastConfig: (docs) => {
          // We can do a similar check and show a toast
          const taskMap = new Map(tasks.map((task) => [task.id, task.status]));
          const changedDocs = docs.filter((doc: any) => taskMap.get(doc.id) !== doc.status);

          changedDocs.forEach((doc: any) => {
            // @ts-ignore
            toast(t("toasts.balance_sheet_ready.title"), {
              // @ts-ignore
              description: t("toasts.balance_sheet_ready.description"),
            });
          });

          return null;
        },
      });
    }
  }, [transactionId, addQueryListener, tasks, fetchTasks]);
  if (loading) {
    return (
      <div className="flex flex-col mt-60 mx-auto h-full">
        <Spinner size={72} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const allValidated = tasks.every((doc: any) => {
    console.log(doc);
    return doc.status === "validated" || doc.status === "not-applicable";
  });

  console.log(tasks);
  // console.log(!allValidated);
  return (
    <>
      <div className="hidden h-full flex-1 flex-col space-y-8 p-2 md:flex">
        <div className="flex flex-col items-center justify-between space-y-2 gap-2">
          {(!documents || !allValidated) && <AlertDestructive />}
          <div className="flex flex-col justify-start mr-auto">
            <h2 className="text-2xl font-bold tracking-tight">
              {
                // @ts-ignore
                t("upload.title")
              }
            </h2>
            <p className="text-muted-foreground">
              {
                // @ts-ignore
                t("upload.description")
              }
            </p>
          </div>
        </div>
        <DataTable data={tasks} columns={columns} />
      </div>
    </>
  );
}
