import { TrendingUp } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import PopulationChart from "./components/overview";
import { PieCustom } from "./components/overview";

export default function DashboardPage({ title, description, combinedCharts, graphs, pie = true }) {
  console.log(pie);
  return (
    <>
      <Card className="max-w-full">
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
        <CardContent className={`grid grid-cols-${graphs} gap-4 col-span-2`}>
          {combinedCharts.map((chart) => (pie ? <PopulationChart key={chart.id} title={`${chart.title}`} data={chart.data} chartConfig={chart.chartConfig} /> : <PieCustom key={chart.id} title={`${chart.title}`} data={chart.data} chartConfig={chart.chartConfig} />))}
        </CardContent>
      </Card>
    </>
  );
}
