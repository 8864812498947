import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../components/ui/card";
import { Separator } from "../../../components/ui/separator";
import React from "react";
import { GoogleMap, MarkerF, Polygon, PolygonF, useJsApiLoader } from "@react-google-maps/api";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll";
import { backendClient } from "../../../api/backend";
import { useTranslation } from "react-i18next";

const maps_api = process.env.REACT_APP_GOOGLE_MAPS || "";
const geocode_api = process.env.REACT_APP_GEOCODE_API || "";
console.log(geocode_api);
const OverviewCard = ({ transaction, data }) => {
  const statistics = Object.entries(data.summary || {}).map(([key, value]) => {
    let formattedValue = value;

    // Check if value is a string that represents a number
    if (typeof value === "string" && /^\d+$/.test(value)) {
      value = Number(value); // Convert to number for proper formatting
    }

    if (typeof value === "number") {
      if (key.toLowerCase().includes("income") || key.toLowerCase().includes("dollar")) {
        formattedValue = `$${value.toLocaleString()}`;
      } else if (key.toLowerCase().includes("rate") || key.toLowerCase().includes("percentage")) {
        formattedValue = `${value.toFixed(1)}%`;
      } else {
        formattedValue = value.toLocaleString(); // General number formatting (commas)
      }
    }

    return { label: key, value: String(formattedValue) }; // Ensure value is always a string
  });

  const aiSummary = {
    title: "Opportunities",
    points: ["Rising Home Prices", "The median home price in Bradenton has increased by 9.8% to $389,900 in October 2024, indicating a strong demand for housing."],
  };

  return (
    <Card className="absolute top-6 right-6 w-[22rem] bg-white shadow-none border">
      <ScrollArea className="h-[21rem] w-full rounded-md border z-12">
        <CardHeader>
          <CardTitle>{transaction.address}</CardTitle>
          <CardDescription>{`${transaction.postcode}, ${transaction.country}`}</CardDescription>
        </CardHeader>
        <CardContent className="px-6 py-3 ">
          <Separator />
          {/* Statistics */}
          <div className="flex flex-col gap-3 mt-2">
            {statistics.map((stat, index) => (
              <div key={index} className="flex justify-between text-sm bg-gray-100">
                <p className="font-semibold ">{stat.label}</p>

                <p className="font-light">
                  {
                    // @ts-ignore
                    stat.value
                  }
                </p>
              </div>
            ))}
          </div>
          <Separator className="my-2" />
          {/* <div className="flex flex-col max-h-full overflow-auto">
            <h4 className="font-bold text-lg mb-2">AI Summary</h4>
            <p className="text-green-600 font-bold mb-2">PROS</p>
            <ul className="list-disc pl-4 text-sm text-gray-700">
              <li>Coming Soon...</li>
            </ul>
          </div> */}
        </CardContent>
      </ScrollArea>
    </Card>
  );
};
const transformToLonLat = (coordArray: number[]): { lat: number; lng: number }[] => {
  if (coordArray.length % 2 !== 0) {
    throw new Error("Invalid coordinate format. Expected even number of elements.");
  }

  let lonLatCoordinates: { lat: number; lng: number }[] = [];

  // Populate coordinates from array
  for (let i = 0; i < coordArray.length; i += 2) {
    lonLatCoordinates.push({
      lng: coordArray[i] || 0, // Longitude at even indices
      lat: coordArray[i + 1] || 0, // Latitude at odd indices
    });
  }

  // Helper function to calculate mean
  const calculateMean = (values: number[]): number => values.reduce((sum, val) => sum + val, 0) / values.length;

  // Helper function to calculate standard deviation
  const calculateStdDev = (values: number[], mean: number): number => {
    const variance = values.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0) / values.length;
    return Math.sqrt(variance);
  };

  // Extract latitudes and longitudes separately
  const lats = lonLatCoordinates.map((coord) => coord.lat);
  const lngs = lonLatCoordinates.map((coord) => coord.lng);

  // Calculate mean and standard deviation
  const latMean = calculateMean(lats);
  const lngMean = calculateMean(lngs);

  const latStdDev = calculateStdDev(lats, latMean);
  const lngStdDev = calculateStdDev(lngs, lngMean);
  const stdW = 2;
  // Filter coordinates within 1 standard deviation
  lonLatCoordinates = lonLatCoordinates.filter((coord) => coord.lat >= latMean - stdW * latStdDev && coord.lat <= latMean + stdW * latStdDev && coord.lng >= lngMean - stdW * lngStdDev && coord.lng <= lngMean + stdW * lngStdDev);

  return lonLatCoordinates;
};

const MapCard = ({ transaction, data }) => {
  const [markerPosition, setMarkerPosition] = React.useState<{ id: any; position: { lat: number; lng: number }; title: string }[]>([]);
  const [polygonContains, setPolygonContains] = React.useState<{ lat: number; lng: number }[]>([]);
  const center = { lat: 27.4975, lng: -82.53 }; // Center coordinates

  const markers = [{ id: 1, position: { lat: 27.4975, lng: -82.53 }, title: `Your ${transaction?.industry || "Business"} Opportunities` }];

  const regionCoordinates = [
    { lat: 27.4, lng: -82.6 },
    { lat: 27.5, lng: -82.5 },
    { lat: 27.4, lng: -82.4 },
    { lat: 27.3, lng: -82.5 },
  ]; // Example region polygon

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: maps_api,
  });

  const getLocation = async (api_key, address) => {
    try {
      const data = await backendClient.fetchLocation(api_key, address);
      console.log("Fetched location data:", data);

      if (data && data.length > 0) {
        const location = data[0]; // Access the first result

        // if (location.boundingbox) {
        //   setPolygonContains(transformToLonLat(location.boundingbox));
        // }

        if (location.lat && location.lon) {
          setMarkerPosition([
            {
              id: "1",
              title: `Your ${transaction?.industry || "Business"} Opportunities`,
              position: {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lon),
              },
            },
          ]);
        }
      } else {
        console.warn("No location data found");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };
  const boundingb = async (city) => {
    try {
      const data = await backendClient.nextSearch(city);
      // @ts-ignore
      if (data.gridData) {
        // @ts-ignore
        setPolygonContains(transformToLonLat(data.gridData));
      }
      console.log("Fetched location data:", data);
    } catch {
      console.log("Didn'manage");
      setPolygonContains([]);
    }
  };

  React.useEffect(() => {
    getLocation(geocode_api, transaction.municipality ?? transaction.city ?? transaction.state);
    boundingb(transaction.municipality);
  }, [transaction, geocode_api]);
  const { t } = useTranslation();

  if (loadError) return <div>Error loading Google Maps</div>;
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Card className="col-span-2 max-w-full">
      <CardHeader>
        <CardTitle>
          {
            // @ts-ignore
            t("market_analysis.title")
          }
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        {/* Google Map Integration */}
        <div className="h-96 w-full relative p-6">
          {/* Map Container */}
          {markerPosition.length > 0 && markerPosition[0]?.position && (
            <>
              <GoogleMap mapContainerStyle={{ height: "100%", width: "100%" }} center={markerPosition[0].position} zoom={10} mapContainerClassName="rounded-xl">
                {markerPosition.map((marker) => (
                  <MarkerF key={marker.id} position={marker.position} title={marker.title} />
                ))}
                {polygonContains.length > 0 && (
                  <PolygonF
                    paths={polygonContains}
                    options={{
                      strokeColor: "#FF0000",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: "#FF0000",
                      fillOpacity: 0.35,
                    }}
                  />
                )}
              </GoogleMap>
              <OverviewCard transaction={transaction} data={data} />{" "}
            </>
          )}
        </div>
        {/* Market Analysis Overlay */}
      </CardContent>
    </Card>
  );
};

export default MapCard;
