import React, { useEffect, useState, useRef } from "react";
import { z } from "zod";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";

import { Button } from "../../../components/ui/button";
import { Progress } from "../../../components/ui/progress";
import { Input } from "../../../components/ui/input";
import { Checkbox } from "../../../components/ui/checkbox";
import { Textarea } from "../../../components/ui/textarea";
import { Form, FormField, FormItem, FormLabel, FormMessage, FormControl } from "../../../components/ui/form";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, DialogClose } from "../../../components/ui/dialog";
import { cn } from "../../../lib/utils";
import { FilterX, ChevronsUpDown, Check } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import { CloudUpload, Link2, FileUp, User } from "lucide-react";
import { Popover, PopoverTrigger, PopoverContent } from "../../../components/ui/popover";
import { Command, CommandGroup, CommandInput, CommandList, CommandEmpty, CommandItem } from "../../../components/ui/command";
import { FileUploader } from "../../../components/file_upload/file-uploader";
import { backendClient } from "../../../api/backend";

import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions, createTransaction, updateTransactionProperty } from "../../../redux/actions/transactionAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearCashflow, clearMinideal, updateMinideal } from "../../../redux/actions/cashflowAction";
import { WebLinkDialog } from "./webdialog";

// /../../file_upload/file-uploader";

// ---------------------
// 1) DEFINE YOUR SCHEMA
// ---------------------
const FullFormSchema = z.object({
  // Step 1 fields
  dealName: z.string().min(1, "Deal name is required."),
  dealIndustry: z.string().min(1, "Industry is required."),
  dealCountry: z.string().min(1, "Country is required."),
  dealAsk: z.string().optional(),
  dealEarning: z.string().min(1, "Deal Earnings is required."),
  dealRevenue: z.string().min(1, "Deal Revenue is required."),
  dealEBITDA: z.string().min(1, "Deal EBITDA is required."),
  dealReason: z.string().optional(),
  dealState: z.string().min(1, "Deal state is required."),
  dealMunicipality: z.string().min(1, "Deal municipality is required."),
  dealCity: z.string().min(1, "Deal city is required."),
  dealAddress: z.string().optional(),
  dealPostcode: z.string().optional(),
  dealSeller: z.string().optional(),

  // Step 2 fields
  dealDescription: z.string().optional(),
  source: z.enum(["Buyer", "Seller"]),

  // Step 3 fields (some checkboxes, for example)
  checkboxes: z.object({
    inventoryIncluded: z.boolean(),
    realEstateIncluded: z.boolean(),
    equipmentIncluded: z.boolean(),
    intellectualProperty: z.boolean(),
    ownerInvolvement: z.boolean(),
    employeeExpertise: z.boolean(),
    supportPlan: z.boolean(),
    deferredPayments: z.boolean(),
  }),

  // ...and so on for all fields you need
  webLink: z.string().optional(),

  files: z.array(z.any()).optional(), // or z.array(z.instanceof(File))
});

const getStep1Config = (t, dynamicOptions) => ({
  // @ts-ignore
  title: t("steps.basic_info.title"),
  fields: [
    {
      name: "dealName",
      type: "text",
      // @ts-ignore
      label: t("steps.basic_info.fields.dealName.label"),
      // @ts-ignore
      placeholder: t("steps.basic_info.fields.dealName.placeholder"),
    },
    {
      name: "dealIndustry",
      type: "popover-select",
      // @ts-ignore
      label: t("steps.basic_info.fields.dealIndustry.label"),
      // @ts-ignore
      placeholder: t("steps.basic_info.fields.dealIndustry.placeholder"),
      // @ts-ignore
      options: dynamicOptions.industries || [t("industry.technology"), t("industry.healthcare"), t("industry.finance")],
    },
    {
      name: "dealCountry",
      type: "popover-select",
      // @ts-ignore
      label: t("steps.basic_info.fields.dealCountry.label"),
      // @ts-ignore
      placeholder: t("steps.basic_info.fields.dealCountry.placeholder"),
      // @ts-ignore
      options: dynamicOptions.countries || ["Germany", "US"],
    },
    {
      name: "source",
      type: "popover-select",
      // @ts-ignore
      label: t("steps.basic_info.fields.source.label"),
      // @ts-ignore
      placeholder: t("steps.basic_info.fields.source.placeholder"),
      // @ts-ignore
      options: dynamicOptions.sources || ["Buyer", "Seller"],
    },
    {
      name: "dealDescription",
      type: "textarea",
      // @ts-ignore
      label: t("steps.basic_info.fields.dealDescription.label"),
      // @ts-ignore
      placeholder: t("steps.basic_info.fields.dealDescription.placeholder"),
    },
  ],
});

const step2 = (t, dynamicOptions) => ({
  // @ts-ignore
  title: t("steps.more_details.title"),
  fields: [
    {
      name: "dealState",
      type: "popover-select",
      // @ts-ignore
      label: t("steps.more_details.fields.dealState.label"),
      // @ts-ignore
      placeholder: t("steps.more_details.fields.dealState.placeholder"),
      options: dynamicOptions.state || [],
    },
    {
      name: "dealMunicipality",
      type: "popover-select",
      // @ts-ignore
      label: t("steps.more_details.fields.dealMunicipality.label"),
      // @ts-ignore
      placeholder: t("steps.more_details.fields.dealMunicipality.placeholder"),
      options: dynamicOptions.municipalities || [],
    },
    {
      name: "dealCity",
      type: dynamicOptions.selectedCountry === "Germany" ? "popover-select" : "text",
      // @ts-ignore
      label: t("steps.more_details.fields.dealCity.label"),
      // @ts-ignore
      placeholder: t("steps.more_details.fields.dealCity.placeholder"),
      options: dynamicOptions.cities || [],
      // value: dynamicOptions.selectedCountry === "Germany" ? undefined : "N/A",
    },
    {
      name: "dealAddress",
      type: "text",
      // @ts-ignore
      label: t("steps.more_details.fields.dealAddress.label"),
      // @ts-ignore
      placeholder: t("steps.more_details.fields.dealAddress.placeholder"),
    },
    {
      name: "dealPostcode",
      type: "text",
      // @ts-ignore
      label: t("steps.more_details.fields.dealPostcode.label"),
      // @ts-ignore
      placeholder: t("steps.more_details.fields.dealPostcode.placeholder"),
    },
    {
      name: "dealReason",
      type: "text",
      // @ts-ignore
      label: t("steps.more_details.fields.dealReason.label"),
      // @ts-ignore
      placeholder: t("steps.more_details.fields.dealReason.placeholder"),
    },
    {
      name: "dealSeller",
      type: "text",
      // @ts-ignore
      label: t("steps.more_details.fields.dealSeller.label"),
      // @ts-ignore
      placeholder: t("steps.more_details.fields.dealSeller.placeholder"),
    },
  ],
});

const step3 = (t) => ({
  // @ts-ignore
  title: t("steps.financial_details.title"),
  fields: [
    {
      name: "dealAsk",
      type: "text",
      // @ts-ignore
      label: t("steps.financial_details.fields.dealAsk.label"),
      // @ts-ignore
      placeholder: t("steps.financial_details.fields.dealAsk.placeholder"),
    },
    {
      name: "dealEarning",
      type: "text",
      // @ts-ignore
      label: t("steps.financial_details.fields.dealEarning.label"),
      // @ts-ignore
      placeholder: t("steps.financial_details.fields.dealEarning.placeholder"),
    },
    {
      name: "dealRevenue",
      type: "text",
      // @ts-ignore
      label: t("steps.financial_details.fields.dealRevenue.label"),
      // @ts-ignore
      placeholder: t("steps.financial_details.fields.dealRevenue.placeholder"),
    },
    {
      name: "dealEBITDA",
      type: "text",
      // @ts-ignore
      label: t("steps.financial_details.fields.dealEBITDA.label"),
      // @ts-ignore
      placeholder: t("steps.financial_details.fields.dealEBITDA.placeholder"),
    },
    {
      name: "checkboxes",
      type: "checkbox-grid",
      // @ts-ignore
      label: t("steps.financial_details.fields.criteria.label"),
    },
  ],
});

const step4 = (t) => ({
  // @ts-ignore
  title: t("steps.upload_files.title"),
  fields: [
    {
      name: "files",
      type: "file-uploader",
      // @ts-ignore
      label: t("steps.upload_files.fields.files.label"),
    },
  ],
});

const webLinkStep = {
  title: "Provide Web Link",
  fields: [{ name: "webLink", type: "text", label: "Web Link", placeholder: "Paste the link to your opportunity or marketplace here" }],
};
type FormFieldKeys = keyof z.infer<typeof FullFormSchema>;
const StepOneFields: FormFieldKeys[] = ["dealName", "dealIndustry", "dealCountry", "source"];
const StepTwoFields: FormFieldKeys[] = ["dealAsk", "dealEarning", "dealRevenue", "dealEBITDA", "dealReason", "checkboxes"];
const StepThreeFields: FormFieldKeys[] = ["files"];
// -----------------------------------
// 3) MULTI-STEP FORM IMPLEMENTATION
// -----------------------------------

interface DynamicDealFormProps {
  dealType: "sell" | "buy" | "web-link" | "document-upload" | "crm" | "upload" | null;
  onClose: () => {};
  transaction?: any;
}

export function DynamicDealForm({ dealType, onClose, transaction = null }: DynamicDealFormProps) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [industries, setIndustries] = useState<any>([]); // Default industry
  const dispatch: ThunkDispatch<{}, {}, AnyAction> = useDispatch();
  const navigate = useNavigate();
  // const [country, setCountry];
  const [getstate, setStates] = useState<any[]>([]);
  const [municipalities, setMunicipalities] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  // const { t } = useTranslation();
  console.log(transaction);
  React.useEffect(() => {
    // Fetch industries from the backend
    const fetchIndustries = async () => {
      try {
        // const response = await fetch("/api/industries"); // Replace with your API endpoint
        // const data = await response.json();
        const data = await backendClient.get_industries();
        // console.log(data);
        const transformedData = Object.keys(data)
          .sort() // Sort categories alphabetically
          .map((key) => ({
            category: key,
            subcategories: data[key].sort().map((subcategory, index) => ({
              id: index,
              name: subcategory,
            })),
          }));
        // console.log(transformedData);
        setIndustries(transformedData);
        // console.log(data["industries"]);
      } catch (error) {
        console.error("Failed to fetch industries", error);
      }
    };

    fetchIndustries();
  }, []);

  let side;
  console.log(transaction);
  if (transaction?.source) {
    if (transaction.source === "SELL_SIDE") side = "Seller";
    else {
      side = "Buyer";
    }
  }
  console.log(side);
  const formMethods = useForm({
    resolver: zodResolver(FullFormSchema),
    defaultValues: React.useMemo(
      () => ({
        dealName: transaction?.name ?? "",
        dealIndustry: transaction?.industry ?? "",
        dealCountry: transaction?.country ?? "",
        dealDescription: transaction?.description ?? "",
        source: side ?? "",
        dealAsk: transaction?.askPrice ? String(transaction?.askPrice) : "",
        dealState: transaction?.state ?? "",
        dealMunicipality: transaction?.municipality ?? "",
        dealCity: transaction?.city ?? "",
        dealAddress: transaction?.address ?? "",
        dealPostcode: transaction?.postcode ?? "",
        dealSeller: transaction?.seller ?? "",
        dealEarning: transaction?.cashFlow ? String(transaction?.cashFlow) : "",
        dealRevenue: transaction?.revenue ? String(transaction?.revenue) : "",
        dealEBITDA: transaction?.ebitda ? String(transaction?.ebitda) : "",
        dealReason: transaction?.reasonForSale ?? "",
        checkboxes: {
          inventoryIncluded: (transaction?.criteria?.includes("Inventory Included") || transaction?.criteria?.includes("inventory Included")) ?? false,
          realEstateIncluded: (transaction?.criteria?.includes("Real Estate Included") || transaction?.criteria?.includes("real Estate Included")) ?? false,
          equipmentIncluded: (transaction?.criteria?.includes("Equipment Included") || transaction?.criteria?.includes("equipment Included")) ?? false,
          intellectualProperty: (transaction?.criteria?.includes("Intellectual Property") || transaction?.criteria?.includes("intellectual Property")) ?? false,
          ownerInvolvement: (transaction?.criteria?.includes("Owner Involvement") || transaction?.criteria?.includes("owner Involvement")) ?? false,
          employeeExpertise: (transaction?.criteria?.includes("Employee Expertise") || transaction?.criteria?.includes("employee Expertise")) ?? false,
          supportPlan: (transaction?.criteria?.includes("Support Plan") || transaction?.criteria?.includes("support Plan")) ?? false,
          deferredPayments: (transaction?.criteria?.includes("Deferred Payments") || transaction?.criteria?.includes("deferred Payments")) ?? false,
        },
        webLink: transaction?.webLink ?? "",
        files: transaction?.files ?? [],
      }),
      [transaction],
    ), // Ensures default values update if `transaction` changes
    mode: "onChange",
  });

  const selectedCountry = formMethods.watch("dealCountry");
  const selectedState = formMethods.watch("dealState");
  const selectedMunicipality = formMethods.watch("dealMunicipality");
  React.useEffect(() => {
    const fetchStates = async () => {
      if (!selectedCountry) return; // No need to fetch if no country is selected

      try {
        const response = await backendClient.get_state(selectedCountry);
        console.log(response);
        // const stateOptions = response.map((state: string) => ({ label: state, value: state }));
        setStates(response);
      } catch (error) {
        console.error("Failed to fetch states", error);
        setStates([]);
      }
    };

    fetchStates();
  }, [selectedCountry]); // Runs every time selectedState changes
  React.useEffect(() => {
    const fetchMunicipalities = async () => {
      if (!selectedState) return; // No need to fetch if no country is selected

      try {
        const response = await backendClient.get_municipalities(selectedCountry, selectedState);
        console.log(response);
        // const stateOptions = response.map((state: string) => ({ label: state, value: state }));
        setMunicipalities(response);
      } catch (error) {
        console.error("Failed to fetch states", error);
        setMunicipalities([]);
      }
    };

    fetchMunicipalities();
  }, [selectedState]); // Runs every time selectedState changes
  React.useEffect(() => {
    const fetchCities = async () => {
      if (!selectedMunicipality) return; // No need to fetch if no country is selected

      try {
        const response = await backendClient.get_cities(selectedCountry, selectedState, selectedMunicipality);
        console.log(response);
        // const stateOptions = response.map((state: string) => ({ label: state, value: state }));
        setCities(response);
      } catch (error) {
        console.error("Failed to fetch states", error);
        setCities([]);
      }
    };

    fetchCities();
  }, [selectedMunicipality]); // Runs every time selectedState changes
  // Keep track of which step we’re on
  if (!dealType) return null;
  // 3) Decide which steps to show
  let stepsToUse: any[] = [];
  const step1Config = getStep1Config(t, { industries });
  const step2Config = step2(t, { state: getstate, municipalities, cities, selectedCountry });
  const step3Config = step3(t);
  const step4Config = step4(t);
  switch (dealType) {
    case "sell":
      // “Sell side input” => only Step 1 & Step 2
      stepsToUse = [step1Config, step2Config, step3Config];
      break;
    case "web-link":
      // “Web Link” => only 1 text field
      stepsToUse = [webLinkStep];
      break;
    case "document-upload":
      // “Buy side input” (Document) => all 3 steps
      // (You might rename it from "document-upload" to "buy" if you prefer.)
      stepsToUse = [step1Config, step2Config, step3Config, step4Config];
      break;
    case "crm":
      // Could be no steps, or an entirely different form
      stepsToUse = [];
      break;
    case "upload":
      // Could be no steps, or an entirely different form
      stepsToUse = [step4Config];
      break;
    default:
      // If you also have a "buy" type separate from "document-upload", handle it here
      stepsToUse = [step1Config, step2Config, step3Config, step4Config];
      break;
  }

  // Calculate progress (percentage)
  // e.g., if there are 4 steps total, and we're on step 1 (0-based index), progress = 1/4 = 25%
  const totalSteps = stepsToUse.length;
  const progress = Math.round(((currentStep + 1) / totalSteps) * 100);

  // Initialize react-hook-form with entire schema

  const {
    handleSubmit,
    trigger,
    formState: { isDirty, isValid },
  } = formMethods;

  // If you want to do something with the final data:
  const onSubmit = async (data: any) => {
    // console.log("Final form submit data:", data);
    toast(
      // @ts-ignore
      t("toasts.data_uploading.title"),
      {
        description:
          // @ts-ignore
          t("toasts.data_uploading.description"),
      },
    );
    // Make your final API call here
    const transformed = {
      name: data.dealName, // Map directly
      industry: data.dealIndustry, // Convert to uppercase, underscore-separated
      state: data.dealState, // Missing field, default to "Unknown"
      municipality: data.dealMunicipality, // Missing field, default to "Unknown"
      city: data.dealCity, // Missing field, default to "Unknown"
      seller: data.dealSeller, // Missing field, default to "Unknown"
      country: data.dealCountry, // Map directly
      address: data.dealAddress, // Missing field, default to "Unknown Address"
      description: data.dealDescription || "No description provided", // Map with fallback
      askPrice: Number(data.dealAsk.replace(/,/g, "")), // Convert to number and remove commas
      postcode: data.dealPostcode, // Missing field, default to "Unknown"
      cashFlow: Number(data.dealEarning.replace(/,/g, "")), // Convert to number and remove commas
      revenue: Number(data.dealRevenue.replace(/,/g, "")), // Convert to number and remove commas
      ebitda: Number(data.dealEBITDA.replace(/,/g, "")), // Convert to number and remove commas
      reasonForSale: data.dealReason || "No reason provided", // Map with fallback
      criteria: Object.entries(data.checkboxes)
        .filter(([key, value]) => value) // Include only true values
        .map(([key]) => key.replace(/([A-Z])/g, " $1").trim()), // Convert camelCase to words
      source: data.source === "Buyer" ? "BUY_SIDE" : "SELL_SIDE", // Map to "BUY_SIDE" or "SELL_SIDE"
    };
    if (transaction && transaction?.id) {
      await dispatch(updateTransactionProperty(transaction.id, transformed));
      onClose();
      toast(
        // @ts-ignore
        t("toasts.data_uploaded.title"),
        {
          description:
            // @ts-ignore
            t("toasts.data_uploaded.description", { name: transformed.name }),
        },
      );
    } else {
      const transactionData = await dispatch(createTransaction(transformed));
      console.log(transactionData);
      await dispatch(clearMinideal());
      await dispatch(clearCashflow());
      await dispatch(updateMinideal(transactionData.revenue, transactionData.ebitda, transactionData.cashFlow, transactionData.earningsMultiple, transactionData.revenueMultiple, transactionData.uploadStatus));
      onClose();
      navigate("/upload");
    }
    // console.log(transformed);
  };

  // Next & Prev step
  const nextStep = async () => {
    // Optionally validate the current step’s fields only
    if (currentStep < totalSteps - 1) {
      // Extract the fields from stepsToUse[currentStep]
      const fieldNames = stepsToUse[currentStep].fields.map((f: any) => f.name);
      const isStepValid = await trigger(fieldNames);

      if (isStepValid) {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };
  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  // Decide button text
  const isLastStep = currentStep === totalSteps - 1;
  return (
    // <FormProvider {...formMethods}>
    <Form key={currentStep} {...formMethods}>
      {/* Step Title */}
      <div className="flex items-center justify-between mb-1 gap-3">
        <div className="text-lg font-semibold">{stepsToUse[currentStep]?.title}</div>
        <div className="font-semibold">
          {" "}
          {
            // @ts-ignore
            t("form.step", { current: currentStep + 1, total: totalSteps })
          }
        </div>
      </div>
      <Progress value={progress} className="h-2 mb-2 " />

      {/* Progress Bar */}

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Dynamically render fields for the current step */}
        <div className="grid auto-rows-min gap-6 md:grid-cols-2">{stepsToUse[currentStep]?.fields.map((fieldConfig: any, index: number) => <DynamicField key={index} {...fieldConfig} control={formMethods.control} />)}</div>

        {/* Navigation buttons */}
        <DialogFooter className="sm:justify-between sm:items-center flex justify-between items-center w-full mt-8">
          <DialogClose>
            <Button variant={"ghost"}>
              {
                // @ts-ignore
                t("form.buttons.cancel")
              }
            </Button>
          </DialogClose>
          <div className="flex justify-end gap-2 ">
            {currentStep > 0 && (
              <Button type="button" variant="outline" onClick={prevStep}>
                {
                  // @ts-ignore
                  t("form.buttons.previous")
                }
              </Button>
            )}

            {/* If last step, show “Submit”, otherwise show “Next” */}
            {!isLastStep ? (
              <Button type="button" onClick={nextStep}>
                {
                  // @ts-ignore
                  t("form.buttons.next")
                }
              </Button>
            ) : (
              <Button type="submit" disabled={!(dealType === "web-link" || dealType === "upload") && !isDirty && !transaction}>
                {
                  // @ts-ignore
                  t("form.buttons.submit")
                }
              </Button>
            )}
          </div>
        </DialogFooter>
      </form>
    </Form>
    // </FormProvider>
  );
}

// -------------------------------------------------
// 4) HELPER COMPONENT FOR RENDERING DYNAMIC FIELDS
// -------------------------------------------------
function toFriendlyLabel(key: string) {
  // e.g. inventoryIncluded -> "Inventory Included"
  return key
    .replace(/([A-Z])/g, " $1") // insert spaces before capital letters
    .replace(/^./, (str) => str.toUpperCase());
}
type DynamicFieldProps = {
  name: string;
  type: "text" | "textarea" | "checkbox" | "select" | "popover-select" | "checkbox-grid" | "file-uploader";
  label?: string;
  placeholder?: string;
  options?: Array<{ category: string; subcategories: Array<{ id: number; name: string }> }> | string[]; // Either an array of objects (for grouped options) or a flat array of strings
  value?: string;
  control: any;
};

export function DynamicField({ name, type, label, placeholder, options, control, value }: DynamicFieldProps) {
  // The react-hook-form field registration is easiest done
  // through the <FormField> + <Controller> or <FormItem> approach:
  // const { control } = useForm();

  // Because we have nested paths like `checkboxes.inventoryIncluded`,
  // we'll parse the name string to handle them properly.
  // Or just pass the exact string to the `Controller name` prop.
  // console.log("this field is", name, type);

  switch (type) {
    case "text":
      return (
        <FormField
          control={control}
          name={name}
          render={({ field }) => {
            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              const inputValue = e.target.value;

              if (inputValue === "") {
                // If the field is cleared, set the value to an empty string
                field.onChange("");
                return;
              }

              // Remove commas and attempt to parse the number
              const numericValue = Number(inputValue.replace(/,/g, ""));
              if (!isNaN(numericValue)) {
                // Format the number using toLocaleString
                field.onChange(numericValue.toLocaleString());
              } else {
                // If not a valid number, pass the raw input value
                field.onChange(inputValue);
              }
            };

            return (
              <FormItem className={`${name === "webLink" ? "col-span-2" : ""}`}>
                {label && <FormLabel>{label}</FormLabel>}
                <FormControl>
                  <Input key={name} {...field} placeholder={placeholder} value={value ?? field.value} onChange={handleChange} />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      );

    case "textarea":
      return (
        <FormField
          control={control}
          name={name}
          render={({ field }) => (
            <FormItem className="col-span-2">
              {label && <FormLabel>{label}</FormLabel>}
              <FormControl>
                <Textarea {...field} placeholder={placeholder} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "checkbox-grid":
      return (
        <FormField
          control={control}
          name={name} // e.g. "checkboxes"
          render={({ field }) => {
            /**
             * field.value is an object like:
             * {
             *   inventoryIncluded: false,
             *   realEstateIncluded: false,
             *   ...
             * }
             *
             * field.onChange expects a new object of the same shape.
             */

            // If you want to specify a fixed order or subset of keys, you could
            // pass them in via props or define them inline. For example:
            const checkboxKeys = Object.keys(field.value); // or an array you define

            return (
              <FormItem className="col-span-2">
                {/* Label for the entire group */}
                {label && <FormLabel>{label}</FormLabel>}

                <div className="grid auto-rows-min gap-4 grid-cols-4">
                  {checkboxKeys.map((key) => (
                    <div key={key} className="flex items-center space-x-2">
                      <Checkbox
                        checked={field.value[key]}
                        onCheckedChange={(val) => {
                          // Update just that key, keep the others the same
                          field.onChange({
                            ...field.value,
                            [key]: val === true, // or Boolean(val)
                          });
                        }}
                        id={key}
                      />
                      <label htmlFor={key} className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        {/* You can transform the key into a friendly label */}
                        {toFriendlyLabel(key)}
                      </label>
                    </div>
                  ))}
                </div>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      );

    case "checkbox":
      return (
        <FormField
          control={control}
          name={name}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-2">
              <FormControl>
                <Checkbox checked={field.value} onCheckedChange={(val) => field.onChange(Boolean(val))} id={name} />
              </FormControl>
              {label && (
                <FormLabel htmlFor={name} className="text-sm font-medium">
                  {label}
                </FormLabel>
              )}
              <FormMessage />
            </FormItem>
          )}
        />
      );

    case "select":
      return (
        <FormField
          control={control}
          name={name}
          render={({ field }) => (
            <FormItem>
              {label && <FormLabel>{label}</FormLabel>}
              <FormControl>
                <select className="block w-full rounded border border-gray-300 px-3 py-2 text-sm" value={field.value ?? ""} onChange={(e) => field.onChange(e.target.value)}>
                  <option value="" disabled>
                    {placeholder || "Select an option"}
                  </option>
                  {options?.map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "popover-select":
      // Render our sub-component
      return (
        <FormField
          control={control}
          name={name}
          render={({ field }) => (
            <FormItem>
              <PopoverSelectField
                label={label}
                placeholder={placeholder}
                options={options}
                field={field} // pass the field object
              />
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "file-uploader":
      return (
        <FormField
          control={control}
          name={name}
          render={({ field }) => (
            <FormItem className="col-span-2">
              {label && <FormLabel>{label}</FormLabel>}
              <FormControl>
                <FileUploader
                  maxFileCount={1}
                  maxSize={8 * 1024 * 1024}
                  // Pass any props you need from your existing FileUploader
                  onValueChange={(uploadedFiles) => {
                    // This will store the files array in RHF form state
                    field.onChange(uploadedFiles);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    default:
      return null;
  }
}

export function DialogComponent({ isOpen, onClose, dialogContent, selectedDealType, transaction = null }) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="min-w-[60%]">
        <DialogHeader>
          <DialogTitle>{dialogContent.title || "Add Deal"}</DialogTitle>
          <DialogDescription>{dialogContent.description || "Provide details for the deal."}</DialogDescription>
        </DialogHeader>
        <DynamicDealForm dealType={selectedDealType} onClose={onClose} transaction={transaction} />
      </DialogContent>
    </Dialog>
  );
}

export function DropdownComponent({ menuItems, onMenuItemClick, buttonText, open, onOpenChange }) {
  const { t } = useTranslation(); // ✅ Use translation hook

  return (
    <DropdownMenu open={open} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button className="relative" size="sm" variant="default">
          {buttonText || "Add Deal"}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>
          {
            // @ts-ignore
            t("dropdown.label")
          }
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {menuItems.map((item, index) => (
            <DropdownMenuItem disabled={item?.disabled || false} key={index} onClick={() => onMenuItemClick(item)}>
              {item.icon}
              <span>{item.label}</span>
              <DropdownMenuShortcut>{item.shortcut}</DropdownMenuShortcut>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function DropdownMenuaction() {
  const { t } = useTranslation();
  const [dialogContent, setDialogContent] = useState({
    title: "",
    description: "",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isdialogOpen, setIsdialogOpen] = useState(false);

  // NEW: We'll handle a separate Web Link Dialog
  const [webLinkDialogOpen, setWebLinkDialogOpen] = useState(false);
  const [transactionFromLink, setTransactionFromLink] = useState<any>(null);

  const [selectedDealType, setSelectedDealType] = useState<"sell" | "buy" | "web-link" | "document-upload" | "crm" | null>(null);

  const menuItems = [
    {
      icon: <User />,

      // @ts-ignore
      label: t("pipeline.dropdown.manual_input.label"),
      shortcut: "⇧⌘P",
      // @ts-ignore
      title: t("pipeline.dropdown.manual_input.title"),
      // @ts-ignore
      description: t("pipeline.dropdown.manual_input.description"),
      type: "sell",
    },

    {
      icon: <Link2 />,

      // @ts-ignore
      label: t("pipeline.dropdown.web_link.label"),

      shortcut: "⌘S",
      disabled: false,
      // @ts-ignore
      title: t("pipeline.dropdown.web_link.title"),
      // @ts-ignore
      description: t("pipeline.dropdown.web_link.description"),
      type: "web-link",
    },
    {
      icon: <CloudUpload />,

      // @ts-ignore
      label: t("pipeline.dropdown.crm_sync.label"),

      shortcut: "⌘B",
      disabled: true,
      // @ts-ignore
      title: t("pipeline.dropdown.crm_sync.title"),
      // @ts-ignore
      description: t("pipeline.dropdown.crm_sync.description"),
      type: "crm",
    },
  ];
  const handleMenuClick = (item) => {
    setDialogContent({
      title: item.title,
      description: item.description,
    });
    setSelectedDealType(item.type);

    // If it's "web-link", we open the separate link dialog
    if (item.type === "web-link") {
      setWebLinkDialogOpen(true);
      // Don’t open the main form yet!
    } else {
      // Otherwise, show the main form as normal
      setIsdialogOpen(true);
    }
  };

  // Called once we get the transaction from the first dialog
  const handleTransactionReady = (transaction: any) => {
    // 1) Store the fetched transaction from link
    setTransactionFromLink(transaction);

    // 2) Close the link dialog
    setWebLinkDialogOpen(false);
    // 3) Now open the main form dialog
    setIsdialogOpen(true);
  };

  // Called when the user closes the main form
  const handleMainDialogClose = () => {
    setIsdialogOpen(false);
    setTransactionFromLink(null);
    // clear out the stored transaction if you want
  };

  const handleDialogClose = () => {
    setIsdialogOpen(false);
  };

  return (
    <>
      <DropdownComponent
        menuItems={menuItems}
        onMenuItemClick={handleMenuClick}
        buttonText={
          // @ts-ignore
          t("pipeline.dropdown.add_deal")
        }
        open={isDropdownOpen}
        onOpenChange={setIsDropdownOpen}
      />
      {/* 2) The new "WebLinkDialog" for entering the link */}
      <WebLinkDialog open={webLinkDialogOpen && !isDropdownOpen} onClose={() => setWebLinkDialogOpen(false)} onTransactionReady={handleTransactionReady} />

      <DialogComponent isOpen={isdialogOpen && !isDropdownOpen && !webLinkDialogOpen} onClose={handleMainDialogClose} dialogContent={dialogContent} selectedDealType={selectedDealType === "web-link" ? "sell" : selectedDealType} transaction={transactionFromLink} />
    </>
  );
}

interface GroupedOption {
  category?: any;
  subcategories?: any;
}

interface PopoverSelectFieldProps {
  label?: string; // Optional label
  placeholder?: string; // Optional placeholder text
  options?: GroupedOption[] | string[]; // Either grouped options or a flat array of strings
  field: {
    value: string; // The current value of the field
    onChange: (val: string) => void; // A function to handle changes
  };
}

export function PopoverSelectField({ label, placeholder, options = [], field }: PopoverSelectFieldProps) {
  // We always call these hooks, regardless of how we render this component
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const [popoverWidth, setPopoverWidth] = React.useState<string | number>("auto");

  React.useEffect(() => {
    const updatePopoverWidth = () => {
      if (buttonRef.current) {
        const buttonWidth = buttonRef.current.getBoundingClientRect().width;
        setPopoverWidth(`${buttonWidth}px`);
      }
    };
    updatePopoverWidth();
    window.addEventListener("resize", updatePopoverWidth);
    return () => {
      window.removeEventListener("resize", updatePopoverWidth);
    };
  }, []);
  const isOptionsObject = Array.isArray(options) && options.length > 0 && typeof options[0] === "object" && options[0] !== null;
  // console.log(isOptionsObject);
  return (
    // <div>
    <FormItem className="space-y-2">
      {/* If you want a label above the popover */}
      {label && <FormLabel>{label}</FormLabel>}

      <Popover open={open} onOpenChange={setOpen} modal={false}>
        <PopoverTrigger asChild>
          <Button ref={buttonRef} variant="outline" role="combobox" className="w-full justify-between py-0" onClick={() => setOpen(!open)}>
            {field.value || placeholder || "Select an option"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent style={{ width: popoverWidth }} className="p-0">
          <Command>
            <CommandInput placeholder={`Search ${placeholder ?? "items"}...`} />
            <CommandList>
              <CommandEmpty>No items found.</CommandEmpty>
              {isOptionsObject
                ? (options as GroupedOption[]).map((group) => {
                    // console.log(group);
                    // return <div></div>;
                    return (
                      <CommandGroup heading={group.category} key={group.category}>
                        {group.subcategories.map((industry) => (
                          <CommandItem
                            key={industry.name?.name}
                            onSelect={() => {
                              field.onChange(industry.name?.name);
                              setOpen(false); // Update selected value
                            }}
                          >
                            {industry.name?.name}
                            <Check className={cn("ml-auto", industry.name?.name === field.value ? "opacity-100" : "opacity-0")} />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    );
                  })
                : options?.map((option) => (
                    <CommandItem
                      key={option}
                      onSelect={() => {
                        field.onChange(option);
                        setOpen(false);
                      }}
                    >
                      {option}
                      <Check className={cn("ml-auto", option === field.value ? "opacity-100" : "opacity-0")} />
                    </CommandItem>
                  ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </FormItem>
    // </div>
  );
}
