import React, { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../../../components/ui/charts";
import PopulationChart from "./../../CashflowScreen/components/overview";

// Chart configuration for debt repayment breakdown

const chartConfig = {
  cashOutflow: {
    label: "Cash Outflow",
    color: "#ef4444", // Blue
  },
  cashInflow: {
    label: "Cash Inflow",
    color: "#93c5fd", // Light Blue
  },
  cashTotal: {
    label: "Total Cash",
    color: "#1e40af", // Dark Blue
    isTotal: true,
  },
};

// Custom Tooltip for Debt Repayment Breakdown
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white shadow-lg rounded p-2 text-sm">
        <p className="font-bold">{label}</p>
        {payload.map((entry: any, index: number) => (
          <div key={`tooltip-item-${index}`} className="flex items-center space-x-2">
            <div
              className="w-3 h-3 rounded-sm"
              style={{
                backgroundColor: entry.color,
              }}
            />
            <p>
              {entry.name}: <span className="font-medium">${new Intl.NumberFormat("en-US").format(Math.round(entry.value))}</span>
            </p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export function DebtRepaymentChart({ schedule }: { schedule: any[] }) {
  // Data preparation for stacked bar chart
  const chartData = useMemo(() => {
    let cumulativeNegative = 0; // Running total for negative cash flows
    let cumulativePositive = 0; // Running total for positive cash flows
    let cumulativeTotal = 0; // Running total for all cash flows

    return schedule.map((row, index) => {
      const cashFlow = parseFloat(row.freeCashFlow) || 0; // Parse and handle invalid inputs

      // Update cumulative totals
      if (cashFlow < 0) {
        cumulativeNegative += cashFlow; // Add to cumulative outflows
      } else {
        cumulativePositive += cashFlow; // Add to cumulative inflows
      }

      cumulativeTotal += cashFlow; // Add to overall cumulative total

      return {
        year: `Year ${row.year}`, // Year from schedule
        cashOutflow: cumulativeNegative, // Cumulative negative cash flow
        cashInflow: cumulativePositive, // Cumulative positive cash flow
        cashTotal: cumulativeTotal, // Cumulative total cash flow
      };
    });
  }, [schedule]);

  return <PopulationChart data={chartData} chartConfig={chartConfig} title="" height="h-64" />;
}
