import {
  CLEAR_TRANSACTIONS,
  CREATE_TRANSACTION,
  SET_TRANSACTIONS,
  DELETE_TRANSACTIONS,
  FETCH_TRANSACTION,
  FETCH_TRANSACTIONS,
  REMOVE_TRANSACTION_DOCUMENT_ID,
  SET_TRANSACTION,
  SET_TRANSACTION_ID,
  UPDATE_TRANSACTION_DOCUMENTS_IDS,
  UPDATE_TRANSACTION_PROPERTY,
  UPDATE_TRANSACTION_DOCUMENT_UPLOADED,
} from "../actions/types";

const initialState = {
  transactionId: null, // used for explore
  transaction: null, // reference to the current active transaction
  transactions: [], // Store the list of all transactions here
};

const updateTransaction = (transactions, updatedTransaction) => {
  const index = transactions.findIndex((t) => t.id === updatedTransaction.id);
  if (index !== -1) {
    return [...transactions.slice(0, index), updatedTransaction, ...transactions.slice(index + 1)];
  }
  return [...transactions, updatedTransaction];
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
        transactions: updateTransaction(state.transactions, action.payload),
      };
    case SET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
        transactions: updateTransaction(state.transactions, action.payload),
      };

    case SET_TRANSACTIONS:
    case FETCH_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };

    case SET_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload,
        transaction: state.transactions.find((t) => t.id === action.payload) || null,
      };

    case UPDATE_TRANSACTION_DOCUMENTS_IDS:
    case UPDATE_TRANSACTION_PROPERTY:
      const updatedTransactions = updateTransaction(state.transactions, action.payload);
      console.log(updatedTransactions);
      return {
        ...state,
        transactions: updatedTransactions,
        transaction: state.transaction && state.transaction.id === action.payload.id ? action.payload : state.transaction,
      };

    case DELETE_TRANSACTIONS:
      const remainingTransactions = state.transactions.filter((t) => !action.payload.includes(t.id));
      return {
        ...state,
        transaction: state.transaction && action.payload.includes(state.transaction.id) ? null : state.transaction,
        transactions: remainingTransactions,
      };

    case REMOVE_TRANSACTION_DOCUMENT_ID:
      const transactionsWithoutDocument = state.transactions.map((transaction) => ({
        ...transaction,
        documents_ids: transaction.documents_ids.filter((docId) => docId !== action.payload),
      }));
      return {
        ...state,
        transactions: transactionsWithoutDocument,
        transaction: state.transaction
          ? {
              ...state.transaction,
              documents_ids: state.transaction.documents_ids.filter((docId) => docId !== action.payload),
            }
          : null,
      };

    case CLEAR_TRANSACTIONS:
      return {
        ...state,
        transaction: null,
        transactions: [],
      };

    case FETCH_TRANSACTION:
      return {
        ...state,
        transaction: action.payload.transaction,
        transactions: updateTransaction(state.transactions, action.payload.transaction),
      };

    case UPDATE_TRANSACTION_DOCUMENT_UPLOADED:
      if (state.transaction) {
        const updatedTransaction = {
          ...state.transaction,
          document_uploaded: action.payload,
        };
        return {
          ...state,
          transaction: updatedTransaction,
          transactions: updateTransaction(state.transactions, updatedTransaction),
        };
      }
      return state;

    default:
      return state;
  }
};

export default transactionReducer;
