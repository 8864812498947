import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, DialogClose } from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { FilterX } from "lucide-react";
import { DropdownMenuaction } from "./buttonaction";
import { useTranslation } from "react-i18next";
export default function EmptyPipeline() {
  const { t } = useTranslation(); // ✅ Use translation hook

  return (
    <div className="flex h-[450px] shrink-0 items-center justify-center rounded-md border border-dashed">
      <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
        <FilterX className="h-10 w-10 text-muted-foreground" />

        <h3 className="mt-4 text-lg font-semibold">
          {
            // @ts-ignore
            t("emptyPipeline.title")
          }
        </h3>
        <p className="mb-4 mt-2 text-sm text-muted-foreground">
          {
            // @ts-ignore
            t("emptyPipeline.description")
          }
        </p>
        <DropdownMenuaction />
      </div>
    </div>
  );
}
