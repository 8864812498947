"use client";

import React, { useMemo } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { ChartConfig, ChartContainer } from "../../../components/ui/charts";

// Chart configuration for debt repayment breakdown
const chartConfig = {
  investment: {
    label: "Initial Investment",
    color: "#ff8042", // Orange
  },
  cashFlow: {
    label: "Cash Flow",
    color: "#82ca9d", // Green
  },
  taxPaid: {
    label: "Tax Paid",
    color: "#8884d8", // Blue
  },
  operations: {
    label: "Operational Profit",
    color: "#ffc658", // Yellow
  },
  workingCapital: {
    label: "Working Capital",
    color: "#E64A3A", // Red
  },
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white shadow-lg rounded p-2 text-sm">
        <p className="font-bold">{label}</p>
        {payload.map((entry: any, index: number) => (
          <div key={`tooltip-item-${index}`} className="flex items-center space-x-2">
            <div
              className="w-3 h-3 rounded-sm"
              style={{
                backgroundColor: entry.color,
              }}
            />
            <p>
              {entry.name}: <span className="font-medium">${new Intl.NumberFormat("en-US").format(Math.round(entry.value))}</span>
            </p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export function Overview({ schedule }: { schedule: any[] }) {
  // Prepare data for the chart
  const chartData = useMemo(() => {
    return schedule.map((row) => ({
      year: `Year ${row.year}`,
      investment: -parseFloat(row.capex),
      cashFlow: parseFloat(row.freeCashFlow > 0 ? row.freeCashFlow : 0),
      taxPaid: parseFloat(row.tax),
      operations: parseFloat(row.cost),
      workingCapital: parseFloat(row.workingCapitalChange),
    }));
  }, [schedule]);
  console.log(schedule);
  return (
    <ChartContainer config={chartConfig} className="w-full h-64">
      <ResponsiveContainer>
        <BarChart data={chartData}>
          <XAxis dataKey="year" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            axisLine={false}
            domain={["auto", "auto"]} // Ensure auto-scaling for the Y-Axis
            tickFormatter={(value) => `$${new Intl.NumberFormat("en-US").format(Math.round(value))}`}
          />
          <Tooltip content={<CustomTooltip />} />
          {/* Interest Payment */}
          <Bar dataKey="investment" name={chartConfig.investment.label} stackId="a" fill={chartConfig.investment.color} />
          {/* Principal Payment */}
          <Bar dataKey="cashFlow" name={chartConfig.cashFlow.label} stackId="a" fill={chartConfig.cashFlow.color} />
          {/* Cash Sweep Payment */}
          <Bar dataKey="taxPaid" name={chartConfig.taxPaid.label} stackId="a" fill={chartConfig.taxPaid.color} />
          {/* Dividends */}
          <Bar dataKey="operations" name={chartConfig.operations.label} stackId="a" fill={chartConfig.operations.color} />
          {/* Debt Outstanding */}
          <Bar dataKey="workingCapital" name={chartConfig.workingCapital.label} stackId="a" fill={chartConfig.workingCapital.color} />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
